import queryString from "query-string";

export function loadRentalPayments(systemId, query) {
  return {
    type: "FETCH_RENTAL_PAYMENTS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/rental-rates/payments?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function fetchRentalPayment(systemId, paymentId) {
  return {
    type: "FETCH_RENTAL_PAYMENT",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/rental-rates/payments/${paymentId}`
      },
      globalLoading: true
    }
  };
}

export function refundRentalPayment(systemId, paymentId) {
  return {
    type: "REFUND_RENTAL_PAYMENT",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/${systemId}/rental-rates/payments/${paymentId}/refund`
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.refund_payment_was_success_message'
        }
      },
      globalLoading: true
    }
  };
}

export function fetchRentalPaymentStatistics(systemId) {
  return {
    type: "FETCH_RENTAL_PAYMENT_STATISTICS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/rental-rates/payments/statistics`
      },
      globalLoading: true
    }
  };
}

export function exportRentalPaymentsToCsv(systemId, query) {
  return {
    type: "EXPORT_RENTAL_PAYMENTS_TO_CSV",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/rental-rates/payments/export/csv?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: true,
      successCallback: (response) => {
        let notification = {
          type: 'SHOW_NOTIFICATION',
          payload: {
            variant: 'success',
            message: 'notifications.export_data_sent_to_email_success_message'
          }
        };
        return response.data ? null : notification
      }
    }
  }
}
