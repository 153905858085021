import React from 'react';

import './BsPanelHeaderActions.scss';

function BsPanelHeaderActions(props) {
    return (
        <div className="bs-header__actions">
            {props.children}
        </div>
    );
}

export default BsPanelHeaderActions;
