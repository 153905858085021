import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {editAdmin, fetchSystemAdmin} from "core/actions/admin-actions";
import {ADMIN} from "core/utils/permissions";
import buildEditAdminForm from "./edit-admin-form";

class EditAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.backUrl = '/system/' + this.props.match.params.systemId + '/admins/' + this.props.match.params.userId + '/info';
    this.props.fetchSystemAdmin(this.props.match.params.systemId, this.props.match.params.userId);
  }

  componentDidMount() {
    BsForm.addValidationRule('requiredRole', (value) => {
      return !!value;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('requiredRole');
  }

  static getDerivedStateFromProps(props, state) {
    if (props.currentAdmin && !state.fields) {
      let formData = {
        fullName: props.currentAdmin.firstName + ' ' + props.currentAdmin.lastName,
        email: props.currentAdmin.email,
        status: props.currentAdmin.status,
        role: props.currentAdmin.role,
        pendingUser: props.currentAdmin.notifications.pendingUser,
        problemReport: props.currentAdmin.notifications.problemReport,
        weeklyReport: props.currentAdmin.notifications.weeklyReport
      };

      return {
        ...state,
        fields: formData,
      }
    }

    return null;
  }

  handleSubmit(fields, e) {
    this.props.editAdmin(this.props.match.params.systemId, this.props.match.params.userId, {
      role: fields.role,
      notifications: {
        pendingUser: fields.pendingUser,
        problemReport: fields.problemReport,
        weeklyReport: fields.weeklyReport
      }
    }, this.backUrl);
  }

  handleChange(fields) {
    this.setState({
      ...this.state,
      fields: fields
    })
  }

  render() {
    if (this.props.currentAdmin) {
      const data = {
        ...this.state.fields
      }

      let formConfig = buildEditAdminForm(this.state.fields.role);
      return (
          <BsFormPanel size="m">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <BsForm
                config={formConfig}
                formData={data}
                onHandleChange={this.handleChange.bind(this)}
                onHandleSubmit={this.handleSubmit.bind(this)}
                cancelAction={
                  <BsFormCancelAction history={this.props.history}
                                      location={this.props.location}
                                      url={this.backUrl}/>
                }/>
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentAdmin: state.admin.currentAdmin
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editAdmin: (systemId, userId, data) => {
      dispatch(editAdmin(systemId, userId, data));
    },
    fetchSystemAdmin: (systemId, userId) => {
      dispatch(fetchSystemAdmin(systemId, userId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAdmin);
