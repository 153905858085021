import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PagePermission from 'components/permission/PagePermission';
import StatisticsPanel from 'components/statistics-panel/StatisticsPanel';
import ConfirmationModel from 'components/model/ConfirmationModel';
import { loadStatistics, selectSystem } from 'core/actions/system-actions';
import { requestMaintenancePlan } from 'core/actions/maintenance-plan-actions';
import MaintenancePlanInfo from 'components/maintenance-panel/MaintenancePlanInfo';
import {ADMIN} from "core/utils/permissions";
import Permitted from "components/permission/Permitted";
import { withTranslation } from 'react-i18next';

import RentalsIcon from 'assets/img/rentals.svg';
import UsersIcon from 'assets/img/users.svg';
import BicyclesIcon from 'assets/img/bicycles.svg';
import CustomerSupportIcon from 'assets/img/customer-support.svg';
import SummarizeIcon from 'assets/img/summarize.svg';

import './Dashboard.scss';

const queryString = require('query-string');

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.props.loadStatistics(this.props.match.params.systemId);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      openConfirmationWindow: false,
    };

    if (+this.props.match.params.systemId !== +this.props.systemId) {
      this.props.selectSystem(this.props.match.params.systemId);
    }
  }

  openActiveRentals() {
    this.props.history.push(
      '/system/' + this.props.match.params.systemId + '/rentals/active'
    );
  }

  openRentalsHistory() {
    this.props.history.push(
      '/system/' + this.props.match.params.systemId + '/rentals/history'
    );
  }

  openActiveUsers() {
    this.props.history.push(
      '/system/' + this.props.match.params.systemId + '/users?status=ACTIVE'
    );
  }

  openPendingUsers() {
    this.props.history.push(
      '/system/' + this.props.match.params.systemId + '/users?status=PENDING'
    );
  }

  openActiveBicycles() {
    this.props.history.push(
      '/system/' +
        this.props.match.params.systemId +
        '/bicycles?status=ACTIVE&status=IN_RENTAL'
    );
  }

  openActiveBicyclesWithBatterySort() {
    this.props.history.push(
      '/system/' +
        this.props.match.params.systemId +
        '/bicycles?direction=asc&sort=powerLevel'
    );
  }

  openMaintenanceBicycles() {
    this.props.history.push(
      '/system/' +
        this.props.match.params.systemId +
        '/bicycles?status=IN_MAINTENANCE'
    );
  }

  openOpenProblems() {
    this.props.history.push(
      '/system/' +
        this.props.match.params.systemId +
        '/customer-support?status=CREATED&status=ASSIGNED&status=IN_PROGRESS'
    );
  }

  openClosedProblems() {
    this.props.history.push(
      '/system/' +
        this.props.match.params.systemId +
        '/customer-support?status=DONE&status=CLOSED'
    );
  }

  openReportedProblems() {
    this.props.history.push(
      '/system/' +
        this.props.match.params.systemId +
        '/maintenance-reports?status=OPEN'
    );
  }

  openCompletedReports() {
    this.props.history.push(
      '/system/' +
        this.props.match.params.systemId +
        '/maintenance-reports?status=COMPLETED'
    );
  }

  openMaintenancePlan() {
    this.props.history.push(
      '/system/' + this.props.match.params.systemId + '/settings/fleet'
    );
  }

  onGetOffer() {
    this.setState({
      ...this.state,
      openConfirmationWindow: true,
    });
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      openConfirmationWindow: false,
    });
  }

  getOffer() {
    this.props.requestMaintenancePlan(this.props.match.params.systemId);
    this.closeConfirmationModel();
  }

  render() {
    if (this.props.statistics) {
      return (
        <Grid container className='dashboard-statistics'>
          <PagePermission anonymous={false} />

          <ConfirmationModel
            open={this.state.openConfirmationWindow}
            message='dashboard.need_maintenance_plan_send_email_message'
            handleClose={this.closeConfirmationModel.bind(this)}
            handleApply={this.getOffer.bind(this)}
            yesBtn='dashboard.send_request'
          />
          <Permitted for={ADMIN}>
            <StatisticsPanel
              data={{
                title: {
                  icon: <RentalsIcon />,
                  label: 'dashboard.rentals_title',
                },
                first: {
                  label: 'dashboard.active_rentals',
                  value: this.props.statistics.activeRentals,
                  onClick: this.openActiveRentals.bind(this),
                },
                second: {
                  label: 'dashboard.completed_rentals',
                  value: this.props.statistics.completedRentals,
                  onClick: this.openRentalsHistory.bind(this),
                },
              }}
            />
            <StatisticsPanel
              data={{
                title: {
                  icon: <UsersIcon />,
                  label: 'dashboard.users_title',
                },
                first: {
                  label: 'dashboard.active_users',
                  value: this.props.statistics.activeUsers,
                  onClick: this.openActiveUsers.bind(this),
                },
                second: {
                  label: 'dashboard.pending_users',
                  value: this.props.statistics.pendingUsers,
                  onClick: this.openPendingUsers.bind(this),
                },
              }}
            />
          </Permitted>
          <StatisticsPanel
            data={{
              title: {
                icon: <BicyclesIcon />,
                label: 'dashboard.bicycles_title',
              },
              first: {
                label: 'dashboard.active_bicycles',
                value: this.props.statistics.activeBicycles,
                onClick: this.openActiveBicycles.bind(this),
                warning:
                  this.props.t('dashboard.critical_battery') +
                  this.props.statistics.locksWithCriticalBattery,
                onWarningClick:
                  this.openActiveBicyclesWithBatterySort.bind(this),
              },
              second: {
                label: 'dashboard.maintenance_bicycles',
                value: this.props.statistics.maintenanceBicycles,
                onClick: this.openMaintenanceBicycles.bind(this),
              },
            }}
          />
          <StatisticsPanel
            data={{
              title: {
                icon: <CustomerSupportIcon />,
                label: 'dashboard.customer_support_title',
              },
              first: {
                label: 'dashboard.open_problems',
                value: this.props.statistics.openProblems,
                onClick: this.openOpenProblems.bind(this),
                valueStyle: {
                  color: '#f44336',
                },
              },
              second: {
                label: 'dashboard.closed_problems',
                value: this.props.statistics.closedProblems,
                onClick: this.openClosedProblems.bind(this),
              },
            }}
          />

          <Permitted for={ADMIN}>
            <StatisticsPanel
              data={{
                title: {
                  icon: <SummarizeIcon />,
                  label: 'dashboard.maintenance_reports_title',
                },
                first: {
                  label: 'dashboard.reported_problems',
                  value: this.props.statistics.reportedProblems,
                  onClick: this.openReportedProblems.bind(this),
                  valueStyle: {
                    color: '#f44336',
                  },
                },
                second: {
                  label: 'dashboard.completed_reports',
                  value: this.props.statistics.completedReports,
                  onClick: this.openCompletedReports.bind(this),
                },
              }}
            />
            <MaintenancePlanInfo
              plan={this.props.statistics.activeMaintenancePlan}
              offerFleetIntegration={this.props.statistics.offerFleetIntegration}
              onOpenMaintenancePlan={this.openMaintenancePlan.bind(this)}
              onGetOffer={this.onGetOffer.bind(this)}
            />
          </Permitted>
        </Grid>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    statistics: state.system.statistics,
    bicycles: state.bicycle.bicycles,
    loadingBicycles: state.bicycle.loading,
    systemId: state.system.systemId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadStatistics: (systemId) => {
      dispatch(loadStatistics(systemId));
    },
    requestMaintenancePlan: (systemId) => {
      dispatch(requestMaintenancePlan(systemId));
    },
    selectSystem: (systemId) => {
      dispatch(selectSystem(systemId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Dashboard));
