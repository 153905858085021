import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import PropTypes from "prop-types";
import {getCurrentPath} from "core/utils/pathUtils";

const PageVtPermission = (props) => {
    // only admin pages for signed in user
    if (props.anonymous && props.isLoggedIn) {
        return <Redirect to="/systems"/>
    }

    // only home page for anonymous
    if (!props.anonymous && !props.isLoggedIn) {
        return <Redirect to={"/sign-in?redirectTo=" + getCurrentPath()}/>
    }

    // has no permissions
    if (!props.isPermitted) {
        return <Redirect to="/systems"/>
    }

    return null
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.security.isLoggedIn,
        isPermitted: state.profile.profile && state.profile.profile.role === 'VT_SUPER_ADMIN'
    };
};

PageVtPermission.propTypes = {
    anonymous: PropTypes.bool,
    permission: PropTypes.string
};

export default connect(mapStateToProps)(PageVtPermission);
