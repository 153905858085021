import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {fetchSystemConfig} from "core/actions/system-config-actions";
import {ADMIN} from "core/utils/permissions";
import UserSettingsActions from "./UserSettingsActions";
import buildUserConfigInfoForm from "./user-config-form";

import './UserSettings.scss';

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    props.fetchSystemConfig(props.match.params.systemId);
  }

  onHandleEditSettings() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-settings/user')
  }

  onHandleEditHelp() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-settings/general-help')
  }

  onShowSystemPasswords() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-settings/passwords')
  }

  render() {
    if (this.props.systemConfig) {
      const actions = {};
      if (this.props.systemConfig.clientPassword === true) {
        actions.clientPassword = this.onShowSystemPasswords.bind(this);
      }

      const formConfig = buildUserConfigInfoForm(actions, this.props.systemConfig.clientAcceptancePolicy, this.props.systemConfig.rentalRates);
      formConfig.title = {
        actions: <UserSettingsActions onHandleEditUserSettings={this.onHandleEditSettings.bind(this)}
                                      onHandleEditHelp={this.onHandleEditHelp.bind(this)}
                                      config={this.props.systemConfig}/>
      }

      const data = {
        ...(this.props.systemConfig.clientAcceptancePolicy ? this.props.systemConfig.clientAcceptancePolicy : {}),
        needClientPayment: this.props.systemConfig.needClientPayment,
        clientPassword: this.props.systemConfig.clientPassword,
        countOfPasswords: this.props.systemConfig.countOfPasswords,
        maxOpenRentals: this.props.systemConfig.maxOpenRentals
      };

      let help;
      if (this.props.systemConfig.generalHelpPreview) {
        help = <div className="general-help__content"
                    dangerouslySetInnerHTML={{__html: this.props.systemConfig.generalHelpPreview}}/>;
      } else {
        help = <div className="general-help__no-data">
          No general help
        </div>
      }

      return (
          <div className="bs-form-container">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <BsForm
                config={formConfig}
                formData={data}
                disabled={true}/>

            {help}
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    systemConfig: state.systemConfig.systemConfig
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
