import queryString from "query-string";

export function fetchSubscriptionTransactions(systemId, query) {
  return {
    type: "FETCH_SUBSCRIPTION_TRANSACTIONS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/system-subscriptions/invoices?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function paySystemSubscriptionInvoice(systemId, invoiceId, data, callback) {
  return {
    type: "UPDATE_SUBSCRIPTION_TRANSACTION",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/${systemId}/system-subscriptions/invoices/${invoiceId}`,
        data: data
      },
      successCallback: (response) => {
        callback()
        return {
          type: 'SHOW_NOTIFICATION',
          payload: {
            variant: 'success',
            message: 'notifications.pay_system_subscription_success_message'
          }
        }
      },
      globalLoading: true
    }
  };
}
