import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {loadAllLocations} from "core/actions/location-actions";
import {createBicycle} from "core/actions/bicycle-actions";
import ADD_BICYCLE_FORM from "./add-bicycle-form";
import NoLocationsForm from "./NoLocationsForm";

import "./add-bicycle.scss";

class AddBicycle extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.backUrl = '/system/' + this.props.match.params.systemId + '/bicycles';
    this.fields = {};
    this.props.loadAllLocations(this.props.match.params.systemId);
  }

  componentDidMount() {
    BsForm.addValidationRule('requiredLocation', (value) => {
      return !!this.fields.location;
    });
    BsForm.addValidationRule('macAddress', (value) => {
      return !this.fields.lockMacAddress || this.fields.lockMacAddress.replaceAll('_', "").length === 17;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('requiredLocation');
    BsForm.removeValidationRule('macAddress');
  }

  handleSubmit(fields, e) {
    this.props.createBicycle(this.props.match.params.systemId, {
      number: fields.number,
      model: fields.model ? fields.model : undefined,
      serialNumber: fields.serialNumber.toUpperCase(),
      lockMacAddress: fields.lockMacAddress ? fields.lockMacAddress : null,
      locationId: fields.location,
      ulockKey: fields.ulockKey ? fields.ulockKey : null,
      status: 'ACTIVE'
    });
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    if (this.props.allLocations && !this.props.loadingLocations) {
      const config = {
        ...ADD_BICYCLE_FORM,
        fields: []
      }
      ADD_BICYCLE_FORM.fields.forEach(field => {
        if (field.name === 'location') {
          config.fields.push({
            ...field,
            items: this.props.allLocations.map(location => {
              return {
                ...location,
                value: location.id
              }
            })
          })
        } else {
          config.fields.push(field)
        }
      })

      return (
          <BsFormPanel size="s">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <BsForm
                config={config}
                onHandleSubmit={this.handleSubmit.bind(this)}
                onHandleChange={this.handleChange.bind(this)}
                cancelAction={
                  <BsFormCancelAction history={this.props.history}
                                      location={this.props.location}
                                      url={this.backUrl}/>
                }
            />
          </BsFormPanel>
      );
    } else if (!this.props.allLocations && !this.props.loadingLocations) {
      return <NoLocationsForm/>;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    allLocations: state.location.allLocations,
    loadingLocations: state.location.loadingAllLocations
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllLocations: (systemId) => {
      dispatch(loadAllLocations(systemId));
    },
    createBicycle: (systemId, data) => {
      dispatch(createBicycle(systemId, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBicycle);
