const SUBSCRIPTION_PLAN_INFO_FORM = {
  fields: [
    {
      name: 'description',
      label: 'subscription_plan_info.description_field',
      type: 'info',
      getValue: (data) => {
        return data.description
      }
    },
    {
      name: 'billingPeriod',
      label: 'subscription_plan_info.billing_period_field',
      type: 'info',
      getValue: (data) => {
        return 'subscription_billing_period.' + data.billingPeriod
      },
      translate: true
    },
    {
      name: 'pricePerBike',
      label: 'subscription_plan_info.price_per_bike_field',
      type: 'price',
      currency: 'currency',
      getValue: (data) => {
        return '' + data.pricePerBike
      }
    },
    {
      name: 'autoCancellation',
      label: 'subscription_plan_info.auto_cancellation_field',
      type: 'info',
      getValue: (data) => {
        return data.autoCancellation ? data.autoCancellation : '-'
      }
    }
  ]
}

export default SUBSCRIPTION_PLAN_INFO_FORM;
