import Moment from "moment";

export const TABLE_PASSWORD_USAGE = {
  columns: [
    {
      label: 'password_info.client_full_name_header',
      field: 'client.firstName,client.lastName',
      getValue: (raw) => {
        return raw.client.firstName + ' ' + raw.client.lastName
      },
      ordering: true
    },
    {
      label: 'password_info.client_email_header',
      field: 'client.email',
      getValue: (raw) => {
        return raw.client.email
      },
      ordering: true
    },
    {
      label: 'password_info.bicycle_header',
      field: 'bike.number',
      getValue: (raw) => {
        return "# " + raw.bike.number
      },
      ordering: true
    },
    {
      label: 'password_info.password_used_at_header',
      field: 'createdAt',
      ordering: true,
      getValue(raw) {
        return Moment(raw.createdAt).format('DD MMM YYYY');
      }
    }
  ]
}
