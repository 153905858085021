const queryString = require('query-string');

export function addressAutocomplete(query) {
    return {
        type: "ADDRESS_AUTOCOMPLETE",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/geocode/autocomplete?`+ queryString.stringify(query)
            }
        }
    };
}

export function getAddressDetails(placeId) {
    return {
        type: "FETCH_ADDRESS_DETAILS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/geocode/by-place-id/${placeId}`
            }
        }
    };
}

export function getAddressDetailsByLatLng(lat, lng) {
    return {
        type: "FETCH_ADDRESS_DETAILS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/geocode/by-lat-lng?lat=${lat}&lng=${lng}`
            }
        }
    };
}