import React from 'react';
import { useTranslation } from 'react-i18next';

import './statistics-item.scss';

const StatisticsItem = (props) => {
  const { t } = useTranslation();

  return (
    <div className='statistic-item'>
      <div className='statistic-item__title'>{t(props.label)}</div>
      <div
        className='statistic-item__value'
        onClick={props.onClick}
        style={props.valueStyle}
      >
        {props.value}
      </div>
      <div className='statistic-item__warning' onClick={props.onWarningClick}>
        {props.warning}
      </div>
    </div>
  );
};

export default StatisticsItem;
