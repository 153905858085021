const buildUserConfigInfoForm = (actions, policy, enablePayment) => {
  const userConfigForm = {
    fields: [
      {
        name: 'maxOpenRentals',
        label: 'rental_config.max_open_rentals_field',
        type: 'info',
        styles: {
          maxWidth: '500px',
          marginTop: '2em'
        },
        getValue: (data) => {
          return data.maxOpenRentals ? (data.maxOpenRentals + '') : 'general.none';
        }
      },
      {
        name: 'max_open_rental_delimiter',
        type: 'delimiter',
        styles: {
          maxWidth: '500px'
        }
      },
      {
        name: 'enable',
        label: 'user_config.need_client_payment_field',
        type: 'info',
        styles: {
          maxWidth: '500px'
        },
        translate: true,
        getValue: (data) => {
          return data && data.needClientPayment ? 'general.yes' : 'general.no';
        },
        showFn: () => {
          return !enablePayment
        }
      },
      {
        name: 'client_password_delimiter',
        type: 'delimiter',
        styles: {
          maxWidth: '500px'
        },
        showFn: () => {
          return !enablePayment
        }
      },
      {
        name: 'clientPassword',
        label: 'user_config.client_password_field',
        type: 'info',
        styles: {
          maxWidth: '500px'
        },
        translate: true,
        getValue: (data) => {
          return data && data.clientPassword ? data.countOfPasswords + ' Active Passwords' : 'general.no';
        },
        action: actions.clientPassword,
        showFn: () => {
          return !enablePayment
        }
      },
      {
        name: 'client_payment_delimiter',
        type: 'delimiter',
        styles: {
          maxWidth: '500px'
        },
        showFn: () => {
          return !enablePayment
        }
      },
      {
        name: 'enable',
        label: 'user_config.enable_field',
        type: 'info',
        styles: {
          maxWidth: '500px'
        },
        translate: true,
        getValue: (data) => {
          return data && data.enable ? 'general.yes' : 'general.no';
        }
      }
    ]
  }

  if (policy && !enablePayment) {
    if (policy.enable) {
      if (policy.acceptAll) {
        userConfigForm.fields.push({
          name: 'accept_all_delimiter',
          type: 'delimiter',
          styles: {
            maxWidth: '500px'
          }
        });
        userConfigForm.fields.push({
          name: 'acceptAll',
          label: 'user_config.accept_all_field',
          type: 'info',
          styles: {
            maxWidth: '500px'
          },
          translate: true,
          getValue: (data) => {
            return data.acceptAll ? 'general.yes' : 'general.no';
          }
        });
      } else if (policy.emailPolicy && policy.emailPolicy.enable) {
        userConfigForm.fields.push({
          name: 'email_policy_delimiter',
          type: 'delimiter',
          styles: {
            maxWidth: '500px'
          }
        });
        userConfigForm.fields.push({
          name: 'emailPolicy',
          label: 'user_config.email_policy_field',
          type: 'info',
          styles: {
            maxWidth: '500px'
          },
          getValue: (data) => {
            return data.emailPolicy && data.emailPolicy.emails ? data.emailPolicy.emails.join(', ') : '';
          }
        });
      }
    }
  }

  userConfigForm.fields.push({
    name: 'help_delimiter',
    type: 'delimiter',
    styles: {
      maxWidth: '500px'
    }
  });
  userConfigForm.fields.push({
    name: 'helpTitle',
    label: 'user_config.help_field',
    type: 'info',
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return '';
    }
  });
  userConfigForm.fields.push({
    name: 'help_message_delimiter',
    type: 'delimiter',
    styles: {
      maxWidth: '500px'
    }
  });

  return userConfigForm
}

export default buildUserConfigInfoForm;
