const LABEL_ADMIN_INFO_FORM = {
  fields: [
    {
      name: 'fullName',
      label: 'label_admin_info.full_name_field',
      type: 'info',
      styles: {
        maxWidth: '500px',
        marginTop: '2em'
      },
      getValue: (data) => {
        return data.firstName + ' ' + data.lastName
      },
    },
    {
      name: 'email',
      label: 'label_admin_info.email_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      }
    },
  ]
}

export default LABEL_ADMIN_INFO_FORM;
