import React from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import './BsFooter.scss';

const BsFooter = (props) => {
    const {t} = useTranslation();

    const version = props.appVersion ? 'v' + props.appVersion : '';

    return (
        <footer className="footer">
            <div className="footer-content">
                <hr className="footer-content__delimiter"/>
                <div className="footer-content__copyright">
                    {t('general.copyright', {year: new Date().getFullYear()})} {version}
                </div>
            </div>
        </footer>
    );
}


const mapStateToProps = (state) => {
    return {
        appVersion: state.app.appVersion
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BsFooter);
