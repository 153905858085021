import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import BsTableHead from "./BsTableHead";
import BsTableBody from "./BsTableBody";
import BsTablePagination from "./BsTablePagination";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import './BsDataTable.scss';

const queryString = require('query-string');

const BsDataTable = (props) => {
    const {t} = useTranslation();

    const handleSort = (field) => {
        const params = queryString.parse(props.location.search);
        const isAsc = params[props.orderConfig ? props.orderConfig.sort : 'sort'] === field
            && params[props.orderConfig ? props.orderConfig.direction : 'direction'] === 'asc';
        params[props.orderConfig ? props.orderConfig.sort : 'sort'] = field;
        params[props.orderConfig ? props.orderConfig.direction : 'direction'] = isAsc ? 'desc' : 'asc';
        props.history.push('?' + queryString.stringify(params));

        props.onRequestSort(params);
    }

    let paging = null;
    if (props.onChangePage) {
        const handleChangeSize = (size) => {
            const params = queryString.parse(props.location.search);
            params[props.pagingConfig ? props.pagingConfig.size : 'size'] = size;
            params[props.pagingConfig ? props.pagingConfig.page : 'page'] = '0';
            props.history.push('?' + queryString.stringify(params));
            props.onChangeSize(params);
        }

        const handleChangePage = (page) => {
            const params = queryString.parse(props.location.search);
            params[props.pagingConfig ? props.pagingConfig.page : 'page'] = page;
            props.history.push('?' + queryString.stringify(params));
            props.onChangePage(params);
        }

        paging = <BsTablePagination content={props.content}
                                    onChangePage={handleChangePage}
                                    onChangeSize={handleChangeSize}/>
    }

    return (
        <TableContainer>
            <Table size="medium">
                <BsTableHead settings={props.settings}
                             order={props.order}
                             orderBy={props.orderBy}
                             onRequestSort={handleSort}/>
                <BsTableBody settings={props.settings}
                             content={props.content}
                             onRowClick={props.onRowClick}/>
                {paging}
            </Table>

            <div className="bs-datatable__footer-info">{t(props.settings.footerLabel)}</div>
        </TableContainer>
    )
}

BsTableBody.propTypes = {
    history: PropTypes.object,
    content: PropTypes.object,
    settings: PropTypes.object,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    onRequestSort: PropTypes.func,
    onChangeSize: PropTypes.func,
    onChangePage: PropTypes.func
};

export default BsDataTable;
