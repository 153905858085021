export function fetchSystemConfig(systemId) {
    return {
        type: "LOAD_SYSTEM_CONFIG",
        payload: {
            request: {
                method: 'get',
                url: '/api/v1/bo/systems/' + systemId + '/config'
            },
            globalLoading: true
        }
    };
}

export function updateSystemConfig(systemId, data, redirectPath) {
    return {
        type: "UPDATE_RENTAL_SYSTEM_CONFIG",
        payload: {
            request: {
                method: 'put',
                url: '/api/v1/bo/systems/' + systemId + '/config/rentals',
                data: data
            },
            redirect: redirectPath,
            globalLoading: true
        }
    };
}

export function updateSystemTos(systemId, data, redirectPath) {
    return {
        type: "UPDATE_TOS_SYSTEM_CONFIG",
        payload: {
            request: {
                method: 'put',
                url: '/api/v1/bo/systems/' + systemId + '/config/terms-of-services',
                data: data
            },
            redirect: redirectPath,
            globalLoading: true
        }
    };
}

export function updateSystemGeneralHelp(systemId, data, redirectPath) {
    return {
        type: "UPDATE_GENERAL_HELP_SYSTEM_CONFIG",
        payload: {
            request: {
                method: 'put',
                url: '/api/v1/bo/systems/' + systemId + '/config/general-help',
                data: data
            },
            redirect: redirectPath,
            globalLoading: true
        }
    };
}

export function updateClientPolicy(systemId, data, redirectPath) {
    return {
        type: "UPDATE_TOS_SYSTEM_CONFIG",
        payload: {
            request: {
                method: 'put',
                url: '/api/v1/bo/systems/' + systemId + '/config/client-policy',
                data: data
            },
            redirect: redirectPath,
            globalLoading: true
        }
    };
}

export function uploadSystemLogo(systemId, logo, callback) {
    const formDara = new FormData();
    formDara.append('file', logo.file, logo.file.name)
    return {
        type: "UPDATE_SYSTEM_LOGO",
        payload: {
            request: {
                method: 'post',
                url: '/api/v1/bo/systems/' + systemId + '/logo/upload',
                data: formDara
            },
            globalLoading: true,
            successCallback: (response) => {
                callback()
                return {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.upload_logo_success_message'
                    }
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.upload_logo_error_message'
                }
            }
        }
    };
}

export function changePaymentProvider(systemId, data) {
    return {
        type: "UPDATE_SYSTEM_PAYMENT_PROVIDER",
        payload: {
            request: {
                method: 'post',
                url: '/api/v1/bo/systems/' + systemId + '/config/payment-provider',
                data: data
            },
            globalLoading: true,
            successCallback: (response) => {
                return {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.update_payment_provider_success_message'
                    }
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.update_payment_provider_error_message'
                }
            }
        }
    };
}

export function changeTaxConfig(systemId, data) {
    return {
        type: "UPDATE_SYSTEM_TAX_CONFIG",
        payload: {
            request: {
                method: 'post',
                url: '/api/v1/bo/systems/' + systemId + '/config/taxes',
                data: data
            },
            globalLoading: true,
            successCallback: (response) => {
                return {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.update_tax_config_success_message'
                    }
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.update_tax_config_error_message'
                }
            }
        }
    };
}
