import {BICYCLE_MODELS} from "core/utils/bicycleModels";

const ADD_BICYCLE_FORM = {
    title: {
        text: 'add_bicycle.title'
    },
    submit: {
        label: 'add_bicycle.submit_btn'
    },
    fields: [
        {
            name: 'number',
            label: 'add_bicycle.number_field',
            type: 'text',
            validators: [
                'required',
                'matchRegexp:^[0-9]{1,100}$',
                'minNumber:1',
                'maxNumber:999999999'
            ],
            errors: [
                'errors.required_number',
                'errors.invalid_number_format',
                'errors.min_number_error',
                'errors.max_number_error'
            ],
            defaultValue: ''
        },
        {
            name: 'model',
            label: 'add_bicycle.model_field',
            type: 'select',
            items: BICYCLE_MODELS,
            errors: [],
            defaultValue: ''
        },
        {
            name: 'serialNumber',
            label: 'add_bicycle.serial_number_field',
            type: 'text',
            validators: [
                'required',
                'minStringLength:5'
            ],
            errors: [
                'errors.required_serial_number',
                'errors.invalid_serial_number_format'
            ],
            defaultValue: ''
        },
        {
            name: 'location',
            label: 'add_bicycle.location_field',
            type: 'select',
            items: [],
            validators: ['requiredLocation'],
            errors: ['errors.required_location'],
            defaultValue: ''
        },
        {
            name: 'lockMacAddress',
            label: 'add_bicycle.lock_field',
            type: 'masked-text',
            mask: '**:**:**:**:**:**',
            formatChars: {
                '9': '[0-9]',
                'a': '[A-Fa-f]',
                '*': '[1-9A-Fa-f]'
            },
            validators: [
                'macAddress'
            ],
            errors: [
                'errors.invalid_lock_format'
            ],
            defaultValue: ''
        },
        {
            name: 'ulockKey',
            label: 'add_bicycle.u_lock_key_field',
            type: 'text',
            defaultValue: '',
            validators: [],
            errors: []
        }
    ]
}

export default ADD_BICYCLE_FORM;
