import React from "react";
import BsCancelBtn from "../../routing/BsBackBtn";
import BsFormActionCancelContainer from "./BsFormActionCancelContainer";
import PropTypes from "prop-types";
import BsFormSubmitAction from "./BsFormSubmitAction";

const BsFormCancelAction = (props) => {
    return (
        <BsFormActionCancelContainer>
            <BsCancelBtn history={props.history}
                         location={props.location}
                         url={props.url}/>
        </BsFormActionCancelContainer>
    )
}

BsFormSubmitAction.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    url: PropTypes.string
};

export default BsFormCancelAction;