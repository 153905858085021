import {priceToString} from "../../../../../../core/utils/priceUtils";

const buildRentalRatePaymentsTable = (showTaxes) => {
  const form = {
    columns: [
      {
        label: 'rental_rates.name_header',
        field: 'name',
        ordering: true
      },
      {
        label: 'rental_rates.client_header',
        field: 'client',
        ordering: true,
        getValue(raw) {
          return raw.client.firstName + " " + raw.client.lastName;
        }
      },
      {
        label: 'rental_rates.price_header',
        field: 'totalAmount',
        ordering: true,
        getValue(raw) {
          return priceToString(raw.totalAmount, raw.totalAmountCurrency);
        }
      }
    ]
  }

  if (showTaxes) {
    form.columns.push({
      label: 'rental_rates.tax_header',
      field: 'taxAmount',
      ordering: true,
      getValue(raw) {
        return priceToString(raw.taxAmount, raw.taxCurrency) + ' (' + raw.taxPercent + '%)';
      }
    })
  }

  form.columns.push({
    label: 'rental_rates.paid_at_header',
    field: 'paidAt',
    ordering: true,
    getValue(raw) {
      return raw.paidAt;
    }
  });
  form.columns.push({
    label: 'rental_rates.rental_duration_header',
    field: 'rentalDuration',
    getValue: (raw) => {
      let unit = raw.rentalDuration.unit.toLowerCase();
      return raw.rentalDuration.amount + ' ' + unit.charAt(0).toUpperCase() + unit.slice(1);
    },
    ordering: false
  });
  form.columns.push({
    label: 'rental_rates.type_header',
    field: 'type',
    translate: true,
    getValue(raw) {
      return 'rental_rate_type.' + raw.type;
    }
  });
  form.columns.push({
    label: 'rental_rates.status_header',
    field: 'status',
    translate: true,
    getValue(raw) {
      return 'rental_payment_status.' + raw.status;
    }
  });

  return form;
}

export default buildRentalRatePaymentsTable;