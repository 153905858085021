import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import ADD_SUBSCRIPTION_PLANS_FORM from "./add-subscription-plans-form";
import {createSubscriptionPlan} from "core/actions/subscription-plan-actions";

import './add-subscription-plan.scss';

class AddSubscriptionPlan extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.backUrl = '/global/subscriptions/plans';
    this.fields = {};
  }

  handleSubmit(fields, e) {
    this.props.createSubscriptionPlan({
      name: fields.name,
      description: fields.description,
      billingPeriod: fields.billingPeriod,
      pricePerBike: fields.pricePerBike,
      currency: fields.currency,
      autoCancellation: fields.autoCancellation ? fields.autoCancellation : null
    });
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    return (
      <div className="add-subscription-plan">
        <BsFormPanel size="s">
          <PagePermission anonymous={false} permission={ADMIN}/>
          <BsForm
            config={ADD_SUBSCRIPTION_PLANS_FORM}
            onHandleSubmit={this.handleSubmit.bind(this)}
            onHandleChange={this.handleChange.bind(this)}
            cancelAction={
              <BsFormCancelAction history={this.props.history}
                                  location={this.props.location}
                                  url={this.backUrl}/>
            }
          />
        </BsFormPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSubscriptionPlan: (data) => {
      dispatch(createSubscriptionPlan(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSubscriptionPlan);
