import React from "react";

const BsFormActions = (props) => {
    return (
        <div className="bs-form__actions">
            {props.children}
        </div>
    )
}

export default BsFormActions;