import React from 'react';
import {Route} from "react-router-dom";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BsPanel from "components/panel/BsPanel";
import PagePermission from "components/permission/PagePermission";
import LocationInfoTab from "./tabs/info/LocationInfoTab";
import LocationBicyclesTab from "./tabs/bicycles/LocationBicyclesTab";
import LocationRentalsTab from "./tabs/rentals/LocationRentalsTab";
import {ADMIN} from "core/utils/permissions";

function LocationInfo(props) {
    const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
    const defaultTab = lastSegment.split('?')[0]
    const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
    const {t} = useTranslation();

    const handleChange = (event, newValue) => {
        if (value !== newValue) {
            props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
        }
        setValue(newValue);
    };

    let tabs = null;
    if (props.profile && props.system && props.system.role) {
        tabs = (
            <Tabs value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary">
                <Tab label={t('location_info.info_tab')} value="info"/>
                <Tab label={t('location_info.bicycles_tab')} value="bicycles"/>
                <Tab label={t('location_info.rentals_tab')} value="rentals"/>
            </Tabs>
        )
    }

    return (
        <>
            <PagePermission anonymous={false} permission={ADMIN}/>
            <BsPanel>
                {tabs}
                <Route exact path="/system/:systemId/location/:locationId" component={LocationInfoTab}/>
                <Route path="/system/:systemId/location/:locationId/info" component={LocationInfoTab}/>
                <Route path="/system/:systemId/location/:locationId/bicycles" component={LocationBicyclesTab}/>
                <Route path="/system/:systemId/location/:locationId/rentals" component={LocationRentalsTab}/>
            </BsPanel>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile.profile,
        system: state.system.currentSystem
    };
};

export default connect(mapStateToProps)(LocationInfo);
