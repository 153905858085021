import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PagePermission from 'components/permission/PagePermission';
import StatisticsPanel from 'components/statistics-panel/StatisticsPanel';
import { withTranslation } from 'react-i18next';
import { loadLabelsStatistics } from 'core/actions/statistics-actions';

import RentalsIcon from 'assets/img/rentals.svg';
import UsersIcon from 'assets/img/users.svg';
import BicyclesIcon from 'assets/img/bicycles.svg';
import CustomerSupportIcon from 'assets/img/customer-support.svg';

import './LabelStatistics.scss';

class LabelStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.props.loadLabelsStatistics(this.props.match.params.labelId);
  }

  openActiveRentals() {
    this.props.history.push(
      '/label/' + this.props.match.params.labelId + '/rentals?status=ACTIVE'
    );
  }

  openRentalsHistory() {
    this.props.history.push(
      '/label/' + this.props.match.params.labelId + '/rentals?status=FINISHED'
    );
  }

  openActiveUsers() {
    this.props.history.push(
      '/label/' + this.props.match.params.labelId + '/users?status=ACTIVE'
    );
  }

  openActiveBicycles() {
    this.props.history.push(
      '/label/' +
        this.props.match.params.labelId +
        '/bicycles?status=ACTIVE&status=IN_RENTAL'
    );
  }

  openActiveBicyclesWithBatterySort() {
    this.props.history.push(
      '/label/' +
        this.props.match.params.labelId +
        '/bicycles?direction=asc&sort=powerLevel'
    );
  }

  openMaintenanceBicycles() {
    this.props.history.push(
      '/label/' +
        this.props.match.params.labelId +
        '/bicycles?status=IN_MAINTENANCE'
    );
  }

  openOpenProblems() {
    this.props.history.push(
      '/label/' +
        this.props.match.params.labelId +
        '/reported-problems?status=CREATED&status=ASSIGNED&status=IN_PROGRESS'
    );
  }

  openClosedProblems() {
    this.props.history.push(
      '/label/' +
        this.props.match.params.labelId +
        '/reported-problems?status=DONE&status=CLOSED'
    );
  }

  render() {
    if (this.props.statistics) {
      return (
        <Grid container className='label-statistics'>
          <PagePermission anonymous={false} />
          <StatisticsPanel
            data={{
              title: {
                icon: <RentalsIcon />,
                label: 'dashboard.rentals_title',
              },
              first: {
                label: 'dashboard.active_rentals',
                value: this.props.statistics.activeRentals,
                onClick: this.openActiveRentals.bind(this),
              },
              second: {
                label: 'dashboard.completed_rentals',
                value: this.props.statistics.completedRentals,
                onClick: this.openRentalsHistory.bind(this),
              },
            }}
          />
          <StatisticsPanel
            data={{
              title: {
                icon: <UsersIcon />,
                label: 'dashboard.users_title',
              },
              first: {
                label: 'dashboard.active_users',
                value: this.props.statistics.activeUsers,
                onClick: this.openActiveUsers.bind(this),
              },
            }}
          />
          <StatisticsPanel
            data={{
              title: {
                icon: <BicyclesIcon />,
                label: 'dashboard.bicycles_title',
              },
              first: {
                label: 'dashboard.active_bicycles',
                value: this.props.statistics.activeBicycles,
                onClick: this.openActiveBicycles.bind(this),
                warning:
                  this.props.t('dashboard.critical_battery') +
                  this.props.statistics.locksWithCriticalBattery,
                onWarningClick:
                  this.openActiveBicyclesWithBatterySort.bind(this),
              },
              second: {
                label: 'dashboard.maintenance_bicycles',
                value: this.props.statistics.maintenanceBicycles,
                onClick: this.openMaintenanceBicycles.bind(this),
              },
            }}
          />
          <StatisticsPanel
            data={{
              title: {
                icon: <CustomerSupportIcon />,
                label: 'dashboard.customer_support_title',
              },
              first: {
                label: 'dashboard.open_problems',
                value: this.props.statistics.openProblems,
                onClick: this.openOpenProblems.bind(this),
                valueStyle: {
                  color: '#f44336',
                },
              },
              second: {
                label: 'dashboard.closed_problems',
                value: this.props.statistics.closedProblems,
                onClick: this.openClosedProblems.bind(this),
              },
            }}
          />
        </Grid>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    statistics: state.statistics.labelStatistics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadLabelsStatistics: (labelId) => {
      dispatch(loadLabelsStatistics(labelId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LabelStatistics));
