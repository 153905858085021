import React from "react";
import {connect} from "react-redux";
import BsValidatorForm from "components/form/BsValidatorForm";
import BsFormTitle from "components/form/title/BsFormTitle";
import BsFormPanel from "components/form/BsFormPanel";
import ConfirmationModel from "components/model/ConfirmationModel";
import MaintenanceVisitInfoMainDesc from "./MaintenanceVisitInfoMainDesc";
import MaintenanceVisitInfoFormActions from "./MaintenanceVisitInfoFormActions";
import ChangeMechanicModel from "../../../models/ChangeMechanicModel";
import ChangeServiceModel from "../../../models/ChangeServiceModel";
import {
  refreshMaintenanceVisit,
  updateMaintenanceExternalVisit,
  updateMaintenanceVisitMechanic,
  updateMaintenanceVisitService,
  updateMaintenanceVisitTime,
  cancelMaintenanceVisit
} from "core/actions/maintenance-plan-actions";
import {loadAllAdmins} from "core/actions/admin-actions";
import {loadServices} from "core/actions/service-actions";
import ChangeServiceExpectedTimeModel from "./ChangeServiceExpectedTimeModel";
import ChangeMaintenanceExternalVisitModel from "./ChangeMaintenanceExternalVisitModel";

class MaintenanceVisitInfoForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openChangeMechanicModel: false,
      openChangeServiceModel: false,
      openChangeVisitTimeModel: false,
      openChangeExternalIdsModel: false,
      confirmationWindow: {
        open: false,
        message: '',
        event: () => {
        }
      },
    };
  }

  onHandleChangeMechanic() {
    this.props.loadAllAdmins(this.props.visit.system.id);

    this.setState({
      ...this.state,
      openChangeMechanicModel: true
    });
  }

  closeChangeMechanicModel() {
    this.setState({
      ...this.state,
      openChangeMechanicModel: false
    });
  }

  submitChangeMechanicModel(mechanic) {
    const data = {mechanicId: mechanic};
    this.props.updateMaintenanceVisitMechanic(this.props.visit.planId, this.props.visit.number, data);
    this.closeChangeMechanicModel();
  }

  onHandleChangeService() {
    this.props.loadServices(this.props.visit.system.id);

    this.setState({
      ...this.state,
      openChangeServiceModel: true
    });
  }

  closeChangeServiceModel() {
    this.setState({
      ...this.state,
      openChangeServiceModel: false
    });
  }

  submitChangeServiceModel(taskStepId) {
    const data = this.props.services.filter((service) => service.taskStepId === taskStepId)[0]
    this.props.updateMaintenanceVisitService(this.props.visit.planId, this.props.visit.number, data);
    this.closeChangeServiceModel();
  }

  onHandleChangeTime() {
    this.setState({
      ...this.state,
      openChangeVisitTimeModel: true
    });
  }

  closeChangeVisitTimeModel() {
    this.setState({
      ...this.state,
      openChangeVisitTimeModel: false
    });
  }

  submitChangeVisitTimeModel(data) {
    const request = {
      startDate: data.startDate.format('YYYY-MM-DD'),
      endDate: data.endDate.format('YYYY-MM-DD'),
      startTime: data.startTime.format('HH:mm:ss'),
      endTime: data.endTime.format('HH:mm:ss')
    }

    this.props.updateMaintenanceVisitTime(this.props.visit.planId, this.props.visit.number, request);
    this.closeChangeVisitTimeModel();
  }

  onHandleRefresh() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'maintenance_plan_info.refresh_maintenance_info_message',
        event: this.refreshVisit
      }
    })
  }

  refreshVisit() {
    this.props.refreshMaintenanceVisit(this.props.visit.planId, this.props.visit.number);
    this.closeConfirmationModel();
  }

  onHandleCancel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'maintenance_plan_info.cancel_visit_message',
        event: this.cancelVisit
      }
    })
  }

  cancelVisit() {
    this.props.cancelMaintenanceVisit(this.props.visit.planId, this.props.visit.number);
    this.closeConfirmationModel();
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  onHandleSetRequest() {
    this.setState({
      ...this.state,
      openChangeExternalIdsModel: true
    });
  }

  closeChangeExternalIdsModel() {
    this.setState({
      ...this.state,
      openChangeExternalIdsModel: false
    });
  }

  submitChangeExternalIdsModel(ids) {
    this.props.updateMaintenanceExternalVisit(this.props.visit.planId, this.props.visit.number, {ids: ids});
    this.closeChangeExternalIdsModel()
  }

  render() {
    if (this.props.visit) {
      const visit = this.props.visit;

      const ids = this.props.visit.externalBookingVisits ?
          this.props.visit.externalBookingVisits.map(visit => visit.id).join(',') : '';

      return (
          <BsFormPanel size="m">
            <ChangeMaintenanceExternalVisitModel
                ids={ids}
                open={this.state.openChangeExternalIdsModel}
                handleClose={this.closeChangeExternalIdsModel.bind(this)}
                handleSubmit={this.submitChangeExternalIdsModel.bind(this)}/>

            <ChangeMechanicModel
                mechanicId={visit.mechanic.id}
                open={this.state.openChangeMechanicModel}
                handleClose={this.closeChangeMechanicModel.bind(this)}
                handleSubmit={this.submitChangeMechanicModel.bind(this)}
                mechanics={this.props.mechanics}/>

            <ChangeServiceModel
                serviceId={visit.service.taskStepId}
                open={this.state.openChangeServiceModel}
                handleClose={this.closeChangeServiceModel.bind(this)}
                handleSubmit={this.submitChangeServiceModel.bind(this)}
                services={this.props.services}/>

            <ChangeServiceExpectedTimeModel
                visit={visit}
                open={this.state.openChangeVisitTimeModel}
                handleClose={this.closeChangeVisitTimeModel.bind(this)}
                handleSubmit={this.submitChangeVisitTimeModel.bind(this)}/>

            <ConfirmationModel
                open={this.state.confirmationWindow.open}
                message={this.state.confirmationWindow.message}
                handleClose={this.closeConfirmationModel.bind(this)}
                handleApply={this.state.confirmationWindow.event.bind(this)}
                backBtn="general.back"/>

            <BsValidatorForm onHandleSubmit={() => {
            }}>
              <BsFormTitle title={{
                text: visit.system.name,
                back: {
                  url: this.props.backUrl,
                  history: this.props.history,
                  location: this.props.location
                },
                actions: <MaintenanceVisitInfoFormActions
                    onHandleChangeMechanic={this.onHandleChangeMechanic.bind(this)}
                    onHandleChangeService={this.onHandleChangeService.bind(this)}
                    onHandleChangeTime={this.onHandleChangeTime.bind(this)}
                    onHandleRefresh={this.onHandleRefresh.bind(this)}
                    onHandleSetRequest={this.onHandleSetRequest.bind(this)}
                    onHandleCancel={this.onHandleCancel.bind(this)}
                    onHandleShowError={this.props.onHandleShowErrorModel.bind(this)}
                    manageMaintenance={this.props.manageMaintenance}
                    status={visit.status}/>
              }}/>

              <MaintenanceVisitInfoMainDesc visit={visit}/>

            </BsValidatorForm>
          </BsFormPanel>
      )
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    mechanics: state.admin.allAdmins,
    services: state.service.services,
    manageMaintenance: state.profile.profile != null && state.profile.profile.role != null
        && state.profile.profile.role.indexOf('VT_SUPER_ADMIN') > -1
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllAdmins: (systemId, query = {page: 0, size: 100, status: 'ACTIVE'}) => {
      dispatch(loadAllAdmins(systemId, query));
    },
    loadServices: (systemId) => {
      dispatch(loadServices(systemId));
    },
    updateMaintenanceVisitMechanic: (planId, number, data) => {
      dispatch(updateMaintenanceVisitMechanic(planId, number, data));
    },
    updateMaintenanceVisitService: (planId, number, data) => {
      dispatch(updateMaintenanceVisitService(planId, number, data));
    },
    updateMaintenanceVisitTime: (planId, number, data) => {
      dispatch(updateMaintenanceVisitTime(planId, number, data));
    },
    refreshMaintenanceVisit: (planId, number) => {
      dispatch(refreshMaintenanceVisit(planId, number));
    },
    cancelMaintenanceVisit: (planId, number) => {
      dispatch(cancelMaintenanceVisit(planId, number));
    },
    updateMaintenanceExternalVisit: (planId, number, data) => {
      dispatch(updateMaintenanceExternalVisit(planId, number, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceVisitInfoForm);
