import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const EditGeneralHelpActions = (props) => {
  const {t} = useTranslation();

  return (
      <Button type="submit"
              variant="contained"
              color="primary">
        {t('edit_general_help_config.submit_btn')}
      </Button>
  )
}

export default EditGeneralHelpActions;
