import React from "react";
import logo from "assets/img/logo.png";
import PropTypes from "prop-types";

const BsLogo = (props) => {
    return (
        <img src={logo}
             alt="Logo"
             className="logo"
             onClick={() => props.history.push('/')}/>
    )
}

BsLogo.propTypes = {
    history: PropTypes.object
};

export default BsLogo