const DEFAULT_FORMAT = /(\d{1,4})/g;

export const CARDS = [
  {
    type: 'maestro',
    pattern: /^(5(018|0[23]|[68])|6(39|7))/,
    format: DEFAULT_FORMAT,
    length: [12, 13, 14, 15, 16, 17, 18, 19],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'mastercard',
    pattern: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[0-1]|2720)/,
    format: DEFAULT_FORMAT,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'amex',
    pattern: /^3[47]/,
    format: DEFAULT_FORMAT,
    length: [15],
    cvcLength: [3, 4],
    luhn: true
  }, {
    type: 'visa',
    pattern: /^4/,
    format: DEFAULT_FORMAT,
    length: [13, 14, 15, 16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'visaelectron',
    pattern: /^4(026|17500|405|508|844|91[37])/,
    format: DEFAULT_FORMAT,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'discover',
    pattern: /^6(44|45|46|47|48|49|22|5|011)/,
    format: DEFAULT_FORMAT,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'dinersclub',
    pattern: /^(3(0[0-5])|54|36|38)/,
    format: DEFAULT_FORMAT,
    length: [14],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'jcb',
    pattern: /^(2131|1800|35)/,
    format: DEFAULT_FORMAT,
    length: [15, 16],
    cvcLength: [3],
    luhn: true
  }
];

const cardFromNumber = (num) => {
  let card, i, len;
  if (!num) {
    return null;
  }
  num = num.toString().replace(/\W/g, '');
  for (i = 0, len = CARDS.length; i < len; i++) {
    card = CARDS[i];
    if (card.pattern.test(num)) {
      return card;
    }
  }

  return null;
};

const luhnCheck = (num) => {
  let digit, digits = num.toString().split('').reverse(), sum = 0, i, odd = true;
  for (i = 0; i < digits.length; i++) {
    digit = digits[i];
    digit = parseInt(digit, 10);
    if ((odd = !odd)) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }

  return sum % 10 === 0;
};

export const validateCardNumber = (num) => {
  if (!num) {
    return false;
  }

  num = num.toString().replace(/\s+|-/g, '');
  if (!/^\d+$/.test(num)) {
    return false;
  }
  const card = cardFromNumber(num);

  return card ? (card.length.indexOf(num.length) >= 0) && (card.luhn === false || luhnCheck(num)) : false;
};

const trim = (str) => {
  return str.toString().replace(/^\s+|\s+$/g, '');
};

export const validateCardExpiry = (month, year) => {
  let currentTime, expiry, prefix;
  if (typeof month === 'object' && month.hasOwnProperty('month')) {
    year = month.year;
    month = month.month;
  }

  if (!(month && year)) {
    return false;
  }

  month = trim(month);
  year = trim(year);
  if (!/^\d+$/.test(month)) {
    return false;
  }
  if (!/^\d+$/.test(year)) {
    return false;
  }
  if (parseInt(month, 10) > 12) {
    return false;
  }
  if (year.length === 2) {
    prefix = (new Date()).getFullYear();
    prefix = prefix.toString().slice(0, 2);
    year = prefix + year;
  }

  expiry = new Date(year, month);
  currentTime = new Date();
  expiry.setMonth(expiry.getMonth() - 1);
  expiry.setMonth(expiry.getMonth() + 1, 1);

  return expiry > currentTime;
};
