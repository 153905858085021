import {saveFile} from "../utils/export";

export const bicycleReducer = (state = {
    bicycles: {},
    allBicycles: {},
    labelBicycles: {},
    scanHistory: {},
    scanByDay: {},
    currentBicycle: null,
    loading: false,
    loadingCurrentBicycle: false
}, action) => {
    switch (action.type) {
        case 'FETCH_BICYCLES':
        case 'FETCH_ALL_BICYCLES':
        case 'FETCH_LABEL_BICYCLES':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_BICYCLES_SUCCESS':
            return {
                ...state,
                loading: false,
                bicycles: action.payload.data
            }
        case 'FETCH_ALL_BICYCLES_SUCCESS':
            return {
                ...state,
                loading: false,
                allBicycles: action.payload.data
            }
        case 'FETCH_LABEL_BICYCLES_SUCCESS':
            return {
                ...state,
                loading: false,
                labelBicycles: action.payload.data
            }
        case 'FETCH_BICYCLE_SCAN_HISTORY_SUCCESS':
            return {
                ...state,
                loading: false,
                scanHistory: action.payload.data
            }
        case 'FETCH_BICYCLE_SCAN_BY_DAY_SUCCESS':
            return {
                ...state,
                loading: false,
                scanByDay: action.payload.data
            }
        case 'FETCH_BICYCLES_FAIL':
        case 'FETCH_ALL_BICYCLES_FAIL':
        case 'FETCH_LABEL_BICYCLES_FAIL':
        case 'FETCH_BICYCLE_SCAN_HISTORY_FAIL':
        case 'FETCH_BICYCLE_SCAN_BY_DAY_FAIL':
            return {
                ...state,
                loading: false
            }

        case 'FETCH_BICYCLE':
        case 'FETCH_BICYCLE_INFO':
        case 'FETCH_LABEL_BICYCLE_INFO':
            return {
                ...state,
                currentBicycle: null,
                loadingCurrentBicycle: true
            }
        case 'FETCH_BICYCLE_FAIL':
        case 'FETCH_BICYCLE_INFO_FAIL':
        case 'FETCH_LABEL_BICYCLE_INFO_FAIL':
            return {
                ...state,
                currentBicycle: null,
                loadingCurrentBicycle: false
            }
        case 'FETCH_BICYCLE_SUCCESS':
        case 'FETCH_BICYCLE_INFO_SUCCESS':
        case 'FETCH_LABEL_BICYCLE_INFO_SUCCESS':
            return {
                ...state,
                loadingCurrentBicycle: false,
                currentBicycle: action.payload.data
            }
        case 'UPDATE_BICYCLE_STATUS_SUCCESS':
        case 'CLEAR_POWER_LEVEL_HISTORY_STATUS_SUCCESS':
            return {
                ...state,
                currentBicycle: action.payload.data
            }
        case 'EXPORT_BICYCLES_TO_CSV_SUCCESS':
        case 'EXPORT_ALL_BICYCLES_TO_CSV_SUCCESS':
        case 'EXPORT_LABEL_BICYCLES_TO_CSV_SUCCESS':
            saveFile(action.payload)
            return state
        case 'UPDATE_BICYCLE_STATUS':
        case 'UPDATE_BICYCLE_STATUS_FAIL':
        case 'CLEAR_POWER_LEVEL_HISTORY_STATUS':
        case 'CLEAR_POWER_LEVEL_HISTORY_STATUS_FAIL':
        case 'CREATE_BICYCLE':
        case 'CREATE_BICYCLE_SUCCESS':
        case 'CREATE_BICYCLE_FAIL':
        case 'UPDATE_BICYCLE':
        case 'UPDATE_BICYCLE_SUCCESS':
        case 'UPDATE_BICYCLE_FAIL':
        case 'EXPORT_BICYCLES_TO_CSV':
        case 'EXPORT_BICYCLES_TO_CSV_FAIL':
        case 'EXPORT_ALL_BICYCLES_TO_CSV':
        case 'EXPORT_ALL_BICYCLES_TO_CSV_FAIL':
        case 'EXPORT_LABEL_BICYCLES_TO_CSV':
        case 'EXPORT_LABEL_BICYCLES_TO_CSV_FAIL':
        default:
            return state
    }
}
