const CUSTOMER_SUPPORT_TABLE = {
    columns: [
        {
            label: 'customer_support.bike_number_header',
            field: 'bike.number',
            getValue: (raw) => {
                if (raw.bike) {
                    return '#' + raw.bike.number
                } else {
                    return '-';
                }
            },
            ordering: true
        },
        {
            label: 'customer_support.problem_header',
            field: 'problem',
            ordering: true,
            translate: true,
            getValue: (raw) => {
                const problem = raw.problem ? raw.problem : "OTHER";
                return  'issue_problem.' + problem;
            }
        },
        {
            label: 'customer_support.priority_header',
            field: 'priority',
            ordering: true,
            translate: true,
            getValue: (raw) => {
                return 'issue_priority.' + raw.priority;
            }
        },
        {
            label: 'customer_support.status_header',
            field: 'status',
            ordering: true,
            translate: true,
            getValue(raw) {
                return 'issue_status.' + raw.status;
            }
        },
        {
            label: 'customer_support.assignee_header',
            field: 'assignee.firstName,assignee.lastName',
            getValue: (raw) => {
                if (raw.assignee && raw.assignee.firstName && raw.assignee.lastName) {
                    return raw.assignee.firstName + ' ' + raw.assignee.lastName
                } else {
                    return '-';
                }
            },
            ordering: true
        },
        {
            label: 'customer_support.created_at_header',
            field: 'createdAt',
            getValue: (raw) => {
                return raw.createdAt
            },
            ordering: true
        },
        {
            label: 'customer_support.last_modified_at_header',
            field: 'lastModifiedAt',
            getValue: (raw) => {
                return raw.lastModifiedAt
            },
            ordering: true
        }
    ]
}

export default CUSTOMER_SUPPORT_TABLE;
