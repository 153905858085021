import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import Col from "components/layout/Col";
import Row from "components/layout/Row";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const LabelRentalsFilters = (props) => {
  const {t} = useTranslation();
  const nameRef = React.createRef();
  const systemRef = React.createRef();
  const bikeRef = React.createRef();

  return (
      <Row>
        <Col size="3">
          <BsPanelHeaderFilterControl>
            <TextField label={t('rentals.system_name_filter')}
                       ref={systemRef}
                       onChange={props.onHandleChange.bind(this, "systemName")}
                       value={props.fields["systemName"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="3">
          <BsPanelHeaderFilterControl>
            <TextField label={t('rentals.customer_name_filter')}
                       ref={nameRef}
                       onChange={props.onHandleChange.bind(this, "name")}
                       value={props.fields["name"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="3">
          <BsPanelHeaderFilterControl>
            <TextField label={t('rentals.bicycle_filter')}
                       ref={bikeRef}
                       onChange={props.onHandleChange.bind(this, "bikeNumber")}
                       value={props.fields["bikeNumber"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="3">
          <BsPanelHeaderFilterControl>
            <FormControl className="form-control">
              <InputLabel id="status-label">{t('rentals.status_filter')}</InputLabel>
              <Select
                  labelId="status-label"
                  id="status"
                  multiple
                  renderValue={(selected) => selected
                      .map(val => t('rental_status.' + val))
                      .join(', ')}
                  value={props.fields["status"]}
                  onChange={props.onHandleChange.bind(this, "status")}>
                <MenuItem value="ACTIVE">{t('rental_status.ACTIVE')}</MenuItem>
                <MenuItem value="FINISHED">{t('rental_status.FINISHED')}</MenuItem>
              </Select>
            </FormControl>
          </BsPanelHeaderFilterControl>
        </Col>
      </Row>
  )
}

export default LabelRentalsFilters;
