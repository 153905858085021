import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const BrandingImg = (props) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="branding-settings__label">{t(props.label)}</div>
            <img src={props.src} alt={t(props.alt)} className={props.className}/>
        </>
    );
}

BrandingImg.propTypes = {
    src: PropTypes.any.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string
};

export default BrandingImg;