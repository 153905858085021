import {saveFile} from "../utils/export";

export const rentalReducer = (state = {
    rentals: {},
    allRentals: {},
    labelRentals: {},
    currentRental: null,
    loading: false
}, action) => {
    switch (action.type) {
        case 'FETCH_RENTALS':
        case 'FETCH_ALL_RENTALS':
        case 'FETCH_LABEL_RENTALS':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_RENTALS_SUCCESS':
            return {
                ...state,
                loading: false,
                rentals: action.payload.data
            }
        case 'FETCH_ALL_RENTALS_SUCCESS':
            return {
                ...state,
                loading: false,
                allRentals: action.payload.data
            }
        case 'FETCH_LABEL_RENTALS_SUCCESS':
            return {
                ...state,
                loading: false,
                labelRentals: action.payload.data
            }
        case 'FETCH_RENTALS_FAIL':
        case 'FETCH_ALL_RENTALS_FAIL':
        case 'FETCH_LABEL_RENTALS_FAIL':
            return {
                ...state,
                loading: false
            }
        case 'FETCH_RENTAL':
        case 'FETCH_RENTAL_FAIL':
        case 'FETCH_RENTAL_INFO':
        case 'FETCH_RENTAL_INFO_FAIL':
        case 'FETCH_LABEL_RENTAL_INFO':
        case 'FETCH_LABEL_RENTAL_INFO_FAIL':
            return {
                ...state,
                currentRental: null
            }
        case 'FETCH_RENTAL_SUCCESS':
        case 'RESOLVE_RENTAL_PROBLEM_SUCCESS':
        case 'FETCH_RENTAL_INFO_SUCCESS':
        case 'FETCH_LABEL_RENTAL_INFO_SUCCESS':
            return {
                ...state,
                currentRental: action.payload.data
            }
        case 'EXPORT_RENTALS_TO_CSV_SUCCESS':
        case 'EXPORT_ALL_RENTALS_TO_CSV_SUCCESS':
        case 'EXPORT_LABEL_RENTALS_TO_CSV_SUCCESS':
            saveFile(action.payload)
            return state
        case 'FINISH_RENTAL':
        case 'RESOLVE_RENTAL_PROBLEM':
        case 'FINISH_RENTAL_SUCCESS':
        case 'FINISH_RENTAL_FAIL':
        case 'RESOLVE_RENTAL_PROBLEM_FAIL':
        case 'EXPORT_RENTALS_TO_CSV':
        case 'EXPORT_RENTALS_TO_CSV_FAIL':
        case 'EXPORT_ALL_RENTALS_TO_CSV':
        case 'EXPORT_ALL_RENTALS_TO_CSV_FAIL':
        case 'EXPORT_LABEL_RENTALS_TO_CSV':
        case 'EXPORT_LABEL_RENTALS_TO_CSV_FAIL':
        default:
            return state
    }
}
