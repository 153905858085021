import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import ADD_SYSTEM_BANK_ACCOUNT_FORM from "./add-system-bank-account-form";
import {MANAGER} from "core/utils/permissions";
import {addSystemBankAccountLegacy} from "core/actions/system-payment-source-actions";

class AddSystemBankAccount extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/subscription';
    this.fields = {};
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.setState({openConfirmationWindow: false})
    this.props.addSystemBankAccountLegacy(this.props.match.params.systemId, this.fields, this.backUrl);
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    return (
        <BsFormPanel size="s">
          <PagePermission anonymous={false} permission={MANAGER}/>
          <ConfirmationModel
              open={this.state.openConfirmationWindow}
              message="add_bank_account.confirmation_message"
              handleClose={() => this.setState({openConfirmationWindow: false})}
              handleApply={this.handleUpdate.bind(this)}
          />

          <BsForm
              config={ADD_SYSTEM_BANK_ACCOUNT_FORM}
              onHandleSubmit={this.handleSubmit.bind(this)}
              onHandleChange={this.handleChange.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }
          />
        </BsFormPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSystemBankAccountLegacy: (systemId, data, redirectPath) => {
      dispatch(addSystemBankAccountLegacy(systemId, data, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSystemBankAccount);
