export function joinSystem(token) {
  return {
    type: "JOIN_SYSTEM",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/clients/activate/${token}`
      },
      globalLoading: true
    }
  };
}
