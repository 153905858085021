import React from "react";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import Col from "components/layout/Col";
import Row from "components/layout/Row";

const BicyclesFilters = (props) => {
    const {t} = useTranslation();
    const labelRef = React.createRef();
    const addressRef = React.createRef();

    return (
        <Row>
            <Col size="3">
                <BsPanelHeaderFilterControl>
                    <TextField label={t('bicycles.number_filter')}
                               ref={labelRef}
                               type="number"
                               onChange={props.onHandleChange.bind(this, "number")}
                               value={props.fields["number"]}
                               className="form-control"/>
                </BsPanelHeaderFilterControl>
            </Col>
            <Col size="3">
                <BsPanelHeaderFilterControl>
                    <TextField label={t('bicycles.address_filter')}
                               ref={addressRef}
                               onChange={props.onHandleChange.bind(this, "address")}
                               value={props.fields["address"]}
                               className="form-control"/>
                </BsPanelHeaderFilterControl>
            </Col>
            <Col size="3">
                <BsPanelHeaderFilterControl>
                    <TextField label={t('bicycles.serial_number_filter')}
                               ref={addressRef}
                               onChange={props.onHandleChange.bind(this, "serialNumber")}
                               value={props.fields["serialNumber"]}
                               className="form-control"/>
                </BsPanelHeaderFilterControl>
            </Col>
            <Col size="3">
                <BsPanelHeaderFilterControl>
                    <FormControl className="form-control">
                        <InputLabel id="status-label">{t('bicycles.status_filter')}</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            multiple
                            renderValue={(selected) => selected
                                .map(val => t('bike_status.' + val))
                                .join(', ')}
                            value={props.fields["status"]}
                            onChange={props.onHandleChange.bind(this, "status")}>
                            <MenuItem value="ACTIVE">{t('bike_status.ACTIVE')}</MenuItem>
                            <MenuItem value="INACTIVE">{t('bike_status.INACTIVE')}</MenuItem>
                            <MenuItem value="IN_MAINTENANCE">{t('bike_status.IN_MAINTENANCE')}</MenuItem>
                            <MenuItem value="IN_RENTAL">{t('bike_status.IN_RENTAL')}</MenuItem>
                        </Select>
                    </FormControl>
                </BsPanelHeaderFilterControl>
            </Col>
        </Row>
    )
}

export default BicyclesFilters;
