import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const EmptyIntegrationSettings = (props) => {
  const {t} = useTranslation();

  const handleClick = (event) => {
    props.onHandleIntegrate()
  };

  return (
      <div className="integration-setting__empty">
        <Button onClick={handleClick}
                variant="contained"
                color="primary">
          {t('integration.integrate')}
        </Button>
      </div>
  )
}

export default EmptyIntegrationSettings
