import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import {MANAGER} from "core/utils/permissions";
import {loadAllLocations} from "core/actions/location-actions";
import {fetchBicycle, updateBicycle} from "core/actions/bicycle-actions";
import EDIT_BICYCLE_FORM from "./edit-bicycle-form";

class EditBicycle extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/bicycle/' + this.props.match.params.bicycleId;
    this.fields = {};
    this.props.loadAllLocations(this.props.match.params.systemId);
    this.props.fetchBicycle(this.props.match.params.systemId, this.props.match.params.bicycleId);
  }

  componentDidMount() {
    BsForm.addValidationRule('requiredLocation', (value) => {
      return !!this.fields.location;
    });
    BsForm.addValidationRule('macAddress', (value) => {
      return !this.fields.lockMacAddress || this.fields.lockMacAddress.replaceAll('_', "").length === 17;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('requiredLocation');
    BsForm.removeValidationRule('macAddress');
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.setState({openConfirmationWindow: false})
    this.props.updateBicycle(this.props.match.params.systemId, this.props.match.params.bicycleId, {
      number: this.fields.number,
      model: this.fields.model ? this.fields.model : undefined,
      serialNumber: this.fields.serialNumber.toUpperCase(),
      lockMacAddress: this.fields.lockMacAddress ? this.fields.lockMacAddress : null,
      locationId: this.fields.location,
      ulockKey: this.fields.ulockKey ? this.fields.ulockKey : null,
    }, this.backUrl);
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    if (this.props.allLocations && this.props.currentBicycle) {
      const config = {
        ...EDIT_BICYCLE_FORM,
        fields: []
      }
      EDIT_BICYCLE_FORM.fields.forEach(field => {
        if (field.name === 'location') {
          config.fields.push({
            ...field,
            items: this.props.allLocations.map(location => {
              return {
                ...location,
                value: location.id
              }
            })
          })
        } else {
          config.fields.push(field)
        }
      })

      const data = {
        ...this.props.currentBicycle,
        location: this.props.currentBicycle.location.id
      }
      if (!this.fields.serialNumber) {
        this.fields = data;
      }

      return (
          <BsFormPanel size="s">
            <PagePermission anonymous={false} permission={MANAGER}/>
            <ConfirmationModel
                open={this.state.openConfirmationWindow}
                message="edit_bicycle.confirmation_message"
                handleClose={() => this.setState({openConfirmationWindow: false})}
                handleApply={this.handleUpdate.bind(this)}
            />

            <BsForm
                config={config}
                formData={data}
                onHandleSubmit={this.handleSubmit.bind(this)}
                onHandleChange={this.handleChange.bind(this)}
                cancelAction={
                  <BsFormCancelAction history={this.props.history}
                                      location={this.props.location}
                                      url={this.backUrl}/>
                }
            />
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    allLocations: state.location.allLocations,
    currentBicycle: state.bicycle.currentBicycle
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllLocations: (systemId) => {
      dispatch(loadAllLocations(systemId));
    },
    fetchBicycle: (systemId, bicycleId) => {
      dispatch(fetchBicycle(systemId, bicycleId));
    },
    updateBicycle: (systemId, bicycleId, data, redirectPath) => {
      dispatch(updateBicycle(systemId, bicycleId, data, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBicycle);
