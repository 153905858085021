import React from "react";
import {connect} from "react-redux";
import BsLogo from "./BsLogo";
import BsSystemItem from "./BsSystemItem";
import PropTypes from "prop-types";

const BsLeftHeader = (props) => {
    if (props.selectedSystem) {
        return <BsSystemItem history={props.history}/>
    } else {
        return <BsLogo history={props.history}/>
    }
}

const mapStateToProps = (state) => {
    return {
        selectedSystem: state.security.isLoggedIn && state.system.systemId && state.system.currentSystem
    };
};

BsLeftHeader.propTypes = {
    history: PropTypes.object
};

export default connect(mapStateToProps)(BsLeftHeader);
