import {saveFile} from "../utils/export";

export const rentalPaymentReducer = (state = {
  rentalPayments: {},
  currentPayment: null,
  loading: false,
  loadingCurrentPayment: false,
  statistics: {},
  loadingStatistics: false
}, action) => {
  switch (action.type) {
    case 'FETCH_RENTAL_PAYMENTS':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_RENTAL_PAYMENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        rentalPayments: action.payload.data
      }
    case 'FETCH_RENTAL_PAYMENTS_FAIL':
      return {
        ...state,
        loading: false
      }
    case 'FETCH_RENTAL_PAYMENT':
      return {
        ...state,
        currentPayment: null,
        loadingCurrentPayment: true
      }
    case 'FETCH_RENTAL_PAYMENT_FAIL':
      return {
        ...state,
        currentPayment: null,
        loadingCurrentPayment: false
      }
    case 'FETCH_RENTAL_PAYMENT_SUCCESS':
    case 'REFUND_RENTAL_PAYMENT_SUCCESS':
      return {
        ...state,
        currentPayment: action.payload.data,
        loadingCurrentPayment: false
      }
    case 'FETCH_RENTAL_PAYMENT_STATISTICS':
      return {
        ...state,
        statistics: {},
        loadingStatistics: true
      }
    case 'FETCH_RENTAL_PAYMENT_STATISTICS_FAIL':
      return {
        ...state,
        statistics: {},
        loadingStatistics: false
      }
    case 'FETCH_RENTAL_PAYMENT_STATISTICS_SUCCESS':
      return {
        ...state,
        statistics: action.payload.data,
        loadingStatistics: false
      }
    case 'EXPORT_RENTAL_PAYMENTS_TO_CSV_SUCCESS':
      saveFile(action.payload)
      return state
    case 'REFUND_RENTAL_PAYMENT':
    case 'REFUND_RENTAL_PAYMENT_FAIL':
    case 'EXPORT_RENTAL_PAYMENTS_TO_CSV':
    case 'EXPORT_RENTAL_PAYMENTS_TO_CSV_FAIL':
    default:
      return state
  }
}
