import React from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import BsPanel from "components/panel/BsPanel";
import LabelInfoTab from "./tabs/info/LabelInfoTab";
import LabelSystemsTab from "./tabs/systems/LabelSystemsTab";
import LabelAdminsTab from "./tabs/admins/LabelAdminsTab";

function LabelInfo(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
  const {t} = useTranslation();

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  let tabs = (
      <Tabs value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary">
        <Tab label={t('label_info.info_tab')} value="info"/>
        <Tab label={t('label_info.systems_tab')} value="systems"/>
        <Tab label={t('label_info.admins_tab')} value="admins"/>
      </Tabs>
  );

  return (
      <>
        <BsPanel>
          {tabs}
          <Route exact path="/global/label/:labelId" component={LabelInfoTab}/>
          <Route path="/global/label/:labelId/info" component={LabelInfoTab}/>
          <Route path="/global/label/:labelId/systems" component={LabelSystemsTab}/>
          <Route path="/global/label/:labelId/admins" component={LabelAdminsTab}/>
        </BsPanel>
      </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelInfo);
