const RENTAL_INFO_FORM = {
  fields: [
    {
      name: 'system',
      label: 'rental_info.system_field',
      type: 'info',
      styles: {
        marginTop: '1em'
      },
      getValue: (data) => {
        return data.system.name
      }
    },
    {
      name: 'client',
      label: 'rental_info.customer_field',
      type: 'info',
      styles: {
        marginTop: '1em'
      },
      getValue: (data) => {
        return data.client.firstName + ' ' + data.client.lastName
      }
    },
    {
      name: 'bike',
      label: 'rental_info.bicycle_field',
      type: 'info',
      getValue: (data) => {
        return '#' + data.bike.number
      }
    },
    {
      name: 'startLocation',
      label: 'rental_info.start_location_field',
      type: 'info',
      getValue: (data) => {
        return data.startLocation.label
      }
    },
    {
      name: 'endLocation',
      label: 'rental_info.end_location_field',
      type: 'info',
      getValue: (data) => {
        return data.endLocation ? data.endLocation.label : '-'
      },
      showFn: (data) => {
        return data.endLocation
      }
    },
    {
      name: 'startTime',
      label: 'rental_info.start_time_field',
      type: 'info',
      getValue: (data) => {
        return data.startTime
      }
    },
    {
      name: 'endTime',
      label: 'rental_info.end_time_field',
      type: 'info',
      getValue: (data) => {
        return data.endTime ? data.endTime : '-'
      }
    },
    {
      name: 'status',
      label: 'rental_info.status_field',
      type: 'info',
      translate: true,
      getValue: (data) => {
        return 'rental_status.' + data.status
      }
    }
  ]
}

export default RENTAL_INFO_FORM;
