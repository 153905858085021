import React from 'react';

import './Col.scss'

const Col = (props) => {
    const className = (props.className ? props.className : '') + ' col-' + props.size;

    return (
        <div className={className}>
            {props.children}
        </div>
    );
}

export default Col;
