import React from 'react'
import Lightbox from 'react-lightbox-component';
import {useTranslation} from "react-i18next";

import 'react-lightbox-component/build/css/index.css'

const MaintenanceReportImages = (props) => {
    const {t} = useTranslation();

    if (props.images != null) {
        const images = []
        const title = t('bicycle_report_info.bike_label') + ' #' + props.bike;

        for (let i = 0; i < props.images.length; i++) {
            images.push({
                id: props.images[i].id,
                src: props.images[i].original,
                title: title,
                description: props.desc
            })
        }

        return (
            <div className="bicycle-report__images-container">
                <h3>{t('bicycle_report_info.report_photo')}</h3>
                <Lightbox images={images}/>
            </div>
        )
    } else {
        return null
    }
}

export default MaintenanceReportImages
