export const systemSubscriptionReducer = (state = {
  systemSubscriptions: {},
  loading: false,
  currentSubscription: null,
  loadingCurrentSubscription: false
}, action) => {
  switch (action.type) {
    case 'FETCH_SYSTEM_SUBSCRIPTIONS':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_SYSTEM_SUBSCRIPTIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        systemSubscriptions: action.payload.data
      }
    case 'FETCH_SYSTEM_SUBSCRIPTIONS_FAIL':
      return {
        ...state,
        loading: false
      }
    case 'FETCH_SYSTEM_SUBSCRIPTION':
      return {
        ...state,
        currentSubscription: null,
        loadingCurrentSubscription: true
      }
    case 'FETCH_SYSTEM_SUBSCRIPTION_FAIL':
      return {
        ...state,
        currentSubscription: null,
        loadingCurrentSubscription: false
      }
    case 'FETCH_SYSTEM_SUBSCRIPTION_SUCCESS':
      return {
        ...state,
        currentSubscription: action.payload.data,
        loadingCurrentSubscription: false
      }
    case 'UPDATE_SYSTEM_SUBSCRIPTION':
    case 'UPDATE_SYSTEM_SUBSCRIPTION_SUCCESS':
    case 'UPDATE_SYSTEM_SUBSCRIPTION_FAIL':
    default:
      return state
  }
}
