const queryString = require('query-string');

export function loadSubscriptionPlans(query) {
  return {
    type: "FETCH_SUBSCRIPTION_PLANS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/subscriptions?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function createSubscriptionPlan(data) {
  return {
    type: "CREATE_SUBSCRIPTION_PLAN",
    payload: {
      request: {
        method: 'post',
        url: `/api/v1/bo/subscriptions`,
        data: data
      },
      globalLoading: true,
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: `/global/subscription-plan/${response.data.id}/info`
        }
      }
    }
  };
}

export function updateSubscriptionPlan(planId, data, redirectPath) {
  return {
    type: "UPDATE_SUBSCRIPTION_PLAN",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/subscriptions/${planId}`,
        data: data
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_subscription_plan_success_message'
        }
      },
      redirect: redirectPath,
      globalLoading: true
    }
  };
}

export function removeSubscriptionPlan(planId, redirectPath) {
  return {
    type: "REMOVE_SUBSCRIPTION_PLAN",
    payload: {
      request: {
        method: 'delete',
        url: `/api/v1/bo/subscriptions/${planId}`
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.remove_subscription_plan_success_message'
        }
      },
      redirect: redirectPath,
      globalLoading: true
    }
  };
}

export function fetchSubscriptionPlan(planId) {
  return {
    type: "FETCH_SUBSCRIPTION_PLAN",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/subscriptions/${planId}`
      },
      globalLoading: true
    }
  };
}
