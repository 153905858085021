export function fetchSystemPaymentSource(systemId) {
  return {
    type: "FETCH_SYSTEM_PAYMENT_SOURCES",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/payment-sources`
      },
      globalLoading: false
    }
  };
}

export function addSystemPaymentSource(systemId, data, redirectPath) {
  return {
    type: "ADD_SYSTEM_PAYMENT_SOURCES",
    payload: {
      request: {
        method: 'post',
        url: `/api/v1/bo/${systemId}/payment-sources`,
        data: data
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.add_system_payment_source_success_message'
        }
      },
      redirect: redirectPath,
      globalLoading: true
    }
  };
}

export function setDefaultSystemPaymentSource(systemId, id) {
  return {
    type: "UPDATE_SYSTEM_PAYMENT_SOURCES",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/${systemId}/payment-sources/${id}`,
        data: {value: true}
      },
      successCallback: (response) => {
        return [
          fetchBankAccount(systemId),
          {
            type: 'SHOW_NOTIFICATION',
            payload: {
              variant: 'success',
              message: 'notifications.set_default_system_payment_source_success_message'
            }
          }
        ]
      },
      globalLoading: true
    }
  };
}

export function removeSystemPaymentSource(systemId, id) {
  return {
    type: "REMOVE_SYSTEM_PAYMENT_SOURCES",
    payload: {
      request: {
        method: 'delete',
        url: `/api/v1/bo/${systemId}/payment-sources/${id}`,
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.remove_system_payment_source_success_message'
        }
      },
      globalLoading: true
    }
  };
}

export function fetchBankAccount(systemId) {
  return {
    type: "FETCH_SYSTEM_BANK_ACCOUNT",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/connected-bank-accounts`,
        data: {}
      },
      globalLoading: true
    }
  };
}

export function updateBankAccount(systemId) {
  return {
    type: "UPDATE_SYSTEM_BANK_ACCOUNT",
    payload: {
      request: {
        method: 'post',
        url: `/api/v1/bo/${systemId}/connected-bank-accounts`,
        data: {}
      },
      globalLoading: true,
      successCallback: (response) => {
        window.location.href = response.data.url;
        return {}
      }
    }
  };
}

export function addSystemBankAccountLegacy(systemId, data, redirectPath) {
  return {
    type: "ADD_SYSTEM_BANK_ACCOUNT_LEGACY",
    payload: {
      request: {
        method: 'post',
        url: `/api/v1/bo/${systemId}/connected-bank-accounts/legacy`,
        data: data
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.add_system_bank_account_success_message'
        }
      },
      redirect: redirectPath,
      globalLoading: true
    }
  };
}

export function setDefaultBankAccount(systemId) {
  return {
    type: "SET_DEFAULT_SYSTEM_BANK_ACCOUNT",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/${systemId}/connected-bank-accounts`,
        data: {value: true}
      },
      globalLoading: true,
      successCallback: (response) => {
        return fetchSystemPaymentSource(systemId)
      }
    }
  };
}

export function removeBankAccount(systemId) {
  return {
    type: "REMOVE_SYSTEM_BANK_ACCOUNT",
    payload: {
      request: {
        method: 'delete',
        url: `/api/v1/bo/${systemId}/connected-bank-accounts`
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.remove_system_bank_account_success_message'
        }
      },
    }
  };
}
