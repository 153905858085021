import React from "react";
import Button from "@material-ui/core/Button";
import BikeNotFound from 'assets/img/bike-not-found.gif'
import {useTranslation} from "react-i18next";

import './not-found-page.scss';

const NotFoundPage = (props) => {
    const {t} = useTranslation();

    const goToHome = () => {
        props.history.push("/")
    }

    return (
        <div className="page-not-fount">
            <div className="page-not-fount__container">
                <div className="page-not-fount__image-content">
                    <img src={BikeNotFound} alt={t('not_found.loading')}/>
                </div>
                <div className="page-not-fount__desc">
                    <div className="page-not-fount__desc-title">{t('not_found.error_not_found')}</div>
                    <div className="page-not-fount__desc-message">{t('not_found.message')}</div>
                    <div className="page-not-fount__desc-back-to-home">
                        <Button variant="contained" color="primary" type="submit" onClick={goToHome}>
                            {t('not_found.back_to_home_btn')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;
