import React from 'react'

import Amex from 'assets/img/payment/amex.png';
import Visa from 'assets/img/payment/visa.png';
import VisaElectron from 'assets/img/payment/visa-electron.png';
import Maestro from 'assets/img/payment/maestro.png';
import MasterCard from 'assets/img/payment/master-card.png';
import Discover from 'assets/img/payment/discover.png';
import DinnersClub from 'assets/img/payment/dinners-club.png';
import JCB from 'assets/img/payment/JCB.png';
import UnknownCard from 'assets/img/payment/unknown.png';

const CardIcon = (props) => {
  const BRANDS = {
    'visa': {icon: Visa, label: 'Visa'},
    'maestro': {icon: Maestro, label: 'Maestro'},
    'visaelectron': {icon: VisaElectron, label: 'Visa Electron'},
    'visa electron': {icon: VisaElectron, label: 'Visa Electron'},
    'visamaster': {icon: VisaElectron, label: 'Visa Master'},
    'mastercard': {icon: MasterCard, label: 'MasterCard'},
    'amex': {icon: Amex, label: 'Amex'},
    'americanexpress': {icon: Amex, label: 'Amex'},
    'american express': {icon: Amex, label: 'Amex'},
    'bcglobal': {icon: Amex, label: 'BCGlobal'},
    'discover': {icon: Discover, label: 'Discover'},
    'dinersclub': {icon: DinnersClub, label: 'DinersClub'},
    'diners club': {icon: DinnersClub, label: 'DinersClub'},
    'jcb': {icon: JCB, label: 'JCB'},
    'carteblanche': {icon: UnknownCard, label: 'CarteBlanche'},
    'instapayment': {icon: UnknownCard, label: 'InstaPayment'},
    'koreanlocal': {icon: UnknownCard, label: 'KoreanLocal'},
    'laser': {icon: UnknownCard, label: 'Laser'},
    'solo': {icon: UnknownCard, label: 'Solo'},
    'switch': {icon: UnknownCard, label: 'Switch'},
    'unionpay': {icon: UnknownCard, label: 'UnionPay'},
    'unknown': {icon: UnknownCard, label: 'Unknown Brand'}
  }

  const brand = props.brand.toLowerCase();
  const icon = BRANDS[brand];

  return (
      <img src={icon.icon}
           alt={icon.label}
           className="card-type"/>
  )
}

export default CardIcon
