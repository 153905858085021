import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import GeneralSettingsActions from "./GeneralSettingsActions";
import buildGeneralConfigForm from "./general-config-form";
import ChangeSystemNameModel from "./ChangeSystemNameModel";
import {changeSystemName, deleteSystem} from "core/actions/system-actions";
import ConfirmationModel from "components/model/ConfirmationModel";
import {fetchSystemConfig, uploadSystemLogo} from "core/actions/system-config-actions";
import NoImage from "assets/img/no-image.png";
import BrandingImg from "./BrandingImg";
import UploadBrandingImage from "./UploadBrandingImage";

import './GeneralSettings.scss';

const GeneralSettings = (props) => {

  const [changeSystemNameWindow, setSystemNameWindow] = useState({
    open: false,
    name: ''
  });

  const [confirmationWindow, setConfirmationWindow] = useState({
    open: false
  });

  useEffect(() => {
    props.fetchSystemConfig(props.match.params.systemId);
  }, []);

  const onHandleEdit = () => {
    props.history.push('/system/' + props.match.params.systemId + '/edit-settings/general')
  }

  const onHandleChangeRentalRates = () => {
    props.history.push('/system/' + props.match.params.systemId + '/edit-settings/rental-rates')
  }

  const onHandleChangeName = () => {
    setSystemNameWindow({
      name: props.system.name,
      open: true
    });
  }

  const closeChangingSystemNameModel = () => {
    setSystemNameWindow({
      name: '',
      open: false
    });
  }

  const changeSystemName = (name) => {
    closeChangingSystemNameModel()
    props.changeSystemName(props.match.params.systemId, name)
  }

  const onHandleDelete = () => {
    setConfirmationWindow({
      open: true
    })
  }

  const closeConfirmationWindow = () => {
    setConfirmationWindow({
      open: false
    })
  }

  const onSubmitConfirmationWindow = () => {
    closeConfirmationWindow();
    props.deleteSystem(props.match.params.systemId)
  }

  const onChooseLogo = (image) => {
    props.uploadSystemLogo(props.match.params.systemId, image, () => {
    })
  }

  if (props.system && props.systemConfig) {
    const actions = {
      changeName: props.isSuperAdmin ? onHandleChangeName.bind(this) : null,
      changeRentalRates: props.systemConfig.rentalRates ? onHandleChangeRentalRates.bind(this) : null,
    };

    const formConfig = buildGeneralConfigForm(actions, props.systemConfig.timeRestrictions);
    formConfig.title = {
      actions: <GeneralSettingsActions isSuperAdmin={props.isSuperAdmin}
                                       onHandleEdit={onHandleEdit}
                                       onHandleDelete={onHandleDelete}/>
    }

    let currentLogo = props.systemConfig.logo ? props.systemConfig.logo.original : NoImage

    return (
        <div className="bs-form-container general-settings">
          <PagePermission anonymous={false} permission={ADMIN}/>
          <ChangeSystemNameModel
              open={changeSystemNameWindow.open}
              name={changeSystemNameWindow.name}
              handleClose={closeChangingSystemNameModel.bind(this)}
              handleSubmit={changeSystemName.bind(this)}
          />
          <ConfirmationModel
              open={confirmationWindow.open}
              message={'general_config.delete_system_confirmation_dialog'}
              handleClose={closeConfirmationWindow.bind(this)}
              handleApply={onSubmitConfirmationWindow.bind(this)}
          />
          <BsForm
              config={formConfig}
              formData={{
                ...props.system,
                ...props.systemConfig
              }}
              disabled={true}
              additionalContent={
                <>
                  <BrandingImg
                      label="branding.current_logo_label"
                      src={currentLogo}
                      alt={'branding.logo'}
                      className="branding-settings__logo"/>
                  <UploadBrandingImage
                      label="branding.upload_logo_label"
                      warning="branding.upload_logo_warning"
                      resolution={{
                        width: 200,
                        height: 100
                      }}
                      onChooseImage={onChooseLogo.bind(this)}/>
                </>
              }/>
        </div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    system: state.system.currentSystem,
    systemConfig: state.systemConfig.systemConfig,
    isSuperAdmin: state.profile.profile != null && state.profile.profile.role != null
        && state.profile.profile.role.indexOf('SUPER_ADMIN') > -1
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    },
    changeSystemName: (systemId, name) => {
      dispatch(changeSystemName(systemId, {name}));
    },
    uploadSystemLogo: (systemId, logo, callback) => {
      dispatch(uploadSystemLogo(systemId, logo, callback));
    },
    deleteSystem: (systemId) => {
      dispatch(deleteSystem(systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
