const queryString = require('query-string');

export function loadUsers(systemId, query) {
    return {
        type: "FETCH_CLIENT_USERS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/clients?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function createClientUser(systemId, data) {
    return {
        type: "CREATE_CLIENT_USER",
        payload: {
            request: {
                method: 'post',
                url: `/api/v1/bo/${systemId}/clients`,
                data: data
            },
            globalLoading: true,
            successCallback: (response) => {
                return {
                    type: 'REDIRECT_TO',
                    payload: `/system/${systemId}/user/${response.data.id}`
                }
            }
        }
    };
}

export function fetchClientUser(systemId, userId) {
    return {
        type: "FETCH_CLIENT_USER",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/clients/${userId}`
            },
            globalLoading: true
        }
    };
}

export function changeClientUserStatus(systemId, userId, status) {
    return {
        type: "UPDATE_CLIENT_USER_STATUS",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/clients/${userId}/status`,
                data: {
                    status: status
                }
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: status === 'ACTIVE' ? 'notifications.activate_client_success_message' : 'notifications.ban_client_success_message'
                }
            },
            globalLoading: true
        }
    };
}

export function resendActivationEmail(systemId, userId) {
    return {
        type: "RESEND_CLIENT_ACTIVATION_EMAIL",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/clients/${userId}/resend-confirmation-email`
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.client_activation_email_resent_success_message'
                }
            },
            globalLoading: true
        }
    };
}

export function changeClientUserEmail(systemId, userId, email) {
    return {
        type: "UPDATE_CLIENT_USER_EMAIL",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/clients/${userId}/email`,
                data: {
                    email: email
                }
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.client_email_was_changed_success_message'
                }
            },
            globalLoading: true
        }
    };
}

export function loadAllUsers(query) {
    return {
        type: "FETCH_ALL_CLIENT_USERS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/clients?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function fetchClientUserInfo(userId) {
    return {
        type: "FETCH_CLIENT_USER_INFO",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/clients/${userId}`
            },
            globalLoading: true
        }
    };
}

export function exportUsersToCsv(systemId, query) {
    return {
        type: "EXPORT_CLIENT_USERS_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/clients/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}

export function exportAllUsersToCsv(query) {
    return {
        type: "EXPORT_ALL_CLIENT_USERS_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/clients/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}

export function loadLabelUsers(labelId, query) {
    return {
        type: "FETCH_LABEL_CLIENT_USERS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/labels/${labelId}/clients?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function fetchLabelClientUserInfo(labelId, userId) {
    return {
        type: "FETCH_LABEL_CLIENT_USER_INFO",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/labels/${labelId}/clients/${userId}`
            },
            globalLoading: true
        }
    };
}

export function exportLabelUsersToCsv(labelId, query) {
    return {
        type: "EXPORT_LABEL_CLIENT_USERS_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/labels/${labelId}/clients/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}
