import {saveFile} from "../utils/export";

export const maintenancePlanReducer = (state = {
  maintenancePlans: {},
  maintenanceVisits: {},
  currentMaintenancePlan: null,
  currentMaintenanceVisit: null,
  loading: false
}, action) => {
  switch (action.type) {
    case 'FETCH_MAINTENANCE_PLANS':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_MAINTENANCE_PLANS_SUCCESS':
      return {
        ...state,
        loading: false,
        maintenancePlans: action.payload.data
      }
    case 'FETCH_MAINTENANCE_PLANS_FAIL':
      return {
        ...state,
        loading: false
      }
    case 'FETCH_MAINTENANCE_VISITS':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_MAINTENANCE_VISITS_SUCCESS':
      return {
        ...state,
        loading: false,
        maintenanceVisits: action.payload.data
      }
    case 'FETCH_MAINTENANCE_VISITS_FAIL':
      return {
        ...state,
        loading: false
      }
    case 'FETCH_MAINTENANCE_PLAN_SUCCESS':
    case 'FETCH_ACTIVE_MAINTENANCE_PLAN_SUCCESS':
    case 'UPDATE_MAINTENANCE_PLAN_MECHANIC_SUCCESS':
    case 'UPDATE_MAINTENANCE_PLAN_SERVICE_SUCCESS':
    case 'UPDATE_MAINTENANCE_PLAN_STATUS_SUCCESS':
      return {
        ...state,
        loading: true,
        currentMaintenancePlan: action.payload.data
      }
    case 'FETCH_MAINTENANCE_PLAN_FAIL':
    case 'FETCH_ACTIVE_MAINTENANCE_PLAN_FAIL':
      return {
        ...state,
        loading: false,
        currentMaintenancePlan: null
      }
    case 'FETCH_MAINTENANCE_VISIT_SUCCESS':
    case 'FETCH_MAINTENANCE_VISIT_INFO_SUCCESS':
    case 'UPDATE_MAINTENANCE_VISIT_MECHANIC_SUCCESS':
    case 'UPDATE_MAINTENANCE_VISIT_SERVICE_SUCCESS':
    case 'UPDATE_MAINTENANCE_VISIT_TIME_SUCCESS':
    case 'REFRESH_MAINTENANCE_VISIT_SUCCESS':
    case 'UPDATE_MAINTENANCE_VISIT_SUCCESS':
      return {
        ...state,
        loading: true,
        currentMaintenanceVisit: action.payload.data
      }
    case 'FETCH_MAINTENANCE_VISIT_FAIL':
    case 'FETCH_MAINTENANCE_VISIT_INFO_FAIL':
      return {
        ...state,
        loading: false,
        currentMaintenanceVisit: null
      }
    case 'EXPORT_MAINTENANCE_PLANS_TO_CSV_SUCCESS':
      saveFile(action.payload)
      return state
    case 'FETCH_MAINTENANCE_PLAN':
    case 'FETCH_ACTIVE_MAINTENANCE_PLAN':
    case 'FETCH_MAINTENANCE_VISIT':
    case 'FETCH_MAINTENANCE_VISIT_INFO':
    case 'CREATE_MAINTENANCE_PLAN':
    case 'CREATE_MAINTENANCE_PLAN_SUCCESS':
    case 'CREATE_MAINTENANCE_PLAN_FAIL':
    case 'RENEW_MAINTENANCE_PLAN':
    case 'RENEW_MAINTENANCE_PLAN_SUCCESS':
    case 'RENEW_MAINTENANCE_PLAN_FAIL':
    case 'UPDATE_MAINTENANCE_PLAN_MECHANIC':
    case 'UPDATE_MAINTENANCE_PLAN_MECHANIC_FAIL':
    case 'UPDATE_MAINTENANCE_PLAN_SERVICE':
    case 'UPDATE_MAINTENANCE_PLAN_SERVICE_FAIL':
    case 'UPDATE_MAINTENANCE_PLAN_STATUS':
    case 'UPDATE_MAINTENANCE_PLAN_STATUS_FAIL':
    case 'UPDATE_MAINTENANCE_VISIT_MECHANIC':
    case 'UPDATE_MAINTENANCE_VISIT_MECHANIC_FAIL':
    case 'UPDATE_MAINTENANCE_VISIT_SERVICE':
    case 'UPDATE_MAINTENANCE_VISIT_SERVICE_FAIL':
    case 'UPDATE_MAINTENANCE_VISIT_TIME':
    case 'UPDATE_MAINTENANCE_VISIT_TIME_FAIL':
    case 'REFRESH_MAINTENANCE_VISIT':
    case 'REFRESH_MAINTENANCE_VISIT_FAIL':
    case 'UPDATE_MAINTENANCE_VISIT':
    case 'UPDATE_MAINTENANCE_VISIT_FAIL':
    case 'EXPORT_MAINTENANCE_PLANS_TO_CSV':
    case 'EXPORT_MAINTENANCE_PLANS_TO_CSV_FAIL':
    default:
      return state
  }
}
