import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanel from "components/panel/BsPanel";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import {exportLabelUsersToCsv, loadLabelUsers} from "core/actions/client-user-actions";
import LabelUsersFilters from "./LabelUsersFilters";
import LABEL_USERS_TABLE from "./label-users-table";
import Button from "@material-ui/core/Button";

const queryString = require('query-string');

class LabelUsers extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        name: query.name != null ? query.name : '',
        email: query.email != null ? query.email : '',
        phone: query.phone != null ? query.phone : '',
        status: query.status != null ? query.status : '',
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.name = this.state.fields.name;
    params.email = this.state.fields.email;
    params.phone = this.state.fields.phone;
    params.status = this.state.fields.status;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.name;
    delete params.email;
    delete params.phone;
    delete params.status;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        name: '',
        email: '',
        phone: '',
        status: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadLabelClientUsers(this.props.match.params.labelId, this.prepareQuery(query));
  }

  export = () => {
    const query = queryString.parse(this.props.location.search);
    this.props.exportLabelClientUsersToCsv(this.props.match.params.labelId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.phone) {
      query['username'] = query.phone
      delete query.phone
    }

    return query
  }

  onRowClick = (row) => {
    this.props.history.push('/label/' + this.props.match.params.labelId + '/user/' + row.id)
  }

  render() {
    return (
        <BsPanel padding={true}>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle action={
              <Button variant="outlined" type="button" onClick={this.export}>
                <Trans i18nKey="general.export"/>
              </Button>
            }>
              <Trans i18nKey="users.title"/>
            </BsPanelTitle>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <LabelUsersFilters fields={this.state.fields}
                               onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={LABEL_USERS_TABLE}
                       content={this.props.users}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.clientUser.labelUsers,
    loading: state.clientUser.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadLabelClientUsers: (labelId, query) => {
      dispatch(loadLabelUsers(labelId, query));
    },
    exportLabelClientUsersToCsv: (labelId, query) => {
      dispatch(exportLabelUsersToCsv(labelId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelUsers);
