export const subscriptionPlanReducer = (state = {
  subscriptionPlans: {},
  loading: false,
  currentPlan: null,
  loadingCurrentPlan: false
}, action) => {
  switch (action.type) {
    case 'FETCH_SUBSCRIPTION_PLANS':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_SUBSCRIPTION_PLANS_SUCCESS':
      return {
        ...state,
        loading: false,
        subscriptionPlans: action.payload.data
      }
    case 'FETCH_SUBSCRIPTION_PLANS_FAIL':
      return {
        ...state,
        loading: false
      }
    case 'FETCH_SUBSCRIPTION_PLAN':
      return {
        ...state,
        currentPlan: null,
        loadingCurrentPlan: true
      }
    case 'FETCH_SUBSCRIPTION_PLAN_FAIL':
      return {
        ...state,
        currentPlan: null,
        loadingCurrentPlan: false
      }
    case 'FETCH_SUBSCRIPTION_PLAN_SUCCESS':
      return {
        ...state,
        loadingCurrentPlan: false,
        currentPlan: action.payload.data
      }
    case 'CREATE_SUBSCRIPTION_PLAN':
    case 'CREATE_SUBSCRIPTION_PLAN_SUCCESS':
    case 'CREATE_SUBSCRIPTION_PLAN_FAIL':
    case 'UPDATE_SUBSCRIPTION_PLAN':
    case 'UPDATE_SUBSCRIPTION_PLAN_SUCCESS':
    case 'UPDATE_SUBSCRIPTION_PLAN_FAIL':
    case 'REMOVE_SUBSCRIPTION_PLAN':
    case 'REMOVE_SUBSCRIPTION_PLAN_SUCCESS':
    case 'REMOVE_SUBSCRIPTION_PLAN_FAIL':
    default:
      return state
  }
}
