import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import {MANAGER} from "core/utils/permissions";
import EDIT_BILLING_ADDRESS_FORM from "./edit-billing-address-form";
import {fetchSystemSubscription, updateSystemBillingAddress} from "core/actions/system-subscription-actions";

class EditBillingAddress extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/subscription';
    this.fields = {};
    this.props.fetchSystemSubscription(this.props.match.params.systemId);
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.setState({openConfirmationWindow: false})
    this.props.updateSystemBillingAddress(this.props.match.params.systemId, {
      name: this.fields.name,
      street: this.fields.street,
      building: this.fields.building,
      city: this.fields.city,
      state: this.fields.state,
      zip: this.fields.zip,
      country: this.fields.country,
    }, this.backUrl);
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    if (this.props.currentSubscription) {
      const data = this.props.currentSubscription.billingAddress ? this.props.currentSubscription.billingAddress : {};

      return (
          <BsFormPanel size="s">
            <PagePermission anonymous={false} permission={MANAGER}/>
            <ConfirmationModel
                open={this.state.openConfirmationWindow}
                message="edit_billing_address.confirmation_message"
                handleClose={() => this.setState({openConfirmationWindow: false})}
                handleApply={this.handleUpdate.bind(this)}
            />

            <BsForm
                config={EDIT_BILLING_ADDRESS_FORM}
                formData={data}
                onHandleSubmit={this.handleSubmit.bind(this)}
                onHandleChange={this.handleChange.bind(this)}
                cancelAction={
                  <BsFormCancelAction history={this.props.history}
                                      location={this.props.location}
                                      url={this.backUrl}/>
                }
            />
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentSubscription: state.systemSubscription.currentSubscription
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemSubscription: (systemId) => {
      dispatch(fetchSystemSubscription(systemId));
    },
    updateSystemBillingAddress: (systemId, data, redirectPath) => {
      dispatch(updateSystemBillingAddress(systemId, data, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBillingAddress);
