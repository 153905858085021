const queryString = require('query-string');

export function loadBicycles(systemId, query) {
    return {
        type: "FETCH_BICYCLES",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/bikes?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function createBicycle(systemId, data) {
    return {
        type: "CREATE_BICYCLE",
        payload: {
            request: {
                method: 'post',
                url: `/api/v1/bo/${systemId}/bikes`,
                data: data
            },
            globalLoading: true,
            successCallback: (response) => {
                return {
                    type: 'REDIRECT_TO',
                    payload: `/system/${systemId}/bicycle/${response.data.id}`
                }
            }
        }
    };
}

export function updateBicycle(systemId, bicycleId, data, redirectPath) {
    return {
        type: "UPDATE_BICYCLE",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/bikes/${bicycleId}`,
                data: data
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.update_bicycle_success_message'
                }
            },
            redirect: redirectPath,
            globalLoading: true
        }
    };
}

export function fetchBicycle(systemId, bicycleId) {
    return {
        type: "FETCH_BICYCLE",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/bikes/${bicycleId}`
            },
            globalLoading: true
        }
    };
}

export function changeBicycleStatus(systemId, bicycleId, status) {
    return {
        type: "UPDATE_BICYCLE_STATUS",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/bikes/${bicycleId}/status`,
                data: {
                    status: status
                }
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: status === 'ACTIVE' ? 'notifications.activate_bicycle_success_message' : 'notifications.inactivate_bicycle_success_message'
                }
            },
            globalLoading: true
        }
    };
}

export function clearPowerLevelHistory(systemId, bicycleId) {
    return {
        type: "CLEAR_POWER_LEVEL_HISTORY_STATUS",
        payload: {
            request: {
                method: 'delete',
                url: `/api/v1/bo/${systemId}/bikes/${bicycleId}/power-level-history`
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.clear_power_level_history_success_message'
                }
            },
            globalLoading: true
        }
    };
}

export function exportBicyclesToCsv(systemId, query) {
    return {
        type: "EXPORT_BICYCLES_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/bikes/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}

export function loadAllBicycles(query) {
    return {
        type: "FETCH_ALL_BICYCLES",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/bikes?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function fetchBicycleInfo(bicycleId) {
    return {
        type: "FETCH_BICYCLE_INFO",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/bikes/${bicycleId}`
            },
            globalLoading: true
        }
    };
}

export function exportAllBicyclesToCsv(query) {
    return {
        type: "EXPORT_ALL_BICYCLES_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/bikes/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}

export function loadLabelBicycles(labelId, query) {
    return {
        type: "FETCH_LABEL_BICYCLES",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/labels/${labelId}/bikes?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function fetchLabelBicycleInfo(labelId, bicycleId) {
    return {
        type: "FETCH_LABEL_BICYCLE_INFO",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/labels/${labelId}/bikes/${bicycleId}`
            },
            globalLoading: true
        }
    };
}

export function exportLabelBicyclesToCsv(labelId, query) {
    return {
        type: "EXPORT_LABEL_BICYCLES_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/labels/${labelId}/bikes/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}

export function loadBicycleScanHistory(query) {
    return {
        type: "FETCH_BICYCLE_SCAN_HISTORY",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/bikes/days?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function loadBicycleScanByDay(location, user, day, query) {
    return {
        type: "FETCH_BICYCLE_SCAN_BY_DAY",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/bikes/day/${location}/${user}/${day}?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}
