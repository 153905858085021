import React from 'react';
import {connect} from "react-redux";
import {Link} from "@material-ui/core"
import BsFormPanel from "components/form/BsFormPanel";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {signIn} from "core/actions/security-actions";
import {selectSystem} from "core/actions/system-actions";
import {useTranslation} from "react-i18next";
import SIGN_IN_FORM from "./sign-in-form";

import './SignIn.scss';

const SignIn = (props) => {
    const {t} = useTranslation();

    props.clearSystem();

    const getRedirectTo = () => {
        if (props.location.search) {
            const params = new URLSearchParams(props.location.search);
            return params.get("redirectTo")
        }
    }

    const handleSubmit = (fields) => {
        props.signIn(fields.email, fields.password, getRedirectTo());
    }

    return (
        <BsFormPanel>
            <PagePermission anonymous={true} />
            <BsForm
                config={SIGN_IN_FORM}
                onHandleSubmit={handleSubmit}
                cancelAction={
                    <Link onClick={() => props.history.push('/forgot-password')}
                          className="forgot-password__link">
                        {t('sign_in.forgot_password')}</Link>
                }/>
        </BsFormPanel>
    );
}

const mapStateToProps = (state) => {
    return {
        error: state.security.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (username, password, redirectTo) => {
            dispatch(signIn(username, password, redirectTo));
        },
        clearSystem: () => {
            dispatch(selectSystem(null));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
