import React from "react";
import BsFormPanel from "components/form/BsFormPanel";
import RENEW_MAINTENANCE_PLAN_FORM from "./renew-maintenance-plan-form";
import BsForm from "components/form/BsForm";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";

const RenewMaintenancePlanForm = (props) => {
  const config = {
    ...RENEW_MAINTENANCE_PLAN_FORM
  }
  if (!props.loading) {
    config.fields = [];
    RENEW_MAINTENANCE_PLAN_FORM.fields.forEach(field => {
      if (field.name === 'mechanic' && props.mechanics && props.mechanics.content) {
        config.fields.push({
          ...field,
          items: props.mechanics.content.map(admin => {
            return {
              ...admin,
              value: admin.uid,
              label: admin.firstName + ' ' + admin.lastName
            }
          })
        })
      } else if (field.name === 'service' && props.services) {
        config.fields.push({
          ...field,
          items: props.services.map(service => {
            return {
              ...service,
              value: service.taskStepId,
              label: service.name
            }
          })
        })
      } else {
        config.fields.push(field)
      }
    })
  }

  return (
      <BsFormPanel size="s">
        <BsForm
            config={config}
            formData={props.fields}
            onHandleSubmit={props.handleSubmit.bind(this)}
            onHandleChange={props.handleChange.bind(this)}
            cancelAction={
              <BsFormCancelAction history={props.history}
                                  location={props.location}
                                  url={props.backUrl}/>
            }/>
      </BsFormPanel>
  );
}

export default RenewMaintenancePlanForm;
