import React, {useEffect} from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import BsForm from "components/form/BsForm";
import ASSIGN_USER_FORM from "./assign-user-form";

import 'components/model/confirmation-model.scss';

const AssignUserModel = (props) => {
    const {t} = useTranslation();
    let fields = {};

    useEffect(() => {
        BsForm.addValidationRule('requiredUser', (value) => {
            return !!fields.user;
        });

        return () => {
            BsForm.removeValidationRule('requiredUser');
        };
    });

    const onSubmit = () => {
        props.handleAssign(fields.user)
    }

    const handleChange = (newData) => {
        fields = newData;
    }

    const onAssignToMe = () => {
        props.handleAssignToMe()
    }

    if (props.allUsers) {
        const config = {
            ...ASSIGN_USER_FORM,
            fields: []
        }
        ASSIGN_USER_FORM.fields.forEach(field => {
            if (field.name === 'user') {
                config.fields.push({
                    ...field,
                    items: props.allUsers.map(user => {
                        return {
                            ...user,
                            value: user.id,
                            label: user.firstName + ' ' + user.lastName
                        }
                    })
                })
            } else if (field.name === 'assignToMe') {
                config.fields.push({
                    ...field,
                    onClick: onAssignToMe
                })
            } else {
                config.fields.push(field)
            }
        })

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="model-window"
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={props.open}>
                    <div className="model-container">
                        <div className="model-body">
                            <h4>{t(props.message)}</h4>

                            <BsForm
                                config={config}
                                onHandleSubmit={onSubmit}
                                onHandleChange={handleChange}
                                cancelAction={<Button variant="outlined" onClick={props.handleClose}>
                                    {t('general.cancel')}
                                </Button>}
                            />
                        </div>
                    </div>
                </Fade>
            </Modal>
        )
    } else {
        return null
    }
}

AssignUserModel.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    handleClose: PropTypes.any.isRequired,
    handleAssign: PropTypes.any.isRequired,
    handleAssignToMe: PropTypes.any.isRequired,
    allUsers: PropTypes.any
};

export default AssignUserModel;
