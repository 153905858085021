import Moment from "moment";

const buildGeneralConfigForm = (actions, timeRestrictions) => {
  let generalConfig = {
    fields: [
      {
        name: 'name',
        label: 'general_config.name_field',
        type: 'info',
        styles: {
          marginTop: '2em'
        },
        action: actions.changeName
      },
      {
        name: 'name_delimiter',
        type: 'delimiter'
      },
      {
        name: 'description',
        label: 'general_config.description_field',
        type: 'info'
      },
      {
        name: 'description_delimiter',
        type: 'delimiter'
      },
      {
        name: 'phone',
        label: 'general_config.phone_field',
        type: 'info',
        getValue: (data) => {
          return data.phone ? data.phone.fullNumber : '';
        }
      },
      {
        name: 'phone_delimiter',
        type: 'delimiter'
      },
      {
        name: 'email',
        label: 'general_config.email_field',
        type: 'info'
      },
      {
        name: 'email_delimiter',
        type: 'delimiter'
      },
      {
        name: 'country',
        label: 'general_config.country_field',
        type: 'info'
      },
      {
        name: 'country_delimiter',
        type: 'delimiter'
      },
      {
        name: 'status',
        label: 'general_config.status_field',
        type: 'info',
        translate: true,
        getValue: (data) => {
          return 'system_status.' + data.status
        }
      },
      {
        name: 'status_delimiter',
        type: 'delimiter'
      },
    ]
  }

  generalConfig.fields.push({
    name: 'timeRestrictions',
    label: 'rental_config.time_restrictions_field',
    type: 'info',
    translate: true,
    getValue: (data) => {
      return data.timeRestrictions ? 'general.yes' : 'general.no';
    }
  });

  if (timeRestrictions) {
    generalConfig.fields.push({
      name: 'time_zone_delimiter',
      type: 'delimiter'
    });
    generalConfig.fields.push({
      name: 'timeZone',
      label: 'rental_config.time_zone_field',
      type: 'info'
    });
    generalConfig.fields.push({
      name: 'work_time_delimiter',
      type: 'delimiter'
    });
    generalConfig.fields.push({
      name: 'workTime',
      label: 'rental_config.work_time_field',
      type: 'info',
      getValue: (data) => {
        if (data.workTime && data.workTime.from && data.workTime.to) {
          return Moment('10-20-2020 ' + data.workTime.from, 'MM-DD-YYYY hh:mm:ss').format('hh:mm A') + ' - '
              + Moment('10-20-2020 ' + data.workTime.to, 'MM-DD-YYYY hh:mm:ss').format('hh:mm A');
        } else {
          return '';
        }
      }
    });
  }

  return generalConfig
}

export default buildGeneralConfigForm;
