import React from "react";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import Col from "components/layout/Col";
import Row from "components/layout/Row";

const AllLabelsFilters = (props) => {
  const {t} = useTranslation();
  const labelRef = React.createRef();

  return (
      <Row>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <TextField label={t('labels.label_filter')}
                       ref={labelRef}
                       onChange={props.onHandleChange.bind(this, "label")}
                       value={props.fields["label"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
      </Row>
  )
}

export default AllLabelsFilters;
