import tokenStore from 'core/store/token-store'

export const securityReducer = (state = {
    isLoggedIn: false,
    passwordIsChanging: false
}, action) => {
    switch (action.type) {
        case 'SIGN_IN_SUCCESS':
            tokenStore.save(action.payload.data)
            return {
                ...state,
                isLoggedIn: true
            }
        case 'SIGN_IN_FAIL':
            return {
                ...state,
                isLoggedIn: false
            }
        case 'LOGOUT':
            return state;
        case 'LOGOUT_SUCCESS':
        case 'LOGOUT_FAIL':
            tokenStore.clear()
            return {
                ...state,
                isLoggedIn: false
            }
        case 'CHECK_SIGN_IN':
            tokenStore.refreshApiToken()
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn
            }
        case 'CHANGE_PASSWORD':
            return {
                ...state,
                passwordIsChanging: true
            }
        case 'CHANGE_PASSWORD_SUCCESS':
        case 'CHANGE_PASSWORD_FAIL':
            return {
                ...state,
                passwordIsChanging: false
            }
        case 'RESET_AUTH':
            tokenStore.clear()
            return {
                ...state,
                isLoggedIn: false
            }
        case 'ACTIVATE_ACCOUNT':
        case 'ACTIVATE_ACCOUNT_SUCCESS':
        case 'ACTIVATE_ACCOUNT_FAIL':
        default:
            return state
    }
}
