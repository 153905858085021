import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {calculateAvailablePages} from "core/utils/paginationUtils";

import PaginationFirst from 'assets/img/pagination-first.svg';
import PaginationLast from 'assets/img/pagination-last.svg';
import PaginationNext from 'assets/img/pagination-next.svg';
import PaginationPrev from 'assets/img/pagination-prev.svg';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import './BsTablePaginationActions.scss';

const BsTablePaginationActions = (props) => {
    const {t} = useTranslation();
    const {count, page, rowsPerPage, onChangePage} = props;
    const pages = calculateAvailablePages(page, count, rowsPerPage);

    return (
        <div className="pagination-actions">
            <IconButton
                size="small"
                onClick={() => onChangePage(0)}
                disabled={page === 0}
                aria-label={t('pagination.first_page')}>
                <PaginationFirst/>
            </IconButton>
            <IconButton
                size="small"
                onClick={() => onChangePage(page - 1)}
                disabled={page === 0}
                aria-label={t('pagination.previous_page')}>
                <PaginationPrev/>
            </IconButton>

            {pages.map((p, index) => {
                return (
                    <IconButton
                        className="pagination-actions__page"
                        key={'pane-number-' + p}
                        size="small"
                        onClick={() => onChangePage(p)}
                        disabled={p === page}
                        aria-label={t('pagination.page_number', {page: p})}>
                        {p + 1}
                    </IconButton>
                )
            })}

            <IconButton
                size="small"
                onClick={() => onChangePage(page + 1)}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t('pagination.next_page')}>
                <PaginationNext/>
            </IconButton>
            <IconButton
                size="small"
                onClick={() => onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t('pagination.last_page')}>
                <PaginationLast/>
            </IconButton>
        </div>
    )
}

BsTablePaginationActions.propTypes = {
    count: PropTypes.number,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    onChangePage: PropTypes.func
};

export default BsTablePaginationActions;