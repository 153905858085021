import queryString from "query-string";
import {getCurrentPath} from "../utils/pathUtils";

const SYSTEM_PAGE_SIZE = 20;

export function loadSystems(name, page) {
    return {
        type: "FETCH_SYSTEMS",
        payload: {
            request: {
                method: 'get',
                url: '/api/v1/bo/systems',
                params: {
                    name: name,
                    page: page,
                    size: SYSTEM_PAGE_SIZE
                }
            }
        }
    };
}

export function loadSystemsByAdminId(adminId, query) {
    return {
        type: "FETCH_USER_SYSTEMS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/systems/by-user/${adminId}?` + queryString.stringify(query)
            },
            query: query,
        }
    };
}

export function loadSystemsByClientId(clientId, query) {
    return {
        type: "FETCH_CLIENT_SYSTEMS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/systems/by-client/${clientId}?` + queryString.stringify(query)
            },
            query: query,
        }
    };
}

export function refreshSystems(name) {
    return {
        type: "REFRESH_SYSTEMS",
        payload: {
            request: {
                method: 'get',
                url: '/api/v1/bo/systems',
                params: {
                    name: name,
                    page: 0,
                    size: SYSTEM_PAGE_SIZE
                }
            }
        }
    };
}

export function loadSystem(id) {
    return {
        type: "LOAD_SYSTEM",
        payload: {
            request: {
                method: 'get',
                url: '/api/v1/bo/systems/' + id
            },
            globalLoading: true,
            errorAction: {
                type: 'REDIRECT_TO',
                payload: '/sign-in?redirectTo=' + getCurrentPath()
            }
        }
    };
}

export function loadDefaultSystem(id) {
    return {
        type: "LOAD_DEFAULT_SYSTEM",
        payload: {
            request: {
                method: 'get',
                url: '/api/v1/bo/systems/' + id
            },
            globalLoading: true,
            successCallback: (response) => {
                return {
                    type: 'REDIRECT_TO',
                    payload: '/system/' + response.data.id
                }
            }
        }
    };
}

export function selectSystem(id) {
    return {
        type: "SET_SYSTEM_ID",
        payload: {
            systemId: id
        }
    };
}

export function createSystem(data){
    return {
        type: "CREATE_SYSTEM",
        payload: {
            request: {
                method: 'post',
                url: '/api/v1/bo/systems/',
                data: data
            },
            successCallback: (response) => {
                return {
                    type: 'REDIRECT_TO',
                    payload: '/system/' + response.data.id
                }
            },
            globalLoading: true
        }
    };
}

export function updateSystem(systemId, data, redirectPath) {
    return {
        type: "UPDATE_SYSTEM_INFO",
        payload: {
            request: {
                method: 'put',
                url: '/api/v1/bo/systems/' + systemId,
                data: data
            },
            redirect: redirectPath,
            globalLoading: true
        }
    };
}

export function changeSystemName(systemId, data) {
    return {
        type: "UPDATE_SYSTEM_NAME",
        payload: {
            request: {
                method: 'put',
                url: '/api/v1/bo/systems/' + systemId + '/name',
                data: data
            },
            globalLoading: true
        }
    };
}

export function deleteSystem(systemId) {
    return {
        type: "DELETE_SYSTEM",
        payload: {
            request: {
                method: 'delete',
                url: '/api/v1/bo/systems/' + systemId
            },
            redirect: '/systems',
            globalLoading: true
        }
    };
}

export function loadStatistics(systemId) {
    return {
        type: "LOAD_SYSTEM_STATISTICS",
        payload: {
            request: {
                method: 'get',
                url: '/api/v1/bo/systems/' + systemId + '/statistics'
            },
            globalLoading: true
        }
    };
}

export function systemsAutocomplete(query) {
    return {
        type: "SYSTEMS_AUTOCOMPLETE",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/systems/autocomplete?name=${query}`
            }
        }
    };
}
