import React from "react";
import SupportIssueInfoForm from "../form/SupportIssueInfoForm";

class SupportIssueInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            backUrl: '/system/' + this.props.match.params.systemId + '/customer-support',
        };
    }

    render() {
        return (
            <SupportIssueInfoForm backUrl={this.state.backUrl}
                                  history={this.props.history}
                                  location={this.props.location}
                                  match={this.props.match}/>
        )
    }
}

export default SupportIssueInfo;