const buildSupportIssueInfoForm = (actions, isAdmin) => {
  return {
    fields: [
      {
        name: 'reporter',
        label: 'issue_info.reporter_field',
        type: isAdmin ? 'reference-link' : 'info',
        getValue: (data) => {
          if (data.reporter) {
            return data.reporter.firstName + ' ' + data.reporter.lastName
          } else {
            return '-'
          }
        },
        styles: {
          marginTop: '2em'
        },
        link: (data) => {
          if (data.reporter.role === 'ADMIN') {
            return '/system/' + data.system.id + '/admins/' + data.reporter.id + '/info'
          } else {
            return '/system/' + data.system.id + '/user/' + data.reporter.id
          }
        }
      },
      {
        name: 'assignee',
        label: 'issue_info.assignee_field',
        type: 'info',
        getValue: (data) => {
          if (data.assignee) {
            return data.assignee.firstName + ' ' + data.assignee.lastName
          } else {
            return '-'
          }
        },
        action: actions.reassign
      },
      {
        name: 'bike',
        label: 'issue_info.bicycle_field',
        type: 'reference-link',
        getValue: (data) => {
          return data.bike ? ('#' + data.bike.number) : '-'
        },
        link: (data) => {
          if (data.bike) {
            return '/system/' + data.system.id + '/bicycle/' + data.bike.id
          } else {
            return "/"
          }
        }
      },
      {
        name: 'location',
        label: 'issue_info.location_field',
        type: isAdmin ? 'reference-link' : 'info',
        getValue: (data) => {
          return data.location ? data.location.label : '-'
        },
        link: (data) => {
          if (data.location) {
            return '/system/' + data.system.id + '/location/' + data.location.id
          } else {
            return "/"
          }
        }
      },
      {
        name: 'status',
        label: 'issue_info.status_field',
        type: 'info',
        translate: true,
        getValue: (data) => {
          return 'issue_status.' + data.status;
        }
      },
      {
        name: 'priority',
        label: 'issue_info.priority_field',
        type: 'info',
        translate: true,
        getValue: (data) => {
          return 'issue_priority.' + data.priority;
        },
        action: actions.priority
      },
      {
        name: 'problem',
        label: 'issue_info.problem_field',
        type: 'info',
        translate: true,
        getValue: (data) => {
          const problem = data.problem ? data.problem : "OTHER";
          return 'issue_problem.' + problem;
        }
      },
      {
        name: 'description',
        label: 'issue_info.description_field',
        type: 'info',
        getValue: (data) => {
          return data.description ? data.description : '-'
        }
      },
      {
        name: 'createdAt',
        label: 'issue_info.created_at_field',
        type: 'info',
        getValue: (data) => {
          return data.createdAt
        }
      },
      {
        name: 'lastModifiedAt',
        label: 'issue_info.last_modified_at_field',
        type: 'info',
        getValue: (data) => {
          return data.lastModifiedAt ? data.lastModifiedAt : '-'
        }
      },
      {
        name: 'completedAt',
        label: 'issue_info.completed_at_field',
        type: 'info',
        getValue: (data) => {
          return data.completedAt ? data.completedAt : '-'
        }
      }
    ]
  }
}


export default buildSupportIssueInfoForm;
