import React from "react";
import {useTranslation} from "react-i18next";
import MaintenanceVisitReportBikeInfo from "./MaintenanceVisitReportBikeInfo";

const MaintenanceVisitInfoReport = (props) => {
  const visit = props.visit;

  return (
      <div className="maintenance-visit-report">
        <h1 className="align-center maintenance-visit-report__title">Report</h1>

        {visit.output.map(bike => {
          return <MaintenanceVisitReportBikeInfo bike={bike} />
        })}
      </div>
  )
}

export default MaintenanceVisitInfoReport;
