import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import {MANAGER} from "core/utils/permissions";
import {fetchSubscriptionPlan, updateSubscriptionPlan} from "core/actions/subscription-plan-actions";
import EDIT_SUBSCRIPTION_PLANS_FORM from "./edit-subscription-plan-form";

import './edit-subscription-plan.scss'

class EditSubscriptionPlanInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/global/subscription-plan/' + this.props.match.params.planId + '/info';
    this.fields = {};
    this.props.fetchSubscriptionPlan(this.props.match.params.planId);
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.setState({openConfirmationWindow: false})
    this.props.updateSubscriptionPlan(this.props.match.params.planId, {
      name: this.fields.name,
      description: this.fields.description,
      billingPeriod: this.fields.billingPeriod,
      pricePerBike: this.fields.pricePerBike,
      currency: this.fields.currency,
      autoCancellation: this.fields.autoCancellation ? this.fields.autoCancellation : null
    }, this.backUrl);
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    if (this.props.currentPlan) {
      return (
        <div className="edit-subscription-plan">
          <BsFormPanel size="s">
            <PagePermission anonymous={false} permission={MANAGER}/>
            <ConfirmationModel
              open={this.state.openConfirmationWindow}
              message="edit_subscription_plans.confirmation_message"
              handleClose={() => this.setState({openConfirmationWindow: false})}
              handleApply={this.handleUpdate.bind(this)}
            />

            <BsForm
              config={EDIT_SUBSCRIPTION_PLANS_FORM}
              formData={this.props.currentPlan}
              onHandleSubmit={this.handleSubmit.bind(this)}
              onHandleChange={this.handleChange.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }
            />
          </BsFormPanel>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentPlan: state.subscriptionPlan.currentPlan
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubscriptionPlan: (bicycleId) => {
      dispatch(fetchSubscriptionPlan(bicycleId));
    },
    updateSubscriptionPlan: (planId, data, redirectPath) => {
      dispatch(updateSubscriptionPlan(planId, data, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSubscriptionPlanInfo);
