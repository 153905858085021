import React from 'react';
import {useTranslation} from "react-i18next";

export function UploadImageError(props) {
    const {t} = useTranslation();

    return (
        <div className="upload-image__error">{t(props.msg)}</div>
    );
}

export default UploadImageError;