const EDIT_TOS_CONFIG_FORM = {
    title: {
        text: 'edit_tos_config.title'
    },
    submit: {
        label: 'edit_tos_config.submit_btn'
    },
    fields: [
        {
            name: 'termsOfServices',
            label: 'edit_tos_config.terms_of_services_field',
            type: 'text-editor',
            defaultValue: ''
        }
    ]
}

export default EDIT_TOS_CONFIG_FORM;
