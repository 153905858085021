export const labelReducer = (state = {
  allLabels: {},
  systems: {},
  admins: {},
  currentSystem: {},
  currentAdmin: {},
  currentLabel: null,
  loading: false,
  loadingCurrentLabel: false,
  userLabels: {},
  userLabelsIsLoading: false,
  userLabelsWasLoaded: false,
  selectedLabelWasLoaded: false,
  selectedLabelIsLoading: false,
  selectedLabel: {}
}, action) => {
  switch (action.type) {
    case 'FETCH_USER_LABELS':
      return {
        ...state,
        userLabelsIsLoading: true
      }
    case 'FETCH_USER_LABELS_SUCCESS':
      return {
        ...state,
        userLabelsIsLoading: false,
        userLabelsWasLoaded: true,
        userLabels: action.payload.data
      }
    case 'FETCH_USER_LABELS_FAIL':
      return {
        ...state,
        userLabelsIsLoading: false,
        userLabelsWasLoaded: true
      }
    case 'LOAD_SELECTED_LABEL':
      return {
        ...state,
        selectedLabelIsLoading: true
      }
    case 'LOAD_SELECTED_LABEL_SUCCESS':
      return {
        ...state,
        selectedLabelIsLoading: false,
        selectedLabelWasLoaded: true,
        selectedLabel: action.payload.data
      }
    case 'LOAD_SELECTED_LABEL_FAIL':
      return {
        ...state,
        selectedLabelIsLoading: false,
        selectedLabelWasLoaded: true
      }
    case 'FETCH_ALL_LABELS':
    case 'FETCH_LABELS_SYSTEMS':
    case 'FETCH_LABELS_ADMINS':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_ALL_LABELS_SUCCESS':
      return {
        ...state,
        loading: false,
        allLabels: action.payload.data
      }
    case 'FETCH_LABELS_SYSTEMS_SUCCESS':
      return {
        ...state,
        loading: false,
        systems: action.payload.data
      }
    case 'FETCH_LABELS_ADMINS_SUCCESS':
      return {
        ...state,
        loading: false,
        admins: action.payload.data
      }
    case 'FETCH_ALL_LABELS_FAIL':
    case 'FETCH_LABELS_SYSTEMS_FAIL':
    case 'FETCH_LABELS_ADMINS_FAIL':
      return {
        ...state,
        loading: false
      }
    case 'LOAD_LABEL':
      return {
        ...state,
        currentLabel: null,
        loadingCurrentLabel: true
      }
    case 'LOAD_LABEL_FAIL':
      return {
        ...state,
        currentLabel: null,
        loadingCurrentLabel: false
      }
    case 'LOAD_LABEL_SUCCESS':
      return {
        ...state,
        loadingCurrentLabel: false,
        currentLabel: action.payload.data
      }
    case 'FETCH_LABELS_SYSTEM':
      return {
        ...state,
        currentSystem: null,
        loadingCurrentLabel: true
      }
    case 'FETCH_LABELS_SYSTEM_FAIL':
      return {
        ...state,
        currentSystem: null,
        loadingCurrentLabel: false
      }
    case 'FETCH_LABELS_SYSTEM_SUCCESS':
      return {
        ...state,
        loadingCurrentLabel: false,
        currentSystem: action.payload.data
      }
    case 'FETCH_LABEL_ADMIN':
      return {
        ...state,
        currentAdmin: null,
        loadingCurrentLabel: true
      }
    case 'FETCH_LABEL_ADMIN_FAIL':
      return {
        ...state,
        currentAdmin: null,
        loadingCurrentLabel: false
      }
    case 'FETCH_LABEL_ADMIN_SUCCESS':
      return {
        ...state,
        loadingCurrentLabel: false,
        currentAdmin: action.payload.data
      }
    case 'CREATE_LABEL':
    case 'CREATE_LABEL_SUCCESS':
    case 'CREATE_LABEL_FAIL':
    case 'UPDATE_LABEL':
    case 'UPDATE_LABEL_SUCCESS':
    case 'UPDATE_LABEL_FAIL':
    default:
      return state
  }
}
