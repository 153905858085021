import {saveFile} from "../utils/export";

export const locationReducer = (state = {
    locations: {},
    allLocations: null,
    currentLocation: null,
    loading: false,
    loadingAllLocations: false
}, action) => {
    switch (action.type) {
        case 'FETCH_LOCATIONS':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_LOCATIONS_SUCCESS':
            return {
                ...state,
                loading: false,
                locations: action.payload.data
            }
        case 'FETCH_LOCATIONS_FAIL':
            return {
                ...state,
                loading: false
            }
        case 'FETCH_ALL_LOCATIONS':
            return {
                ...state,
                loadingAllLocations: true,
                allLocations: null
            }
        case 'FETCH_ALL_LOCATIONS_SUCCESS':
            return {
                ...state,
                loadingAllLocations: false,
                allLocations: action.payload.data.content
            }
        case 'FETCH_ALL_LOCATIONS_FAIL':
            return {
                ...state,
                loadingAllLocations: false,
                allLocations: null
            }
        case 'FETCH_LOCATION':
        case 'FETCH_LOCATION_FAIL':
            return {
                ...state,
                currentLocation: null
            }
        case 'FETCH_LOCATION_SUCCESS':
            return {
                ...state,
                currentLocation: action.payload.data
            }
        case 'UPDATE_LOCATION_STATUS_SUCCESS':
            return {
                ...state,
                currentLocation: action.payload.data
            }
        case 'EXPORT_LOCATIONS_TO_CSV_SUCCESS':
            saveFile(action.payload)
            return state
        case 'UPDATE_LOCATION_STATUS':
        case 'UPDATE_LOCATION_STATUS_FAIL':
        case 'CREATE_LOCATION':
        case 'CREATE_LOCATION_SUCCESS':
        case 'CREATE_LOCATION_FAIL':
        case 'UPDATE_LOCATION':
        case 'UPDATE_LOCATION_SUCCESS':
        case 'UPDATE_LOCATION_FAIL':
        case 'EXPORT_LOCATIONS_TO_CSV':
        case 'EXPORT_LOCATIONS_TO_CSV_FAIL':
        default:
            return state
    }
}
