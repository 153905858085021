import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {fetchRentalRate, updateRentalRate} from "core/actions/rental-rate-actions";
import EDIT_RENTAL_RATE_FORM from "./edit-rental-rate-form";

import './edit-rental-rate.scss'

class EditRentalRate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: '',
        price: '',
        currency: 'USD',
        rentalDurationAmount: '',
        rentalDurationUnit: 'HOURS',
        /*refundPolicyAmount: '',
        refundPolicyUnit: 'MINUTES',
        gracePeriodAmount: '',
        gracePeriodUnit: 'MINUTES',*/
        lateFeeAmount: '',
        lateFeeCurrency: 'USD',
        type: 'SINGLE'
      }
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/rental-rate/' + this.props.match.params.rateId;

    this.props.fetchRentalRate(this.props.match.params.systemId, this.props.match.params.rateId);
  }

  handleSubmit(fields, e) {
    this.props.updateRentalRate(this.props.match.params.systemId, this.props.match.params.rateId, {
      name: fields.name,
      price: fields.price,
      currency: fields.currency,
      rentalDuration: {
        amount: fields.rentalDurationAmount,
        unit: fields.rentalDurationUnit,
      },
      /*refundPolicy: {
        amount: fields.refundPolicyAmount,
        unit: fields.refundPolicyUnit,
      },
      gracePeriod: {
        amount: fields.gracePeriodAmount,
        unit: fields.gracePeriodUnit,
      },*/
      lateFeeAmount: fields.lateFeeAmount,
      lateFeeCurrency: fields.lateFeeCurrency,
      type: fields.type
    });
  }

  handleChange(fields) {
    this.setState({
      ...this.state,
      fields: fields
    });
  }

  render() {
    if (this.props.currentRate) {
      const data = {
        ...this.props.currentRate,
        rentalDurationAmount: this.props.currentRate.rentalDuration.amount,
        rentalDurationUnit: this.props.currentRate.rentalDuration.unit,
        /*refundPolicyAmount: this.props.currentRate.refundPolicy.amount,
        refundPolicyUnit: this.props.currentRate.refundPolicy.unit,
        gracePeriodAmount: this.props.currentRate.gracePeriod.amount,
        gracePeriodUnit: this.props.currentRate.gracePeriod.unit,*/
      }

      this.fields = data;

      return (
        <div className="edit-rental-rate">
          <BsFormPanel>
            <PagePermission anonymous={false} permission={ADMIN}/>
            <BsForm
              config={EDIT_RENTAL_RATE_FORM}
              formData={data}
              onHandleSubmit={this.handleSubmit.bind(this)}
              onHandleChange={this.handleChange.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }/>
          </BsFormPanel>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentRate: state.rentalRate.currentRate
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRentalRate: (systemId, rateId, data) => {
      dispatch(updateRentalRate(systemId, rateId, data));
    },
    fetchRentalRate: (systemId, rateId) => {
      dispatch(fetchRentalRate(systemId, rateId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRentalRate);
