const CREATE_RENTAL_RATE_FORM = {
  title: {
    text: 'add_rental_rate.title'
  },
  submit: {
    label: 'add_rental_rate.submit_btn'
  },
  fields: [
    {
      name: 'name',
      label: 'add_rental_rate.name_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_name'],
      defaultValue: ''
    },
    {
      name: 'price_grid',
      type: 'grid',
      fields: [
        {
          name: 'price',
          label: 'add_rental_rate.price_field',
          type: 'text',
          validators: [
            'required',
            'matchRegexp:^[0-9]{1,100}$',
            'minNumber:0',
            'maxNumber:999999999'
          ],
          errors: [
            'errors.required_price_error',
            'errors.invalid_number_format',
            'errors.min_price_error',
            'errors.max_price_error'
          ],
          defaultValue: ''
        },
        {
          name: 'currency',
          label: 'add_rental_rate.currency_field',
          type: 'select',
          items: [
            {label: 'currency.usd', value: 'USD', translate: true},
            {label: 'currency.cad', value: 'CAD', translate: true}
          ],
          validators: ['required'],
          errors: ['errors.required_currency'],
          defaultValue: 'USD'
        },
      ]
    },
    {
      name: 'rentalDuration',
      type: 'grid',
      fields: [
        {
          name: 'rentalDurationAmount',
          label: 'add_rental_rate.rental_duration_amount_field',
          type: 'text',
          validators: [
            'required',
            'matchRegexp:^[0-9]{1,100}$',
            'minNumber:0',
            'maxNumber:999999999'
          ],
          errors: [
            'errors.required_amount',
            'errors.invalid_number_format',
            'errors.min_amount_error',
            'errors.max_amount_error'
          ],
          defaultValue: ''
        },
        {
          name: 'rentalDurationUnit',
          label: 'add_rental_rate.rental_duration_unit_field',
          type: 'select',
          items: [
            {label: 'duration_unit.MINUTES', value: 'MINUTES', translate: true},
            {label: 'duration_unit.HOURS', value: 'HOURS', translate: true},
            {label: 'duration_unit.DAYS', value: 'DAYS', translate: true}
          ],
          validators: ['required'],
          errors: ['errors.required_rental_duration_unit'],
          defaultValue: 'HOURS'
        },
      ]
    },
    /*{
      name: 'refundPolicy',
      type: 'grid',
      fields: [
        {
          name: 'refundPolicyAmount',
          label: 'add_rental_rate.refund_policy_amount_field',
          type: 'text',
          validators: [
            'required',
            'matchRegexp:^[0-9]{1,100}$',
            'minNumber:0',
            'maxNumber:999999999'
          ],
          errors: [
            'errors.required_amount',
            'errors.invalid_number_format',
            'errors.min_amount_error',
            'errors.max_amount_error'
          ],
          defaultValue: ''
        },
        {
          name: 'refundPolicyUnit',
          label: 'add_rental_rate.refund_policy_unit_field',
          type: 'select',
          items: [
            {label: 'duration_unit.MINUTES', value: 'MINUTES', translate: true},
            {label: 'duration_unit.HOURS', value: 'HOURS', translate: true},
            {label: 'duration_unit.DAYS', value: 'DAYS', translate: true}
          ],
          validators: ['required'],
          errors: ['errors.required_refund_policy_unit'],
          defaultValue: 'MINUTES'
        },
      ]
    },*/
    /*{
      name: 'gracePeriod',
      type: 'grid',
      fields: [
        {
          name: 'gracePeriodAmount',
          label: 'add_rental_rate.grace_period_amount_field',
          type: 'text',
          validators: [
            'required',
            'matchRegexp:^[0-9]{1,100}$',
            'minNumber:0',
            'maxNumber:999999999'
          ],
          errors: [
            'errors.required_amount',
            'errors.invalid_number_format',
            'errors.min_amount_error',
            'errors.max_amount_error'
          ],
          defaultValue: ''
        },
        {
          name: 'gracePeriodUnit',
          label: 'add_rental_rate.grace_period_unit_field',
          type: 'select',
          items: [
            {label: 'duration_unit.MINUTES', value: 'MINUTES', translate: true},
            {label: 'duration_unit.HOURS', value: 'HOURS', translate: true},
            {label: 'duration_unit.DAYS', value: 'DAYS', translate: true}
          ],
          validators: ['required'],
          errors: ['errors.required_grace_period_unit'],
          defaultValue: 'MINUTES'
        },
      ]
    },*/
    {
      name: 'late_fee_amount_grid',
      type: 'grid',
      fields: [
        {
          name: 'lateFeeAmount',
          label: 'add_rental_rate.late_fee_amount_field',
          type: 'text',
          validators: [
            'required',
            'matchRegexp:^[0-9]{1,100}$',
            'minNumber:0',
            'maxNumber:999999999'
          ],
          errors: [
            'errors.required_late_fee_amount',
            'errors.invalid_number_format',
            'errors.min_price_error',
            'errors.max_price_error'
          ],
          defaultValue: ''
        },
        {
          name: 'lateFeeCurrency',
          label: 'add_rental_rate.currency_field',
          type: 'select',
          items: [
            {label: 'currency.usd', value: 'USD', translate: true},
            {label: 'currency.cad', value: 'CAD', translate: true}
          ],
          validators: ['required'],
          errors: ['errors.required_currency'],
          defaultValue: 'USD'
        },
      ]
    },
    {
      name: 'type',
      label: 'add_rental_rate.type_field',
      type: 'select',
      items: [
        {label: 'rental_rate_type.SINGLE', value: 'SINGLE', translate: true},
        {label: 'rental_rate_type.MULTIPLE', value: 'MULTIPLE', translate: true}
      ],
      validators: ['required'],
      errors: ['errors.required_billing_period'],
      defaultValue: 'SINGLE'
    },
  ]
};

export default CREATE_RENTAL_RATE_FORM;
