import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import LinkIcon from "assets/img/link.svg";

const BsFormReferenceLinkField = (props) => {
    const {t} = useTranslation();

    let valueDecoration = 'bs-form__info-value';
    switch (props.valueDecoration) {
        case 'capitalize':
            valueDecoration += ' bs-form__info-value-capitalize'
            break;
    }

    if (props.onClick) {
        valueDecoration += ' bs-form__info-value-active'
    }

    return (
        <div className="bs-form__info">
            <div className="bs-form__info-label">
                {t(props.label)}
            </div>
            <div className={valueDecoration}>
                <a target="_blank" href={props.link} className="bs-form__info-value bs-form__info-value-active" style={{cursor: 'pointer'}}>
                    {(props.translate ? t(props.value) : props.value)}<LinkIcon className="bs-form__link-icon"/>
                </a>
            </div>
        </div>
    )
}

BsFormReferenceLinkField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    translate: PropTypes.bool,
    link: PropTypes.string.isRequired,
    valueDecoration: PropTypes.string
};

export default BsFormReferenceLinkField;
