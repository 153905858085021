import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import ReactPhoneInput from 'react-phone-input-mui';
import {TextValidator} from "react-material-ui-form-validator";
import 'react-phone-input-2/lib/style.css'

const BsFormPhoneField = (props) => {
    const {t} = useTranslation();

    const onChangePhone = (value) => {
        props.onHandleChange({target: {value}})
    }

    return (
        <ReactPhoneInput
            value={props.value}
            name={props.name}
            disabled={props.disabled}
            defaultCountry={props.defaultCountry || 'us'}
            onChange={onChangePhone}
            inputClass="form-control bs-form__phone"
            component={TextValidator}
            inputExtraProps={{
                validators: props.validators,
                errorMessages: props.errors.map(error => t(error)),
                label: t(props.label)
            }}
        />
    )
}

BsFormPhoneField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onHandleChange: PropTypes.func,
    value: PropTypes.any,
    validators: PropTypes.array,
    errors: PropTypes.array
};

export default BsFormPhoneField;