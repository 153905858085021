export function getValidationMessage(error, defaultMessage) {
    let message = defaultMessage;
    if (error && error.data) {
        if (error.data.violations && error.data.violations.length > 0) {
            message = error.data.violations[0].message;
        }
    }

    return message
}
