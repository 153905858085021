import React from "react";
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import PagePermission from "components/permission/PagePermission";
import BsForm from "components/form/BsForm";
import ACTIVATION_ACCOUNT_FORM from "./activation-account-form";
import {activateAccount} from "core/actions/security-actions";
import {PASSWORD_REGEX} from "core/utils/consts";

class ActivationAccount extends React.Component {

    componentDidMount() {
        BsForm.addValidationRule('isPasswordMatch', (value) => {
            return value === (this.fields ? this.fields.password : null);
        });
        BsForm.addValidationRule('strongPassword', (value) => {
            return PASSWORD_REGEX.test(value)
        });
    }

    componentWillUnmount() {
        BsForm.removeValidationRule('isPasswordMatch');
        BsForm.removeValidationRule('strongPassword');
    }

    handleChange(fields, e) {
        this.fields = fields;
    }

    handleSubmit(fields, e) {
        this.props.activateAccount(this.props.match.params.token, {
            password: fields.password,
            repeatPassword: fields.repeatPassword
        });
    }

    render() {
        return (
            <BsFormPanel>
                <PagePermission anonymous={true}/>
                <BsForm
                    config={ACTIVATION_ACCOUNT_FORM}
                    onHandleChange={this.handleChange.bind(this)}
                    onHandleSubmit={this.handleSubmit.bind(this)}/>
            </BsFormPanel>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        activateAccount: (token, data) => {
            dispatch(activateAccount(token, data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivationAccount);
