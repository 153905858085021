const MAINTENANCE_REPORT_INFO_FORM = {
  fields: [
    {
      name: 'system',
      label: 'bicycle_report_info.system_field',
      type: 'info',
      getValue: (data) => {
        return data.system.name
      },
      styles: {
        marginTop: '2em'
      }
    },
    {
      name: 'reporter',
      label: 'bicycle_report_info.reporter_field',
      type: 'info',
      getValue: (data) => {
        return data.reporter.firstName + ' ' + data.reporter.lastName
      }
    },
    {
      name: 'reportedAt',
      label: 'bicycle_report_info.reported_at_field',
      type: 'info',
      getValue: (data) => {
        return data.reportedAt
      }
    },
    {
      name: 'status',
      label: 'bicycle_report_info.status_field',
      type: 'info',
      getValue: (data) => {
        return 'maintenance_report_status.' + data.status
      },
      translate: true
    },
    {
      name: 'bike',
      label: 'bicycle_report_info.bike_field',
      type: 'info',
      getValue: (data) => {
        return '#' + data.bike.number
      }
    },
    {
      name: 'location',
      label: 'bicycle_report_info.location_field',
      type: 'info',
      getValue: (data) => {
        return data.location ? data.location.label : '-'
      }
    },
    {
      name: 'maintenanceComment',
      label: 'bicycle_report_info.comment_field',
      type: 'info',
      getValue: (data) => {
        return data.maintenanceComment ? data.maintenanceComment : '-'
      }
    }
  ]
}

export default MAINTENANCE_REPORT_INFO_FORM;
