import React, {useEffect} from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import BsForm from "components/form/BsForm";

import 'components/model/confirmation-model.scss';

const ChangeMechanicModel = (props) => {
  const {t} = useTranslation();
  let fields = {};

  useEffect(() => {
    BsForm.addValidationRule('requiredMechanic', (value) => {
      return !!fields.mechanic;
    });

    return () => {
      BsForm.removeValidationRule('requiredMechanic');
    };
  });

  const onSubmit = () => {
    props.handleSubmit(fields.mechanic)
  }

  const handleChange = (newData) => {
    fields = newData;
  }

  if (props.mechanics.content) {
    const config = {
      submit: {
        label: 'maintenance_plan_info.save_btn'
      },
      fields: [
        {
          name: 'mechanic',
          label: 'maintenance_plan_info.mechanic_field',
          type: 'select',
          items: props.mechanics.content.map(admin => {
            return {
              ...admin,
              value: admin.uid,
              label: admin.firstName + ' ' + admin.lastName
            }
          }),
          validators: ['requiredMechanic'],
          errors: ['errors.required_mechanic'],
          defaultValue: ''
        }
      ]
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="model-window"
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
          <Fade in={props.open}>
            <div className="model-container">
              <div className="model-body">
                <h4>{t('maintenance_plan_info.change_mechanic_model_title')}</h4>

                <BsForm
                    config={config}
                    onHandleSubmit={onSubmit}
                    onHandleChange={handleChange}
                    cancelAction={<Button variant="outlined" onClick={props.handleClose}>
                      {t('general.cancel')}
                    </Button>}
                />
              </div>
            </div>
          </Fade>
        </Modal>
    )
  } else {
    return null
  }
}

ChangeMechanicModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.any.isRequired,
  handleSubmit: PropTypes.any.isRequired,
  mechanics: PropTypes.any
};

export default ChangeMechanicModel;
