import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Permitted from "components/permission/Permitted";
import {ADMIN} from 'core/utils/permissions'
import SelectIcon from 'assets/img/select-icon.svg'
import {useTranslation} from "react-i18next";

const AdminInfoActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    props.onHandleEdit();
  }

  const handleDisable = () => {
    handleClose();
    props.onHandleDisable();
  }

  const handleEnable = () => {
    handleClose();
    props.onHandleEnable();
  }

  const handleResendActivationLink = () => {
    handleClose();
    props.onHandleResendActivationLink();
  }

  let changeStatusMenuItem;
  if (props.user && props.user.status === 'ACTIVE') {
    changeStatusMenuItem = <MenuItem onClick={handleDisable}>{t('general.disable')}</MenuItem>
  } else if (props.user && props.user.status === 'INACTIVE') {
    changeStatusMenuItem = <MenuItem onClick={handleEnable}>{t('general.enable')}</MenuItem>
  }
  const resendActivationLinkMenuItem = props.user && props.user.status === 'PENDING_EMAIL'
      ? <MenuItem onClick={handleResendActivationLink}>{t('admin_info.resend_activation_link')}</MenuItem>
      : null

  return (
      <Permitted for={ADMIN}>
        <Button aria-controls="admin-info-menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                color="primary">
          {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
        </Button>
        <Menu
            id="admin-info-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}>
          <MenuItem onClick={handleEdit}>{t('general.edit')}</MenuItem>
          {changeStatusMenuItem}
          {resendActivationLinkMenuItem}
        </Menu>
      </Permitted>
  )
}

export default AdminInfoActions
