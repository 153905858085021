import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import {addLabelSystem} from "core/actions/label-actions";
import ADD_LABEL_SYSTEM_FORM from "./add-label-system-form";

class AddLabelSystem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.backUrl = '/global/label/' + this.props.match.params.labelId + '/systems';
    this.fields = {};
  }

  componentDidMount() {
    BsForm.addValidationRule('requiredSystem', (value) => {
      return !!this.fields.system && !!this.fields.system.id;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('requiredSystem');
  }

  handleSubmit(fields, e) {
    this.props.addLabelSystem(this.props.match.params.labelId, fields.system.id);
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    return (
        <BsFormPanel size="s">
          <BsForm
              config={ADD_LABEL_SYSTEM_FORM}
              onHandleSubmit={this.handleSubmit.bind(this)}
              onHandleChange={this.handleChange.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }
          />
        </BsFormPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addLabelSystem: (labelId, systemId) => {
      dispatch(addLabelSystem(labelId, systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLabelSystem);
