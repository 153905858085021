import React from 'react';
import {connect} from "react-redux";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BsPanel from "components/panel/BsPanel";
import AllMaintenanceReportsFilters from "./AllMaintenanceReportsFilters";
import ALL_MAINTENANCE_REPORTS_TABLE from "./all-maintenance-reports-table";
import {loadAllBikeReports} from "core/actions/bike-report-actions";
import {Trans} from "react-i18next";

const queryString = require('query-string');

class AllMaintenanceReports extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        name: query.name != null ? query.name : '',
        systemName: query.systemName != null ? query.systemName : '',
        status: query.status != null ? query.status : ''
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.name = this.state.fields.name;
    params.systemName = this.state.fields.systemName;
    params.status = this.state.fields.status;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.name;
    delete params.systemName;
    delete params.status;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        name: '',
        systemName: '',
        status: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadAllBikeReports(this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/global/maintenance-report/' + row.id + '/info')
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.name) {
      query['reporter.firstName'] = query.name
      query['reporter.lastName'] = query.name
    }
    if (query.systemName) {
      query['system.name'] = query.systemName;
    }

    return query
  }

  render() {
    return (
        <BsPanel padding={true}>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle>
              <Trans i18nKey="bicycle_reports.title"/>
            </BsPanelTitle>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <AllMaintenanceReportsFilters fields={this.state.fields}
                                          onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={ALL_MAINTENANCE_REPORTS_TABLE}
                       content={this.props.reports}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    reports: state.bikeReports.reports,
    loading: state.clientUser.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllBikeReports: (systemId, query) => {
      dispatch(loadAllBikeReports(systemId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllMaintenanceReports);
