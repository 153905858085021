import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {createAdmin} from "core/actions/admin-actions";
import {ADMIN} from "core/utils/permissions";
import buildAddAdminForm from "./add-admin-form";

class AddAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        notifications: {}
      }
    }
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/admins';
  }

  componentDidMount() {
    BsForm.addValidationRule('requiredRole', (value) => {
      return !!value;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('requiredRole');
  }

  handleSubmit(fields, e) {
    this.props.createAdmin(this.props.match.params.systemId, {
      firstName: fields.firstName,
      lastName: fields.lastName,
      email: fields.email,
      role: fields.role,
      notifications: {
        pendingUser: fields.pendingUser,
        problemReport: fields.problemReport,
        weeklyReport: fields.weeklyReport
      }
    }, this.backUrl);
  }

  handleChange(fields) {
    this.setState({
      ...this.state,
      fields: fields
    })
  }

  render() {
    const data = {
      ...this.state.fields
    }

    let formConfig = buildAddAdminForm(this.state.fields.role);
    return (
        <BsFormPanel size="m">
          <PagePermission anonymous={false} permission={ADMIN}/>
          <BsForm
              config={formConfig}
              formData={data}
              onHandleChange={this.handleChange.bind(this)}
              onHandleSubmit={this.handleSubmit.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }/>
        </BsFormPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createAdmin: (systemId, data) => {
      dispatch(createAdmin(systemId, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAdmin);
