import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsTabPanel from "components/panel/BsTabPanel";
import LABEL_CLIENT_USER_PROBLEMS_TABLE from "./label-client-user-problems-table";
import Button from "@material-ui/core/Button";
import {exportLabelMaintenanceToCsv, loadLabelMaintenanceIssue} from "core/actions/maintenance-actions";

const queryString = require('query-string');

class LabelClientUserReportedProblemsTab extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  onLoadData = (query) => {
    this.props.loadLabelMaintenanceIssue(this.props.match.params.labelId, this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/label/' + this.props.match.params.labelId +
        '/user-info/' + this.props.match.params.userId + '/reported-problem/' + row.id)
  }

  export = () => {
    const query = queryString.parse(this.props.location.search);
    this.props.exportLabelMaintenanceToCsv(this.props.match.params.labelId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    query['client.id'] = this.props.match.params.userId;
    return query;
  }

  render() {
    return (
        <BsTabPanel>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle action={
              <Button variant="outlined" type="button" onClick={this.export}>
                <Trans i18nKey="general.export"/>
              </Button>
            }>
            </BsPanelTitle>
          </BsPanelHeader>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={LABEL_CLIENT_USER_PROBLEMS_TABLE}
                       content={this.props.issues}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsTabPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    issues: state.maintenance.labelIssues,
    loading: state.clientUser.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadLabelMaintenanceIssue: (labelId, query) => {
      dispatch(loadLabelMaintenanceIssue(labelId, query));
    },
    exportLabelMaintenanceToCsv: (labelId, query) => {
      dispatch(exportLabelMaintenanceToCsv(labelId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelClientUserReportedProblemsTab);
