import React from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import BsForm from "components/form/BsForm";
import CHANGE_USER_EMAIL_FORM from "./change-user-email-form";

import 'components/model/confirmation-model.scss';

const ChangeUserEmailModel = (props) => {

    const {t} = useTranslation();
    let fields = {};

    const onSubmit = () => {
        props.handleSubmit(fields.email || props.email)
    }

    const handleChange = (newData) => {
        fields = newData;
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="model-window"
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={props.open}>
                <div className="model-container model-container__middle">
                    <div className="model-body">
                        <h4 className="model-container__title">{t('change_user_email.title')}</h4>

                        <BsForm
                            config={CHANGE_USER_EMAIL_FORM}
                            formData={{email: props.email}}
                            onHandleSubmit={onSubmit}
                            onHandleChange={handleChange}
                            cancelAction={<Button variant="outlined" onClick={props.handleClose}>
                                {t('general.cancel')}
                            </Button>}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

ChangeUserEmailModel.propTypes = {
    open: PropTypes.bool.isRequired,
    email: PropTypes.string,
    handleClose: PropTypes.any.isRequired,
    handleSubmit: PropTypes.any.isRequired
};

export default ChangeUserEmailModel;
