import React from "react";
import InputMask from 'react-input-mask';
import {TextValidator} from "react-material-ui-form-validator";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsFormMaskTextField = (props) => {
    const {t} = useTranslation();

    return (
        <InputMask
            mask={props.mask}
            formatChars={props.formatChars}
            value={props.value}
            onChange={props.onHandleChange.bind(this)}
            disabled={props.disabled}
            name={props.name}
            type="text"
        >
            {() => <TextValidator
                className="form-control"
                label={t(props.label)}
                validators={props.validators}
                errorMessages={props.errors.map(error => t(error))}/>}
        </InputMask>
    )
}

BsFormMaskTextField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    mask: PropTypes.any,
    formatChars: PropTypes.any,
    disabled: PropTypes.bool,
    onHandleChange: PropTypes.func,
    value: PropTypes.any,
    validators: PropTypes.array,
    errors: PropTypes.array
};

export default BsFormMaskTextField;
