export function loadIntegrationWithVt(systemId) {
  return {
    type: "FETCH_INTEGRATION_WITH_VT",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/systems/${systemId}/fleet-integrations/vt`
      },
      globalLoading: false
    }
  };
}

export function integrateWithVt(systemId, data) {
  return {
    type: "INTEGRATE_WITH_VT",
    payload: {
      request: {
        method: 'post',
        url: `/api/v1/bo/systems/${systemId}/fleet-integrations/vt`,
        data: data
      },
      globalLoading: true,
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: `/system/${systemId}/edit-settings/integration/show`
        }
      },
      errorAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'error',
          message: 'notifications.vt_integration_error_message'
        }
      }
    }
  };
}

export function clearIntegrationWithVt(systemId) {
  return {
    type: "CLEAR_INTEGRATE_WITH_VT",
    payload: {
      request: {
        method: 'delete',
        url: `/api/v1/bo/systems/${systemId}/fleet-integrations/vt`
      },
      globalLoading: true
    },
    successAction: {
      type: 'SHOW_NOTIFICATION',
      payload: {
        variant: 'success',
        message: 'notifications.clear_integration_success_message'
      }
    },
  };
}

export function toggleOfferIntegration(systemId, data) {
  return {
    type: "TOGGLE_OFFER_INTEGRATION_INTEGRATION",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/systems/${systemId}/config/ofi`,
        data: data
      },
      globalLoading: true
    },
    successAction: {
      type: 'SHOW_NOTIFICATION',
      payload: {
        variant: 'success',
        message: 'notifications.change_offer_maintenance_plan_success_message'
      }
    },
  };
}
