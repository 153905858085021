import React from 'react';
import {connect} from "react-redux";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsTabPanel from "components/panel/BsTabPanel";
import BACK_OFFICE_USER_SYSTEMS_TABLE from "./back-office-user-systems-table";
import {loadSystemsByAdminId} from "core/actions/system-actions";

const queryString = require('query-string');

class BackOfficeUserSystemsTab extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  onLoadData = (query) => {
    this.props.loadSystemsByAdminId(this.props.match.params.userId, this.prepareQuery(query));
  }

  onRowClick = (row) => {
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }

    return query
  }

  render() {
    return (
        <BsTabPanel>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle/>
          </BsPanelHeader>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={BACK_OFFICE_USER_SYSTEMS_TABLE}
                       content={this.props.systems}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsTabPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    systems: state.system.userSystems,
    loading: state.system.loadingSystems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSystemsByAdminId: (adminId, query) => {
      dispatch(loadSystemsByAdminId(adminId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeUserSystemsTab);
