import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsFormSubmitAction = (props) => {
    const {t} = useTranslation();

    return (
        <div className="bs-form__submit-action">
            <Button variant="contained" color="primary" type="submit">
                {t(props.label)}
            </Button>
        </div>
    )
}

BsFormSubmitAction.propTypes = {
    label: PropTypes.string
};

export default BsFormSubmitAction;