import React from 'react';
import {connect} from "react-redux";
import {storeRoute} from "core/actions/route-actions";
import PropTypes from "prop-types";

class BsRouteHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromProps(props, state) {
        props.storeRoute({
            pathname: props.route.location.pathname,
            search: props.route.location.search
        })

        return null;
    }

    render() {
        return <div/>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeRoute: (route) => {
            dispatch(storeRoute(route));
        }
    };
};

BsRouteHistory.propTypes = {
    route: PropTypes.object.isRequired
};

export default connect(null, mapDispatchToProps)(BsRouteHistory)