import React from 'react'
import {useTranslation} from "react-i18next";
import Link from "@material-ui/core/Link";

const SubscriptionSettingsAddPaymentSource = (props) => {
  const {t} = useTranslation();

  const addPaymentSource = () => {
    props.history.push('/system/' + props.systemId + '/payment-source/add');
  }

  const addBankAccount = () => {
    //props.addBankAccount();
    props.history.push('/system/' + props.systemId + '/bank-account/add');
  }

  return (
    <div className="payment-source__add">
      <Link onClick={addPaymentSource.bind(this)}
            style={{cursor: 'pointer'}}>
        {t('subscription_config.add_payment_source')}
      </Link>
      {!props.bankAccount ? <>
        or
        <Link onClick={addBankAccount.bind(this)}
              style={{cursor: 'pointer'}}>
          {t('subscription_config.add_bank_account')}
        </Link>
      </> : undefined
      }

    </div>
  )
}

export default SubscriptionSettingsAddPaymentSource
