import React from "react";
import {Trans} from "react-i18next";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import BsForm from "components/form/BsForm";
import BsFormPanel from "components/form/BsFormPanel";
import isPossiblePhoneNumber from "components/validation/phone";
import PagePermission from "components/permission/PagePermission";
import ADD_SYSTEM_FORM from "./add-system-form";
import {createSystem} from "core/actions/system-actions";
import {parsePhone} from "core/utils/phone";

class AddSystem extends React.Component {

    componentDidMount() {
        BsForm.addValidationRule('isValidPhoneNumber', (value) => {
            return isPossiblePhoneNumber(value);
        });
    }

    componentWillUnmount() {
        BsForm.removeValidationRule('isValidPhoneNumber');
    }

    handleSubmit(fields) {
        const phone = parsePhone(fields.phone);
        this.props.createSystem({
            name: fields.name,
            description: fields.desc,
            phone: {
                countryCode: phone.countryCallingCode,
                number: phone.nationalNumber
            },
            country: 'US'
        });
    }

    render() {
        return (
            <BsFormPanel>
                <PagePermission anonymous={false} />
                <BsForm
                    config={ADD_SYSTEM_FORM}
                    onHandleSubmit={this.handleSubmit.bind(this)}
                    cancelAction={
                        <Button variant="outlined"
                                onClick={() => this.props.history.push('/systems')}>
                            <Trans i18nKey="general.cancel"/>
                        </Button>
                    }/>
            </BsFormPanel>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createSystem: (data) => {
            dispatch(createSystem(data));
        }
    };
};

export default connect(null, mapDispatchToProps)(AddSystem);
