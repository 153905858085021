const BICYCLE_POWER_LEVEL_HISTORY_TABLE = {
    columns: [
        {
            label: 'bicycle_info.power_level_field',
            field: 'powerLevel',
            getValue: (raw) => {
                return raw.powerLevel + '%';
            }
        },
        {
            label: 'bicycle_info.updated_at_power_level_scan_field',
            field: 'updatedAt',
            getValue: (raw) => {
                return raw.updatedAt ? raw.updatedAt : '-'
            }
        }
    ]
}

export default BICYCLE_POWER_LEVEL_HISTORY_TABLE;
