import React from 'react';
import GoogleMapReact from 'google-map-react';
import MapPin from "assets/img/maps-pin.svg";
import PropTypes from "prop-types";

const Marker = props => (
    <MapPin style={{
        transform: "translate(-20px, -36px)"
    }}/>
);

class GoogleMap extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            center: null,
            marker: null
        }
    }

    componentDidMount() {
        this.initData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.initData();
    }

    initData() {
        if (this.state.center !== this.props.center) {
            this.setState({
                ...this.state,
                center: this.props.center
            })
        }

        if (this.state.marker !== this.props.marker) {
            this.setState({
                ...this.state,
                marker: this.props.marker
            })
        }
    }

    onMapClick(e) {
        if (this.props.onChangeMarker) {
            this.props.onChangeMarker(e);
        }
    }

    onChildMouseUp(o, markerPosition, e) {
        if (this.state.clickEvent.x === e.x && this.state.clickEvent.y === e.y && this.props.onChangeMarker) {
            this.props.onChangeMarker(e);
        }
    }

    onChildMouseDown(o, markerPosition, e) {
        this.setState({
            ...this.state,
            clickEvent: e
        })
    }

    render() {
        let marker = null;
        if (this.state.marker) {
            marker = <Marker
                lat={this.state.marker.lat}
                lng={this.state.marker.lng}/>;
        }

        return (
            <div style={{height: (this.props.height ? this.props.height : '320px'), width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: 'AIzaSyD2oiYwVOea9j8e_nk1g0cwbCP0jwSg-Tc'}}
                    center={this.state.center}
                    defaultCenter={{lat: 41.304788, lng: -72.922763}}
                    onClick={this.onMapClick.bind(this)}
                    onChildMouseUp={this.onChildMouseUp.bind(this)}
                    onChildMouseDown={this.onChildMouseDown.bind(this)}
                    defaultZoom={11}>
                    {marker}
                </GoogleMapReact>
            </div>
        );
    }
}

GoogleMap.propTypes = {
    center: PropTypes.object,
    marker: PropTypes.object,
    onChangeMarker: PropTypes.func
};

export default GoogleMap;
