export const statisticsReducer = (state = {
  statistics: {},
  labelStatistics: {}
}, action) => {
  switch (action.type) {
    case 'LOAD_GLOBAL_STATISTICS':
    case 'LOAD_GLOBAL_STATISTICS_FAIL':
      return {
        ...state,
        statistics: null
      }
    case 'LOAD_GLOBAL_STATISTICS_SUCCESS':
      return {
        ...state,
        statistics: action.payload.data
      }
    case 'LOAD_LABEL_STATISTICS':
    case 'LOAD_LABEL_STATISTICS_FAIL':
      return {
        ...state,
        labelStatistics: null
      }
    case 'LOAD_LABEL_STATISTICS_SUCCESS':
      return {
        ...state,
        labelStatistics: action.payload.data
      }
    default:
      return state
  }
}
