import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

const Permitted = (props) => {
    if (!props.profile || !props.system || !props.system.role || props.for.indexOf(props.system.role) === -1) {
        return null
    }

    return (
        <>{props.children}</>
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile.profile,
        system: state.system.currentSystem
    };
};

Permitted.propTypes = {
    for: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(Permitted);
