import React from "react";
import {useTranslation} from "react-i18next";
import BsFormPanel from "components/form/BsFormPanel";

const NoLocationsForm = (props) => {
  const {t} = useTranslation();

  return (
      <BsFormPanel size="s">
        <div className="add-bike__no_locations">
          {t('add_bicycle.no_locations_error')}
        </div>
      </BsFormPanel>
  )
}

export default NoLocationsForm;
