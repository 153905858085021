import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsForm from "components/form/BsForm";
import {resetPassword, resetAuth} from "core/actions/security-actions";
import RESET_PASSWORD_FORM from "./reset-password-form";
import {PASSWORD_REGEX} from "core/utils/consts";

class ResetPassword extends React.Component {

    componentDidMount() {
        BsForm.addValidationRule('isPasswordMatch', (value) => {
            return value === (this.fields ? this.fields.password : null);
        });
        BsForm.addValidationRule('strongPassword', (value) => {
            return PASSWORD_REGEX.test(value)
        });
        this.props.resetAuth()
    }

    componentWillUnmount() {
        BsForm.removeValidationRule('isPasswordMatch');
        BsForm.removeValidationRule('strongPassword');
    }

    handleChange(fields, e) {
        this.fields = fields;
    }

    handleSubmit(fields, e) {
        this.props.resetPassword(fields.password, fields.repeatPassword, this.props.match.params.token);
    }

    render() {
        return (
            <BsFormPanel>
                <BsForm
                    config={RESET_PASSWORD_FORM}
                    onHandleChange={this.handleChange.bind(this)}
                    onHandleSubmit={this.handleSubmit.bind(this)}/>
            </BsFormPanel>
        );
    }
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (password, repeatPassword, verificationToken) => {
            dispatch(resetPassword(password, repeatPassword, verificationToken));
        },
        resetAuth: () => {
            dispatch(resetAuth());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
