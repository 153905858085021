import React from "react";
import LABEL_CLIENT_RENTAL_INFO_FORM from "./label-client-rental-info-form";
import BsFormPanel from "components/form/BsFormPanel";
import BsForm from "components/form/BsForm";
import GoogleMap from "components/map/GoogleMap";
import {fetchLabelRentalInfo} from "core/actions/rental-actions";
import {connect} from "react-redux";

class LabelClientUserRentalInfo extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.props.fetchLabelRentalInfo(this.props.match.params.labelId, this.props.match.params.rentalId);
  }

  render() {
    if (this.props.currentRental) {
      const formConfig = {
        ...LABEL_CLIENT_RENTAL_INFO_FORM,
        title: {
          back: {
            url: '/label/' + this.props.match.params.labelId + '/user/' + this.props.match.params.userId + '/rentals',
            history: this.props.history,
            location: this.props.location
          },
          text: 'rental_info.title'
        }
      };

      const markerLocation = {
        lat: this.props.currentRental.startLocation.address.latitude,
        lng: this.props.currentRental.startLocation.address.longitude
      };

      return (
          <BsFormPanel size="l">
            <BsForm
                config={formConfig}
                formData={this.props.currentRental}
                disabled={true}
                additionalContent={<GoogleMap
                    marker={markerLocation}
                    center={markerLocation}
                    height="288px"/>}/>
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentRental: state.rental.currentRental
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLabelRentalInfo: (labelId, rentalId) => {
      dispatch(fetchLabelRentalInfo(labelId, rentalId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelClientUserRentalInfo);
