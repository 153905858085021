const ADD_LOCATION_FORM = {
    title: {
        text: 'add_location.title'
    },
    submit: {
        label: 'add_location.submit_btn'
    },
    fields: [
        {
            name: 'label',
            label: 'add_location.label_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_location_name'],
            defaultValue: ''
        },
        {
            name: 'address',
            label: 'add_location.address_field',
            type: 'google-autocomplete',
            validators: ['requiredAddress'],
            errors: ['errors.required_address'],
            defaultValue: {}
        },
        {
            name: 'beaconMacAddress',
            label: 'add_location.beacon_field',
            type: 'masked-text',
            mask: '**:**:**:**:**:**',
            formatChars: {
                '9': '[0-9]',
                'a': '[A-Fa-f]',
                '*': '[1-9A-Fa-f]'
            },
            validators: [
                'macAddress'
            ],
            errors: [
                'errors.invalid_lock_format'
            ],
            defaultValue: ''
        },
        {
            name: 'capacity',
            label: 'add_location.capacity_field',
            type: 'text',
            validators: [
                'required',
                'matchRegexp:^[0-9]{1,100}$',
                'minNumber:1',
                'maxNumber:255'
            ],
            errors: [
                'errors.required_capacity',
                'errors.invalid_number_format',
                'errors.min_capacity_error',
                'errors.max_capacity_error'
            ],
            defaultValue: ''
        }
    ]
}

export default ADD_LOCATION_FORM;
