import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import {loadBicycleScanByDay} from "core/actions/bicycle-actions";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanel from "components/panel/BsPanel";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BICYCLE_SCAN_BY_DAY from "./bicycle-scan-by-day";

const queryString = require('query-string');

class BicycleScanByDay extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  onLoadData = (query) => {
    this.props.loadBicycleScanByDay(this.props.match.params.location, this.props.match.params.user,
      this.props.match.params.day, this.prepareQuery(query));
  }

  onRowClick = (row) => {
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }

    return query
  }

  render() {
    return (
      <BsPanel padding={true}>
        <BsPanelLoader show={this.props.loading}/>
        <BsPanelHeader>
          <BsPanelTitle>
            <Trans i18nKey="bicycle_scan_history.title"/>
          </BsPanelTitle>
        </BsPanelHeader>

        <BsDataTable location={this.props.location}
                     history={this.props.history}
                     order={this.state.direction}
                     orderBy={this.state.sort}
                     settings={BICYCLE_SCAN_BY_DAY}
                     content={this.props.list}
                     onRowClick={this.onRowClick}
                     onChangePage={this.onLoadData}
                     onChangeSize={this.onLoadData}
                     onRequestSort={this.onRequestSort}/>

      </BsPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.bicycle.scanByDay,
    loading: state.bicycle.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadBicycleScanByDay: (location, user, day, query) => {
      dispatch(loadBicycleScanByDay(location, user, day, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BicycleScanByDay);
