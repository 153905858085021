import React from "react";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import Col from "components/layout/Col";
import Row from "components/layout/Row";

const BicycleScanHistoryFilters = (props) => {
  const {t} = useTranslation();
  const systemRef = React.createRef();
  const locationRef = React.createRef();
  const userRef = React.createRef();
  const dateRef = React.createRef();

  return (
    <Row>
      <Col size="3">
        <BsPanelHeaderFilterControl>
          <TextField label={t('bicycle_scan_history.system_name_filter')}
                     ref={systemRef}
                     onChange={props.onHandleChange.bind(this, "system")}
                     value={props.fields["system"]}
                     className="form-control"/>
        </BsPanelHeaderFilterControl>
      </Col>
      <Col size="3">
        <BsPanelHeaderFilterControl>
          <TextField label={t('bicycle_scan_history.location_filter')}
                     ref={locationRef}
                     onChange={props.onHandleChange.bind(this, "location")}
                     value={props.fields["location"]}
                     className="form-control"/>
        </BsPanelHeaderFilterControl>
      </Col>
      <Col size="3">
        <BsPanelHeaderFilterControl>
          <TextField label={t('bicycle_scan_history.user_filter')}
                     ref={userRef}
                     onChange={props.onHandleChange.bind(this, "user")}
                     value={props.fields["user"]}
                     className="form-control"/>
        </BsPanelHeaderFilterControl>
      </Col>
      <Col size="3">
        <BsPanelHeaderFilterControl>
            <TextField
              label={props.fields["date"] ? t('bicycle_scan_history.date_filter') : ' '}
              ref={dateRef}
              type="date"
              onChange={props.onHandleChange.bind(this, "date")}
              value={props.fields["date"]}
              className="form-control"/>
        </BsPanelHeaderFilterControl>
      </Col>
    </Row>
  )
}

export default BicycleScanHistoryFilters;
