import React from "react";
import Col from "components/layout/Col";
import Row from "components/layout/Row";
import {useTranslation} from "react-i18next";
import MaintenanceVisitReportImages from "./MaintenanceVisitReportImages";

const MaintenanceVisitReportBikeInfo = (props) => {
  const {t} = useTranslation();
  const bike = props.bike;
  const title = t('maintenance_visit_info.bike_label') + ' ' + bike.number;
  const desc = bike.maintenanceComment ? bike.maintenanceComment : t('maintenance_visit_info.no_bike_report_info');

  return (
      <Row className="visit-bike-info">
        <Col size="2">
          <div className="visit-bike-info__number">
            {title}
          </div>
        </Col>
        <Col size="10">
          <div className="visit-bike-info__comment-title">
            Maintenance Comment:
          </div>
          <div className="visit-bike-info__comment-body">
            {bike.maintenanceComment ? bike.maintenanceComment : t('maintenance_visit_info.no_bike_report_info')}
          </div>
          <div className="visit-bike-info__maintenance-images">
            <MaintenanceVisitReportImages images={bike.maintenanceImages}
                                          title={title}
                                          desc={desc}/>
          </div>
        </Col>
      </Row>
  )
}

export default MaintenanceVisitReportBikeInfo;
