import React from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BsPanel from "components/panel/BsPanel";
import PagePermission from "components/permission/PagePermission";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {ADMIN} from "core/utils/permissions";
import RentalRatesTab from "./tabs/rates/RentalRatesTab";
import RentalRatesPaymentsTab from "./tabs/payments/RentalRatesPaymentsTab";
import PaymentProviderTab from "./tabs/payment-provider/PaymentProviderTab";

function RentalRates(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'rates');
  const {t} = useTranslation();

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  let tabs = null;
  if (props.profile && props.system) {
    tabs = (
        <Tabs value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary">
          <Tab label={t('rental_rates.title')} value="rates"/>
          <Tab label={t('rental_rates.payments')} value="payments"/>
          <Tab label={t('rental_rates.payment_provider')} value="payment-provider"/>
        </Tabs>
    )

    return (
        <>
          <PagePermission anonymous={false} permission={ADMIN}/>
          <BsPanel>
            {tabs}
            <Route exact path="/system/:systemId/rental-rates" component={RentalRatesTab}/>
            <Route path="/system/:systemId/rental-rates/rates" component={RentalRatesTab}/>
            <Route path="/system/:systemId/rental-rates/payments" component={RentalRatesPaymentsTab}/>
            <Route path="/system/:systemId/rental-rates/payment-provider" component={PaymentProviderTab}/>
          </BsPanel>
        </>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    system: state.system.currentSystem
  };
};

export default connect(mapStateToProps)(RentalRates);
