const buildBicycleReportInfoForm = (isAdmin) => {
  return {
    fields: [
      {
        name: 'reporter',
        label: 'bicycle_report_info.reporter_field',
        type: 'info',
        getValue: (data) => {
          return data.reporter.firstName + ' ' + data.reporter.lastName
        },
        styles: {
          marginTop: '2em'
        }
      },
      {
        name: 'reportedAt',
        label: 'bicycle_report_info.reported_at_field',
        type: 'info',
        getValue: (data) => {
          return data.reportedAt
        }
      },
      {
        name: 'status',
        label: 'bicycle_report_info.status_field',
        type: 'info',
        getValue: (data) => {
          return 'maintenance_report_status.' + data.status
        },
        translate: true
      },
      {
        name: 'bike',
        label: 'bicycle_report_info.bike_field',
        type: 'info',
        getValue: (data) => {
          return '#' + data.bike.number
        }
      },
      {
        name: 'location',
        label: 'bicycle_report_info.location_field',
        type: isAdmin ? 'reference-link' : 'info',
        getValue: (data) => {
          return data.location ? data.location.label : '-'
        },
        link: (data) => {
          if (data.location) {
            return '/system/' + data.system.id + '/location/' + data.location.id
          } else {
            return "/"
          }
        }
      },
      {
        name: 'maintenanceComment',
        label: 'bicycle_report_info.comment_field',
        type: 'info',
        getValue: (data) => {
          return data.maintenanceComment ? data.maintenanceComment : '-'
        }
      }
    ]
  };
}


export default buildBicycleReportInfoForm;
