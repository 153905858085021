import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import ADD_SYSTEM_PAYMENT_SOURCE_FORM from "./add-system-payment-source-form";
import {MANAGER} from "core/utils/permissions";
import {validateCardExpiry, validateCardNumber} from "core/utils/paymentCards";
import {addSystemPaymentSource} from "core/actions/system-payment-source-actions";

class AddSystemPaymentSource extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/subscription';
    this.fields = {};
  }

  componentDidMount() {
    BsForm.addValidationRule('isValidCardName', (value) => {
      if (value) {
        let names = value.split(' ');
        return names.length === 2 && names[0].length > 0 && names[1].length > 0
      }

      return true;
    });
    BsForm.addValidationRule('isValidCardNumber', (value) => {
      return validateCardNumber(value);
    });
    BsForm.addValidationRule('isValidExpiryDate', (value) => {
      if (value) {
        let data = value.split("/");
        if (data.length !== 2) {
          return false;
        }

        return validateCardExpiry(data[0], data[1])
      }

      return true;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('isValidCardName');
    BsForm.removeValidationRule('isValidExpiryDate');
    BsForm.removeValidationRule('isValidCardNumber');
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.setState({openConfirmationWindow: false})
    this.props.addSystemPaymentSource(this.props.match.params.systemId, {
      name: this.fields.name,
      number: this.fields.number,
      expiryDate: this.getExpiryDate(),
      cvc: this.fields.cvc,
      postalCode: this.fields.zip
    }, this.backUrl);
  }

  getExpiryDate() {
    const data = this.fields.expiry.split("/")
    return '20' + data[1] + '-' + data[0];
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    return (
        <BsFormPanel size="s">
          <PagePermission anonymous={false} permission={MANAGER}/>
          <ConfirmationModel
              open={this.state.openConfirmationWindow}
              message="add_payment_source.confirmation_message"
              handleClose={() => this.setState({openConfirmationWindow: false})}
              handleApply={this.handleUpdate.bind(this)}
          />

          <BsForm
              config={ADD_SYSTEM_PAYMENT_SOURCE_FORM}
              onHandleSubmit={this.handleSubmit.bind(this)}
              onHandleChange={this.handleChange.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }
          />
        </BsFormPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSystemPaymentSource: (systemId, data, redirectPath) => {
      dispatch(addSystemPaymentSource(systemId, data, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSystemPaymentSource);
