import {parsePhoneNumberFromString} from 'libphonenumber-js/core';
import metadata from 'libphonenumber-js/metadata.min.json'

export default function isPossiblePhoneNumber(value) {
    if (!value) {
        return false;
    }

    let phoneNumber = parsePhoneNumberFromString(value, metadata);

    if (!phoneNumber) {
        return false;
    }

    return phoneNumber.isPossible();
}