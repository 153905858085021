import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import Col from "components/layout/Col";
import Row from "components/layout/Row";
import {useTranslation} from "react-i18next";

const LocationFilters = (props) => {
    const {t} = useTranslation();
    const labelRef = React.createRef();
    const addressRef = React.createRef();

    return (
        <Row>
            <Col size="6">
                <BsPanelHeaderFilterControl>
                    <TextField label={t('locations.label_filter')}
                               ref={labelRef}
                               onChange={props.onHandleChange.bind(this, "label")}
                               value={props.fields["label"]}
                               className="form-control"/>
                </BsPanelHeaderFilterControl>
            </Col>
            <Col size="6">
                <BsPanelHeaderFilterControl>
                    <TextField label={t('locations.address_filter')}
                               ref={addressRef}
                               onChange={props.onHandleChange.bind(this, "address")}
                               value={props.fields["address"]}
                               className="form-control"/>
                </BsPanelHeaderFilterControl>
            </Col>
        </Row>
    )
}

export default LocationFilters;
