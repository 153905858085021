import React from "react";
import RentalPaymentForm from "../form/RentalPaymentForm";

class RentalPaymentInfo extends React.Component {

  constructor(props) {
    super(props);
    this.backUrl = '/system/' + this.props.match.params.systemId + '/rental-rate/'
        + this.props.match.params.rateId + '/payments'
  }

  render() {
    return (
        <RentalPaymentForm backUrl={this.backUrl}
                           history={this.props.history}
                           location={this.props.location}
                           match={this.props.match}/>
    );
  }
}

export default RentalPaymentInfo;
