import React from "react";
import Col from "components/layout/Col";
import Row from "components/layout/Row";
import {useTranslation} from "react-i18next";
import MaintenanceVisitInfoReport from "./MaintenanceVisitInfoReport";
import Moment from "moment";

import './MaintenanceVisitInfoMainDesc.scss';

const MaintenanceVisitInfoMainDesc = (props) => {
  const {t} = useTranslation();
  const visit = props.visit;
  const showReport = visit.externalBookingVisits && visit.externalBookingVisits.length > 0;

  function timeFormat(time) {
    const parts = time.split(':');
    return parts[0] + ':' + parts[1];
  }

  const appointment = showReport ? Moment(visit.externalBookingVisits[0].appointment.startDate).format('MMM DD')
      : (Moment(visit.startDate).format('MMM DD') + '-' +
          Moment(visit.endDate).format('MMM DD') + '(' + timeFormat(visit.startTime) + '-'
          + timeFormat(visit.endTime) + ')');

  return (
      <div className="maintenance-visit-desc">
        <h2 className="align-center">{visit.service.name + ' #' + visit.number}</h2>

        <hr/>

        <Row>
          <Col size="6">
            <div className="maintenance-visit-desc__item-title">
              {t('maintenance_visit_info.mechanic_label')}
            </div>
            <div className="maintenance-visit-desc__item-info">
              {visit.mechanic.firstName} {visit.mechanic.lastName}
            </div>
          </Col>
          <Col size="6">
            <div className="maintenance-visit-desc__item-title">
              {t('maintenance_visit_info.status_label')}
            </div>
            <div className="maintenance-visit-desc__item-info">
              {t('maintenance_visit_status.' + visit.status)}
              <br/>
              {appointment}
            </div>
          </Col>
        </Row>

        {showReport ? <MaintenanceVisitInfoReport visit={visit}/> : null}
      </div>
  )
}

export default MaintenanceVisitInfoMainDesc;
