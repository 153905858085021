import React from "react";

import './BsTabPanel.scss'

const BsTabPanel = (props) => {
    return (
        <div className="bs-tab-panel">
            {props.children}
        </div>
    )
}

export default BsTabPanel;