import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsFormSubTitle = (props) => {
    const {t} = useTranslation();

    return (
        <h2>{t(props.text, props.args)}</h2>
    )
}

BsFormSubTitle.propTypes = {
    text: PropTypes.string
};

export default BsFormSubTitle;
