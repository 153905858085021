const SELECT_SYSTEM_FORM = {
    submit: {
        label: 'add_maintenance_plan.select_system_submit_btn'
    },
    fields: [
        {
            name: 'system',
            label: 'add_maintenance_plan.system_field',
            type: 'systems-autocomplete',
            validators: ['requiredSystem'],
            errors: ['errors.required_system'],
            defaultValue: {}
        }
    ]
}

export default SELECT_SYSTEM_FORM;
