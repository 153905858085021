import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import GoogleMap from "components/map/GoogleMap";
import ConfirmationModel from "components/model/ConfirmationModel";
import BsFormPanel from "components/form/BsFormPanel";
import RentalInfoFormActions from "./RentalInfoFormActions";
import {ADMIN} from "core/utils/permissions";
import {fetchRental, finishRental, resolveRentalProblem} from "core/actions/rental-actions";
import buildRenalInfoForm from "./rental-info-form";
import ResolveRentalProblemModel from "./ResolveRentalProblemModel";

class RentalInfoForm extends React.Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            confirmationWindow: {
                open: false,
                message: '',
                event: () => {
                }
            },
            resolveProblemWindow: {
                open: false,
            }
        };
        this.props.fetchRental(this.props.match.params.systemId, this.props.match.params.rentalId);
    }

    onHandleFinish() {
        this.setState({
            ...this.state,
            confirmationWindow: {
                open: true,
                message: 'rental_info.finish_rental_message',
                event: this.finishRental
            }
        })
    }

    finishRental() {
        this.closeConfirmationModel();
        this.props.finishRental(this.props.match.params.systemId, this.props.match.params.rentalId)
    }

    closeConfirmationModel() {
        this.setState({
            ...this.state,
            confirmationWindow: {
                ...this.state.confirmationWindow,
                open: false
            }
        });
    }

    onHandleResolve() {
        this.setState({
            ...this.state,
            resolveProblemWindow: {
                open: true
            }
        })
    }

    resolveProblem(comment) {
        this.closeResolveModel();
        this.props.resolveRentalProblem(this.props.match.params.systemId,
            this.props.match.params.rentalId, {comment: comment})
    }

    closeResolveModel() {
        this.setState({
            ...this.state,
            resolveProblemWindow: {
                open: false
            }
        })
    }

    render() {
        if (this.props.currentRental) {
            const formConfig = buildRenalInfoForm(this.props.currentRental);
            formConfig.title = {
                actions: <RentalInfoFormActions
                    rental={this.props.currentRental}
                    onHandleFinish={this.onHandleFinish.bind(this)}
                    onHandleResolve={this.onHandleResolve.bind(this)}/>,
                back: {
                    url: this.props.backUrl,
                    history: this.props.history,
                    location: this.props.location
                },
                text: 'rental_info.title'
            }

            const markerLocation = {
                lat: this.props.currentRental.startLocation.address.latitude,
                lng: this.props.currentRental.startLocation.address.longitude
            };

            return (
                <BsFormPanel size="l">
                    <PagePermission anonymous={false} permission={ADMIN}/>
                    <ConfirmationModel
                        open={this.state.confirmationWindow.open}
                        message={this.state.confirmationWindow.message}
                        handleClose={this.closeConfirmationModel.bind(this)}
                        handleApply={this.state.confirmationWindow.event.bind(this)}
                    />

                    <ResolveRentalProblemModel
                        open={this.state.resolveProblemWindow.open}
                        handleClose={this.closeResolveModel.bind(this)}
                        handleSubmit={this.resolveProblem.bind(this)}
                    />

                    <BsForm
                        config={formConfig}
                        formData={this.props.currentRental}
                        disabled={true}
                        additionalContent={<GoogleMap
                            marker={markerLocation}
                            center={markerLocation}
                            height="246px"/>}/>
                </BsFormPanel>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        currentRental: state.rental.currentRental
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRental: (systemId, rentalId) => {
            dispatch(fetchRental(systemId, rentalId));
        },
        finishRental: (systemId, rentalId) => {
            dispatch(finishRental(systemId, rentalId));
        },
        resolveRentalProblem: (systemId, rentalId, data) => {
            dispatch(resolveRentalProblem(systemId, rentalId, data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalInfoForm);
