import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import PropTypes from "prop-types";
import {getCurrentPath} from "core/utils/pathUtils";

const PagePermission = (props) => {
    // only admin pages for signed in user
    if (props.anonymous && props.isLoggedIn) {
        return <Redirect to="/systems"/>
    }

    // only home page for anonymous
    if (!props.anonymous && !props.isLoggedIn) {
        return <Redirect to={"/sign-in?redirectTo=" + getCurrentPath()}/>
    }

    // has no permissions
    if (props.role && props.roles && props.roles.indexOf(props.role) === -1) {
        return <Redirect to={"/system/" + props.systemId}/>
    }

    return null
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.security.isLoggedIn,
        role: state.system.currentSystem ? state.system.currentSystem.role : null,
        systemId: state.system.systemId
    };
};

PagePermission.propTypes = {
    anonymous: PropTypes.bool,
    roles: PropTypes.string
};

export default connect(mapStateToProps)(PagePermission);
