import React from "react";
import {TextValidator} from "react-material-ui-form-validator";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsFormTextField = (props) => {
    const {t} = useTranslation();

    return (
        <TextValidator label={t(props.label)}
                       name={props.name}
                       type={props.type}
                       multiline={props.multiline}
                       disabled={props.disabled}
                       onChange={props.onHandleChange.bind(this)}
                       value={props.value}
                       validators={props.validators}
                       errorMessages={props.errors.map(error => t(error))}
                       className="form-control"/>
    )
}

BsFormTextField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onHandleChange: PropTypes.func,
    value: PropTypes.any,
    validators: PropTypes.array,
    errors: PropTypes.array
};

export default BsFormTextField;