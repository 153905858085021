const ADD_LABEL_FORM = {
  title: {
    text: 'add_label.title'
  },
  submit: {
    label: 'add_label.submit_btn'
  },
  fields: [
    {
      name: 'label',
      label: 'add_label.label_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_label'],
      defaultValue: ''
    }
  ]
}

export default ADD_LABEL_FORM;
