const queryString = require('query-string');

export function createAdmin(systemId, data) {
    return {
        type: "CREATE_ADMIN_USER",
        payload: {
            request: {
                method: 'post',
                url: `/api/v1/bo/${systemId}/users`,
                data: data
            },
            globalLoading: true,
            successCallback: (response) => {
                return {
                    type: 'REDIRECT_TO',
                    payload: `/system/${systemId}/admins/${response.data.id}/info`
                }
            }
        }
    };
}

export function fetchSystemAdmin(systemId, userId) {
    return {
        type: "FETCH_SYSTEM_ADMIN",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/users/${userId}`
            },
            globalLoading: true
        }
    };
}

export function editAdmin(systemId, userId, data) {
    return {
        type: "UPDATE_ADMIN_PERMISSIONS",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/users/${userId}`,
                data: data
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.update_permissions_success_message'
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.update_permissions_error_message'
                }
            },
            redirect: '/system/' + systemId + '/admins/' + userId + '/info',
            globalLoading: true
        }
    };
}

export function changeAdminStatus(systemId, userId, status) {
    return {
        type: "UPDATE_ADMIN_STATUS",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/users/${userId}/status`,
                data: {
                    status: status
                }
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: status === 'ACTIVE' ? 'notifications.activate_admin_success_message' : 'notifications.inactivate_admin_success_message'
                }
            },
            globalLoading: true
        }
    };
}

export function resendActivationLink(systemId, userId) {
    return {
        type: "RESEND_ACTIVATION_LINK",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/users/${userId}/resend-activation-link`
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.activation_admin_link_was_successfully_resent'
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.resend_activation_admin_link_error'
                }
            },
            globalLoading: true
        }
    };
}

export function setEditingPermissions(setEditingPermissions) {
    return {
        type: "SET_EDITING_PERMISSIONS",
        payload: setEditingPermissions
    };
}

export function loadAdmins(systemId, query) {
    return {
        type: "FETCH_ADMIN_USERS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/users?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function loadAllAdmins(systemId, query) {
    return {
        type: "FETCH_ALL_ADMIN_USERS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/users?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true
        }
    };
}

export function exportAdminsToCsv(systemId, query) {
    return {
        type: "EXPORT_ADMIN_USERS_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/users/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}

export function fetchBoUser(userId) {
    return {
        type: "FETCH_BO_USER",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/users/${userId}`
            },
            globalLoading: true
        }
    };
}

export function loadBoUsers(query) {
    return {
        type: "FETCH_BO_USERS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/users?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function exportBoUsersToCsv(query) {
    return {
        type: "EXPORT_BO_USERS_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/users/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}

export function adminsAutocomplete(query) {
    return {
        type: "ADMINS_AUTOCOMPLETE",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/users/autocomplete?firstName=${query}&lastName=${query}`
            }
        }
    };
}
