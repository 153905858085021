const LABEL_SYSTEM_INFO_FORM = {
  fields: [
    {
      name: 'name',
      label: 'label_system_info.system_name_field',
      type: 'info',
      styles: {
        maxWidth: '500px',
        marginTop: '2em'
      },
    },
  ]
}

export default LABEL_SYSTEM_INFO_FORM;
