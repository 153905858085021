import React from 'react';

import './BsPage.scss';

class BsPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="bs-page">
                {this.props.children}
            </div>
        );
    }
}

export default BsPage;
