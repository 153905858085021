import Moment from 'moment';

const PASSWORDS_TABLE = {
  columns: [
    {
      label: 'passwords.password_header',
      field: 'password',
      ordering: true
    },
    {
      label: 'passwords.status_header',
      field: 'status',
      ordering: true,
      translate: true,
      getValue(raw) {
        return 'password_status.' + raw.status;
      }
    },
    {
      label: 'passwords.from_header',
      field: 'from',
      getValue: (raw) => {
        return Moment(raw.from).format('DD MMM YYYY')
      },
      ordering: true
    },
    {
      label: 'passwords.to_header',
      field: 'to',
      getValue: (raw) => {
        return Moment(raw.to).format('DD MMM YYYY')
      },
      ordering: true
    },
    {
      label: 'passwords.limit_header',
      field: 'limit',
      getValue: (raw) => {
        return raw.unlimited ? '-' : raw.limit
      },
      ordering: false
    }
  ]
}

export default PASSWORDS_TABLE;
