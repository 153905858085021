import React, {useEffect} from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import BsPanel from "components/panel/BsPanel";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {fetchRentalRate} from "core/actions/rental-rate-actions";
import RentalRateInfoTab from "./tabs/info/RentalRateInfoTab";
import RentalPaymentsTab from "./tabs/payments/RentalPaymentsTab";

function RentalRateInfo(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
  const {t} = useTranslation();

  useEffect(() => {
    if (!props.loadingcurrentRate) {
      props.fetchRentalRate(props.match.params.systemId, props.match.params.rateId);
    }
  }, []);

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  let tabs = null;
  if (props.profile && props.system && props.system.role && props.currentRate) {
    tabs = (
        <Tabs value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary">
          <Tab label={t('rental_rate_info.info_tab')} value="info"/>
          <Tab label={t('rental_rate_info.payments_tab')} value="payments"/>
        </Tabs>
    )
  }

  return (
      <>
        <PagePermission anonymous={false} permission={ADMIN}/>
        <BsPanel>
          {tabs}
          <Route exact path="/system/:systemId/rental-rate/:rateId" component={RentalRateInfoTab}/>
          <Route path="/system/:systemId/rental-rate/:rateId/info" component={RentalRateInfoTab}/>
          <Route path="/system/:systemId/rental-rate/:rateId/payments" component={RentalPaymentsTab}/>
        </BsPanel>
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    system: state.system.currentSystem,
    currentRate: state.rentalRate.currentRate
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRentalRate: (systemId, rateId) => {
      dispatch(fetchRentalRate(systemId, rateId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalRateInfo);
