import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {createRentalRate} from "core/actions/rental-rate-actions";
import CREATE_RENTAL_RATE_FORM from "./add-rental-rate-form";

import "./add-rental-rate.scss";

class AddRentalRate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: '',
        price: '',
        currency: 'USD',
        rentalDurationAmount: '',
        rentalDurationUnit: 'HOURS',
        /*refundPolicyAmount: '',
        refundPolicyUnit: 'MINUTES',
        gracePeriodAmount: '',
        gracePeriodUnit: 'MINUTES',*/
        lateFeeAmount: '',
        lateFeeCurrency: 'USD',
        type: 'SINGLE'
      }
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/rental-rates';
  }

  handleSubmit(fields, e) {
    this.props.createRentalRate(this.props.match.params.systemId, {
      name: fields.name,
      price: fields.price,
      currency: fields.currency,
      rentalDuration: {
        amount: fields.rentalDurationAmount,
        unit: fields.rentalDurationUnit,
      },
      /*refundPolicy: {
        amount: fields.refundPolicyAmount,
        unit: fields.refundPolicyUnit,
      },
      gracePeriod: {
        amount: fields.gracePeriodAmount,
        unit: fields.gracePeriodUnit,
      },*/
      lateFeeAmount: fields.lateFeeAmount,
      lateFeeCurrency: fields.lateFeeCurrency,
      type: fields.type
    });
  }

  handleChange(fields) {
    this.setState({
      ...this.state,
      fields: fields
    });
  }

  render() {
    return (
      <div className="add-rental-rate">
        <BsFormPanel>
          <PagePermission anonymous={false} permission={ADMIN}/>
          <BsForm
            config={CREATE_RENTAL_RATE_FORM}
            onHandleSubmit={this.handleSubmit.bind(this)}
            onHandleChange={this.handleChange.bind(this)}
            cancelAction={
              <BsFormCancelAction history={this.props.history}
                                  location={this.props.location}
                                  url={this.backUrl}/>
            }/>
        </BsFormPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRentalRate: (systemId, data) => {
      dispatch(createRentalRate(systemId, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRentalRate);
