import React from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BsPanel from "components/panel/BsPanel";
import PagePermission from "components/permission/PagePermission";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {ADMIN} from "core/utils/permissions";
import ActiveRentals from "./tabs/active/ActiveRentals";
import RentalsHistory from "./tabs/history/RentalsHistory";

function Rentals(props) {
    const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
    const defaultTab = lastSegment.split('?')[0]
    const [value, setValue] = React.useState(defaultTab ? defaultTab : 'active');
    const {t} = useTranslation();

    const handleChange = (event, newValue) => {
        if (value !== newValue) {
            props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
        }
        setValue(newValue);
    };

    let tabs = null;
    if (props.profile && props.system) {
        tabs = (
            <Tabs value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary">
                <Tab label={t('rentals.active')} value="active"/>
                <Tab label={t('rentals.history')} value="history"/>
            </Tabs>
        )
    }

    return (
        <>
            <PagePermission anonymous={false} permission={ADMIN}/>
            <BsPanel>
                {tabs}
                <Route exact path="/system/:systemId/rentals" component={ActiveRentals}/>
                <Route path="/system/:systemId/rentals/active" component={ActiveRentals}/>
                <Route path="/system/:systemId/rentals/history" component={RentalsHistory}/>
            </BsPanel>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile.profile,
        system: state.system.currentSystem
    };
};

export default connect(mapStateToProps)(Rentals);
