const INTEGRATE_WITH_VELOTOOLER_FORM = {
    title: {
        text: 'integrate_with_velotooler.title'
    },
    submit: {
        label: 'integrate_with_velotooler.submit_btn'
    },
    fields: [
        {
            name: 'code',
            label: 'integrate_with_velotooler.app_code_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_app_code'],
            defaultValue: ''
        },
        {
            name: 'clientId',
            label: 'integrate_with_velotooler.client_id_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_client_id_field'],
            defaultValue: ''
        },
        {
            name: 'clientSecret',
            label: 'integrate_with_velotooler.client_secret_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_client_secret'],
            defaultValue: ''
        },
        {
            name: 'scopes',
            label: 'integrate_with_velotooler.scopes_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_scopes'],
            defaultValue: ''
        }
    ]
}

export default INTEGRATE_WITH_VELOTOOLER_FORM;
