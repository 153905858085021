import Moment from 'moment';

const FLEET_MAINTENANCE_FORM = {
    fields: [
        {
            name: 'plan',
            label: 'integration.maintenance_plan_field',
            type: 'info',
            translate: true,
            styles: {
                maxWidth: '500px'
            },
            getValue: (data) => {
                return 'maintenance_plan_type.plan_' + data.plan;
            }
        },
        {
            name: 'plan_delimiter',
            type: 'delimiter',
            styles: {
                maxWidth: '500px'
            }
        },
        {
            name: 'runTime',
            label: 'integration.maintenance_start_date_field',
            type: 'info',
            translate: false,
            styles: {
                maxWidth: '500px'
            },
            getValue: (data) => {
                return data.runTime ? Moment(data.runTime).format('MMMM YYYY') : '-'
            }
        },
        {
            name: 'run_time_delimiter',
            type: 'delimiter',
            styles: {
                maxWidth: '500px'
            }
        },
        {
            name: 'serviceProvider',
            label: 'integration.service_provider_field',
            type: 'info',
            translate: true,
            styles: {
                maxWidth: '500px'
            },
            getValue: (data) => {
                return 'integration.service_provider_value'
            }
        },
        {
            name: 'service_provider_delimiter',
            type: 'delimiter',
            styles: {
                maxWidth: '500px'
            }
        }
    ]
}

export default FLEET_MAINTENANCE_FORM;
