import {saveFile} from "../utils/export";

export const maintenanceReducer = (state = {
    issues: {},
    allIssues: {},
    labelIssues: {},
    currentIssue: null,
    loading: false
}, action) => {
    switch (action.type) {
        case 'FETCH_MAINTENANCE_ISSUES':
        case 'FETCH_ALL_MAINTENANCE_ISSUES':
        case 'FETCH_LABEL_MAINTENANCE_ISSUES':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_MAINTENANCE_ISSUES_SUCCESS':
            return {
                ...state,
                loading: false,
                issues: action.payload.data
            }
        case 'FETCH_ALL_MAINTENANCE_ISSUES_SUCCESS':
            return {
                ...state,
                loading: false,
                allIssues: action.payload.data
            }
        case 'FETCH_LABEL_MAINTENANCE_ISSUES_SUCCESS':
            return {
                ...state,
                loading: false,
                labelIssues: action.payload.data
            }
        case 'FETCH_MAINTENANCE_ISSUES_FAIL':
        case 'FETCH_ALL_MAINTENANCE_ISSUES_FAIL':
        case 'FETCH_LABEL_MAINTENANCE_ISSUES_FAIL':
            return {
                ...state,
                loading: false
            }
        case 'FETCH_MAINTENANCE_ISSUE_FAIL':
        case 'FETCH_MAINTENANCE_ISSUE_INFO_FAIL':
        case 'FETCH_LABEL_MAINTENANCE_ISSUE_INFO_FAIL':
        case 'UPDATE_MAINTENANCE_ISSUE_STATUS_FAIL':
        case 'UPDATE_MAINTENANCE_ISSUE_PRIORITY_FAIL':
            return {
                ...state,
                currentIssue: null
            }
        case 'FETCH_MAINTENANCE_ISSUE_SUCCESS':
        case 'FETCH_MAINTENANCE_ISSUE_INFO_SUCCESS':
        case 'FETCH_LABEL_MAINTENANCE_ISSUE_INFO_SUCCESS':
        case 'UPDATE_MAINTENANCE_ISSUE_STATUS_SUCCESS':
        case 'UPDATE_MAINTENANCE_ISSUE_PRIORITY_SUCCESS':
            return {
                ...state,
                currentIssue: action.payload.data
            }
        case 'EXPORT_MAINTENANCE_TO_CSV_SUCCESS':
        case 'EXPORT_ALL_MAINTENANCE_TO_CSV_SUCCESS':
        case 'EXPORT_LABEL_MAINTENANCE_TO_CSV_SUCCESS':
            saveFile(action.payload)
            return state
        case 'FETCH_MAINTENANCE_ISSUE':
        case 'FETCH_MAINTENANCE_ISSUE_INFO':
        case 'FETCH_LABEL_MAINTENANCE_ISSUE_INFO':
        case 'UPDATE_MAINTENANCE_ISSUE_STATUS':
        case 'UPDATE_MAINTENANCE_ISSUE_PRIORITY':
        case 'EXPORT_MAINTENANCE_TO_CSV':
        case 'EXPORT_MAINTENANCE_TO_CSV_FAIL':
        case 'EXPORT_ALL_MAINTENANCE_TO_CSV':
        case 'EXPORT_ALL_MAINTENANCE_TO_CSV_FAIL':
        case 'EXPORT_LABEL_MAINTENANCE_TO_CSV':
        case 'EXPORT_LABEL_MAINTENANCE_TO_CSV_FAIL':
        default:
            return state
    }
}
