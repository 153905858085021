const queryString = require('query-string');

export function loadRentalRates(systemId, query) {
  return {
    type: "FETCH_RENTAL_RATES",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/rental-rates?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function createRentalRate(systemId, data) {
  return {
    type: "CREATE_RENTAL_RATE",
    payload: {
      request: {
        method: 'post',
        url: `/api/v1/bo/${systemId}/rental-rates`,
        data: data
      },
      globalLoading: true,
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: `/system/${systemId}/rental-rate/${response.data.id}/info`
        }
      }
    }
  };
}

export function fetchRentalRate(systemId, rateId) {
  return {
    type: "FETCH_RENTAL_RATE",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/rental-rates/${rateId}`
      },
      globalLoading: true
    }
  };
}

export function updateRentalRate(systemId, rateId, data) {
  return {
    type: "UPDATE_RENTAL_RATE",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/${systemId}/rental-rates/${rateId}`,
        data: data
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_rental_rate_success_message'
        }
      },
      redirect: `/system/${systemId}/rental-rate/${rateId}/info`,
      globalLoading: true
    }
  };
}

export function deleteRentalRate(systemId, rateId) {
  return {
    type: "DELETE_RENTAL_RATE",
    payload: {
      request: {
        method: 'delete',
        url: `/api/v1/bo/${systemId}/rental-rates/${rateId}`
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.delete_rental_rate_success_message'
        }
      },
      redirect: `/system/${systemId}/rental-rates`,
      globalLoading: true
    }
  };
}
