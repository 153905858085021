import React from 'react'
import Lightbox from 'react-lightbox-component';
import {useTranslation} from "react-i18next";

import 'react-lightbox-component/build/css/index.css'

const SupportIssueImages = (props) => {
    const {t} = useTranslation();

    if (props.images != null) {
        const images = []
        const problem = props.title ? props.title : "OTHER";
        const title = t('issue_problem.' + problem);

        for (let i = 0; i < props.images.length; i++) {
            images.push({
                id: props.images[i].id,
                src: props.images[i].original,
                title: title,
                description: props.desc
            })
        }

        return (
            <div className="support-issue__images-container">
                <h3>{t('issue_info.problem_photo')}</h3>
                <Lightbox images={images}/>
            </div>
        )
    } else {
        return null
    }
}

export default SupportIssueImages
