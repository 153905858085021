import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import {createLabel} from "core/actions/label-actions";
import ADD_LABEL_FORM from "./add-label-form";

class AddLabel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.backUrl = '/global/labels';
    this.fields = {};
  }

  handleSubmit(fields, e) {
    this.props.createLabel({
      label: fields.label
    });
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    return (
        <BsFormPanel size="s">
          <BsForm
              config={ADD_LABEL_FORM}
              onHandleSubmit={this.handleSubmit.bind(this)}
              onHandleChange={this.handleChange.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }
          />
        </BsFormPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createLabel: (data) => {
      dispatch(createLabel(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLabel);
