import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import Link from "@material-ui/core/Link";

const BsFormLink = (props) => {
    const {t} = useTranslation();

    return (
        <div className="bs-form__link-container" style={props.styles}>
            <Link onClick={props.onHandleClick}
                  style={{cursor: 'pointer'}}>{(props.translate ? t(props.label) : props.label)}</Link>
        </div>
    )
}

BsFormLink.propTypes = {
    label: PropTypes.string.isRequired,
    translate: PropTypes.bool,
    styles: PropTypes.any,
    onHandleClick: PropTypes.func.isRequired
};

export default BsFormLink;
