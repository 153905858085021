import React from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useTranslation} from "react-i18next";
import BsPanel from "components/panel/BsPanel";
import PageVtPermission from "components/permission/PageVtPermission";
import ClientUserInfoTab from "./tabs/info/ClientUserInfoTab";
import ClientUserRentalsTab from "./tabs/rentals/ClientUserRentalsTab";
import ClientUserReportedProblemsTab from "./tabs/reported-problems/ClientUserReportedProblemsTab";
import ClientUserSystemsTab from "./tabs/systems/ClientUserSystemsTab";

function ClientUserInfo(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
  const {t} = useTranslation();

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  return (
      <>
        {/*<PageVtPermission/>*/}
        <BsPanel>
          <Tabs value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary">
            <Tab label={t('user_info.info_tab')} value="info"/>
            <Tab label={t('user_info.rentals_tab')} value="rentals"/>
            <Tab label={t('user_info.reported_problems_tab')} value="reported-problems"/>
            <Tab label={t('user_info.systems_tab')} value="systems"/>
          </Tabs>

          <Route exact path="/global/user/:userId" component={ClientUserInfoTab}/>
          <Route path="/global/user/:userId/info" component={ClientUserInfoTab}/>
          <Route path="/global/user/:userId/rentals" component={ClientUserRentalsTab}/>
          <Route path="/global/user/:userId/reported-problems" component={ClientUserReportedProblemsTab}/>
          <Route path="/global/user/:userId/systems" component={ClientUserSystemsTab}/>
        </BsPanel>
      </>
  );
}

export default ClientUserInfo;
