import React from 'react';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button";
import BsPanel from "components/panel/BsPanel";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsBody from "components/panel/BsBody";
import InfiniteLoading from "components/infinite-loading/InfiniteLoading";
import PagePermission from "components/permission/PagePermission";
import SearchSystems from "./SearchSystems";
import {Trans} from "react-i18next";
import {loadSystems, refreshSystems, selectSystem} from "core/actions/system-actions";
import PlusIcon from "assets/img/plus.svg";

import './Systems.scss';

class Systems extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      search: ''
    };

    this.countOfCalls = 0;
  }

  componentDidMount() {
    this.props.refreshSystems(this.state.search)
  }

  handleChange(field, e) {
    this.setState({
      ...this.state,
      search: e.target.value
    });
    this.refreshSystems()
  }

  refreshSystems() {
    this.countOfCalls++;
    setTimeout(() => {
      this.countOfCalls--;
      // skip some fast serial calls
      if (this.countOfCalls === 0) {
        this.props.refreshSystems(this.state.search)
      }
    }, 500);
  }

  loadSystems() {
    if (this.props.systems.hasNext) {
      this.props.loadSystems(this.state.search, this.props.systems.current + 1)
    }
  }

  selectSystem(system) {
    this.props.history.push('/system/' + system.id);
    this.props.selectSystem(system.id);
  }

  addSystem() {
    this.props.history.push('/create-system');
  }

  render() {
    if (this.props.systemIsLoading) {
      return <div></div>;
    }

    const list = this.props.systems.content.map((item, i) => {
      return (
          <div className={item.id == this.props.systemId ? 'system-item active' : 'system-item'}
               key={i}
               onClick={this.selectSystem.bind(this, item)}>{item.name}</div>
      )
    })

    let progress = null;
    if (this.props.loadingSystems) {
      progress = <CircularProgress/>
    }

    let addSystem = null;
    if (this.props.isSuperAdmin) {
      addSystem = <Button variant="contained"
                          color="primary"
                          type="button"
                          onClick={this.addSystem.bind(this)}>
        <PlusIcon/>&nbsp;<Trans i18nKey="systems.add"/>
      </Button>;
    }

    return (
        <BsPanel padding={true}>
          <PagePermission anonymous={false}/>
          <BsPanelHeader>
            <BsPanelTitle action={addSystem}>
              <Trans i18nKey="systems.title"/>
            </BsPanelTitle>
          </BsPanelHeader>
          <BsBody>
            <SearchSystems search={this.state.search}
                           onHandleChange={this.handleChange.bind(this)}/>
            <div className="system-list">
              <InfiniteLoading handleLoading={this.loadSystems.bind(this)}
                               loading={this.props.loadingSystems}>
                {list}
                <div className="system-list__loading">
                  {progress}
                </div>
              </InfiniteLoading>
            </div>
          </BsBody>
        </BsPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    systems: state.system.systems,
    systemId: state.system.systemId,
    loadingSystems: state.system.loadingSystems,
    profile: state.profile.profile,
    systemIsLoading: state.system.systemIsLoading,
    isSuperAdmin: state.profile.profile != null && state.profile.profile.role != null
        && state.profile.profile.role.indexOf('SUPER_ADMIN') > -1
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSystems: (name, page) => {
      dispatch(loadSystems(name, page));
    },
    refreshSystems: (name) => {
      dispatch(refreshSystems(name));
    },
    selectSystem: (id) => {
      dispatch(selectSystem(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Systems);
