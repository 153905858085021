import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import ConfirmationModel from "components/model/ConfirmationModel";
import {loadLabel, updateLabel} from "core/actions/label-actions";
import EDIT_LABEL_FORM from "./edit-label-form";

class EditLabel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/global/label/' + this.props.match.params.labelId;
    this.fields = {};
    this.props.loadLabel(this.props.match.params.labelId);
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.setState({openConfirmationWindow: false})
    this.props.updateLabel(this.props.match.params.labelId, {
      label: this.fields.label
    });
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    if (this.props.currentLabel) {
      const data = {
        ...this.props.currentLabel
      }

      return (
          <BsFormPanel size="s">
            <ConfirmationModel
                open={this.state.openConfirmationWindow}
                message="edit_label.confirmation_message"
                handleClose={() => this.setState({openConfirmationWindow: false})}
                handleApply={this.handleUpdate.bind(this)}
            />

            <BsForm
                config={EDIT_LABEL_FORM}
                formData={data}
                onHandleSubmit={this.handleSubmit.bind(this)}
                onHandleChange={this.handleChange.bind(this)}
                cancelAction={
                  <BsFormCancelAction history={this.props.history}
                                      location={this.props.location}
                                      url={this.backUrl}/>
                }
            />
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentLabel: state.label.currentLabel
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadLabel: (labelId) => {
      dispatch(loadLabel(labelId));
    },
    updateLabel: (bicycleId, data) => {
      dispatch(updateLabel(bicycleId, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLabel);
