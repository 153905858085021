import React from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import BsForm from "components/form/BsForm";
import ADD_LIST_ITEM_FORM from "./add-email-domain-form";

import 'components/model/confirmation-model.scss';

const AddEmailDomainModel = (props) => {
    const {t} = useTranslation();
    let fields = {};

    const onSubmit = () => {
        props.handleSubmit(fields.text)
    }

    const handleChange = (newData) => {
        fields = newData;
    }

    const config = {
        ...ADD_LIST_ITEM_FORM,
        fields: [
            ...ADD_LIST_ITEM_FORM.fields
        ]
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="model-window"
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={props.open}>
                <div className="model-container">
                    <div className="model-body">
                        <h4>{t('edit_user_config.add_email_domain_title')}</h4>

                        <BsForm
                            config={config}
                            onHandleSubmit={onSubmit}
                            onHandleChange={handleChange}
                            cancelAction={<Button variant="outlined" onClick={props.handleClose}>
                                {t('general.cancel')}
                            </Button>}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default AddEmailDomainModel;