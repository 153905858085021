const buildAdminInfoForm = (role) => {
  const adminInfoForm = {
    title: {
      text: 'admin_info.title',
      size: 'm'
    },
    submit: {
      label: 'admin_info.submit_btn'
    },
    fields: [
      {
        name: 'fullName',
        label: 'admin_info.full_name_field',
        type: 'info'
      },
      {
        name: 'email',
        label: 'admin_info.email_field',
        type: 'info'
      },
      {
        name: 'status',
        label: 'admin_info.status_field',
        type: 'info',
        translate: true,
        getValue: (data) => {
          return 'admin_status.' + data.status;
        }
      },
      {
        name: 'role',
        label: 'admin_info.role_field',
        type: 'info',
        translate: true,
        getValue: (data) => {
          return 'admin_role.' + data.role;
        }
      }
    ]
  };

  if (role === 'ADMIN') {
    adminInfoForm.fields.push({
      name: 'adminNotifications',
      type: 'subTitle',
      label: 'admin_info.admin_notifications_sub_title'
    });
    adminInfoForm.fields.push({
      name: "notifications.pendingUser",
      label: 'admin_info.pending_user_notifications',
      type: 'info',
      translate: true,
      getValue: (data) => {
        return data.notifications.pendingUser ? "general.yes" : "general.no";
      }
    });
    adminInfoForm.fields.push({
      name: "notifications.problemReport",
      label: 'admin_info.problem_report_notifications',
      type: 'info',
      translate: true,
      getValue: (data) => {
        return data.notifications.problemReport ? "general.yes" : "general.no";
      }
    });
    adminInfoForm.fields.push({
      name: "notifications.weeklyReport",
      label: 'admin_info.weekly_report_notifications',
      type: 'info',
      translate: true,
      getValue: (data) => {
        return data.notifications.weeklyReport ? "general.yes" : "general.no";
      }
    });
  }

  return adminInfoForm;
}


export default buildAdminInfoForm;
