import Moment from 'moment';

export const BACK_OFFICE_USERS_TABLE = {
  columns: [
    {
      label: 'admins.full_name_header',
      field: 'firstName,lastName',
      getValue: (raw) => {
        return raw.firstName + ' ' + raw.lastName
      },
      ordering: true
    },
    {
      label: 'admins.email_header',
      field: 'email',
      getValue: (raw) => {
        return raw.email
      },
      ordering: true
    },
    {
      label: 'admins.status_header',
      field: 'status',
      ordering: false,
      translate: true,
      getValue(raw) {
        return 'admin_status.' + raw.status;
      }
    },
    {
      label: 'admins.joined_at_header',
      field: 'createdAt',
      getValue: (raw) => {
        return Moment(raw.createdAt).format('DD MMM YYYY')
      },
      ordering: true
    },
  ]
}
