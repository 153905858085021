import React from 'react';

import './BsPanelHeaderFilterControl.scss';

function BsPanelHeaderFilterControl(props) {
    return (
        <div className="bs-header__filters-control">
            {props.children}
        </div>
    );
}

export default BsPanelHeaderFilterControl;
