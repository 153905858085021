const CHANGE_TAX_FORM = {
  submit: {
    label: 'general_config.save_payment_provider_btn'
  },
  fields: [
    {
      name: 'collectTax',
      label: 'general_config.collect_tax_field',
      type: 'checkbox',
      styles: {
        marginRight: '-12px'
      },
      defaultValue: false
    },
    {
      name: 'tax',
      label: 'general_config.tax_field',
      type: 'text',
      validators: [
        'required',
        'matchRegexp:^(?=.)(?:\\d+,)*\\d*(?:\\.\\d+)?$',
      ],
      errors: [
        'errors.required_api_key',
        'errors.invalid_number_format'
      ],
      defaultValue: '0',
      showFn: (data) => {
        return data.collectTax
      }
    }
  ]
}

export default CHANGE_TAX_FORM;
