import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"

import './BsPanelLoader.scss'
import PropTypes from "prop-types";

const BsPanelLoader = (props) => {
    if (props.show) {
        return (
            <div className="bs-panel__loader-container">
                <div className="bs-panel__loader">
                    <CircularProgress/>
                </div>
            </div>
        );
    } else {
        return null
    }
}

BsPanelLoader.propTypes = {
    show: PropTypes.bool.isRequired
};

export default BsPanelLoader;