const CLIENT_USER_SYSTEMS_TABLE = {
  columns: [
    {
      label: 'admin_info.system_name_header',
      field: 'name',
      translate: false,
      ordering: true
    },
    {
      label: 'admin_info.status_header',
      field: 'userStatus',
      translate: true,
      getValue: (raw) => {
        return 'client_system_status.' + raw.userStatus;
      },
      ordering: true
    }
  ]
}

export default CLIENT_USER_SYSTEMS_TABLE;
