import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import ADD_LABEL_ADMIN_FORM from "./add-label-admin-form";
import {addLabelAdmin} from "core/actions/label-actions";

class AddLabelAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.backUrl = '/global/label/' + this.props.match.params.labelId + '/admins';
    this.fields = {};
  }

  componentDidMount() {
    BsForm.addValidationRule('requiredUser', (value) => {
      return !!this.fields.user && !!this.fields.user.id;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('requiredUser');
  }

  handleSubmit(fields, e) {
    this.props.addLabelAdmin(this.props.match.params.labelId, fields.user.id);
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    return (
        <BsFormPanel size="s">
          <BsForm
              config={ADD_LABEL_ADMIN_FORM}
              onHandleSubmit={this.handleSubmit.bind(this)}
              onHandleChange={this.handleChange.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }
          />
        </BsFormPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addLabelAdmin: (labelId, systemId) => {
      dispatch(addLabelAdmin(labelId, systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLabelAdmin);
