import React from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import BsDataTable from "components/data-table/BsDataTable";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import BICYCLE_POWER_LEVEL_HISTORY_TABLE from "./power-level-history-table";

import './PowerLevelHistoryModel.scss';
import 'components/model/confirmation-model.scss';

const PowerLevelHistoryModel = (props) => {

    const {t} = useTranslation();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="model-window power-level-history"
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={props.open}>
                <div className="model-container model-container__middle">
                    <div className="model-body">
                        <h4 className="model-container__title">{t('bicycle_info.power_level_info_model_title')}</h4>

                        <BsDataTable settings={BICYCLE_POWER_LEVEL_HISTORY_TABLE}
                                     content={{content: props.history}}/>

                        <div className="model-footer">
                            <div className="model-footer__cancel-action">
                                <Button variant="outlined" onClick={props.handleClose}>
                                    {t('general.cancel')}
                                </Button>
                            </div>

                            <div className="model-footer__submit-action">
                                <Button variant="contained" color="primary" onClick={props.handleClear}>
                                    {t('bicycle_info.clear_power_level_history')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

PowerLevelHistoryModel.propTypes = {
    open: PropTypes.bool.isRequired,
    history: PropTypes.array.isRequired,
    handleClose: PropTypes.any.isRequired,
    handleClear: PropTypes.any.isRequired
};

export default PowerLevelHistoryModel;
