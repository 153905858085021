import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import {changePassword} from "core/actions/security-actions";
import RESET_PASSWORD_FORM from "./change-password-form";
import {PASSWORD_REGEX} from "core/utils/consts";

class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.backUrl = '/systems';
        this.state = {
            data: {
                oldPassword: '',
                password: '',
                repeatPassword: ''
            }
        };
    }

    componentDidMount() {
        BsForm.addValidationRule('isPasswordMatch', (value) => {
            return value === (this.fields ? this.fields.password : null);
        });
        BsForm.addValidationRule('strongPassword', (value) => {
            return PASSWORD_REGEX.test(value)
        });
    }

    componentWillUnmount() {
        BsForm.removeValidationRule('isPasswordMatch');
        BsForm.removeValidationRule('strongPassword');
    }

    handleChange(fields, e) {
        this.fields = fields;
    }

    handleSubmit(fields, e) {
        this.props.changePassword({
            oldPassword: fields.oldPassword,
            password: fields.password,
            repeatPassword: fields.repeatPassword
        }, () => {
            this.setState({
                data: {
                    oldPassword: '',
                    password: '',
                    repeatPassword: ''
                }
            })
        });
    }

    render() {
        if (!this.props.passwordIsChanging) {
            return (
                <BsFormPanel>
                    <PagePermission anonymous={false}/>
                    <BsForm
                        config={RESET_PASSWORD_FORM}
                        formData={this.state.data}
                        onHandleChange={this.handleChange.bind(this)}
                        onHandleSubmit={this.handleSubmit.bind(this)}
                        cancelAction={
                            <BsFormCancelAction history={this.props.history}
                                                location={this.props.location}
                                                url={this.backUrl}/>
                        }/>
                </BsFormPanel>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        passwordIsChanging: state.security.passwordIsChanging
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (data, callback) => {
            dispatch(changePassword(data, callback));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
