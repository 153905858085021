import React from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import './confirmation-model.scss';

const InfoModel = (props) => {
  const {t} = useTranslation();
  const yesBtn = props.yesBtn ? props.yesBtn : 'general.ok';

  return (
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model-window"
          open={props.open}
          onClose={props.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
        <Fade in={props.open}
              style={{maxWidth: '500px'}}>
          <div className="model-container">
            <div className="model-title">
              <h2>{t(props.title)}</h2>
            </div>

            <div className="model-body">
              <h4>{t(props.message)}</h4>
            </div>

            <div className="model-footer">
              <div className="model-footer__cancel-action"/>

              <div className="model-footer__submit-action">
                <Button variant="contained" color="primary" onClick={props.handleClose}>
                  {t(yesBtn)}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
  )
}

InfoModel.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.any.isRequired
};

export default InfoModel;
