const CHANGE_SYSTEM_NAME_FORM = {
  submit: {
    label: 'general_config.change_btn'
  },
  fields: [
    {
      name: 'name',
      label: 'general_config.name_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_name'],
      defaultValue: ''
    }
  ]
}

export default CHANGE_SYSTEM_NAME_FORM;
