import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const BsTableHead = (props) => {
    const {t} = useTranslation();
    const {order, orderBy, onRequestSort, settings} = props;

    const createSortHandler = (headCell) => (event) => {
        if (headCell.ordering) {
            onRequestSort(headCell.field);
        }
    };

    const getLabel = (headCell) => {
        if (headCell.ordering) {
            return <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell)}>
                {t(headCell.label)}
            </TableSortLabel>
        } else {
            return t(headCell.label);
        }
    }

    return (
        <TableHead>
            <TableRow>
                {settings.columns.map((headCell) => (
                    <TableCell
                        key={headCell.field}
                        align='left'
                        padding='default'
                        sortDirection={orderBy === headCell.field ? order : false}>
                        {getLabel(headCell)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

BsTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string,
    settings: PropTypes.object,
    onRequestSort: PropTypes.func
};

export default BsTableHead;