import React from 'react';
import {connect} from "react-redux";
import Button from "@material-ui/core/Button"
import BsFormPanel from "components/form/BsFormPanel";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {forgotPassword} from "core/actions/security-actions";
import FORGOT_PASSWORD_FORM from "./forgot-password-form";
import {useTranslation} from "react-i18next";

const ForgotPassword = (props) => {
    const {t} = useTranslation();

    const handleSubmit = (fields) => {
        props.forgotPassword(fields.email);
    }

    return (
        <BsFormPanel>
            <PagePermission anonymous={true} />
            <BsForm
                config={FORGOT_PASSWORD_FORM}
                onHandleSubmit={handleSubmit}
                cancelAction={
                    <Button variant="outlined"
                            onClick={() => props.history.push('/sign-in')}>
                        {t('general.cancel')}
                    </Button>
                }/>
        </BsFormPanel>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        forgotPassword: (email) => {
            dispatch(forgotPassword(email));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
