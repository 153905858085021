import React from 'react';
import Row from "../../layout/Row";
import Col from "../../layout/Col";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

import './BsPanelHeaderFilters.scss';
import PropTypes from "prop-types";

function BsPanelHeaderFilters(props) {
    const {t} = useTranslation();

    return (
        <div className="bs-header__filters">
            <Row>
                <Col size={8}>
                    <div className="bs-header__filters-inputs">
                        {props.children}
                    </div>
                </Col>
                <Col size={4}>
                    <div className="bs-header__filters-actions">
                        <Button className="bs-filters-actions__filter"
                                variant="contained"
                                color="primary"
                                onClick={() => props.onHandleFilter()}>
                            {t('general.filter')}
                        </Button>
                        <Button className="bs-filters-actions__clear"
                                variant="outlined"
                                onClick={() => props.onHandleClear()}>
                            {t('general.clear')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

BsPanelHeaderFilters.propTypes = {
    onHandleFilter: PropTypes.func.isRequired,
    onHandleClear: PropTypes.func.isRequired
};

export default BsPanelHeaderFilters;
