const CHANGE_PROFILE_FORM = {
    title: {
        text: 'change_profile.title'
    },
    submit: {
        label: 'change_profile.submit_btn'
    },
    fields: [
        {
            name: 'firstName',
            label: 'change_profile.first_name_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_first_name'],
            defaultValue: ''
        },
        {
            name: 'lastName',
            label: 'change_profile.last_name_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_last_name'],
            defaultValue: ''
        }
    ]
}

export default CHANGE_PROFILE_FORM;
