import Moment from 'moment';

export const TABLE_SETTINGS = {
    columns: [
        {
            label: 'admins.full_name_header',
            field: 'user.firstName,user.lastName',
            getValue: (raw) => {
                return raw.firstName + ' ' + raw.lastName
            },
            ordering: true
        },
        {
            label: 'admins.email_header',
            field: 'user.email',
            getValue: (raw) => {
                return raw.email
            },
            ordering: true
        },
        {
            label: 'admins.role_header',
            field: 'user.role',
            getValue: (raw) => {
                return 'admin_role.' + raw.role;
            },
            translate: true,
            ordering: true
        },
        {
            label: 'admins.status_header',
            field: 'status',
            ordering: false,
            translate: true,
            getValue(raw) {
                return 'admin_status.' + raw.status;
            }
        },
        {
            label: 'admins.joined_at_header',
            field: 'createdAt',
            getValue: (raw) => {
                return Moment(raw.createdAt).format('DD MMM YYYY')
            },
            ordering: true
        },
    ]
}
