import React from 'react'
import Lightbox from 'react-lightbox-component';

import 'react-lightbox-component/build/css/index.css'

const MaintenanceVisitReportImages = (props) => {
  if (props.images != null) {
    const images = props.images.map(image => {
      return {
        id: image.id,
        src: image.original,
        title: props.title,
        description: props.desc
      }
    })

    return (
        <Lightbox images={images}/>
    )
  } else {
    return null
  }
}

export default MaintenanceVisitReportImages
