import React from "react";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanel from "components/panel/BsPanel";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import {exportBoUsersToCsv, loadBoUsers} from "core/actions/admin-actions";
import {BACK_OFFICE_USERS_TABLE} from "./back-office-users-table";
import BackOfficeUsersFilters from "./BackOfficeUsersFilters";
import {Trans} from "react-i18next";

const queryString = require('query-string');

class BackOfficeUsers extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        name: query.name != null ? query.name : '',
        email: query.email != null ? query.email : ''
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.name = this.state.fields.name;
    params.email = this.state.fields.email;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.name;
    delete params.email;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        name: '',
        email: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadBoUsers(this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/global/bo-user-info/' + row.id)
  }

  export = () => {
    const query = queryString.parse(this.props.location.search);
    this.props.exportBoUsersToCsv(this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.name) {
      query['firstName'] = query.name
      query['lastName'] = query.name
    }

    return query
  }

  render() {
    return (
        <BsPanel padding={true}>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle action={
              <Button variant="outlined" type="button" onClick={this.export}>
                <Trans i18nKey="general.export"/>
              </Button>
            }>
              <Trans i18nKey="admins.title"/>
            </BsPanelTitle>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <BackOfficeUsersFilters fields={this.state.fields}
                                    onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={BACK_OFFICE_USERS_TABLE}
                       content={this.props.admins}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    admins: state.admin.boUsers,
    loading: state.admin.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadBoUsers: (query) => {
      dispatch(loadBoUsers(query));
    },
    exportBoUsersToCsv: (query) => {
      dispatch(exportBoUsersToCsv(query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeUsers);
