import Moment from 'moment';

const columnStyle = (row) => {
  if (['CREATED', 'RESOLVED', 'CANCELED'].indexOf(row.status) !== -1) {
    return {color: 'rgba(0, 0, 0, 0.54)'};
  } else {
    return {}
  }
};

const MAINTENANCE_VISITS_TABLE = {
  columns: [
    {
      label: 'maintenance_visits.system_name_header',
      field: 'system.name',
      getValue: (raw) => {
        return raw.system.name;
      },
      ordering: false,
      getStyle: columnStyle
    },
    {
      label: 'maintenance_visits.number_header',
      field: 'number',
      getValue: (raw) => {
        return raw.number;
      },
      ordering: true,
      getStyle: columnStyle
    },
    {
      label: 'maintenance_visits.status_header',
      field: 'status',
      ordering: true,
      translate: true,
      getValue(raw) {
        return 'maintenance_visit_status.' + raw.status;
      },
      getStyle: (row) => {
        if (['CREATED', 'CANCELED'].indexOf(row.status) !== -1) {
          return {color: 'rgba(0, 0, 0, 0.54)'};
        } else if ('FAILED' === row.status) {
          return {color: '#f44336'};
        } else if ('RESOLVED' === row.status) {
          return {color: '#4CAF50'};
        } else {
          return {}
        }
      }
    },
    {
      label: 'maintenance_visits.mechanic_header',
      field: 'mechanic.firstName,mechanic.lastName',
      getValue: (raw) => {
        return raw.mechanic.firstName + ' ' + raw.mechanic.lastName
      },
      ordering: true,
      getStyle: columnStyle
    },
    {
      label: 'maintenance_visits.service_header',
      field: 'service.name',
      getValue: (raw) => {
        return raw.service.name
      },
      ordering: true,
      getStyle: columnStyle
    },
    {
      label: 'maintenance_visits.visit_date_header',
      field: 'startDate',
      getValue: (raw) => {
        return Moment(raw.startDate).format('MMM YYYY')
      },
      ordering: true,
      getStyle: columnStyle
    }
  ]
}

export default MAINTENANCE_VISITS_TABLE;
