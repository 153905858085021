import {priceToString} from "../../../../../core/utils/priceUtils";

const SUBSCRIPTION_PLANS_TABLE = {
  columns: [
    {
      label: 'subscription_plans.name_header',
      field: 'name',
      ordering: true
    },
    {
      label: 'subscription_plans.billing_period_header',
      field: 'billingPeriod',
      ordering: true,
      translate: true,
      getValue(raw) {
        return 'subscription_billing_period.' + raw.billingPeriod;
      }
    },
    {
      label: 'subscription_plans.price_per_bike_header',
      field: 'pricePerBike',
      getValue: (raw) => {
        return priceToString(raw.pricePerBike, raw.currency)
      },
      ordering: true,
    },
    {
      label: 'subscription_plans.auto_cancellation_header',
      field: 'autoCancellation',
      ordering: true,
      translate: true,
      getValue: (raw) => {
        return raw.autoCancellation ? raw.autoCancellation : 'subscription_plans.none_auto_cancellation';
      }
    }
  ]
}

export default SUBSCRIPTION_PLANS_TABLE;
