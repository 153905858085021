const EDIT_BILLING_ADDRESS_FORM = {
  title: {
    text: 'edit_billing_address.title'
  },
  submit: {
    label: 'edit_billing_address.submit_btn'
  },
  fields: [
    {
      name: 'name',
      label: 'edit_billing_address.name_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_client_name'],
      defaultValue: ''
    },
    {
      name: 'street',
      label: 'edit_billing_address.street_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_street'],
      defaultValue: ''
    },
    {
      name: 'building',
      label: 'edit_billing_address.building_field',
      type: 'text',
      validators: [],
      errors: [],
      defaultValue: ''
    },
    {
      name: 'city',
      label: 'edit_billing_address.city_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_city'],
      defaultValue: ''
    },
    {
      name: 'state',
      label: 'edit_billing_address.state_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_state'],
      defaultValue: ''
    },
    {
      name: 'zip',
      label: 'edit_billing_address.zip_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_zip'],
      defaultValue: ''
    },
    {
      name: 'country',
      label: 'edit_billing_address.country_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_country'],
      defaultValue: ''
    },
  ]
}

export default EDIT_BILLING_ADDRESS_FORM;
