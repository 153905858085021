import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import BsTablePaginationActions from "./BsTablePaginationActions";
import PropTypes from "prop-types";

import './BsTablePagination.scss';

const BsTablePagination = (props) => {
    const {content, onChangePage, onChangeSize} = props;

    const handleChangePage = (newPage) => {
        onChangePage(newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        onChangeSize(parseInt(event.target.value, 10))
    };

    return (
        <TableFooter>
            <TableRow>
                <TablePagination
                    className="bs-table-pagination"
                    rowsPerPageOptions={[10, 25, 50, 100, 500]}
                    count={content.totalElements ? content.totalElements : 0}
                    rowsPerPage={content.pageable ? content.pageable.pageSize : 10}
                    page={content.pageable ? content.pageable.pageNumber : 0}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={BsTablePaginationActions}

                />
            </TableRow>
        </TableFooter>
    )
}

BsTablePagination.propTypes = {
    content: PropTypes.object,
    onChangePage: PropTypes.func,
    onChangeSize: PropTypes.func
};

export default BsTablePagination;
