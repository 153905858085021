import React from 'react'
import {useTranslation} from "react-i18next";
import EditIcon from "assets/img/ic-edit.svg";
import RadioButtonCheckedIcon from "assets/img/radio-button-checked.svg";
import RadioButtonUncheckedIcon from "assets/img/radio-button-unchecked.svg";
import BankAccountIcon from 'assets/img/account-balance-wallet.svg';
import ErrorIcon from 'assets/img/error.svg';
import TaskAllIcon from 'assets/img/task-alt.svg';
import DeleteIcon from "assets/img/delete.svg";

const SubscriptionSettingsBankAccount = (props) => {
  const {t} = useTranslation();

  const setDefault = () => {
    if (!props.bankAccount.default) {
      props.setDefault();
    }
  }

  const onUpdate = () => {
    props.onUpdate();
  }

  const onRemove = () => {
    props.onRemove();
  }

  const isDefault = props.bankAccount.default ? <RadioButtonCheckedIcon/> : <RadioButtonUncheckedIcon/>;
  const paymentMessage = props.bankAccount.paymentsEnabled
    ? t('subscription_config.payments_enabled_label')
    : t('subscription_config.payments_disabled_label');

  return (
    <div className="payment-source">
      <div className="payment-source__default-status" onClick={setDefault.bind(this)}>
        {isDefault}
      </div>

      <div className="payment-source__icon">
        <BankAccountIcon/>
      </div>

      <div className="payment-source__number">
        {t('subscription_config.bank_account_label')}
      </div>

      <div className="payment-source__remove">
        <DeleteIcon  onClick={onRemove.bind(this)} />

        { !props.bankAccount.legacy ? <>
          <EditIcon onClick={onUpdate.bind(this)}/>
          <div title={paymentMessage}>
            {props.bankAccount.paymentsEnabled ? <TaskAllIcon/> : <ErrorIcon/>}
          </div>
        </> : undefined}
      </div>
    </div>
  )
}

export default SubscriptionSettingsBankAccount
