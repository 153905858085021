import React from 'react'
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'
import {enUS} from '@material-ui/core/locale';
import LoadingIndicator from "components/loading/LoadingIndicator";

import {checkSignIn} from "core/actions/security-actions";
import {selectSystem} from "core/actions/system-actions";
import {connect} from "react-redux";
import {getSystemId} from "core/utils/pathUtils";

import './App.scss';
import AppRouting from "./AppRouting";

class App extends React.Component {
  constructor(props) {
    super(props);
    props.checkSignIn();
    props.selectSystem(getSystemId());

    this.theme = createMuiTheme({
      palette: {
        primary: {main: '#4080BF'},
      },
    }, enUS);
  }

  render() {
    return (
        <ThemeProvider theme={this.theme}>
          <AppRouting/>

          <LoadingIndicator show={this.props.loading}/>
        </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.app.loading,
    isLoggedIn: state.security.isLoggedIn
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkSignIn: () => {
      dispatch(checkSignIn());
    },
    selectSystem: (id) => {
      dispatch(selectSystem(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
