import React from "react";
import {TextValidator} from "react-material-ui-form-validator";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {CARDS} from "core/utils/paymentCards";

const BsFormCurdNumberField = (props) => {
  const {t} = useTranslation();

  const onHandleChange = (e) => {
    const number = e.target.value;
    e.target.value = formatCardNumber(number);
    props.onHandleChange(e);
  }

  const cardFromNumber = (num) => {
    let card;
    if (!num) {
      return null;
    }
    num = num.toString().replace(/\W/g, '');
    for (let i = 0, len = CARDS.length; i < len; i++) {
      card = CARDS[i];
      if (card.pattern.test(num)) {
        return card;
      }
    }

    return null;
  };

  const formatCardNumber = (num) => {
    if (!num)
      return '';

    num = num.replace(/\D/g, '');
    const card = cardFromNumber(num);

    if (!card) {

      let length = num.length;
      num = num.split('');

      //Add separator
      if (length > 4) {
        num.splice(4, 0, ' ');
        length++;
      }
      if (length > 9) {
        num.splice(9, 0, ' ');
        length++;
      }
      if (length > 14) {
        num.splice(14, 0, ' ');
        length++;
      }
      if (length > 19) {
        num.splice(19, 0, ' ');
      }

      return num.join('').substr(0, 19);
    }

    const upperLength = card.length[card.length.length - 1];

    num = num.slice(0, +upperLength || 9e9);

    let result;
    let ref;

    if (card.format.global) {
      result = (ref = num.match(card.format)) !== null ? ref.join(' ') : '';
    } else {
      const groups = card.format.exec(num);
      if (groups !== null) {
        groups.shift();
      }
      result = groups !== null ? groups.join(' ') : '';
    }

    return result;
  };

  return (
      <TextValidator label={t(props.label)}
                     name={props.name}
                     type={props.type}
                     multiline={props.multiline}
                     disabled={props.disabled}
                     onChange={onHandleChange.bind(this)}
                     value={props.value}
                     validators={props.validators}
                     errorMessages={props.errors.map(error => t(error))}
                     className="form-control"/>
  )
}

BsFormCurdNumberField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onHandleChange: PropTypes.func,
  value: PropTypes.any,
  validators: PropTypes.array,
  errors: PropTypes.array
};

export default BsFormCurdNumberField;
