import React from "react";
import {connect} from "react-redux";
import BsDataTable from "components/data-table/BsDataTable";
import BsTabPanel from "components/panel/BsTabPanel";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import RentalPaymentsFilters from "./RentalPaymentsFilters";
import buildRentalRatePaymentsTable from "./rental-payments-table";
import {loadRentalPayments} from "core/actions/rental-payment-actions";
import {fetchSystemConfig} from "core/actions/system-config-actions";

const queryString = require('query-string');

class RentalPaymentsTab extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        name: query.name != null ? query.name : '',
      }
    };

    this.onLoadData(query);
    this.props.fetchSystemConfig(props.match.params.systemId);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.name = this.state.fields.name;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.name;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        name: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadRentalPayments(this.props.match.params.systemId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.name) {
      query['client.firstName'] = query.name
      query['client.lastName'] = query.name
    }
    query.rateId = this.props.match.params.rateId;

    return query
  }

  onRowClick = (row) => {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/rental-rate-payment/'
        + this.props.match.params.rateId + '/' + row.id)
  }

  render() {
    if (this.props.systemConfig) {
      return (
        <BsTabPanel>
          <PagePermission anonymous={false} permission={ADMIN}/>
          <BsPanelLoader show={this.props.loading}/>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <RentalPaymentsFilters fields={this.state.fields}
                                   onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={buildRentalRatePaymentsTable(this.props.systemConfig.collectTax)}
                       content={this.props.payments}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsTabPanel>
      )
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    payments: state.rentalPayment.rentalPayments,
    loading: state.rentalPayment.loading,
    systemConfig: state.systemConfig.systemConfig
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRentalPayments: (systemId, query) => {
      dispatch(loadRentalPayments(systemId, query));
    },
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalPaymentsTab);
