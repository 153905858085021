const BACK_OFFICE_USER_INFO_FORM = {
  fields: [
    {
      name: 'fullName',
      label: 'admin_info.full_name_field',
      type: 'info',
      styles: {
        maxWidth: '500px',
        marginTop: '2em'
      },
      getValue: (data) => {
        return data.firstName + ' ' + data.lastName
      },
    },
    {
      name: 'email',
      label: 'admin_info.email_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      }
    },
    {
      name: 'status',
      label: 'admin_info.status_field',
      type: 'info',
      translate: true,
      getValue: (data) => {
        return 'admin_status.' + data.status;
      },
      styles: {
        maxWidth: '500px'
      }
    }
  ]
}

export default BACK_OFFICE_USER_INFO_FORM;
