import React from "react";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const CustomerSupportFilters = (props) => {
    const {t} = useTranslation();

    return (
        <>
            {/*<BsPanelHeaderFilterControl>
                <TextField label={t('customer_support.name_filter')}
                           ref={nameRef}
                           onChange={props.onHandleChange.bind(this, "name")}
                           value={props.fields["name"]}
                           className="form-control"/>
            </BsPanelHeaderFilterControl>*/}
            <BsPanelHeaderFilterControl>
                <FormControl className="form-control">
                    <InputLabel id="status-label">{t('customer_support.status_filter')}</InputLabel>
                    <Select
                        labelId="status-label"
                        id="status"
                        multiple
                        value={props.fields["status"]}
                        renderValue={(selected) => selected
                            .map(val => t('issue_status.' + val))
                            .join(', ')}
                        onChange={props.onHandleChange.bind(this, "status")}>
                        <MenuItem value="CREATED">{t('issue_status.CREATED')}</MenuItem>
                        <MenuItem value="ASSIGNED">{t('issue_status.ASSIGNED')}</MenuItem>
                        <MenuItem value="IN_PROGRESS">{t('issue_status.IN_PROGRESS')}</MenuItem>
                        <MenuItem value="DONE">{t('issue_status.DONE')}</MenuItem>
                        <MenuItem value="CLOSED">{t('issue_status.CLOSED')}</MenuItem>
                    </Select>
                </FormControl>
            </BsPanelHeaderFilterControl>
        </>
    )
}

export default CustomerSupportFilters;
