export function startLoading() {
    return {type: 'START_LOADING'}
}

export function stopLoading() {
    return {type: 'STOP_LOADING'}
}

export function clearNotifications() {
    return {type: 'CLEAR_NOTIFICATIONS'}
}

export function redirectTo(path) {
    return {
        type: 'REDIRECT_TO',
        payload: path
    }
}

export function clearRedirect() {
    return {type: 'CLEAR_REDIRECT'}
}

export function showNotification(variant, message) {
    return {
        type: 'SHOW_NOTIFICATION',
        payload: {
            variant: variant,
            message: message
        }
    }
}

export function fetchAppVersion() {
    return {
        type: "FETCH_VERSION",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/version`
            },
            globalLoading: true
        }
    };
}
