import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import BsFormPanel from "components/form/BsFormPanel";
import ConfirmationModel from "components/model/ConfirmationModel";
import {ADMIN, MANAGER} from "core/utils/permissions";
import BicycleReportImages from "./BicycleReportImages";
import {changeBicycleReportStatus, createIssueByReport, fetchBikeReport} from "core/actions/bike-report-actions";
import buildBicycleReportInfoForm from "./bicycle-report-info-form";
import BicycleReportActions from "./BicycleReportActions";

import './bicycle-report-info-form.scss';

class BicycleReportInfo extends React.Component {

  constructor(props) {
    super(props);
    this.backUrl = '/system/' + this.props.match.params.systemId + '/bicycle/' + this.props.match.params.bicycleId + '/reports'
    this.state = {
      openChangeStatusConfirmationWindow: false,
      openCreateIssueConfirmationWindow: false
    };

    this.props.fetchBikeReport(this.props.match.params.systemId, this.props.match.params.reportId);
  }

  onHandleReportProblem() {
    this.setState({
      ...this.state,
      openCreateIssueConfirmationWindow: true
    })
  }

  closeReportProblemModel() {
    this.setState({
      ...this.state,
      openCreateIssueConfirmationWindow: false
    })
  }

  onSubmitReportProblem() {
    this.closeReportProblemModel();
    this.props.createIssueByReport(this.props.match.params.systemId,
        this.props.match.params.reportId)
  }

  onHandleChangeStatus() {
    this.setState({
      ...this.state,
      openChangeStatusConfirmationWindow: true
    })
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      openChangeStatusConfirmationWindow: false
    })
  }

  onSubmitChangeStatus() {
    this.closeConfirmationModel();
    this.props.changeBicycleReportStatus(this.props.match.params.systemId,
        this.props.match.params.reportId, {status: "COMPLETED"})
  }

  render() {
    if (this.props.currentReport) {
      const formConfig = buildBicycleReportInfoForm(this.props.currentRole === ADMIN);
      formConfig.title = {
        back: {
          url: this.backUrl,
          history: this.props.history,
          location: this.props.location
        },
        text: 'bicycle_report_info.title',
        actions: <BicycleReportActions
            report={this.props.currentReport}
            onHandleReportProblem={this.onHandleReportProblem.bind(this)}
            onHandleChangeStatus={this.onHandleChangeStatus.bind(this)}/>
      }
      return (
          <BsFormPanel size="m">
            <PagePermission anonymous={false} permission={MANAGER}/>
            <ConfirmationModel
                open={this.state.openChangeStatusConfirmationWindow}
                message="bicycle_report_info.complete_report_confirmation_message"
                handleClose={this.closeConfirmationModel.bind(this)}
                handleApply={this.onSubmitChangeStatus.bind(this)}
            />

            <ConfirmationModel
                open={this.state.openCreateIssueConfirmationWindow}
                message="bicycle_report_info.create_issue_confirmation_message"
                handleClose={this.closeReportProblemModel.bind(this)}
                handleApply={this.onSubmitReportProblem.bind(this)}
            />

            <BsForm
                config={formConfig}
                formData={this.props.currentReport}
                disabled={true}/>

            <BicycleReportImages images={this.props.currentReport.maintenanceImages}
                                 bike={this.props.currentReport.bike.number}
                                 desc={this.props.currentReport.maintenanceComment}/>
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentReport: state.bikeReports.currentReport,
    currentRole: state.system.currentSystem ? state.system.currentSystem.role : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBikeReport: (systemId, reportId) => {
      dispatch(fetchBikeReport(systemId, reportId));
    },
    changeBicycleReportStatus: (systemId, reportId, data) => {
      dispatch(changeBicycleReportStatus(systemId, reportId, data));
    },
    createIssueByReport: (systemId, reportId) => {
      dispatch(createIssueByReport(systemId, reportId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BicycleReportInfo);
