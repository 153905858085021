export const TABLE_LOCATION_RENTALS = {
    columns: [
        {
            label: 'rentals.bicycle_header',
            field: 'bike.number',
            getValue: (raw) => {
                return "# " + raw.bike.number
            },
            ordering: true
        },
        {
            label: 'rentals.full_name_header',
            field: 'client.firstName,client.lastName',
            getValue: (raw) => {
                return raw.client.firstName + ' ' + raw.client.lastName
            },
            ordering: true
        },
        {
            label: 'rentals.start_time_header',
            field: 'startTime',
            getValue: (raw) => {
                return raw.startTime ? raw.startTime : '-'
            },
            ordering: true
        },
        {
            label: 'rentals.end_time_header',
            field: 'endTime',
            getValue: (raw) => {
                return raw.endTime ? raw.endTime : '-'
            },
            ordering: true
        },
        {
            label: 'rentals.status_header',
            field: 'status',
            translate: true,
            getValue: (raw) => {
                return 'rental_status.' + raw.status
            },
            ordering: true
        }
    ]
}
