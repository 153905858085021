const ADD_USER_FORM = {
    title: {
        text: 'add_user.title'
    },
    submit: {
        label: 'add_user.submit_btn'
    },
    fields: [
        {
            name: 'firstName',
            label: 'add_user.first_name_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_first_name'],
            defaultValue: ''
        },
        {
            name: 'lastName',
            label: 'add_user.last_name_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_last_name'],
            defaultValue: ''
        },
        {
            name: 'email',
            label: 'add_user.email_field',
            type: 'text',
            validators: ['required', 'isEmail'],
            errors: ['errors.required_email', 'errors.invalid_email'],
            defaultValue: ''
        },
        {
            name: 'phone',
            label: 'add_user.phone_field',
            type: 'phone',
            validators: ['required', 'isValidPhoneNumber'],
            errors: ['errors.required_phone', 'errors.invalid_phone_number'],
            defaultValue: '',
            styles: {
                display: 'flex'
            }
        }
    ]
}

export default ADD_USER_FORM;
