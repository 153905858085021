import React from 'react'
import Button from '@material-ui/core/Button';
import {useTranslation} from "react-i18next";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import SelectIcon from "assets/img/select-icon.svg";
import {loadAllAdmins} from "../../../../../../../core/actions/admin-actions";
import {loadServices} from "../../../../../../../core/actions/service-actions";
import {
  cancelMaintenanceVisit,
  refreshMaintenanceVisit, updateMaintenanceExternalVisit,
  updateMaintenanceVisitMechanic,
  updateMaintenanceVisitService, updateMaintenanceVisitTime
} from "../../../../../../../core/actions/maintenance-plan-actions";
import {connect} from "react-redux";

const MaintenanceVisitInfoFormActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeMechanic = () => {
    handleClose();
    props.onHandleChangeMechanic();
  };

  const handleChangeService = () => {
    handleClose();
    props.onHandleChangeService();
  };

  const handleChangeTime = () => {
    handleClose();
    props.onHandleChangeTime();
  };

  const handleRefresh = () => {
    handleClose();
    props.onHandleRefresh();
  };

  const handleSetRequest = () => {
    handleClose();
    props.onHandleSetRequest();
  };

  const handleShowError = () => {
    handleClose();
    props.onHandleShowError();
  };

  const handleCancel = () => {
    handleClose();
    props.onHandleCancel();
  };

  if (props.status !== 'CANCELED' && props.manageMaintenance) {
    let menuItems = [
      <MenuItem key="refresh" onClick={handleRefresh}>{t('maintenance_plan_info.action_refresh')}</MenuItem>
    ];

    if (props.status === 'CREATED') {
      menuItems.push(<MenuItem key="change_service" onClick={handleChangeService}>
        {t('maintenance_plan_info.action_change_service')}</MenuItem>)
      menuItems.push(<MenuItem key="change_mechanic" onClick={handleChangeMechanic}>
        {t('maintenance_plan_info.action_change_mechanic')}</MenuItem>)
      menuItems.push(<MenuItem key="change_time" onClick={handleChangeTime}>
        {t('maintenance_plan_info.action_change_time')}</MenuItem>)
      menuItems.push(<MenuItem key="cancel" onClick={handleCancel}>
        {t('maintenance_plan_info.action_cancel_visit')}</MenuItem>)
    }

    if (props.profile.role === 'VT_SUPER_ADMIN') {
      menuItems.push(<MenuItem key="show_error" onClick={handleShowError}>
        {t('maintenance_plan_info.action_show_error')}</MenuItem>)
      menuItems.push(<MenuItem key="set_requests" onClick={handleSetRequest}>
        {t('maintenance_plan_info.set_requests')}</MenuItem>)
    }

    return (
        <>
          <Button aria-controls="support-issue-info-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  variant="contained"
                  color="primary"
                  style={{
                    marginBottom: '1em'
                  }}>
            {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
          </Button>
          <Menu
              id="support-issue-info-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}>
            {menuItems}
          </Menu>
        </>
    )
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile
  };
};

export default connect(mapStateToProps)(MaintenanceVisitInfoFormActions);
