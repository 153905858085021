import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const IntegrationSettingsActions = (props) => {
  const {t} = useTranslation();

  const handleClick = (event) => {
    props.onHandleClear()
  };

  return (
      <Button onClick={handleClick}
              variant="contained"
              color="primary">
        {t('integration.clear')}
      </Button>
  )
}

export default IntegrationSettingsActions
