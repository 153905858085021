const ADD_LIST_ITEM_FORM = {
    submit: {
        label: 'general.add'
    },
    fields: [
        {
            name: 'text',
            label: 'edit_user_config.email_domain_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_email_domain'],
            defaultValue: ''
        }
    ]
}

export default ADD_LIST_ITEM_FORM;
