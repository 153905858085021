import {saveFile} from "../utils/export";

export const adminReducer = (state = {
    admins: {},
    boUsers: {},
    allAdmins: {},
    currentAdmin: null,
    loading: false,
    disabledEditPermission: true,
    autocomplete: [],
    loadingAutocomplete: false
}, action) => {
    switch (action.type) {
        case 'ADMINS_AUTOCOMPLETE':
            return {
                ...state,
                loadingAutocomplete: true
            }
        case 'ADMINS_AUTOCOMPLETE_SUCCESS':
            return {
                ...state,
                loadingAutocomplete: false,
                autocomplete: action.payload.data
            }
        case 'ADMINS_AUTOCOMPLETE_FAIL':
            return {
                ...state,
                autocomplete: [],
                loadingAutocomplete: false
            }
        case 'FETCH_ADMIN_USERS':
        case 'FETCH_BO_USERS':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_ADMIN_USERS_SUCCESS':
            return {
                ...state,
                loading: false,
                admins: action.payload.data
            }
        case 'FETCH_BO_USERS_SUCCESS':
            return {
                ...state,
                loading: false,
                boUsers: action.payload.data
            }
        case 'FETCH_ADMIN_USERS_FAIL':
        case 'FETCH_BO_USERS_FAIL':
            return {
                ...state,
                loading: false
            }
        case 'FETCH_ALL_ADMIN_USERS':
            return {
                ...state,
                loading: true,
                allAdmins: {}
            }
        case 'FETCH_ALL_ADMIN_USERS_SUCCESS':
            return {
                ...state,
                loading: false,
                allAdmins: action.payload.data
            }
        case 'FETCH_ALL_ADMIN_USERS_FAIL':
            return {
                ...state,
                loading: false,
                allAdmins: {}
            }
        case 'FETCH_SYSTEM_ADMIN':
        case 'FETCH_BO_USER':
            return {
                ...state,
                currentAdmin: null,
                disabledEditPermission: true
            }
        case 'FETCH_SYSTEM_ADMIN_SUCCESS':
        case 'FETCH_BO_USER_SUCCESS':
            return {
                ...state,
                currentAdmin: action.payload.data,
                disabledEditPermission: true
            }
        case 'FETCH_SYSTEM_ADMIN_FAIL':
        case 'FETCH_BO_USER_FAIL':
            return {
                ...state,
                currentAdmin: null
            }
        case 'UPDATE_ADMIN_PERMISSIONS':
            return {
                ...state,
                currentAdmin: {
                    ...state.currentAdmin,
                    permissions: action.payload.request.data
                }
            }
        case 'UPDATE_ADMIN_PERMISSIONS_SUCCESS':
            return {
                ...state,
                currentAdmin: action.payload.data,
                disabledEditPermission: true
            }
        case 'UPDATE_ADMIN_PERMISSIONS_FAIL':
            return state
        case 'SET_EDITING_PERMISSIONS':
            return {
                ...state,
                disabledEditPermission: action.payload
            }
        case 'UPDATE_ADMIN_STATUS_SUCCESS':
            return {
                ...state,
                currentAdmin: action.payload.data
            }
        case 'EXPORT_ADMIN_USERS_TO_CSV_SUCCESS':
        case 'EXPORT_BO_USERS_TO_CSV_SUCCESS':
            saveFile(action.payload)
            return state
        case 'UPDATE_ADMIN_STATUS':
        case 'UPDATE_ADMIN_STATUS_FAIL':
        case 'CREATE_ADMIN_USER':
        case 'CREATE_ADMIN_USER_SUCCESS':
        case 'CREATE_ADMIN_USER_FAIL':
        case 'RESEND_ACTIVATION_LINK':
        case 'RESEND_ACTIVATION_LINK_SUCCESS':
        case 'RESEND_ACTIVATION_LINK_FAIL':
        case 'EXPORT_ADMIN_USERS_TO_CSV':
        case 'EXPORT_ADMIN_USERS_TO_CSV_FAIL':
        case 'EXPORT_BO_USERS_TO_CSV':
        case 'EXPORT_BO_USERS_TO_CSV_FAIL':
        default:
            return state
    }
}
