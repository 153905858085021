import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import {MANAGER} from "core/utils/permissions";
import PagePermission from "components/permission/PagePermission";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanel from "components/panel/BsPanel";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import Permitted from "components/permission/Permitted";
import CUSTOMER_SUPPORT_TABLE from "./customer-support-table";
import {exportMaintenanceToCsv, loadMaintenanceIssue} from "core/actions/maintenance-actions";
import CustomerSupportFilters from "./CustomerSupportFilters";
import Button from "@material-ui/core/Button";

const queryString = require('query-string');

class CustomerSupport extends React.Component {

    constructor(props) {
        super(props);
        const query = queryString.parse(this.props.location.search);
        this.state = {
            sort: query.sort,
            direction: query.direction,
            fields: {
                status: query.status != null ? (typeof query.status === "string" ? [query.status] : query.status) : []
            }
        };

        this.onLoadData(query);
    }

    onRequestSort = (params) => {
        this.setState({
            sort: params.sort,
            direction: params.direction
        });
        this.onLoadData(params);
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    onHandleFilter = () => {
        const params = queryString.parse(this.props.location.search);
        params.status = this.state.fields.status;
        params.page = '0';
        this.props.history.push('?' + queryString.stringify(params));
        this.onLoadData(params)
    }

    onHandleClear = () => {
        const params = queryString.parse(this.props.location.search);
        delete params.status;
        params.page = '0';
        this.props.history.push('?' + queryString.stringify(params));
        this.onLoadData(params)
        this.setState({
            ...this.state,
            fields: {
                status: []
            }
        })
    }

    onLoadData = (query) => {
        this.props.loadMaintenanceIssue(this.props.match.params.systemId, this.prepareQuery(query));
    }

    onRowClick = (row) => {
        this.props.history.push('/system/' + this.props.match.params.systemId + '/support/issue/' + row.id)
    }

    export = () => {
        const query = queryString.parse(this.props.location.search);
        this.props.exportMaintenanceToCsv(this.props.match.params.systemId, this.prepareQuery(query));
    }

    prepareQuery = (query) => {
        if (query.sort) {
            query.sort = query.sort + ',' + query.direction;
        }

        if (query.status) {
            query.status = query.status instanceof Array ? query.status.join(',') : query.status
        }

        return query
    }

    render() {
        return (
            <BsPanel padding={true}>
                <PagePermission anonymous={false} permission={MANAGER}/>
                <BsPanelLoader show={this.props.loading}/>
                <BsPanelHeader>
                    <BsPanelTitle action={
                        <Button variant="outlined" type="button" onClick={this.export}>
                            <Trans i18nKey="general.export"/>
                        </Button>
                    }>
                        <Trans i18nKey="customer_support.title"/>
                    </BsPanelTitle>
                </BsPanelHeader>

                <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                      onHandleClear={this.onHandleClear.bind(this)}>
                    <CustomerSupportFilters fields={this.state.fields}
                                            onHandleChange={this.handleChange.bind(this)}/>
                </BsPanelHeaderFilters>

                <BsDataTable location={this.props.location}
                             history={this.props.history}
                             order={this.state.direction}
                             orderBy={this.state.sort}
                             settings={CUSTOMER_SUPPORT_TABLE}
                             content={this.props.issues}
                             onRowClick={this.onRowClick}
                             onChangePage={this.onLoadData}
                             onChangeSize={this.onLoadData}
                             onRequestSort={this.onRequestSort}/>
            </BsPanel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        issues: state.maintenance.issues,
        loading: state.clientUser.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadMaintenanceIssue: (systemId, query) => {
            dispatch(loadMaintenanceIssue(systemId, query));
        },
        exportMaintenanceToCsv: (systemId, query) => {
            dispatch(exportMaintenanceToCsv(systemId, query));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupport);
