import React from "react";
import RentalInfoForm from "../../../../../rentals/info/RentalInfoForm";

class LocationRentalInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            backUrl: '/system/' + this.props.match.params.systemId + '/location/' + this.props.match.params.locationId + '/rentals',
        };
    }

    render() {
        return (
            <RentalInfoForm backUrl={this.state.backUrl}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}/>
        )
    }
}

export default LocationRentalInfo;