import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import SunEditor from "suneditor-react";

const BsTextEditorField = (props) => {
    const {t} = useTranslation();

    const onChangeValue = (value) => {
        props.onHandleChange({target: {value}})
    }

    return (
        <SunEditor
            label={t(props.label)}
            name={props.name}
            setContents={props.value}
            setOptions={{
                height : 'auto',
                minHeight : '100px',
                buttonList : [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'fullScreen', 'showBlocks']
                ]
            }}
            onChange={onChangeValue}
        />
    )
}

BsTextEditorField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    onHandleChange: PropTypes.func,
    value: PropTypes.any
};

export default BsTextEditorField;
