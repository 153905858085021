import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import {loadBicycleScanHistory} from "core/actions/bicycle-actions";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanel from "components/panel/BsPanel";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BICYCLE_SCAN_HISTORY from "./bicycle-scan-history";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BicycleScanHistoryFilters from "./BicycleScanHistoryFilters";

const queryString = require('query-string');

class BicycleScanHistory extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        system: query.system != null ? query.system : '',
        location: query.location != null ? query.location : '',
        user: query.user != null ? query.user : '',
        date: query.date != null ? query.date : ''
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.system = this.state.fields.system;
    params.location = this.state.fields.location;
    params.user = this.state.fields.user;
    params.date = this.state.fields.date;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.system;
    delete params.location;
    delete params.user;
    delete params.date;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        system: '',
        location: '',
        user: '',
        date: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadBicycleScanHistory(this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/global/scan-bicycles/' + row.location.id + '/' + row.user.id + '/' + row.scanDate)
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }

    return query
  }

  render() {
    return (
      <BsPanel padding={true}>
        <BsPanelLoader show={this.props.loading}/>
        <BsPanelHeader>
          <BsPanelTitle>
            <Trans i18nKey="bicycle_scan_history.title"/>
          </BsPanelTitle>
        </BsPanelHeader>

        <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                              onHandleClear={this.onHandleClear.bind(this)}>
          <BicycleScanHistoryFilters fields={this.state.fields}
                                     onHandleChange={this.handleChange.bind(this)}/>
        </BsPanelHeaderFilters>

        <BsDataTable location={this.props.location}
                     history={this.props.history}
                     order={this.state.direction}
                     orderBy={this.state.sort}
                     settings={BICYCLE_SCAN_HISTORY}
                     content={this.props.scanHistory}
                     onRowClick={this.onRowClick}
                     onChangePage={this.onLoadData}
                     onChangeSize={this.onLoadData}
                     onRequestSort={this.onRequestSort}/>

      </BsPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    scanHistory: state.bicycle.scanHistory,
    loading: state.bicycle.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadBicycleScanHistory: (query) => {
      dispatch(loadBicycleScanHistory(query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BicycleScanHistory);
