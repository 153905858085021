import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import Col from "components/layout/Col";
import Row from "components/layout/Row";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const MaintenanceReportsFilters = (props) => {
  const {t} = useTranslation();
  const nameRef = React.createRef();

  return (
      <Row>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <TextField label={t('bicycle_reports.reporter_name_filter')}
                       ref={nameRef}
                       onChange={props.onHandleChange.bind(this, "name")}
                       value={props.fields["name"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <FormControl className="form-control">
              <InputLabel id="status-label">{t('bicycle_reports.status_filter')}</InputLabel>
              <Select
                  labelId="status-label"
                  id="status"
                  value={props.fields["status"]}
                  onChange={props.onHandleChange.bind(this, "status")}>
                <MenuItem value="OPEN">{t('maintenance_report_status.OPEN')}</MenuItem>
                <MenuItem value="COMPLETED">{t('maintenance_report_status.COMPLETED')}</MenuItem>
              </Select>
            </FormControl>
          </BsPanelHeaderFilterControl>
        </Col>
      </Row>
  )
}

export default MaintenanceReportsFilters;
