const buildRentalRateInfoForm = (t, systemConfig) => {
  const form = {
    fields: [
      {
        name: 'name',
        label: 'rental_rate_info.name_field',
        type: 'info',
        styles: {
          maxWidth: '500px',
          marginTop: '2em'
        }
      },
      {
        name: 'price',
        label: 'rental_rate_info.price_field',
        type: 'price',
        currency: 'currency',
        styles: {
          maxWidth: '500px'
        },
        getValue: (data) => {
          return data.price + '';
        }
      },
      /*{
        name: 'refundPolicy',
        label: 'rental_rate_info.refund_policy_field',
        type: 'info',
        styles: {
          maxWidth: '500px'
        },
        getValue: (data) => {
          return data.refundPolicy.amount + ' ' + t('duration_unit.' + data.refundPolicy.unit)
        }
      },*/
      /*{
        name: 'gracePeriod',
        label: 'rental_rate_info.grace_period_field',
        type: 'info',
        styles: {
          maxWidth: '500px'
        },
        getValue: (data) => {
          return data.gracePeriod.amount + ' ' + t('duration_unit.' + data.gracePeriod.unit)
        }
      },*/
    ]
  }

  if (systemConfig.collectTax) {
    form.fields.push({
      name: 'tax',
      label: 'rental_rate_info.tax_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        return systemConfig.tax + '%';
      }
    });
    form.fields.push({
      name: 'totalAmount',
      label: 'rental_rate_info.total_amount_field',
      type: 'price',
      currency: 'currency',
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        let totalAmount = (data.price + (data.price * systemConfig.tax / 100));
        totalAmount = Math.round(totalAmount * 100) / 100;
        return totalAmount + '';
      }
    });
  }

  form.fields.push({
    name: 'rentalDuration',
    label: 'rental_rate_info.rental_duration_field',
    type: 'info',
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return data.rentalDuration.amount + ' ' + t('duration_unit.' + data.rentalDuration.unit)
    }
  });
  form.fields.push({
    name: 'lateFeeAmount',
    label: 'rental_rate_info.late_fee_amount_field',
    type: 'price',
    currency: 'lateFeeCurrency',
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return data.lateFeeAmount + ''
    }
  });
  form.fields.push({
    name: 'type',
    label: 'rental_rate_info.type_field',
    type: 'info',
    translate: true,
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return 'rental_rate_type.' + data.type;
    }
  });

  return form;
}

export default buildRentalRateInfoForm;
