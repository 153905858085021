import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import BsFormPanel from "components/form/BsFormPanel";
import {ADMIN, MANAGER} from "core/utils/permissions";
import {
  assignIssue,
  assignIssueToMe,
  changeIssuePriority,
  closeIssue,
  doneIssue,
  fetchMaintenanceIssue,
  startProgressIssue
} from "core/actions/maintenance-actions";
import {loadAllAdmins} from "core/actions/admin-actions";
import buildSupportIssueInfoForm from "./support-issue-info-form";
import SupportIssueInfoFormActions from "./SupportIssueInfoFormActions";
import AssignUserModel from "./AssignUserModel";
import ChangePriorityModel from "./ChangePriorityModel";
import SupportIssueImages from "./SupportIssueImages";

import './support-issue-info-form.scss'

class SupportIssueInfoForm extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      confirmationWindow: {
        open: false,
        message: '',
        event: () => {
        }
      },
      assignUserWindow: {
        open: false,
        message: '',
        event: () => {
        }
      },
      changePriorityWindow: {
        open: false,
        message: 'issue_info.change_issue_priority_message'
      }
    };
    this.props.fetchMaintenanceIssue(this.props.match.params.systemId, this.props.match.params.issueId);
  }

  onHandleCloseIssue() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'issue_info.close_issue_message',
        event: this.closeIssue
      }
    })
  }

  closeIssue() {
    this.closeConfirmationModel();
    this.props.closeIssue(this.props.match.params.systemId, this.props.match.params.issueId)
  }

  onHandleStartProgress() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'issue_info.start_progress_issue_message',
        event: this.startProgressIssue
      }
    })
  }

  startProgressIssue() {
    this.closeConfirmationModel();
    this.props.startProgressIssue(this.props.match.params.systemId, this.props.match.params.issueId)
  }

  onHandleDone() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'issue_info.done_issue_message',
        event: this.doneIssue
      }
    })
  }

  doneIssue() {
    this.closeConfirmationModel();
    this.props.doneIssue(this.props.match.params.systemId, this.props.match.params.issueId)
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  onHandleAssign() {
    if (!this.props.allAdmins.data && !this.props.loadAdmins) {
      this.props.loadAllAdmins(this.props.match.params.systemId)
    }
    this.setState({
      ...this.state,
      assignUserWindow: {
        open: true,
        message: 'issue_info.assign_issue_message',
        event: this.assignUser
      }
    })
  }

  onHandleReassign() {
    if (!this.props.allAdmins.content && !this.props.loadAdmins) {
      this.props.loadAllAdmins(this.props.match.params.systemId)
    }
    this.setState({
      ...this.state,
      assignUserWindow: {
        open: true,
        message: 'issue_info.reassign_issue_message',
        event: this.assignUser
      }
    })
  }

  assignUser(id) {
    this.closeAssignUserModel();
    this.props.assignIssue(this.props.match.params.systemId, this.props.match.params.issueId, {id: id})
  }

  assignToMe() {
    this.closeAssignUserModel();
    this.props.assignIssueToMe(this.props.match.params.systemId, this.props.match.params.issueId)
  }

  closeAssignUserModel() {
    this.setState({
      ...this.state,
      assignUserWindow: {
        ...this.state.assignUserWindow,
        open: false
      }
    });
  }

  onHandleChangeIssuePriority() {
    this.setState({
      ...this.state,
      changePriorityWindow: {
        ...this.state.changePriorityWindow,
        open: true
      }
    });
  }

  changePriority(priority) {
    this.closePriorityModel();
    this.props.changeIssuePriority(this.props.match.params.systemId, this.props.match.params.issueId, priority)
  }

  closePriorityModel() {
    this.setState({
      ...this.state,
      changePriorityWindow: {
        ...this.state.changePriorityWindow,
        open: false
      }
    });
  }

  render() {
    if (this.props.currentIssue && this.props.profile) {
      const actions = {};
      switch (this.props.currentIssue.status) {
        case 'ASSIGNED':
        case 'IN_PROGRESS':
          actions.reassign = this.onHandleReassign.bind(this)
        case 'CREATED':
          actions.priority = this.onHandleChangeIssuePriority.bind(this)
          break;
      }

      const formConfig = buildSupportIssueInfoForm(actions, this.props.currentRole === ADMIN);
      formConfig.title = {
        actions: <SupportIssueInfoFormActions
            issue={this.props.currentIssue}
            onHandleAssign={this.onHandleAssign.bind(this)}
            onHandleCloseIssue={this.onHandleCloseIssue.bind(this)}
            onHandleStartProgress={this.onHandleStartProgress.bind(this)}
            onHandleDone={this.onHandleDone.bind(this)}
        />,
        back: {
          url: this.props.backUrl,
          history: this.props.history,
          location: this.props.location
        },
        text: 'issue_info.title'
      }

      return (
          <BsFormPanel size="m">
            <PagePermission anonymous={false} permission={MANAGER}/>
            <ConfirmationModel
                open={this.state.confirmationWindow.open}
                message={this.state.confirmationWindow.message}
                handleClose={this.closeConfirmationModel.bind(this)}
                handleApply={this.state.confirmationWindow.event.bind(this)}
            />
            <AssignUserModel
                me={this.props.profile}
                open={this.state.assignUserWindow.open}
                message={this.state.assignUserWindow.message}
                handleClose={this.closeAssignUserModel.bind(this)}
                handleAssign={this.assignUser.bind(this)}
                handleAssignToMe={this.assignToMe.bind(this)}
                allUsers={this.props.allAdmins.content}
            />
            <ChangePriorityModel
                open={this.state.changePriorityWindow.open}
                message={this.state.changePriorityWindow.message}
                handleClose={this.closePriorityModel.bind(this)}
                handleSubmit={this.changePriority.bind(this)}
            />

            <BsForm
                config={formConfig}
                formData={this.props.currentIssue}
                disabled={true}/>

            <SupportIssueImages images={this.props.currentIssue.images}
                                title={this.props.currentIssue.problem}
                                desc={this.props.currentIssue.description}/>
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentIssue: state.maintenance.currentIssue,
    profile: state.profile.profile,
    allAdmins: state.admin.allAdmins,
    loadAdmins: state.admin.loading,
    currentRole: state.system.currentSystem ? state.system.currentSystem.role : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMaintenanceIssue: (systemId, issueId) => {
      dispatch(fetchMaintenanceIssue(systemId, issueId));
    },
    closeIssue: (systemId, issueId) => {
      dispatch(closeIssue(systemId, issueId));
    },
    startProgressIssue: (systemId, issueId) => {
      dispatch(startProgressIssue(systemId, issueId));
    },
    doneIssue: (systemId, issueId) => {
      dispatch(doneIssue(systemId, issueId));
    },
    assignIssue: (systemId, issueId, request) => {
      dispatch(assignIssue(systemId, issueId, request));
    },
    assignIssueToMe: (systemId, issueId) => {
      dispatch(assignIssueToMe(systemId, issueId));
    },
    changeIssuePriority: (systemId, issueId, priority) => {
      dispatch(changeIssuePriority(systemId, issueId, priority));
    },
    loadAllAdmins: (systemId, query = {page: 0, size: 100, status: 'ACTIVE'}) => {
      dispatch(loadAllAdmins(systemId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportIssueInfoForm);
