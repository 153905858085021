import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Permitted from "components/permission/Permitted";
import {ADMIN, MANAGER} from 'core/utils/permissions'
import SelectIcon from 'assets/img/select-icon.svg'
import {useTranslation} from "react-i18next";

const BicycleInfoActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    props.onHandleEdit();
  }

  const handleChangeStatus = (status) => {
    handleClose();
    props.onHandleChangeStatus(status);
  }

  let changeStatusMenuItems = [];
  if (props.bicycle && props.bicycle.status !== 'DELETED') {
    if (props.bicycle && props.bicycle.status !== 'ACTIVE') {
      changeStatusMenuItems.push(<MenuItem key="active" onClick={() => handleChangeStatus('ACTIVE')}>
        {t('general.active')}</MenuItem>)
    }
    if (props.isSuperAdmin && props.bicycle && props.bicycle.status !== 'INACTIVE') {
      changeStatusMenuItems.push(<MenuItem key="inactive" onClick={() => handleChangeStatus('INACTIVE')}>
        {t('general.inactive')}</MenuItem>)
    }
    if (props.bicycle && props.bicycle.status !== 'IN_MAINTENANCE') {
      changeStatusMenuItems.push(<MenuItem key="in_maintenance"
                                           onClick={() => handleChangeStatus('IN_MAINTENANCE')}>
        {t('bicycle_info.change_status_to_in_maintenance')}</MenuItem>)
    }
    if (props.isSuperAdmin) {
      changeStatusMenuItems.push(<MenuItem key="deleted" onClick={() => handleChangeStatus('DELETED')}>
        {t('bicycle_info.change_status_to_deleted')}</MenuItem>)
    }
  }

  return (
    <Permitted for={MANAGER}>
      <Button aria-controls="admin-info-menu"
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="primary"
              style={{
                marginBottom: '1em'
              }}>
        {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
      </Button>
      <Menu
        id="admin-info-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}>
        <MenuItem onClick={handleEdit}>{t('general.edit')}</MenuItem>
        {changeStatusMenuItems}
      </Menu>
    </Permitted>
  )
}

export default BicycleInfoActions
