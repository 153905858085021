import React, {useEffect} from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import BsForm from "components/form/BsForm";
import SELECT_SYSTEM_FORM from "./select-system-form";

import 'components/model/confirmation-model.scss';

const SelectSystemModel = (props) => {
    const {t} = useTranslation();
    let fields = {};

    useEffect(() => {
        BsForm.addValidationRule('requiredSystem', (value) => {
            return !!fields.system && !!fields.system.id;
        });

        return () => {
            BsForm.removeValidationRule('requiredSystem');
        };
    });

    const onSubmit = () => {
        props.handleSelect(fields.system)
    }

    const handleChange = (newData) => {
        fields = newData;
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="model-window"
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={props.open}>
                <div className="model-container">
                    <div className="model-body">
                        <h4>{t('add_maintenance_plan.select_system_model')}</h4>

                        <BsForm
                            config={SELECT_SYSTEM_FORM}
                            onHandleSubmit={onSubmit}
                            onHandleChange={handleChange}
                            cancelAction={<Button variant="outlined" onClick={props.handleClose}>
                                {t('general.cancel')}
                            </Button>}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

SelectSystemModel.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.any.isRequired,
    handleSelect: PropTypes.any.isRequired,
};

export default SelectSystemModel;
