const EDIT_GENERAL_HELP_CONFIG_FORM = {
  title: {
    text: 'edit_tos_config.title'
  },
  submit: {
    label: 'edit_tos_config.submit_btn'
  },
  fields: [
    {
      name: 'generalHelp',
      label: 'edit_general_help_config.general_help_field',
      type: 'text-editor',
      defaultValue: ''
    }
  ]
}

export default EDIT_GENERAL_HELP_CONFIG_FORM;
