import Moment from 'moment';

const ALL_MAINTENANCE_REPORTS_TABLE = {
  columns: [
    {
      label: 'bicycle_reports.system_header',
      field: 'system.name',
      getValue: (raw) => {
        return raw.system.name
      },
      ordering: true
    },
    {
      label: 'bicycle_reports.bike_header',
      field: 'bike.number',
      getValue: (raw) => {
        return raw.bike.number
      },
      ordering: true
    },
    {
      label: 'bicycle_reports.reporter_header',
      field: 'reporter.firstName,reporter.lastName',
      getValue: (raw) => {
        return raw.reporter.firstName + ' ' + raw.reporter.lastName
      },
      ordering: true
    },
    {
      label: 'bicycle_reports.reported_at_header',
      field: 'reportedAt',
      getValue: (raw) => {
        return Moment(raw.reportedAt).format('DD MMM YYYY')
      },
      ordering: true
    },
    {
      label: 'bicycle_reports.status_header',
      field: 'reportedAt',
      getValue: (raw) => {
        return 'maintenance_report_status.' + raw.status
      },
      translate: true,
      ordering: true
    },
    {
      label: 'bicycle_reports.maintenance_comment_header',
      field: 'maintenanceComment',
      getValue: (raw) => {
        return raw.maintenanceComment
      },
      getStyle: (row) => {
        return {
          maxWidth: '300px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      }
    },
    {
      label: 'bicycle_reports.maintenance_images_header',
      field: 'maintenanceImages',
      getValue: (raw) => {
        return raw.maintenanceImages ? raw.maintenanceImages.length : 0
      }
    }
  ]
}

export default ALL_MAINTENANCE_REPORTS_TABLE;
