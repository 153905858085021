const LABEL_INFO_FORM = {
  fields: [
    {
      name: 'label',
      label: 'label_info.label_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      }
    },
  ]
}

export default LABEL_INFO_FORM;
