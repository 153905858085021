import React from "react";
import {SelectValidator} from "react-material-ui-form-validator";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";

const BsFormSelectField = (props) => {
    const {t} = useTranslation();

    return (
        <SelectValidator
            className="form-control"
            label={t(props.label)}
            name={props.name}
            value={props.value}
            onChange={props.onHandleChange.bind(this)}
            validators={props.validators}
            errorMessages={props.errors.map(error => t(error))}>
            <MenuItem value="">
                <em>{t( props.noneLabel ? props.noneLabel : 'general.none')}</em>
            </MenuItem>
            {props.items.map((item, index) => {
                return <MenuItem key={item.label + '-' + index} value={item.value}>
                    {item.translate ? t(item.label) : item.label}
                </MenuItem>
            })}
        </SelectValidator>
    )
}

BsFormSelectField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    items: PropTypes.array,
    disabled: PropTypes.bool,
    onHandleChange: PropTypes.func,
    value: PropTypes.any,
    validators: PropTypes.array,
    errors: PropTypes.array,
    noneLabel: PropTypes.string
};

export default BsFormSelectField;
