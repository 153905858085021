const LOCATION_INFO_FORM = {
    fields: [
        {
            name: 'label',
            label: 'location_info.label_field',
            type: 'info',
            styles: {
                marginTop: '1em'
            }
        },
        {
            name: 'status',
            label: 'location_info.status_field',
            type: 'info',
            translate: true,
            getValue: (data) => {
                return 'location_status.' + data.status;
            }
        },
        {
            name: 'address',
            label: 'location_info.address_field',
            type: 'info',
            getValue: (data) => {
                return data.address.address
            }
        },
        {
            name: 'beaconMacAddress',
            label: 'location_info.beacon_field',
            type: 'info',
        },
        {
            name: 'capacity',
            label: 'location_info.capacity_field',
            type: 'info',
            getValue: (data) => {
                return data.capacity + ''
            }
        },
        {
            name: 'countOfBicycle',
            label: 'location_info.available_bicycle_field',
            type: 'info',
            getValue: (data) => {
                const count = data.capacity - data.countOfBicycle;
                return (count < 0 ? 0 : count) + '';
            }
        }
    ]
}

export default LOCATION_INFO_FORM;
