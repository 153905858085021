import Moment from "moment";

const buildPasswordInfoForm = () => {
  return {
    fields: [
      {
        name: 'password',
        label: 'password_info.password_field',
        type: 'info',
        styles: {
          maxWidth: '500px',
          marginTop: '2em'
        },
        getValue: (data) => {
          return data.password + '';
        }
      },
      {
        name: 'date',
        label: 'password_info.date_field',
        type: 'info',
        styles: {
          maxWidth: '500px'
        },
        getValue: (data) => {
          return Moment(data.from).format('DD MMM YYYY') + ' - ' + Moment(data.to).format('DD MMM YYYY');
        }
      },
      {
        name: 'status',
        label: 'password_info.status_field',
        type: 'info',
        translate: true,
        styles: {
          maxWidth: '500px'
        },
        getValue: (data) => {
          return 'password_status.' + data.status;
        }
      },
      {
        name: 'limit',
        label: 'password_info.limit_field',
        type: 'info',
        translate: true,
        styles: {
          maxWidth: '500px'
        },
        getValue: (data) => {
          return data.unlimited ? 'password_info.unlimited_label' : (data.limit + '');
        }
      }
    ]
  }
}

export default buildPasswordInfoForm;
