const ADD_SYSTEM_BANK_ACCOUNT_FORM = {
  title: {
    text: 'add_bank_account.title'
  },
  submit: {
    label: 'add_bank_account.submit_btn'
  },
  fields: [
    {
      name: 'country',
      label: 'add_bank_account.country_field',
      type: 'text',
      disabled: true,
      validators: ['required'],
      errors: ['errors.required_country'],
      defaultValue: 'US'
    },
    {
      name: 'currency',
      label: 'add_bank_account.currency_field',
      type: 'text',
      disabled: true,
      validators: ['required'],
      errors: ['errors.required_currency'],
      defaultValue: 'USD'
    },
    {
      name: 'accountHolderName',
      label: 'add_bank_account.account_holder_name_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_account_holder_name'],
      defaultValue: ''
    },
    {
      name: 'accountHolderType',
      label: 'add_bank_account.account_holder_type_field',
      type: 'select',
      items: [
        {value: "INDIVIDUAL", label: "bank_account_type.INDIVIDUAL", translate: true},
        {value: "COMPANY", label: "bank_account_type.COMPANY", translate: true}
      ],
      validators: ['required'],
      errors: ['errors.required_account_holder_type'],
      defaultValue: ''
    },
    {
      name: 'email',
      label: 'add_bank_account.email_field',
      type: 'text',
      validators: ['required', 'isEmail'],
      errors: ['errors.required_email', 'errors.invalid_email'],
      defaultValue: ''
    },
    {
      name: 'accountNumber',
      label: 'add_bank_account.account_number_field',
      type: 'text',
      validators: [
        'required',
        'matchRegexp:^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$',
      ],
      errors: [
        'errors.required_account_number',
        'errors.invalid_account_number'
      ],
      defaultValue: ''
    },
    {
      name: 'routingNumber',
      label: 'add_bank_account.routing_number_field',
      type: 'text',
      validators: [
        'required',
        'matchRegexp:^[0-9]{9}$',
      ],
      errors: [
        'errors.required_routing_number',
        'errors.invalid_routing_number'
      ],
      defaultValue: ''
    }
  ]
}

export default ADD_SYSTEM_BANK_ACCOUNT_FORM;
