const ADD_SYSTEM_FORM = {
    title: {
        text: 'add_system.title'
    },
    submit: {
        label: 'add_system.submit_btn'
    },
    fields: [
        {
            name: 'name',
            label: 'add_system.name_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_name'],
            defaultValue: ''
        },
        {
            name: 'desc',
            label: 'add_system.desc_field',
            type: 'text',
            validators: ['required'],
            errors: ['errors.required_desc'],
            defaultValue: ''
        },
        {
            name: 'phone',
            label: 'add_system.phone_field',
            type: 'phone',
            validators: ['required', 'isValidPhoneNumber'],
            errors: ['errors.required_desc', 'errors.invalid_phone_number'],
            defaultValue: '',
            styles: {
                display: 'flex'
            }
        }
    ]
}

export default ADD_SYSTEM_FORM;
