import React from "react";
import {connect} from "react-redux";
import BsDataTable from "components/data-table/BsDataTable";
import BsTabPanel from "components/panel/BsTabPanel";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import {LABEL_SYSTEMS_TABLE} from "./label-systems-table";
import LabelSystemsFilters from "./LabelSystemsFilters";
import Button from "@material-ui/core/Button";
import {Trans} from "react-i18next";
import {loadLabelSystems} from "core/actions/label-actions";

const queryString = require('query-string');

class LabelSystemsTab extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        name: query.name != null ? query.name : ''
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.name = this.state.fields.name;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.name;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        name: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadLabelSystems(this.props.match.params.labelId, this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/global/update-label-data/' + this.props.match.params.labelId + '/system/' + row.id);
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }

    if (query.name) {
      query['system.name'] = query.name
    }
    return query
  }

  onAddSystem = () => {
    this.props.history.push('/global/update-label-data/' + this.props.match.params.labelId + '/add-system')
  }

  render() {
    return (
        <BsTabPanel>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle action={
              <Button variant="outlined" type="button" onClick={this.onAddSystem}>
                <Trans i18nKey="general.add"/>
              </Button>
            }>
            </BsPanelTitle>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <LabelSystemsFilters fields={this.state.fields}
                                 onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={LABEL_SYSTEMS_TABLE}
                       content={this.props.systems}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsTabPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    systems: state.label.systems,
    loading: state.label.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadLabelSystems: (labelId, query) => {
      dispatch(loadLabelSystems(labelId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelSystemsTab);
