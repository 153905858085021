import React from 'react'
import {connect} from "react-redux"
import {Redirect} from "react-router";
import {clearRedirect} from "core/actions/app-actions";

class AutoRedirect extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.redirectPath) {
            this.props.clearRedirect();
        }
    }

    render() {
        if (this.props.redirectPath) {
            return (<Redirect to={this.props.redirectPath}/>)
        } else {
            return <div/>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        redirectPath: state.app.redirectPath
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearRedirect: () => {
            dispatch(clearRedirect());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoRedirect)