import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const SubscriptionSystemInfoActions = (props) => {
  const {t} = useTranslation();

  const handleEdit = (event) => {
    props.onHandleEdit();
  };

  return (
      <Button variant="contained"
              color="primary"
              onClick={() => handleEdit()}>
        {t('subscription_system_info.edit_btn')}
      </Button>
  )
}

export default SubscriptionSystemInfoActions
