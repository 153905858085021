import React from "react";
import {connect} from "react-redux";
import {ADMIN} from "core/utils/permissions";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import buildPasswordInfoForm from "./password-info-form";
import PasswordInfoActions from "./PasswordInfoActions";
import ConfirmationModel from "components/model/ConfirmationModel";
import {changeSystemPasswordStatus, fetchSystemPassword} from "core/actions/password-actions";

class PasswordInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      backUrl: '/system/' + this.props.match.params.systemId + '/edit-settings/passwords',
      confirmationWindow: {
        open: false,
        message: ''
      }
    };
    this.props.fetchSystemPassword(this.props.match.params.systemId, this.props.match.params.passwordId);
  }

  onHandleChangeStatus(status) {
    let message = '';

    switch (status) {
      case 'ACTIVE':
        message = 'password_info.active_password_confirmation_message';
        break;
      case 'INACTIVE':
        message = 'password_info.inactive_password_confirmation_message';
        break;
    }

    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: message
      },
      status: status
    })
  }

  onSubmitChangeStatus() {
    this.closeConfirmationModel();
    this.props.changeSystemPasswordStatus(this.props.match.params.systemId,
        this.props.match.params.passwordId, this.state.status)
  }

  onHandleEdit() {
    /*this.props.history.push('/system/' + this.props.match.params.systemId
        + '/edit-bicycle/' + this.props.match.params.bicycleId)*/
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  render() {
    if (this.props.currentPassword) {
      const formConfig = buildPasswordInfoForm();
      formConfig.title = {
        back: {
          url: this.state.backUrl,
          history: this.props.history,
          location: this.props.location
        }
      }

      if (this.props.currentPassword.status === 'CREATED' || this.props.currentPassword.status === 'ACTIVE') {
        formConfig.title.actions = <PasswordInfoActions
            password={this.props.currentPassword}
            onHandleEdit={this.onHandleEdit.bind(this)}
            onHandleChangeStatus={this.onHandleChangeStatus.bind(this)}/>
      }

      return (
          <div className="bs-form-container">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <ConfirmationModel
                open={this.state.confirmationWindow.open}
                message={this.state.confirmationWindow.message}
                handleClose={this.closeConfirmationModel.bind(this)}
                handleApply={this.onSubmitChangeStatus.bind(this)}
            />

            <BsForm
                config={formConfig}
                formData={this.props.currentPassword}
                disabled={true}/>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentPassword: state.systemPassword.currentPassword
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSystemPasswordStatus: (systemId, passwordId, status) => {
      dispatch(changeSystemPasswordStatus(systemId, passwordId, status));
    },
    fetchSystemPassword: (systemId, passwordId) => {
      dispatch(fetchSystemPassword(systemId, passwordId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordInfoTab);
