import api from '../utils/api'

const getExpiredDate = (time) => {
    const expiredDate = new Date();
    expiredDate.setSeconds(expiredDate.getSeconds() + time);
    return expiredDate.getTime();
}

export default {
    save: (data) => {
        data.expired_date = getExpiredDate(data.expires_in);
        localStorage.setItem("access-token", JSON.stringify(data));
        api.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
    },
    clear: () => {
        localStorage.removeItem("access-token");
        delete api.defaults.headers.common['Authorization'];
    },
    update: (data) => {
        localStorage.removeItem("access-token");
        data.expired_date = getExpiredDate(data.expires_in);
        localStorage.setItem("access-token", JSON.stringify(data));
        api.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
    },
    isLoggedIn() {
        const access = localStorage.getItem("access-token");
        return !!access;
    },
    isExpired() {
        const access = localStorage.getItem("access-token");
        const token = JSON.parse(access);
        return token.expired_date < new Date().getTime();
    },
    getRefreshToken() {
        const token = localStorage.getItem("access-token");
        if (token) {
            const data = JSON.parse(token);
            return data.refresh_token;
        }

        return null;
    },
    refreshApiToken() {
        const token = localStorage.getItem("access-token");
        if (token) {
            const data = JSON.parse(token);
            api.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
        }
    },
    getBearer() {
        const token = localStorage.getItem("access-token");
        const data = JSON.parse(token);
        return 'Bearer ' + data.access_token;
    }
}