const LABEL_USERS_TABLE = {
  columns: [
    {
      label: 'users.full_name_header',
      field: 'firstName,lastName',
      getValue: (raw) => {
        if (raw.firstName && raw.lastName) {
          return raw.firstName + ' ' + raw.lastName
        } else {
          return '';
        }
      },
      ordering: true
    },
    {
      label: 'users.email_header',
      field: 'email',
      getValue: (raw) => {
        return raw.email
      },
      ordering: true
    },
    {
      label: 'users.phone_header',
      field: 'phone',
      getValue: (raw) => {
        return raw.phone ? raw.phone.fullNumber : ''
      },
      ordering: true
    },
    {
      label: 'users.device_info_header',
      field: 'lastSuccessAuthDeviceInfo',
      getValue: (raw) => {
        if (raw.deviceInfo) {
          const device = raw.deviceInfo.split(";device=")[1].split(";type=")[0];
          const os = raw.deviceInfo.split(";device=")[0].split("os=")[1];
          if (!device || device === 'unknown') {
            return os;
          } else {
            return device + " (" + os + ")"
          }
        }

        return "";
      },
      ordering: true
    },
    {
      label: 'users.app_version_header',
      field: 'lastSuccessAuthAppVersion',
      getValue: (raw) => {
        return raw.appVersion
      },
      ordering: true
    }
  ]
}

export default LABEL_USERS_TABLE;
