import React, {useEffect} from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import BsPanel from "components/panel/BsPanel";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import PasswordInfoTab from "./tabs/info/PasswordInfoTab";
import PasswordUsageTab from "./tabs/used/PasswordUsageTab";
import {fetchSystemPassword} from "core/actions/password-actions";

function PasswordInfo(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
  const {t} = useTranslation();

  useEffect(() => {
    if (!props.loadingCurrentPassword) {
      props.fetchSystemPassword(props.match.params.systemId, props.match.params.passwordId);
    }
  }, []);

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  let tabs = null;
  if (props.profile && props.system && props.system.role && props.currentPassword) {
    tabs = (
        <Tabs value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary">
          <Tab label={t('password_info.info_tab')} value="info"/>
          <Tab label={t('password_info.usage_tab')} value="usage"/>
        </Tabs>
    )
  }

  return (
      <>
        <PagePermission anonymous={false} permission={ADMIN}/>
        <BsPanel>
          {tabs}
          <Route exact path="/system/:systemId/edit-settings/password/:passwordId" component={PasswordInfoTab}/>
          <Route path="/system/:systemId/edit-settings/password/:passwordId/info" component={PasswordInfoTab}/>
          <Route path="/system/:systemId/edit-settings/password/:passwordId/usage" component={PasswordUsageTab}/>
        </BsPanel>
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    system: state.system.currentSystem,
    currentPassword: state.systemPassword.currentPassword
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemPassword: (systemId, pwdId) => {
      dispatch(fetchSystemPassword(systemId, pwdId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordInfo);
