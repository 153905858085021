const buildSubscriptionSettingsInfoForm = (billingPeriod, paymentMethods, invoices) => {
  const subscriptionSettingsInfoForm = {
    fields: [
      {
        name: 'subscription',
        label: 'subscription_config.subscription_field',
        type: 'info',
        styles: {
          maxWidth: '500px',
          marginTop: '2em'
        },
        getValue: (data) => {
          return data.subscription.name;
        }
      },
      {
        name: 'subscription_delimiter',
        type: 'delimiter',
        styles: {
          maxWidth: '500px'
        }
      },
      {
        name: 'period',
        label: 'subscription_config.billing_frequency_field',
        type: 'info',
        styles: {
          maxWidth: '500px'
        },
        translate: true,
        getValue: (data) => {
          return 'subscription_billing_period.' +  data.billingPeriod;
        }
      }
    ]
  }

  if (billingPeriod !== 'NONE') {
    subscriptionSettingsInfoForm.fields.push({
      name: 'subscriptionFee',
      label: 'subscription_config.subscription_fee_field',
      type: 'price',
      currency: 'currency',
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        return '' + (data.bikes * data.pricePerBike);
      }
    });
    subscriptionSettingsInfoForm.fields.push({
      name: 'bikes',
      label: 'subscription_config.bikes_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        return data.bikes + '';
      }
    });
    subscriptionSettingsInfoForm.fields.push({
      name: 'pricePerBike',
      label: 'subscription_config.price_per_bike_field',
      type: 'price',
      currency: 'currency',
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        return '' + data.pricePerBike;
      }
    });
    subscriptionSettingsInfoForm.fields.push({
      name: 'currentBillingPeriod',
      label: 'subscription_config.current_billing_period_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        return data.currentBillingPeriod;
      }
    });
    subscriptionSettingsInfoForm.fields.push({
      name: 'nextBillingPeriod',
      label: 'subscription_config.next_billing_period_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        return data.nextBillingPeriod;
      }
    });
  }

  subscriptionSettingsInfoForm.fields.push({
    name: 'billing_period_delimiter',
    type: 'delimiter',
    styles: {
      maxWidth: '500px'
    }
  });

  subscriptionSettingsInfoForm.fields.push({
    name: 'billingInfo',
    label: 'subscription_config.billing_info_field',
    type: 'info',
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return '';
    }
  });
  subscriptionSettingsInfoForm.fields.push({
    name: 'billingAddress.name',
    label: 'subscription_config.billing_address_name_field',
    type: 'info',
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return data.billingAddress ? data.billingAddress.name : '-';
    }
  });
  subscriptionSettingsInfoForm.fields.push({
    name: 'billingAddress.street',
    label: 'subscription_config.billing_address_street_field',
    type: 'info',
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return data.billingAddress ? data.billingAddress.street : '-';
    }
  });
  subscriptionSettingsInfoForm.fields.push({
    name: 'billingAddress.building',
    label: 'subscription_config.billing_address_building_field',
    type: 'info',
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return data.billingAddress ? data.billingAddress.building : '-';
    }
  });
  subscriptionSettingsInfoForm.fields.push({
    name: 'billingAddress.city',
    label: 'subscription_config.billing_address_city_field',
    type: 'info',
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return data.billingAddress ? data.billingAddress.city : '-';
    }
  });
  subscriptionSettingsInfoForm.fields.push({
    name: 'billingAddress.state',
    label: 'subscription_config.billing_address_state_field',
    type: 'info',
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return data.billingAddress ? data.billingAddress.state : '-';
    }
  });
  subscriptionSettingsInfoForm.fields.push({
    name: 'billingAddress.zip',
    label: 'subscription_config.billing_address_zip_field',
    type: 'info',
    styles: {
      maxWidth: '500px'
    },
    getValue: (data) => {
      return data.billingAddress ? data.billingAddress.zip : '-';
    }
  });

  subscriptionSettingsInfoForm.fields.push({
    name: 'billing_address_delimiter',
    type: 'delimiter',
    styles: {
      maxWidth: '830px'
    }
  });

  subscriptionSettingsInfoForm.fields.push({
    name: 'paymentMethods',
    label: 'subscription_config.payment_methods_field',
    type: 'any',
    styles: {
      maxWidth: '830px'
    },
    className: "payment-methods",
    value: paymentMethods
  });

  subscriptionSettingsInfoForm.fields.push({
    name: 'payment_methods_delimiter',
    type: 'delimiter',
    styles: {
      maxWidth: '830px'
    }
  });

  subscriptionSettingsInfoForm.fields.push({
    name: 'invoices',
    label: 'subscription_config.invoices_field',
    type: 'any',
    className: "transactions",
    value: invoices
  });

  return subscriptionSettingsInfoForm
}

export default buildSubscriptionSettingsInfoForm;
