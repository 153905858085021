import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

import './MaintenancePlanInfo.scss';
const MaintenancePlanInfo = (props) => {
  const { t } = useTranslation();

  if (props.offerFleetIntegration) {
    if (props.plan) {
      return (
        <Grid item sm xs className='statistics-panel'>
          <Paper elevation={3}>
            <div className='plan-info'>
              <div className='plan-info__desc'>
                {t('dashboard.maintenance_plan_title', { months: props.plan })}
              </div>

              <div
                className='plan-info__status'
                onClick={props.onOpenMaintenancePlan}
              >
                {t('maintenance_plan_status.ACTIVE')}
              </div>
            </div>
          </Paper>
        </Grid>
      );
    } else {
      return (
        <Grid item sm xs className='statistics-panel'>
          <Paper elevation={3}>
            <div className='plan-info'>
              <div className='plan-info__no-plan' onClick={props.onGetOffer}>
                {t('dashboard.no_maintenance_plan_info')}
              </div>
            </div>
          </Paper>
        </Grid>
      );
    }
  } else {
    return <Grid item sm xs className='statistics-panel' />;
  }
};

export default MaintenancePlanInfo;
