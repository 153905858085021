import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import {fetchLabelClientUserInfo} from "core/actions/client-user-actions";
import LABEL_CLIENT_USER_INFO_FORM from "./label-client-user-info-form";

class LabelClientUserInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.props.fetchLabelClientUserInfo(this.props.match.params.labelId, this.props.match.params.userId);
  }

  render() {
    if (this.props.currentUser) {
      const formConfig = {
        ...LABEL_CLIENT_USER_INFO_FORM,
        title: {
          back: {
            url: '/label/' + this.props.match.params.labelId + '/users',
            history: this.props.history,
            location: this.props.location
          }
        }
      };

      return (
          <div className="bs-form-container">
            <BsForm
                config={formConfig}
                formData={this.props.currentUser}
                disabled={true}/>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.clientUser.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLabelClientUserInfo: (labelId, userId) => {
      dispatch(fetchLabelClientUserInfo(labelId, userId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelClientUserInfoTab);
