import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import GoogleMap from "components/map/GoogleMap";
import ConfirmationModel from "components/model/ConfirmationModel";
import {ADMIN} from "core/utils/permissions";
import {getAddressDetails} from "core/actions/geocode-actions";
import {updateLocation, fetchLocation} from "core/actions/location-actions";
import EDIT_LOCATION_FORM from "./edit-location-form";

class EditLocationInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openConfirmationWindow: false
        };
        this.backUrl = '/system/' + this.props.match.params.systemId + '/location/' + this.props.match.params.locationId;
        this.props.fetchLocation(this.props.match.params.systemId, this.props.match.params.locationId);
    }

    componentDidMount() {
        BsForm.addValidationRule('requiredAddress', (value) => {
            return !!this.props.decodedAddress;
        });
        BsForm.addValidationRule('macAddress', (value) => {
            return !value || value.replaceAll('_', "").length === 17;
        });
    }

    componentWillUnmount() {
        BsForm.removeValidationRule('requiredAddress');
        BsForm.removeValidationRule('macAddress');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.currentLocation && this.props.currentLocation.address && !this.state.decodedAddress) {
            this.updateDecodedAddress(this.props.currentLocation.address);
        }

        if (this.props.decodedAddress && this.props.decodedAddress.placeId && (!this.state.decodedAddress
            || this.props.decodedAddress.placeId !== this.state.decodedAddress.placeId)) {
            this.updateDecodedAddress(this.props.decodedAddress);
        }
    }

    updateDecodedAddress(address) {
        const latLng = {
            lat: address.latitude,
            lng: address.longitude
        }
        this.setState({
            ...this.state,
            decodedAddress: address,
            marker: latLng,
            center: latLng
        })
    }

    handleSubmit(fields, e) {
        this.fields = fields;

        if (!this.props.decodedAddress.country) {
            this.props.showNotification('error', 'We could not recognize your address. ' +
                'Please make sure you entered a full address, including street');
        } else {
            this.setState({openConfirmationWindow: true})
        }
    }

    handleUpdate() {
        this.setState({openConfirmationWindow: false})
        this.props.updateLocation(this.props.match.params.systemId, this.props.match.params.locationId, {
            label: this.fields.label,
            address: {
                ...this.props.decodedAddress,
                building: this.props.decodedAddress.building ? this.props.decodedAddress.building : 1,
                latitude: this.state.marker.lat,
                longitude: this.state.marker.lng
            },
            beaconMacAddress: this.fields.beaconMacAddress ? this.fields.beaconMacAddress : null,
            capacity: this.fields.capacity
        }, this.backUrl);
    }

    handleChange(fields) {
        if (fields.address.placeId !== this.props.decodedAddress.placeId) {
            this.props.getAddressDetails(fields.address.placeId);
        }
    }

    handleChangeMarker(e) {
        this.setState({
            ...this.state,
            marker: e,
            center: e
        });
    }

    render() {
        if (this.props.currentLocation) {
            return (
                <BsFormPanel size="l">
                    <PagePermission anonymous={false} permission={ADMIN}/>
                    <ConfirmationModel
                        open={this.state.openConfirmationWindow}
                        message="edit_location.confirmation_message"
                        handleClose={() => this.setState({openConfirmationWindow: false})}
                        handleApply={this.handleUpdate.bind(this)}
                    />

                    <BsForm
                        config={EDIT_LOCATION_FORM}
                        formData={this.props.currentLocation}
                        onHandleSubmit={this.handleSubmit.bind(this)}
                        onHandleChange={this.handleChange.bind(this)}
                        cancelAction={
                            <BsFormCancelAction history={this.props.history}
                                                location={this.props.location}
                                                url={this.backUrl}/>
                        }
                        additionalContent={
                            <GoogleMap
                                onChangeMarker={this.handleChangeMarker.bind(this)}
                                marker={this.state.marker}
                                center={this.state.center}/>
                        }
                    />
                </BsFormPanel>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        currentLocation: state.location.currentLocation,
        decodedAddress: state.geocode.decodedAddress
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAddressDetails: (placeId) => {
            dispatch(getAddressDetails(placeId));
        },
        fetchLocation: (systemId, locationId) => {
            dispatch(fetchLocation(systemId, locationId));
        },
        updateLocation: (systemId, locationId, data, backUrl) => {
            dispatch(updateLocation(systemId, locationId, data, backUrl));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLocationInfo);
