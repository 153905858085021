import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import {useTranslation} from "react-i18next";
import Row from "components/layout/Row";
import Col from "components/layout/Col";

const ActiveRentalsFilters = (props) => {
  const {t} = useTranslation();
  const nameRef = React.createRef();
  const bikeRef = React.createRef();

  return (
    <Row>
      <Col size="6">
        <BsPanelHeaderFilterControl>
          <TextField label={t('rentals.customer_name_filter')}
                     ref={nameRef}
                     onChange={props.onHandleChange.bind(this, "name")}
                     value={props.fields["name"]}
                     className="form-control"/>
        </BsPanelHeaderFilterControl>
      </Col>
      <Col size="6">
        <BsPanelHeaderFilterControl>
          <TextField label={t('rentals.bicycle_filter')}
                     ref={bikeRef}
                     onChange={props.onHandleChange.bind(this, "bikeNumber")}
                     value={props.fields["bikeNumber"]}
                     className="form-control"/>
        </BsPanelHeaderFilterControl>
      </Col>
    </Row>
  )
}

export default ActiveRentalsFilters;
