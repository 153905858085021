export const integrationsReducer = (state = {
  vt: null,
  loading: false
}, action) => {
  switch (action.type) {
    case 'INTEGRATE_WITH_VT':
    case 'FETCH_INTEGRATION_WITH_VT':
    case 'CLEAR_INTEGRATE_WITH_VT':
      return {
        ...state,
        loading: true
      }
    case 'INTEGRATE_WITH_VT_SUCCESS':
    case 'FETCH_INTEGRATION_WITH_VT_SUCCESS':
      return {
        ...state,
        loading: false,
        vt: action.payload.data
      }
    case 'CLEAR_INTEGRATE_WITH_VT_SUCCESS':
      return {
        ...state,
        loading: false,
        vt: null
      }
    case 'INTEGRATE_WITH_VT_FAIL':
    case 'FETCH_INTEGRATION_WITH_VT_FAIL':
    case 'CLEAR_INTEGRATE_WITH_VT_FAIL':
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
