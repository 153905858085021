import React from "react";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import {connect} from "react-redux";
import ArrowBack from "assets/img/arrow-back.svg";
import {useTranslation} from "react-i18next";

import './BsBackBtn.scss';
import PropTypes from "prop-types";

const BsBackBtn = (props) => {
    const {t} = useTranslation();

    const goBack = () => {
        props.history.push(getLast())
    }

    const getLast = () => {
        if (props.routes.length > 0) {
            for (let i = props.routes.length - 1; i >= 0; i--) {
                if (props.routes[i].pathname === props.url) {
                    return props.routes[i].pathname + (props.routes[i].search ? props.routes[i].search : '')
                }
            }
        }

        return props.url
    }

    if (props.type === 'fab') {
        return (
            <Fab size="small" aria-label="Back" onClick={goBack} className="fab__white">
                <ArrowBack/>
            </Fab>
        )
    } else {
        return (
            <Button variant="outlined"
                    onClick={goBack}>{t('general.cancel')}</Button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        routes: state.route.routes
    };
};

BsBackBtn.propTypes = {
    type: PropTypes.string,
    url: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(BsBackBtn);