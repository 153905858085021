const ADD_LABEL_SYSTEM_FORM = {
  title: {
    text: 'add_label_system.title'
  },
  submit: {
    label: 'add_label_system.submit_btn'
  },
  fields: [
    {
      name: 'system',
      label: 'add_label_system.system_field',
      type: 'systems-autocomplete',
      validators: ['requiredSystem'],
      errors: ['errors.required_system'],
      defaultValue: {}
    }
  ]
}

export default ADD_LABEL_SYSTEM_FORM;
