import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import BsFormPanel from "components/form/BsFormPanel";
import {fetchLabelMaintenanceIssueInfo} from "core/actions/maintenance-actions";
import LABEL_CUSTOMER_SUPPORT_ISSUE_INFO from "./label-customer-support-issue-info";
import SupportIssueImages from "../../../admin/cusomer-support/form/SupportIssueImages";

import './label-customer-support-issue-info.scss'

class LabelCustomerSupportIssueInfo extends React.Component {

  constructor(props) {
    super(props);
    this.props.fetchLabelMaintenanceIssueInfo(this.props.match.params.labelId, this.props.match.params.issueId);
  }

  render() {
    if (this.props.currentIssue) {
      const formConfig = {
        ...LABEL_CUSTOMER_SUPPORT_ISSUE_INFO,
        title: {
          back: {
            url: '/label/' + this.props.match.params.labelId + '/reported-problems',
            history: this.props.history,
            location: this.props.location
          },
          text: 'issue_info.title'
        }
      };

      return (
          <BsFormPanel size="m">
            <BsForm
                config={formConfig}
                formData={this.props.currentIssue}
                disabled={true}/>

            <SupportIssueImages images={this.props.currentIssue.images}
                                title={this.props.currentIssue.problem}
                                desc={this.props.currentIssue.description}/>
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentIssue: state.maintenance.currentIssue
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLabelMaintenanceIssueInfo: (labelId, issueId) => {
      dispatch(fetchLabelMaintenanceIssueInfo(labelId, issueId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelCustomerSupportIssueInfo);
