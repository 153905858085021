import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Grid } from '@material-ui/core';

import StatisticsItem from 'components/statistics-item/StatisticsItem';

import './statistics-panel.scss';

function StatisticsPanel(props) {
  const { t } = useTranslation();

  const first = props.data.first ? props.data.first : {};
  const second = props.data.second ? props.data.second : {};

  return (
    <Grid item sm xs className='statistics-panel' onClick={props.onClick}>
      <Paper elevation={3}>
        <div className='statistics-panel__container'>
          <div className='statistics-panel__title'>
            <div className='statistics-panel__title-icon'>
              {props.data.title.icon}
            </div>
            <div className='statistics-panel__title-label'>
              {t(props.data.title.label)}
            </div>
          </div>

          <div className='statistics-panel__content'>
            <StatisticsItem
              key={first.label}
              label={first.label}
              onClick={first.onClick}
              valueStyle={first.valueStyle}
              value={first.value}
              onWarningClick={first.onWarningClick}
              warning={first.warning}
            />

            <StatisticsItem
              key={second.label}
              label={second.label}
              onClick={second.onClick}
              valueStyle={second.valueStyle}
              value={second.value}
              onWarningClick={second.onWarningClick}
              warning={second.warning}
            />
          </div>
        </div>
      </Paper>
    </Grid>
  );
}

export default StatisticsPanel;
