import React from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BsPanel from "components/panel/BsPanel";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import SubscriptionSystems from "./systems/SubscriptionSystems";
import SubscriptionPlans from "./plans/SubscriptionPlans";

function Subscriptions(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'systems');
  const {t} = useTranslation();

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  let tabs = null;
  if (props.profile) {
    tabs = (
        <Tabs value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary">
          <Tab label={t('subscriptions.systems')} value="systems"/>
          <Tab label={t('subscriptions.plans')} value="plans"/>
        </Tabs>
    )
  }

  return (
      <>
        <BsPanel>
          {tabs}
          <Route exact path="/global/subscriptions" component={SubscriptionSystems}/>
          <Route path="/global/subscriptions/systems" component={SubscriptionSystems}/>
          <Route path="/global/subscriptions/plans" component={SubscriptionPlans}/>
        </BsPanel>
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile
  };
};

export default connect(mapStateToProps)(Subscriptions);
