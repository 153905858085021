import React from "react";
import {connect} from "react-redux";
import BsValidatorForm from "components/form/BsValidatorForm";
import BsFormTitle from "components/form/title/BsFormTitle";
import BsFormPanel from "components/form/BsFormPanel";
import BsTableHead from "components/data-table/BsTableHead";
import BsTableBody from "components/data-table/BsTableBody";
import ConfirmationModel from "components/model/ConfirmationModel";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import {
  fetchMaintenancePlan,
  updateMaintenancePlanMechanic,
  updateMaintenancePlanService,
  cancelMaintenancePlan
} from "core/actions/maintenance-plan-actions";
import {loadAllAdmins} from "core/actions/admin-actions";
import {loadServices} from "core/actions/service-actions";
import MaintenancePlanInfoFormActions from "./MaintenancePlanInfoFormActions";
import ChangeMechanicModel from "../../models/ChangeMechanicModel";
import ChangeServiceModel from "../../models/ChangeServiceModel";
import MAINTENANCE_VISITS_TABLE from "./maintenance-visits-table";
import Moment from "moment";

class MaintenancePlanInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openChangeMechanicModel: false,
      openChangeServiceModel: false,
      confirmationWindow: {
        open: false,
        message: '',
        event: () => {
        }
      },
    };
    this.props.fetchMaintenancePlan(this.props.match.params.planId);
  }

  goToService(visit) {
    this.props.history.push('/global/maintenance-plan/' + this.props.match.params.planId + '/visit/' + visit.number + '/info')
  }

  onHandleChangeMechanic() {
    this.props.loadAllAdmins(this.props.currentMaintenancePlan.system.id);

    this.setState({
      ...this.state,
      openChangeMechanicModel: true
    });
  }

  closeChangeMechanicModel() {
    this.setState({
      ...this.state,
      openChangeMechanicModel: false
    });
  }

  submitChangeMechanicModel(mechanic) {
    this.props.updateMaintenancePlanMechanic(this.props.match.params.planId, {mechanicId: mechanic});

    this.setState({
      ...this.state,
      openChangeMechanicModel: false
    });
  }

  onHandleChangeService() {
    this.props.loadServices(this.props.currentMaintenancePlan.system.id);

    this.setState({
      ...this.state,
      openChangeServiceModel: true
    });
  }

  closeChangeServiceModel() {
    this.setState({
      ...this.state,
      openChangeServiceModel: false
    });
  }

  submitChangeServiceModel(taskStepId) {
    const data = this.props.services.filter((service) => service.taskStepId === taskStepId)[0]
    this.props.updateMaintenancePlanService(this.props.match.params.planId, data);

    this.setState({
      ...this.state,
      openChangeServiceModel: false
    });
  }

  onHandleCancel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'maintenance_plan_info.cancel_maintenance_plan_message',
        event: this.cancelPlan
      }
    })
  }

  cancelPlan() {
    this.props.cancelMaintenancePlan(this.props.match.params.planId);
    this.closeConfirmationModel();
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  onHandleRenew() {
    this.props.history.push('/global/maintenance-plan/' + this.props.match.params.planId + '/renew?systemId='
        + this.props.currentMaintenancePlan.system.id)
  }

  render() {
    if (this.props.currentMaintenancePlan) {
      const plan = this.props.currentMaintenancePlan;

      return (
          <BsFormPanel size="l">
            <ChangeMechanicModel
                mechanicId={plan.mechanic.id}
                open={this.state.openChangeMechanicModel}
                handleClose={this.closeChangeMechanicModel.bind(this)}
                handleSubmit={this.submitChangeMechanicModel.bind(this)}
                mechanics={this.props.mechanics}/>

            <ChangeServiceModel
                serviceId={plan.service.taskStepId}
                open={this.state.openChangeServiceModel}
                handleClose={this.closeChangeServiceModel.bind(this)}
                handleSubmit={this.submitChangeServiceModel.bind(this)}
                services={this.props.services}/>

            <ConfirmationModel
                open={this.state.confirmationWindow.open}
                message={this.state.confirmationWindow.message}
                handleClose={this.closeConfirmationModel.bind(this)}
                handleApply={this.state.confirmationWindow.event.bind(this)}
                backBtn="general.back"/>

            <BsValidatorForm onHandleSubmit={() => {
            }}>
              <BsFormTitle title={{
                text: (plan.system.name + ' ' + Moment(plan.runRime).format('YYYY')
                    + ' (' + (plan.totalVisits - plan.visitsLeft) + '/' + plan.totalVisits + ')'),
                back: {
                  url: '/global/maintenance/plans',
                  history: this.props.history,
                  location: this.props.location
                },
                actions: <MaintenancePlanInfoFormActions
                    onHandleChangeMechanic={this.onHandleChangeMechanic.bind(this)}
                    onHandleChangeService={this.onHandleChangeService.bind(this)}
                    onHandleCancel={this.onHandleCancel.bind(this)}
                    onHandleRenew={this.onHandleRenew.bind(this)}
                    status={plan.status}
                    manageMaintenance={this.props.manageMaintenance}/>
              }}/>

              <TableContainer>
                <Table size="medium">
                  <BsTableHead settings={MAINTENANCE_VISITS_TABLE}/>
                  <BsTableBody settings={MAINTENANCE_VISITS_TABLE}
                               content={{content: this.props.currentMaintenancePlan.schedule}}
                               onRowClick={this.goToService.bind(this)}/>
                </Table>
              </TableContainer>

            </BsValidatorForm>
          </BsFormPanel>
      )
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentMaintenancePlan: state.maintenancePlan.currentMaintenancePlan,
    loading: state.maintenancePlan.loading,
    mechanics: state.admin.allAdmins,
    services: state.service.services,
    manageMaintenance: state.profile.profile != null && state.profile.profile.role != null
        && state.profile.profile.role.indexOf('VT_SUPER_ADMIN') > -1
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMaintenancePlan: (planId) => {
      dispatch(fetchMaintenancePlan(planId));
    },
    updateMaintenancePlanMechanic: (planId, data) => {
      dispatch(updateMaintenancePlanMechanic(planId, data));
    },
    updateMaintenancePlanService: (planId, data) => {
      dispatch(updateMaintenancePlanService(planId, data));
    },
    cancelMaintenancePlan: (planId) => {
      dispatch(cancelMaintenancePlan(planId));
    },
    loadAllAdmins: (systemId, query = {page: 0, size: 100, status: 'ACTIVE'}) => {
      dispatch(loadAllAdmins(systemId, query));
    },
    loadServices: (systemId) => {
      dispatch(loadServices(systemId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePlanInfo);
