import {put, takeEvery} from "redux-saga/effects"
import {startLoading, stopLoading} from "../actions/app-actions"
import api from 'core/utils/api'

function* makeActionWithLoading(action) {
    try {
        yield callStartLoading(action.payload)

        const response = yield api.request(action.payload.request)
        yield put({type: action.type + '_SUCCESS', payload: response});

        yield callStopLoading(action.payload);
        yield callSuccessCallback(action.payload, response);
        yield callRedirectCallback(action.payload);
    } catch (error) {
        yield put({type: action.type + '_FAIL', payload: error});
        yield callStopLoading(action.payload);
        yield callErrorCallback(action.payload, error);
        yield handleError(error);
    }
}

function* callRedirectCallback(payload) {
    if (payload && payload.redirect) {
        yield put({type: 'REDIRECT_TO', payload: payload.redirect});
    }
}

function* callSuccessCallback(payload, response) {
    if (payload && payload.successAction) {
        yield put(payload.successAction);
    }
    if (payload && payload.successCallback) {
        let successCallback = payload.successCallback(response);
        if (successCallback instanceof Array) {
            for (let callback of successCallback) {
                yield put(callback);
            }
        } else {
            yield put(successCallback);
        }
    }
}

function* callErrorCallback(payload, error) {
    if (payload && payload.errorAction) {
        yield  put(payload.errorAction);
    }
    if (payload && payload.errorCallback) {
        yield put(payload.errorCallback(error));
    }
}

function* callStartLoading(payload) {
    if (payload && payload.globalLoading) {
        yield  put(startLoading());
    }
}

function* callStopLoading(payload) {
    if (payload && payload.globalLoading) {
        yield  put(stopLoading());
    }
}

function* handleError(error) {
    if (error && error.status !== 401) {
        let message;
        if (error.status === 403) {
            message = 'Permission denied'
        } else if (error.data) {
            if (error.data.violations && error.data.violations.length > 0) {
                message = error.data.violations[0].message;
            } else if (error.data.detail) {
                message = error.data.detail;
            }
        }

        if (message) {
            yield put({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: message,
                    variant: 'error'
                }
            });
        }
    }
}

export default function* apiSaga() {
    yield takeEvery('LOAD_SYSTEM', makeActionWithLoading)
    yield takeEvery('LOAD_DEFAULT_SYSTEM', makeActionWithLoading)
    yield takeEvery('SIGN_IN', makeActionWithLoading)
    yield takeEvery('LOGOUT', makeActionWithLoading)
    yield takeEvery('FORGOT_PASSWORD', makeActionWithLoading)
    yield takeEvery('RESET_PASSWORD', makeActionWithLoading)
    yield takeEvery('LOAD_PROFILE', makeActionWithLoading)
    yield takeEvery('FETCH_SYSTEMS', makeActionWithLoading)
    yield takeEvery('FETCH_USER_SYSTEMS', makeActionWithLoading)
    yield takeEvery('FETCH_CLIENT_SYSTEMS', makeActionWithLoading)
    yield takeEvery('REFRESH_SYSTEMS', makeActionWithLoading)
    yield takeEvery('CREATE_ADMIN_USER', makeActionWithLoading)
    yield takeEvery('FETCH_ADMIN_USERS', makeActionWithLoading)
    yield takeEvery('FETCH_BO_USERS', makeActionWithLoading)
    yield takeEvery('FETCH_ALL_ADMIN_USERS', makeActionWithLoading)
    yield takeEvery('FETCH_AUTHORITIES', makeActionWithLoading)
    yield takeEvery('FETCH_SYSTEM_ADMIN', makeActionWithLoading)
    yield takeEvery('FETCH_BO_USER', makeActionWithLoading)
    yield takeEvery('UPDATE_ADMIN_PERMISSIONS', makeActionWithLoading)
    yield takeEvery('UPDATE_ADMIN_STATUS', makeActionWithLoading)
    yield takeEvery('ACTIVATE_ACCOUNT', makeActionWithLoading)
    yield takeEvery('ADMINS_AUTOCOMPLETE', makeActionWithLoading)
    yield takeEvery('CREATE_SYSTEM', makeActionWithLoading)
    yield takeEvery('SYSTEMS_AUTOCOMPLETE', makeActionWithLoading)
    yield takeEvery('FETCH_CLIENT_USERS', makeActionWithLoading)
    yield takeEvery('FETCH_ALL_CLIENT_USERS', makeActionWithLoading)
    yield takeEvery('CREATE_CLIENT_USER', makeActionWithLoading)
    yield takeEvery('FETCH_CLIENT_USER', makeActionWithLoading)
    yield takeEvery('FETCH_CLIENT_USER_INFO', makeActionWithLoading)
    yield takeEvery('FETCH_LABEL_CLIENT_USERS', makeActionWithLoading)
    yield takeEvery('FETCH_LABEL_CLIENT_USER_INFO', makeActionWithLoading)
    yield takeEvery('UPDATE_CLIENT_USER_STATUS', makeActionWithLoading)
    yield takeEvery('RESEND_CLIENT_ACTIVATION_EMAIL', makeActionWithLoading)
    yield takeEvery('UPDATE_CLIENT_USER_EMAIL', makeActionWithLoading)
    yield takeEvery('FETCH_LOCATIONS', makeActionWithLoading)
    yield takeEvery('FETCH_ALL_LOCATIONS', makeActionWithLoading)
    yield takeEvery('ADDRESS_AUTOCOMPLETE', makeActionWithLoading)
    yield takeEvery('FETCH_ADDRESS_DETAILS', makeActionWithLoading)
    yield takeEvery('CREATE_LOCATION', makeActionWithLoading)
    yield takeEvery('FETCH_LOCATION', makeActionWithLoading)
    yield takeEvery('UPDATE_LOCATION_STATUS', makeActionWithLoading)
    yield takeEvery('UPDATE_LOCATION', makeActionWithLoading)
    yield takeEvery('FETCH_BICYCLES', makeActionWithLoading)
    yield takeEvery('FETCH_ALL_BICYCLES', makeActionWithLoading)
    yield takeEvery('CREATE_BICYCLE', makeActionWithLoading)
    yield takeEvery('UPDATE_BICYCLE', makeActionWithLoading)
    yield takeEvery('FETCH_BICYCLE', makeActionWithLoading)
    yield takeEvery('FETCH_BICYCLE_INFO', makeActionWithLoading)
    yield takeEvery('FETCH_LABEL_BICYCLES', makeActionWithLoading)
    yield takeEvery('FETCH_LABEL_BICYCLE_INFO', makeActionWithLoading)
    yield takeEvery('FETCH_BICYCLE_SCAN_HISTORY', makeActionWithLoading)
    yield takeEvery('FETCH_BICYCLE_SCAN_BY_DAY', makeActionWithLoading)
    yield takeEvery('UPDATE_BICYCLE_STATUS', makeActionWithLoading)
    yield takeEvery('FETCH_BICYCLE_REPORTS', makeActionWithLoading)
    yield takeEvery('FETCH_BICYCLE_REPORT', makeActionWithLoading)
    yield takeEvery('FETCH_ALL_BICYCLE_REPORTS', makeActionWithLoading)
    yield takeEvery('FETCH_BICYCLE_REPORT_INFO', makeActionWithLoading)
    yield takeEvery('UPDATE_BICYCLE_REPORT', makeActionWithLoading)
    yield takeEvery('CREATE_ISSUE_BY_REPORT', makeActionWithLoading)
    yield takeEvery('CLEAR_POWER_LEVEL_HISTORY_STATUS', makeActionWithLoading)
    yield takeEvery('CHANGE_PROFILE', makeActionWithLoading)
    yield takeEvery('CHANGE_PASSWORD', makeActionWithLoading)
    yield takeEvery('LOAD_SYSTEM_CONFIG', makeActionWithLoading)
    yield takeEvery('UPDATE_RENTAL_SYSTEM_CONFIG', makeActionWithLoading)
    yield takeEvery('UPDATE_SYSTEM_INFO', makeActionWithLoading)
    yield takeEvery('UPDATE_SYSTEM_NAME', makeActionWithLoading)
    yield takeEvery('UPDATE_TOS_SYSTEM_CONFIG', makeActionWithLoading)
    yield takeEvery('UPDATE_GENERAL_HELP_SYSTEM_CONFIG', makeActionWithLoading)
    yield takeEvery('UPDATE_SYSTEM_LOGO', makeActionWithLoading)
    yield takeEvery('UPDATE_SYSTEM_PAYMENT_PROVIDER', makeActionWithLoading)
    yield takeEvery('UPDATE_SYSTEM_TAX_CONFIG', makeActionWithLoading)
    yield takeEvery('DELETE_SYSTEM', makeActionWithLoading)
    yield takeEvery('FETCH_RENTALS', makeActionWithLoading)
    yield takeEvery('FETCH_ALL_RENTALS', makeActionWithLoading)
    yield takeEvery('FETCH_RENTAL', makeActionWithLoading)
    yield takeEvery('FETCH_RENTAL_INFO', makeActionWithLoading)
    yield takeEvery('FETCH_LABEL_RENTALS', makeActionWithLoading)
    yield takeEvery('FETCH_LABEL_RENTAL_INFO', makeActionWithLoading)
    yield takeEvery('FINISH_RENTAL', makeActionWithLoading)
    yield takeEvery('RESOLVE_RENTAL_PROBLEM', makeActionWithLoading)
    yield takeEvery('FETCH_MAINTENANCE_ISSUES', makeActionWithLoading)
    yield takeEvery('FETCH_ALL_MAINTENANCE_ISSUES', makeActionWithLoading)
    yield takeEvery('FETCH_MAINTENANCE_ISSUE', makeActionWithLoading)
    yield takeEvery('FETCH_MAINTENANCE_ISSUE_INFO', makeActionWithLoading)
    yield takeEvery('FETCH_LABEL_MAINTENANCE_ISSUES', makeActionWithLoading)
    yield takeEvery('FETCH_LABEL_MAINTENANCE_ISSUE_INFO', makeActionWithLoading)
    yield takeEvery('UPDATE_MAINTENANCE_ISSUE_STATUS', makeActionWithLoading)
    yield takeEvery('UPDATE_MAINTENANCE_ISSUE_PRIORITY', makeActionWithLoading)
    yield takeEvery('LOAD_SYSTEM_STATISTICS', makeActionWithLoading)
    yield takeEvery('RESEND_ACTIVATION_LINK', makeActionWithLoading)
    yield takeEvery('FETCH_VERSION', makeActionWithLoading)
    yield takeEvery('FETCH_INTEGRATION_WITH_VT', makeActionWithLoading)
    yield takeEvery('INTEGRATE_WITH_VT', makeActionWithLoading)
    yield takeEvery('CLEAR_INTEGRATE_WITH_VT', makeActionWithLoading)
    yield takeEvery('TOGGLE_OFFER_INTEGRATION_INTEGRATION', makeActionWithLoading)
    yield takeEvery('REQUEST_MAINTENANCE_PLAN', makeActionWithLoading)
    yield takeEvery('FETCH_MAINTENANCE_PLANS', makeActionWithLoading)
    yield takeEvery('FETCH_MAINTENANCE_PLAN', makeActionWithLoading)
    yield takeEvery('FETCH_ACTIVE_MAINTENANCE_PLAN', makeActionWithLoading)
    yield takeEvery('CREATE_MAINTENANCE_PLAN', makeActionWithLoading)
    yield takeEvery('RENEW_MAINTENANCE_PLAN', makeActionWithLoading)
    yield takeEvery('FETCH_MAINTENANCE_VISIT', makeActionWithLoading)
    yield takeEvery('UPDATE_MAINTENANCE_PLAN_MECHANIC', makeActionWithLoading)
    yield takeEvery('UPDATE_MAINTENANCE_PLAN_SERVICE', makeActionWithLoading)
    yield takeEvery('UPDATE_MAINTENANCE_PLAN_STATUS', makeActionWithLoading)
    yield takeEvery('UPDATE_MAINTENANCE_VISIT_MECHANIC', makeActionWithLoading)
    yield takeEvery('UPDATE_MAINTENANCE_VISIT_SERVICE', makeActionWithLoading)
    yield takeEvery('UPDATE_MAINTENANCE_VISIT_TIME', makeActionWithLoading)
    yield takeEvery('UPDATE_MAINTENANCE_VISIT', makeActionWithLoading)
    yield takeEvery('REFRESH_MAINTENANCE_VISIT', makeActionWithLoading)
    yield takeEvery('FETCH_MAINTENANCE_VISITS', makeActionWithLoading)
    yield takeEvery('FETCH_MAINTENANCE_VISIT_INFO', makeActionWithLoading)
    yield takeEvery('FETCH_SERVICES', makeActionWithLoading)
    yield takeEvery('FETCH_ALL_LABELS', makeActionWithLoading)
    yield takeEvery('LOAD_LABEL', makeActionWithLoading)
    yield takeEvery('LOAD_SELECTED_LABEL', makeActionWithLoading)
    yield takeEvery('CREATE_LABEL', makeActionWithLoading)
    yield takeEvery('UPDATE_LABEL', makeActionWithLoading)
    yield takeEvery('FETCH_LABELS_SYSTEMS', makeActionWithLoading)
    yield takeEvery('FETCH_LABELS_SYSTEM', makeActionWithLoading)
    yield takeEvery('ADD_LABEL_SYSTEM', makeActionWithLoading)
    yield takeEvery('DELETE_LABEL_SYSTEM', makeActionWithLoading)
    yield takeEvery('FETCH_LABELS_ADMINS', makeActionWithLoading)
    yield takeEvery('FETCH_LABEL_ADMIN', makeActionWithLoading)
    yield takeEvery('ADD_LABEL_ADMIN', makeActionWithLoading)
    yield takeEvery('FETCH_USER_LABELS', makeActionWithLoading)
    yield takeEvery('DELETE_LABEL_ADMIN', makeActionWithLoading)
    yield takeEvery('LOAD_GLOBAL_STATISTICS', makeActionWithLoading)
    yield takeEvery('LOAD_LABEL_STATISTICS', makeActionWithLoading)
    yield takeEvery('JOIN_SYSTEM', makeActionWithLoading)
    yield takeEvery('FETCH_SYSTEM_PASSWORDS', makeActionWithLoading)
    yield takeEvery('CREATE_SYSTEM_PASSWORDS', makeActionWithLoading)
    yield takeEvery('FETCH_SYSTEM_PASSWORD', makeActionWithLoading)
    yield takeEvery('UPDATE_SYSTEM_PASSWORD_STATUS', makeActionWithLoading)
    yield takeEvery('FETCH_SYSTEM_PASSWORD_USAGE_HISTORY', makeActionWithLoading)
    yield takeEvery('FETCH_SUBSCRIPTION_PLANS', makeActionWithLoading)
    yield takeEvery('CREATE_SUBSCRIPTION_PLAN', makeActionWithLoading)
    yield takeEvery('UPDATE_SUBSCRIPTION_PLAN', makeActionWithLoading)
    yield takeEvery('REMOVE_SUBSCRIPTION_PLAN', makeActionWithLoading)
    yield takeEvery('FETCH_SUBSCRIPTION_PLAN', makeActionWithLoading)
    yield takeEvery('FETCH_SYSTEM_SUBSCRIPTIONS', makeActionWithLoading)
    yield takeEvery('FETCH_SYSTEM_SUBSCRIPTION', makeActionWithLoading)
    yield takeEvery('UPDATE_SYSTEM_SUBSCRIPTION', makeActionWithLoading)
    yield takeEvery('FETCH_SUBSCRIPTION_TRANSACTIONS', makeActionWithLoading)
    yield takeEvery('FETCH_SYSTEM_PAYMENT_SOURCES', makeActionWithLoading)
    yield takeEvery('ADD_SYSTEM_PAYMENT_SOURCES', makeActionWithLoading)
    yield takeEvery('FETCH_SYSTEM_BANK_ACCOUNT', makeActionWithLoading)
    yield takeEvery('SET_DEFAULT_SYSTEM_BANK_ACCOUNT', makeActionWithLoading)
    yield takeEvery('REMOVE_SYSTEM_BANK_ACCOUNT', makeActionWithLoading)
    yield takeEvery('UPDATE_SYSTEM_BANK_ACCOUNT', makeActionWithLoading)
    yield takeEvery('ADD_SYSTEM_BANK_ACCOUNT_LEGACY', makeActionWithLoading)
    yield takeEvery('UPDATE_SYSTEM_PAYMENT_SOURCES', makeActionWithLoading)
    yield takeEvery('REMOVE_SYSTEM_PAYMENT_SOURCES', makeActionWithLoading)
    yield takeEvery('UPDATE_SUBSCRIPTION_TRANSACTION', makeActionWithLoading)
    yield takeEvery('FETCH_RENTAL_RATES', makeActionWithLoading)
    yield takeEvery('CREATE_RENTAL_RATE', makeActionWithLoading)
    yield takeEvery('FETCH_RENTAL_RATE', makeActionWithLoading)
    yield takeEvery('UPDATE_RENTAL_RATE', makeActionWithLoading)
    yield takeEvery('DELETE_RENTAL_RATE', makeActionWithLoading)
    yield takeEvery('FETCH_RENTAL_PAYMENTS', makeActionWithLoading)
    yield takeEvery('FETCH_RENTAL_PAYMENT', makeActionWithLoading)
    yield takeEvery('REFUND_RENTAL_PAYMENT', makeActionWithLoading)
    yield takeEvery('FETCH_RENTAL_PAYMENT_STATISTICS', makeActionWithLoading)
    yield takeEvery('EXPORT_CLIENT_USERS_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_ALL_CLIENT_USERS_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_RENTALS_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_ALL_RENTALS_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_MAINTENANCE_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_ALL_MAINTENANCE_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_BICYCLES_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_ALL_BICYCLES_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_LOCATIONS_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_ADMIN_USERS_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_BO_USERS_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_MAINTENANCE_PLANS_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_LABEL_RENTALS_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_LABEL_MAINTENANCE_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_LABEL_BICYCLES_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_LABEL_CLIENT_USERS_TO_CSV', makeActionWithLoading)
    yield takeEvery('EXPORT_RENTAL_PAYMENTS_TO_CSV', makeActionWithLoading)
}
