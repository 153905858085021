import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import {useTranslation} from "react-i18next";

const GeneralBicycleRentalsFilters = (props) => {
  const {t} = useTranslation();
  const nameRef = React.createRef();

  return (
      <>
        <BsPanelHeaderFilterControl>
          <TextField label={t('rentals.customer_name_filter')}
                     ref={nameRef}
                     onChange={props.onHandleChange.bind(this, "name")}
                     value={props.fields["name"]}
                     className="form-control"/>
        </BsPanelHeaderFilterControl>
      </>
  )
}

export default GeneralBicycleRentalsFilters;
