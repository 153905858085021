const PAYMENT_PROVIDER_FORM = {
  fields: [
    {
      name: 'paymentProvider',
      label: 'rental_config.payment_provider_field',
      type: 'info',
      translate: true,
      getValue: (data) => {
        return data && data.paymentProviderApiKey ? 'rental_rates.connected' : 'general.na';
      },
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'collectTax',
      label: 'rental_config.collect_tax_field',
      type: 'info',
      translate: false,
      getValue: (data) => {
        return data && data.collectTax ? (data.tax + '%') : '0%';
      },
      showFn: (data) => {
        return data && data.collectTax
      },
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'payment_provider_delimiter',
      type: 'delimiter',
      styles: {
        maxWidth: '500px'
      },
    }
  ]
}

export default PAYMENT_PROVIDER_FORM;
