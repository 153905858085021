import React from "react";
import {connect} from "react-redux";
import SubscriptionSettingsAddPaymentSource from "./SubscriptionSettingsAddPaymentSource";
import SubscriptionSettingsPaymentSource from "./SubscriptionSettingsPaymentSource";
import SubscriptionSettingsBankAccount from "./SubscriptionSettingsBankAccount";
import {
  fetchBankAccount,
  fetchSystemPaymentSource,
  removeSystemPaymentSource,
  setDefaultBankAccount,
  removeBankAccount,
  setDefaultSystemPaymentSource,
  updateBankAccount
} from "core/actions/system-payment-source-actions";
import ConfirmationModel from "components/model/ConfirmationModel";

class SubscriptionSettingsPaymentSources extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmationWindow: {
        open: false,
        message: '',
        event: () => {
        }
      }
    }
    props.fetchSystemPaymentSource(props.systemId);
    props.fetchBankAccount(props.systemId);
  }

  setDefault(source) {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'subscription_config.set_default_payment_source_confirmation_message',
        event: this.props.setDefaultSystemPaymentSource,
        sourceId: source.id
      }
    });
  }

  onRemove(source) {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'subscription_config.remove_payment_source_confirmation_message',
        event: this.props.removeSystemPaymentSource,
        sourceId: source.id
      }
    });
  }

  setDefaultBankAccount() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'subscription_config.set_default_payment_source_confirmation_message',
        event: this.props.setDefaultBankAccount,
      }
    });
  }

  onAddBinkAccount() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'subscription_config.remove_payment_source_confirmation_message',
        event: this.props.createBankAccount
      }
    });
  }

  closeConfirmationWindow() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: false,
        message: '',
        event: () => {
        }
      }
    });
  }

  onSubmitConfirmationWindow() {
    this.state.confirmationWindow.event(this.props.systemId, this.state.confirmationWindow.sourceId);
    this.closeConfirmationWindow();
  }

  onUpdateBankAccount() {
    this.props.createBankAccount(this.props.systemId);
  }

  onRemoveBankAccount() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'subscription_config.remove_bank_account_confirmation_message',
        event: this.props.removeBankAccount
      }
    });
  }

  render() {
    const addSource = this.props.paymentSources.length < 5
      ? <SubscriptionSettingsAddPaymentSource systemId={this.props.systemId}
                                              bankAccount={this.props.bankAccount}
                                              addBankAccount={this.onAddBinkAccount.bind(this)}
                                              history={this.props.history}/> : null;

    return <div className="payment-source-table">
      <ConfirmationModel
        open={this.state.confirmationWindow.open}
        message={this.state.confirmationWindow.message}
        handleClose={this.closeConfirmationWindow.bind(this)}
        handleApply={this.onSubmitConfirmationWindow.bind(this)}
      />

      {(this.props.paymentSources && this.props.paymentSources.length > 0 ? this.props.paymentSources : []).map((row, index) => {
        return <SubscriptionSettingsPaymentSource key={index}
                                                  setDefault={this.setDefault.bind(this)}
                                                  onRemove={this.onRemove.bind(this)}
                                                  source={row}/>;
      })}
      {this.props.bankAccount ? <SubscriptionSettingsBankAccount onUpdate={this.onUpdateBankAccount.bind(this)}
                                                                 setDefault={this.setDefaultBankAccount.bind(this)}
                                                                 onRemove={this.onRemoveBankAccount.bind(this)}
                                                                 bankAccount={this.props.bankAccount}/> : undefined}
      {addSource}
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    paymentSources: state.systemPaymentSource.paymentSources,
    bankAccount: state.systemPaymentSource.bankAccount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemPaymentSource: (systemId) => {
      dispatch(fetchSystemPaymentSource(systemId));
    },
    fetchBankAccount: (systemId) => {
      dispatch(fetchBankAccount(systemId));
    },
    setDefaultSystemPaymentSource: (systemId, sourceId) => {
      dispatch(setDefaultSystemPaymentSource(systemId, sourceId));
    },
    removeSystemPaymentSource: (systemId, sourceId) => {
      dispatch(removeSystemPaymentSource(systemId, sourceId));
    },
    createBankAccount: (systemId) => {
      dispatch(updateBankAccount(systemId));
    },
    setDefaultBankAccount: (systemId) => {
      dispatch(setDefaultBankAccount(systemId));
    },
    removeBankAccount: (systemId) => {
      dispatch(removeBankAccount(systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSettingsPaymentSources);
