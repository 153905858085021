import React from "react";
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import PagePermission from "components/permission/PagePermission";
import BsForm from "components/form/BsForm";
import {joinSystem} from "core/actions/client-auth-actions";

class JoinSystem extends React.Component {

  constructor(props) {
    super(props);
    this.props.joinSystem(this.props.match.params.token);
  }

  render() {
    if (this.props.error) {
      return (
          <BsFormPanel>
            <PagePermission anonymous={true}/>
            <BsForm
                config={{
                  fields: [
                    {
                      name: 'system',
                      type: 'notes',
                      label: 'join_system.error_message',
                      styles: {
                        textAlign: 'center'
                      }
                    }
                  ]
                }}
                disabled={true}/>
          </BsFormPanel>
      );
    } else if (this.props.client) {
      const system = this.props.client.system
      return (
          <BsFormPanel>
            <PagePermission anonymous={true}/>
            <BsForm
                config={{
                  fields: [
                    {
                      name: 'title',
                      type: 'notes',
                      label: 'join_system.success_message_title',
                      styles: {
                        textAlign: 'center',
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        marginBottom: '2rem'
                      }
                    },
                    {
                      name: 'line1',
                      type: 'notes',
                      label: 'join_system.success_message_line1'
                    },
                    {
                      name: 'line2',
                      type: 'notes',
                      label: 'join_system.success_message_line2',
                      styles: {
                        marginTop: '1rem'
                      }
                    }
                  ]
                }}
                disabled={true}/>
          </BsFormPanel>
      );
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.clientAuth.joinedClient,
    error: state.clientAuth.joiningError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    joinSystem: (token) => {
      dispatch(joinSystem(token));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinSystem);
