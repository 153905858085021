import React from 'react'
import Button from '@material-ui/core/Button'
import Permitted from "components/permission/Permitted";
import {ADMIN} from 'core/utils/permissions'
import {useTranslation} from "react-i18next";

const UserInfoActions = (props) => {
  const {t} = useTranslation();

  const handleDisable = () => {
    props.onHandleDisable();
  }

  const handleEnable = () => {
    props.onHandleEnable();
  }

  const handleDecline = () => {
    props.onHandleDecline();
  }

  const handleResend = () => {
    props.onHandleResend();
  }

  let action;
  if (props.user && props.user.status === 'ACTIVE') {
    action = <Button onClick={handleDisable}
                     variant="outlined">{t('user_info.ban_action')}</Button>
  } else if (props.user && props.user.status === 'BANNED') {
    action = <Button onClick={handleEnable}
                     variant="contained"
                     color="primary">{t('user_info.activate_action')}</Button>
  } else if (props.user && props.user.status === 'PENDING') {
    action = <div className="user-info__actions">
      <Button className="user-info__actions--enable"
              variant="contained"
              color="primary"
              onClick={() => handleEnable()}>
        {t('user_info.accept_action')}
      </Button>
      <Button className="user-info__actions--disable"
              variant="outlined"
              onClick={() => handleDisable()}>
        {t('user_info.decline_action')}
      </Button>
    </div>
  } else if (props.user && props.user.status === 'PENDING_EMAIL') {
    action = <div className="user-info__actions">
      <Button className="user-info__actions--enable"
              variant="contained"
              color="primary"
              onClick={() => handleResend()}>
        {t('user_info.resend_activation_email_action')}
      </Button>
      <Button className="user-info__actions--enable"
              variant="outlined"
              onClick={() => handleEnable()}>
        {t('user_info.accept_action')}
      </Button>
      <Button className="user-info__actions--disable"
              variant="outlined"
              onClick={() => handleDisable()}>
        {t('user_info.decline_action')}
      </Button>
    </div>
  } else if (props.user && props.user.status === 'CLOSED') {
    action = <div className="user-info__actions">
      <Button className="user-info__actions--enable"
              variant="contained"
              color="primary"
              onClick={() => handleEnable()}>
        {t('user_info.accept_action')}
      </Button>
    </div>
  }

  return (
      <Permitted for={ADMIN}>
        {action}
      </Permitted>
  )
}

export default UserInfoActions
