import React from 'react'
import {useTranslation} from "react-i18next";
import Link from "@material-ui/core/Link";
import DownloadIcon from "assets/img/download.svg";

const SubscriptionSettingsInvoice = (props) => {
  const {t} = useTranslation();

  return (
      <div className="invoice">
        <div className="invoice__status">
          <span className={"invoice__status--" + props.invoice.status.toLowerCase()}>
          {t('subscription_payment_status.' + props.invoice.status)}
          </span>
        </div>

        <div className="invoice__id">
          {t('subscription_config.invoice_id')} {props.invoice.invoiceNumber} / {props.invoice.billingPeriodFrom} - {props.invoice.billingPeriodTo}
        </div>

        <div className="invoice__doc">
          <Link href={props.invoice.invoice.original}
                target="_blank"
                style={{cursor: 'pointer'}}>
            <DownloadIcon className="invoice__doc__link-icon"/> {t('subscription_config.download_invoice')}
          </Link>
        </div>
      </div>
  )
}

export default SubscriptionSettingsInvoice
