import React from 'react';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const SignInMenuItem = (props) => {
    const {t} = useTranslation();

    return (
        <Button onClick={() => props.history.push('/sign-in')}
                color="primary"
                className="header__link">
            {t('menu.sign_in')}
        </Button>
    )
}

SignInMenuItem.propTypes = {
    history: PropTypes.object
};

export default SignInMenuItem;