import Moment from 'moment';

const ADD_MAINTENANCE_PLAN_FORM = {
    title: {
        text: 'add_maintenance_plan.title'
    },
    submit: {
        label: 'add_maintenance_plan.submit_btn'
    },
    fields: [
        {
            name: 'plan',
            label: 'add_maintenance_plan.plan_field',
            type: 'select',
            items: [
                {translate: true, value: 9, label: 'maintenance_plan_type.plan_9'},
                {translate: true, value: 12, label: 'maintenance_plan_type.plan_12'},
                {translate: true, value: 18, label: 'maintenance_plan_type.plan_18'},
                {translate: true, value: 24, label: 'maintenance_plan_type.plan_24'},
                {translate: true, value: 36, label: 'maintenance_plan_type.plan_36'},
                {translate: true, value: 48, label: 'maintenance_plan_type.plan_48'},
            ],
            validators: ['requiredPlan'],
            errors: ['errors.required_plan'],
            defaultValue: ''
        },
        {
            name: 'runTime',
            label: 'add_maintenance_plan.run_time_field',
            type: 'date',
            validators: [],
            errors: [],
            defaultValue: Moment(new Date()).format('yyyy MM DD')
        },
        {
            name: 'service',
            label: 'add_maintenance_plan.service_field',
            type: 'select',
            items: [],
            validators: ['requiredService'],
            errors: ['errors.required_service'],
            defaultValue: ''
        },
        {
            name: 'mechanic',
            label: 'add_maintenance_plan.mechanic_field',
            type: 'select',
            items: [],
            validators: ['requiredMechanic'],
            errors: ['errors.required_mechanic'],
            defaultValue: ''
        }
    ]
}

export default ADD_MAINTENANCE_PLAN_FORM;
