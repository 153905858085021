export const GENERAL_BICYCLE_RENTALS_TABLE = {
  columns: [
    {
      label: 'rentals.system_header',
      field: 'system.name',
      getValue: (raw) => {
        return raw.system.name
      },
      ordering: true
    },
    {
      label: 'rentals.full_name_header',
      field: 'client.firstName,client.lastName',
      getValue: (raw) => {
        return raw.client.firstName + ' ' + raw.client.lastName
      },
      ordering: true
    },
    {
      label: 'rentals.start_location_header',
      field: 'startLocation.label',
      ordering: true,
      getValue(raw) {
        return raw.startLocation.label;
      }
    },
    {
      label: 'rentals.end_location_header',
      field: 'endLocation.label',
      ordering: true,
      getValue(raw) {
        return raw.endLocation ? raw.endLocation.label : '-';
      }
    },
    {
      label: 'rentals.start_time_header',
      field: 'startTime',
      getValue: (raw) => {
        return raw.startTime ? raw.startTime : '-'
      },
      ordering: true
    },
    {
      label: 'rentals.end_time_header',
      field: 'endTime',
      getValue: (raw) => {
        return raw.endTime ? raw.endTime : '-'
      },
      ordering: true
    },
    {
      label: 'rentals.status_header',
      field: 'status',
      translate: true,
      getValue: (raw) => {
        return 'rental_status.' + raw.status
      },
      ordering: true
    }
  ]
}
