import React from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import BsForm from "components/form/BsForm";

import 'components/model/confirmation-model.scss';

const ChangeMaintenanceExternalVisitModel = (props) => {
  const {t} = useTranslation();
  let fields = {
    ids: props.ids
  };

  const onSubmit = () => {
    props.handleSubmit(fields.ids.replace(/\s/g,'').split(","))
  }

  const handleChange = (newData) => {
    fields = newData;
  }

  const config = {
    submit: {
      label: 'maintenance_plan_info.save_btn'
    },
    fields: [
      {
        name: 'ids',
        label: 'maintenance_plan_info.external_ids_field',
        type: 'text',
        validators: ['required'],
        errors: ['errors.required_external_ids'],
        defaultValue: ''
      }
    ]
  }

  return (
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model-window"
          open={props.open}
          onClose={props.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
        <Fade in={props.open}>
          <div className="model-container">
            <div className="model-body">
              <h4>{t('maintenance_plan_info.change_external_isd_model_title')}</h4>

              <BsForm
                  config={config}
                  formData={fields}
                  onHandleSubmit={onSubmit}
                  onHandleChange={handleChange}
                  cancelAction={<Button variant="outlined" onClick={props.handleClose}>
                    {t('general.cancel')}
                  </Button>}
              />
            </div>
          </div>
        </Fade>
      </Modal>
  )
}

ChangeMaintenanceExternalVisitModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.any.isRequired,
  handleSubmit: PropTypes.any.isRequired,
  ids: PropTypes.string
};

export default ChangeMaintenanceExternalVisitModel;
