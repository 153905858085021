import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import {useTranslation} from "react-i18next";
import Row from "components/layout/Row";
import Col from "components/layout/Col";

const LabelAdminsFilters = (props) => {
  const {t} = useTranslation();
  const nameRef = React.createRef();
  const emailRef = React.createRef();

  return (
      <Row>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <TextField label={t('label_admin_info.name_filter')}
                       ref={nameRef}
                       onChange={props.onHandleChange.bind(this, "name")}
                       value={props.fields["name"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <TextField label={t('label_admin_info.email_filter')}
                       ref={emailRef}
                       onChange={props.onHandleChange.bind(this, "email")}
                       value={props.fields["email"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
      </Row>
  )
}

export default LabelAdminsFilters;
