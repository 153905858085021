import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import GoogleMap from "components/map/GoogleMap";
import {ADMIN} from "core/utils/permissions";
import {getAddressDetails} from "core/actions/geocode-actions";
import {createLocation} from "core/actions/location-actions";
import {showNotification} from "core/actions/app-actions";
import ADD_LOCATION_FORM from "./add-location-form";

class AddLocation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.backUrl = '/system/' + this.props.match.params.systemId + '/locations';
    }

    componentDidMount() {
        BsForm.addValidationRule('requiredAddress', (value) => {
            return !!this.props.decodedAddress;
        });
        BsForm.addValidationRule('macAddress', (value) => {
            return !value || value.replaceAll('_', "").length === 17;
        });
    }

    componentWillUnmount() {
        BsForm.removeValidationRule('requiredAddress');
        BsForm.removeValidationRule('macAddress');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.decodedAddress && this.props.decodedAddress.placeId && (!this.state.decodedAddress
            || this.props.decodedAddress.placeId !== this.state.decodedAddress.placeId)) {
            const latLng = {
                lat: this.props.decodedAddress.latitude,
                lng: this.props.decodedAddress.longitude
            }
            this.setState({
                ...this.state,
                decodedAddress: this.props.decodedAddress,
                marker: latLng,
                center: latLng
            })
        }
    }

    handleSubmit(fields, e) {
        if (!this.props.decodedAddress.country) {
            this.props.showNotification('error', 'errors.recognize_location_error');
            return;
        }

        this.props.createLocation(this.props.match.params.systemId, {
            label: fields.label,
            address: {
                ...this.props.decodedAddress,
                building: this.props.decodedAddress.building ? this.props.decodedAddress.building : 1,
                latitude: this.state.marker ? this.state.marker.lat : this.props.decodedAddress.latitude,
                longitude: this.state.marker ? this.state.marker.lng : this.props.decodedAddress.longitude
            },
            beaconMacAddress: fields.beaconMacAddress ? fields.beaconMacAddress : null,
            capacity: fields.capacity
        });
    }

    handleChange(fields) {
        if (fields.address.placeId && fields.address.placeId !== this.props.decodedAddress.placeId) {
            this.props.getAddressDetails(fields.address.placeId);
        }
    }

    handleChangeMarker(e) {
        this.setState({
            ...this.state,
            marker: e,
            center: e
        });
    }

    render() {
        return (
            <BsFormPanel size="l">
                <PagePermission anonymous={false} permission={ADMIN}/>
                <BsForm
                    config={ADD_LOCATION_FORM}
                    onHandleSubmit={this.handleSubmit.bind(this)}
                    onHandleChange={this.handleChange.bind(this)}
                    cancelAction={
                        <BsFormCancelAction history={this.props.history}
                                            location={this.props.location}
                                            url={this.backUrl}/>
                    }
                    additionalContent={
                        <GoogleMap
                            onChangeMarker={this.handleChangeMarker.bind(this)}
                            marker={this.state.marker}
                            center={this.state.center}/>
                    }
                />
            </BsFormPanel>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        decodedAddress: state.geocode.decodedAddress,
        decodingAddress: state.geocode.decodingAddress
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAddressDetails: (placeId) => {
            dispatch(getAddressDetails(placeId));
        },
        showNotification: (variant, message) => {
            dispatch(showNotification(variant, message));
        },
        createLocation: (systemId, data) => {
            dispatch(createLocation(systemId, data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);
