import React from "react";
import {connect} from "react-redux";
import {ADMIN} from "core/utils/permissions";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {
  changeClientUserStatus,
  changeClientUserEmail,
  resendActivationEmail,
  fetchClientUser
} from "core/actions/client-user-actions";
import buildUserInfoForm from "./user-info-form";
import UserInfoActions from "./UserInfoActions";
import ConfirmationModel from "components/model/ConfirmationModel";
import ChangeUserEmailModel from "./ChangeUserEmailModel";

import "./UserInfoTab.scss";

class UserInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      backUrl: '/system/' + this.props.match.params.systemId + '/users',
      confirmationWindow: {
        open: false,
        message: '',
        event: () => {
        }
      },
      changeUserEmailWindow: {
        open: false,
        email: '',
        event: () => {
        }
      }
    };
    this.props.fetchClientUser(this.props.match.params.systemId, this.props.match.params.userId);
  }

  onHandleActive() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'user_info.activate_user_confirmation_message',
        event: this.activeUser
      }
    })
  }

  activeUser() {
    this.closeConfirmationModel();
    this.props.changeClientUserStatus(this.props.match.params.systemId, this.props.match.params.userId, 'ACTIVE')
  }

  onHandleDecline() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'user_info.decline_user_confirmation_message',
        event: this.declineUser
      }
    })
  }

  declineUser() {
    this.closeConfirmationModel();
    this.props.changeClientUserStatus(this.props.match.params.systemId, this.props.match.params.userId, 'CLOSED')
  }

  onHandleBan() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'user_info.ban_user_confirmation_message',
        event: this.banUser
      }
    })
  }

  banUser() {
    this.closeConfirmationModel();
    this.props.changeClientUserStatus(this.props.match.params.systemId, this.props.match.params.userId, 'BANNED')
  }

  onHandleResend() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'user_info.resend_activation_email_confirmation_message',
        event: this.resendActivationEmail
      }
    })
  }

  resendActivationEmail() {
    this.closeConfirmationModel();
    this.props.resendActivationEmail(this.props.match.params.systemId, this.props.match.params.userId)
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  onHandleChangeEmail() {
    this.setState({
      ...this.state,
      changeUserEmailWindow: {
        open: true,
        email: this.props.currentUser.email,
        event: this.activeLocation
      }
    })
  }

  closeChangingUserEmailModel() {
    this.setState({
      ...this.state,
      changeUserEmailWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  changeUserEmail(email) {
    this.closeChangingUserEmailModel()
    this.props.changeClientUserEmail(this.props.match.params.systemId, this.props.match.params.userId, email)
  }

  render() {
    if (this.props.currentUser) {
      const actions = {
        changeEmail: this.onHandleChangeEmail.bind(this)
      };

      const formConfig = buildUserInfoForm(actions, this.props.permissions);
      formConfig.title = {
        actions: <UserInfoActions
            user={this.props.currentUser}
            onHandleEnable={this.onHandleActive.bind(this)}
            onHandleDecline={this.onHandleDecline.bind(this)}
            onHandleDisable={this.onHandleBan.bind(this)}
            onHandleResend={this.onHandleResend.bind(this)}/>,
        back: {
          url: this.state.backUrl,
          history: this.props.history,
          location: this.props.location
        }
      }

      return (
          <div className="bs-form-container user-info">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <ConfirmationModel
                open={this.state.confirmationWindow.open}
                message={this.state.confirmationWindow.message}
                handleClose={this.closeConfirmationModel.bind(this)}
                handleApply={this.state.confirmationWindow.event.bind(this)}
            />
            <ChangeUserEmailModel
                open={this.state.changeUserEmailWindow.open}
                email={this.state.changeUserEmailWindow.email}
                handleClose={this.closeChangingUserEmailModel.bind(this)}
                handleSubmit={this.changeUserEmail.bind(this)}
            />

            <BsForm
                config={formConfig}
                formData={this.props.currentUser}
                disabled={true}/>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.clientUser.currentUser,
    permissions: state.system.currentSystem ? state.system.currentSystem.permissions : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeClientUserStatus: (systemId, userId, status) => {
      dispatch(changeClientUserStatus(systemId, userId, status));
    },
    resendActivationEmail: (systemId, userId) => {
      dispatch(resendActivationEmail(systemId, userId));
    },
    changeClientUserEmail: (systemId, userId, email) => {
      dispatch(changeClientUserEmail(systemId, userId, email));
    },
    fetchClientUser: (systemId, userId) => {
      dispatch(fetchClientUser(systemId, userId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoTab);
