import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Permitted from "components/permission/Permitted";
import {MANAGER} from 'core/utils/permissions'
import SelectIcon from 'assets/img/select-icon.svg'
import {useTranslation} from "react-i18next";

const MaintenanceBicycleReportActions = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {t} = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onHandleReportProblem = () => {
        handleClose();
        props.onHandleReportProblem();
    }

    const handleChangeStatus = (status) => {
        handleClose();
        props.onHandleChangeStatus(status);
    }

    let changeStatusMenuItem = null;
    if (props.report && props.report.status === 'OPEN') {
        changeStatusMenuItem = <MenuItem key="complete" onClick={() => handleChangeStatus('COMPLETED')}>
            {t('general.complete')}</MenuItem>
    }

    return (
        <Permitted for={MANAGER}>
            <Button aria-controls="admin-info-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                    style={{
                        marginBottom: '1em'
                    }}>
                {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
            </Button>
            <Menu
                id="admin-info-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}>
                <MenuItem onClick={onHandleReportProblem}>{t('bicycle_report_info.report_problem')}</MenuItem>
                {changeStatusMenuItem}
            </Menu>
        </Permitted>
    )
}

export default MaintenanceBicycleReportActions
