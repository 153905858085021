const queryString = require('query-string');

export function loadBikeReports(systemId, query) {
  return {
    type: "FETCH_BICYCLE_REPORTS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/bike-reports?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function fetchBikeReport(systemId, reportId) {
  return {
    type: "FETCH_BICYCLE_REPORT",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/bike-reports/${reportId}`
      },
      globalLoading: true
    }
  };
}

export function changeBicycleReportStatus(systemId, reportId, data) {
  return {
    type: "UPDATE_BICYCLE_REPORT",
    payload: {
      request: {
        method: 'PUT',
        url: `/api/v1/bo/${systemId}/bike-reports/${reportId}/status`,
        data: data
      },
      globalLoading: true
    }
  };
}

export function createIssueByReport(systemId, reportId) {
  return {
    type: "CREATE_ISSUE_BY_REPORT",
    payload: {
      request: {
        method: 'PUT',
        url: `/api/v1/bo/${systemId}/bike-reports/${reportId}/issue`
      },
      globalLoading: true,
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: `/system/${systemId}/support/issue/${response.data.id}`
        }
      }
    }
  };
}

export function loadAllBikeReports(query) {
  return {
    type: "FETCH_ALL_BICYCLE_REPORTS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/bike-reports?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function fetchBikeReportInfo(reportId) {
  return {
    type: "FETCH_BICYCLE_REPORT_INFO",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/bike-reports/${reportId}`
      },
      globalLoading: true
    }
  };
}
