import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import CHANGE_PROFILE_FORM from "./change-profile-form";
import {changeProfile} from "core/actions/profile-actions";

class ChangeProfile extends React.Component {

    constructor(props) {
        super(props);
        this.backUrl = '/systems';
    }

    handleSubmit(fields, e) {
        this.props.changeProfile({
            firstName: fields.firstName,
            lastName: fields.lastName
        });
    }

    render() {
        if (this.props.profile) {
            return (
                <BsFormPanel>
                    <PagePermission anonymous={false}/>
                    <BsForm
                        config={CHANGE_PROFILE_FORM}
                        formData={this.props.profile}
                        onHandleSubmit={this.handleSubmit.bind(this)}
                        cancelAction={
                            <BsFormCancelAction history={this.props.history}
                                                location={this.props.location}
                                                url={this.backUrl}/>
                        }/>
                </BsFormPanel>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile.profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeProfile: (data) => {
            dispatch(changeProfile(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeProfile);
