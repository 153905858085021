import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Permitted from "components/permission/Permitted";
import {ADMIN} from 'core/utils/permissions'
import SelectIcon from 'assets/img/select-icon.svg'
import {useTranslation} from "react-i18next";

const RentalRateInfoActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleUpdate = () => {
    handleClose();
    props.onHandleUpdate();
  }

  const onHandleRemove = () => {
    handleClose();
    props.onHandleRemove();
  }

  return (
      <Permitted for={ADMIN}>
        <Button aria-controls="admin-info-menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                color="primary"
                style={{
                  marginBottom: '1em'
                }}>
          {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
        </Button>
        <Menu
            id="admin-info-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}>
          <MenuItem key="update" onClick={() => onHandleUpdate()}>{t('general.edit')}</MenuItem>
          <MenuItem key="remove" onClick={() => onHandleRemove()}>{t('general.delete')}</MenuItem>
        </Menu>
      </Permitted>
  )
}

export default RentalRateInfoActions
