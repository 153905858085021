import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const SubscriptionPlanInfoActions = (props) => {
  const {t} = useTranslation();

  const handleEdit = (event) => {
    props.onHandleEdit();
  };

  const handleRemove = (event) => {
    props.onHandleRemove();
  };

  return (
      <div className="subscription-plan-info__actions">
        <Button className="subscription-plan-info__actions--left"
                variant="outlined"
                onClick={() => handleRemove()}>
          {t('subscription_plan_info.remove_btn')}
        </Button>
        <Button className="subscription-plan-info__actions--right"
                variant="contained"
                color="primary"
                onClick={() => handleEdit()}>
          {t('subscription_plan_info.edit_btn')}
        </Button>
      </div>
  )
}

export default SubscriptionPlanInfoActions
