import React from "react";
import Col from "components/layout/Col";
import Row from "components/layout/Row";
import {useTranslation} from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import BsValidatorForm from "components/form/BsValidatorForm";
import BsFormTitle from "components/form/title/BsFormTitle";
import BsFormActions from "components/form/actions/BsFormActions";
import BsFormSubmitAction from "components/form/actions/BsFormSubmitAction";
import BsFormPanel from "components/form/BsFormPanel";
import {SelectValidator} from "react-material-ui-form-validator";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import Fab from "@material-ui/core/Fab";
import Close from "assets/img/close.svg";
import Button from "@material-ui/core/Button";

const RenewMaintenancePlanScheduleService = (props) => {
  const {t} = useTranslation();

  return (
      <BsFormPanel size="l">
        <BsValidatorForm onHandleSubmit={props.onHandleCreatePlan.bind(this)}>
          <BsFormTitle title={{text: "add_maintenance_plan.schedule_title"}}/>

          {props.schedule.map((row, index) =>
              <Row key={'schedule-item-' + index} spacing={true}>
                <Col size="4">
                  <Row spacing={true}>
                    <Col size="1" className="vertical-center">
                      #{row.number}
                    </Col>

                    <Col size="11">
                      <SelectValidator
                          className="form-control"
                          label={t('add_maintenance_plan.service_label')}
                          name={'service-' + index}
                          value={row.service}
                          onChange={(value) => props.onChangeSchedule(index, 'service', value)}>
                        {props.services.map((item, serviceIndex) => {
                          return <MenuItem key={item.name + '-' + serviceIndex} value={item.taskStepId}>
                            {item.name}
                          </MenuItem>
                        })}
                      </SelectValidator>
                    </Col>
                  </Row>
                </Col>

                <Col size="7">
                  <Row spacing={true}>
                    <Col size="3">
                      <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <DatePicker
                            label={t('add_maintenance_plan.start_date_label')}
                            name={'start-date-' + index}
                            onChange={(value) => props.onChangeSchedule(index, 'startDate', value)}
                            value={row.startDate}
                            format="yyyy/MM/DD"
                            className="form-control"/>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col size="3">
                      <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <DatePicker
                            label={t('add_maintenance_plan.end_date_label')}
                            name={'end-date-' + index}
                            onChange={(value) => props.onChangeSchedule(index, 'endDate', value)}
                            value={row.endDate}
                            format="yyyy/MM/DD"
                            className="form-control"/>
                      </MuiPickersUtilsProvider>
                    </Col>

                    <Col size="3">
                      <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <TimePicker
                            showTodayButton
                            todayLabel={t('add_maintenance_plan.today_label')}
                            label={t('add_maintenance_plan.start_time_label')}
                            value={row.startTime}
                            minutesStep={15}
                            ampm={false}
                            format="HH:mm"
                            onChange={(value) => props.onChangeSchedule(index, 'startTime', value)}
                        />
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col size="3">
                      <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <TimePicker
                            showTodayButton
                            todayLabel={t('add_maintenance_plan.today_label')}
                            label={t('add_maintenance_plan.end_time_label')}
                            value={row.endTime}
                            minutesStep={15}
                            ampm={false}
                            format="HH:mm"
                            onChange={(value) => props.onChangeSchedule(index, 'endTime', value)}
                        />
                      </MuiPickersUtilsProvider>
                    </Col>
                  </Row>
                </Col>

                <Col size="1" className="vertical-center">
                  <Fab size="small"
                       aria-label={t('add_maintenance_plan.remove_btn')}
                       onClick={props.onRemoveRow.bind(this, index)}
                       className="fab__white fab--without-shadow">
                    <Close/>
                  </Fab>
                </Col>
              </Row>
          )}

          <Row>
            <Col size={12} className="align-center">
              <Button variant="outlined"
                      onClick={props.onAddRow.bind(this)}>{t('add_maintenance_plan.add_service_btn')}</Button>
            </Col>
          </Row>

          <BsFormActions>
            <Button variant="outlined"
                    onClick={props.goBack.bind(this)}>{t('add_maintenance_plan.back_btn')}</Button>
            <BsFormSubmitAction label="add_maintenance_plan.submit_schedule_btn"/>
          </BsFormActions>
        </BsValidatorForm>
      </BsFormPanel>
  )
}

export default RenewMaintenancePlanScheduleService;
