import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import {fetchBicycleInfo} from "core/actions/bicycle-actions";
import GENERAL_BICYCLE_INFO_FORM from "./general-bicycle-info-form";

class GeneralBicycleInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    if (!this.props.currentBicycle) {
      this.props.fetchBicycleInfo(this.props.match.params.bicycleId);
    }
  }

  render() {
    if (this.props.currentBicycle) {
      const formConfig = {
        ...GENERAL_BICYCLE_INFO_FORM,
        title: {
          back: {
            url: '/global/bicycles',
            history: this.props.history,
            location: this.props.location
          }
        }
      };

      return (
          <div className="bs-form-container">
            <BsForm
                config={formConfig}
                formData={this.props.currentBicycle}
                disabled={true}/>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentBicycle: state.bicycle.currentBicycle
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBicycleInfo: (bicycleId) => {
      dispatch(fetchBicycleInfo(bicycleId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralBicycleInfoTab);
