import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import {useTranslation} from "react-i18next";
import Row from "components/layout/Row";
import Col from "components/layout/Col";
import DateMomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

const RentalRatesPaymentsFilters = (props) => {
  const {t} = useTranslation();
  const nameRef = React.createRef();
  const dateFromRef = React.createRef();
  const dateToRef = React.createRef();

  return (
      <Row>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <TextField label={t('rental_rate_info.client_name_filter')}
                       ref={nameRef}
                       onChange={props.onHandleChange.bind(this, "name")}
                       value={props.fields["name"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <MuiPickersUtilsProvider utils={DateMomentUtils}>
              <DatePicker
                label={t('rental_rate_info.date_from_filter')}
                name={props.dateFrom}
                onChange={props.onHandleChange.bind(this, "dateFrom")}
                value={props.fields["dateFrom"]}
                format="DD MMM yyyy"
                minDate={"2020/01/01"}
                maxDate={new Date()}
                className="form-control" />
            </MuiPickersUtilsProvider>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <MuiPickersUtilsProvider utils={DateMomentUtils}>
              <DatePicker
                label={t('rental_rate_info.date_to_filter')}
                name={props.dateTo}
                onChange={props.onHandleChange.bind(this, "dateTo")}
                value={props.fields["dateTo"]}
                format="DD MMM yyyy"
                minDate={"2020/01/01"}
                maxDate={new Date()}
                className="form-control" />
            </MuiPickersUtilsProvider>
          </BsPanelHeaderFilterControl>
        </Col>
      </Row>
  )
}

export default RentalRatesPaymentsFilters;
