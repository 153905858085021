import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './InfiniteLoading.scss';

/**
 * Infinite Loading
 * @type {Object}
 * @copyright https://github.com/JasonBoy/react-infinity-loading
 */
class InfiniteLoading extends Component {
    constructor(props) {
        super(props);
        const {elementScroll, scrollHeight, loading, isLoading, asLoading, scrollThreshold} = this.props;
        this.state = {
            elementScroll: elementScroll,
            scrollHeight: scrollHeight,
            loading: loading,
            isLoading: isLoading,
            asLoading: asLoading,
            scrollThreshold: scrollThreshold
        }
        this.isScrollWatching = false;
        this.onPageScroll = this.throttle(this.onPageScroll).bind(this);
        this.onResize = this.throttle(this.onResize).bind(this);
    }

    componentDidMount() {
        this.enableScrollWatch();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.loading !== state.loading
            || props.elementScroll !== state.elementScroll
            || props.scrollHeight !== state.scrollHeight) {
            const newState = {...state}

            if (props.loading !== state.loading) state.loading = props.loading;
            if (props.elementScroll !== state.elementScroll) state.elementScroll = props.elementScroll;
            if (props.scrollHeight !== state.scrollHeight) state.scrollHeight = props.scrollHeight;

            return newState;
        }

        return null;
    }

    componentWillUnmount() {
        this.bindScrollWatchEvents(false);
    }

    // bind event start
    enableScrollWatch() {
        if (this.isScrollWatching) {
            return;
        }
        this.updateTop();
        this.updateScroller();
        this.isScrollWatching = true;
        this.bindScrollWatchEvents(true);
    }

    // bind scroll event
    bindScrollWatchEvents(isBind) {
        this.enableScroll = isBind;
        const addRemove = isBind ? 'addEventListener' : 'removeEventListener';
        this.scroller[addRemove]('scroll', this.onPageScroll);
        window[addRemove]('resize', this.onResize);
    }

    // processing scroll event
    onPageScroll() {
        if (this.enableScroll) {
            const {loading} = this.props;
            const distance = this.getBottomDistance();
            if (distance <= this.state.scrollThreshold && !loading) {
                this.scrollThreshold();
            }
        }
    }

    // on resize
    onResize() {
        if (this.enableScroll) {
            this.updateTop();
        }
    }

    scrollThreshold() {
        this.setState({loading: true});
        //this.handleLoading();
        this.props.handleLoading();
    }

    // get element/window bottom distance
    getBottomDistance() {
        if (this.state.elementScroll) {
            return this.getElementBottomDistance();
        } else {
            return this.getWindowBottomDistance();
        }
    }

    getElementBottomDistance() {
        if (this.scroller) {
            const bottom = this.scroller.scrollHeight;
            const scrollY = this.scroller.scrollTop + this.scroller.clientHeight;
            return bottom - scrollY;
        } else {
            return null;
        }
    }

    getWindowBottomDistance() {
        if (this.scroller) {
            const bottom = this.scroller.scrollHeight;
            const scrollY = this.windowHeight + window.pageYOffset;
            return bottom - scrollY;
        } else {
            return null;
        }
    }

    updateTop() {
        this.windowHeight = window.innerHeight;
        const rect = this.element.getBoundingClientRect();
        this.top = rect.top + window.pageYOffset;
    }

    updateScroller() {
        // if elementScroll equal true, scroller => element, equal false scroller => window
        if (!this.state.elementScroll) {
            this.scroller = window;
        }
    }

    throttle(fn, threshold) {
        threshold = threshold || 200;
        let last, timeout;

        return function () {
            let now = +new Date();
            let args = arguments;
            let trigger = function () {
                last = now;
                fn.apply(this, args);
            }.bind(this);
            if (last && now < last + threshold) {
                // hold on to it
                clearTimeout(timeout);
                timeout = setTimeout(trigger, threshold);
            } else {
                trigger();
            }
        };
    }

    render() {
        const {children} = this.props;
        const {scrollHeight} = this.state;

        return (
            <div className="infinite" ref={(e) => this.element = this.scroller = e} style={{height: scrollHeight}}>
                {children}
            </div>
        )
    }
}

InfiniteLoading.defaultProps = {
    scrollHeight: 'auto',
    elementScroll: false,
    isLoading: true,
    scrollThreshold: 200
};
InfiniteLoading.propTypes = {
    loading: PropTypes.bool,
    isLoading: PropTypes.bool,
    asLoading: PropTypes.node,
    elementScroll: PropTypes.bool,
    scrollHeight: PropTypes.any.isRequired,
    scrollThreshold: PropTypes.number,
    handleLoading: PropTypes.func,
    children: PropTypes.node
};

export default InfiniteLoading;
