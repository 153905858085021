import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import {fetchLabelBicycleInfo} from "core/actions/bicycle-actions";
import LABEL_GENERAL_BICYCLE_INFO_FORM from "./label-general-bicycle-info-form";

class LabelGeneralBicycleInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    if (!this.props.currentBicycle) {
      this.props.fetchLabelBicycleInfo(this.props.match.params.labelId, this.props.match.params.bicycleId);
    }
  }

  render() {
    if (this.props.currentBicycle) {
      const formConfig = {
        ...LABEL_GENERAL_BICYCLE_INFO_FORM,
        title: {
          back: {
            url: '/label/' + this.props.match.params.labelId + '/bicycles',
            history: this.props.history,
            location: this.props.location
          }
        }
      };

      return (
          <div className="bs-form-container">
            <BsForm
                config={formConfig}
                formData={this.props.currentBicycle}
                disabled={true}/>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentBicycle: state.bicycle.currentBicycle
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLabelBicycleInfo: (labelId, bicycleId) => {
      dispatch(fetchLabelBicycleInfo(labelId, bicycleId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelGeneralBicycleInfoTab);
