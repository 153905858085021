const ADD_LABEL_ADMIN_FORM = {
  title: {
    text: 'add_label_admin.title'
  },
  submit: {
    label: 'add_label_admin.submit_btn'
  },
  fields: [
    {
      name: 'user',
      label: 'add_label_admin.user_field',
      type: 'admins-autocomplete',
      validators: ['requiredUser'],
      errors: ['errors.required_admin'],
      defaultValue: {}
    }
  ]
}

export default ADD_LABEL_ADMIN_FORM;
