import React from 'react'
import {useTranslation} from "react-i18next";
import DeleteIcon from "assets/img/delete.svg";
import RadioButtonCheckedIcon from "assets/img/radio-button-checked.svg";
import RadioButtonUncheckedIcon from "assets/img/radio-button-unchecked.svg";
import CardIcon from "./CardIcon";

const SubscriptionSettingsPaymentSource = (props) => {
  const {t} = useTranslation();

  const setDefault = () => {
    if (!props.source.default) {
      props.setDefault(props.source);
    }
  }

  const onRemove = () => {
    props.onRemove(props.source);
  }

  const isDefault = props.source.default ? <RadioButtonCheckedIcon/> : <RadioButtonUncheckedIcon/>;

  return (
      <div className="payment-source">
        <div className="payment-source__default-status" onClick={setDefault.bind(this)}>
          {isDefault}
        </div>

        <div className="payment-source__icon">
          <CardIcon brand={props.source.brand}/>
        </div>

        <div className="payment-source__number">
          {props.source.details}
        </div>

        <div className="payment-source__remove">
          <DeleteIcon  onClick={onRemove.bind(this)} />
        </div>
      </div>
  )
}

export default SubscriptionSettingsPaymentSource
