import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import ConfirmationModel from "components/model/ConfirmationModel";
import EDIT_SUBSCRIPTION_SYSTEM_FORM from "./edit-subscription-system-form";
import {loadSubscriptionPlans} from "core/actions/subscription-plan-actions";
import {fetchSystemSubscription, updateSystemSubscription} from "core/actions/system-subscription-actions";
import Moment from "moment";

import './edit-subscription-system.scss';

class EditSubscriptionSystem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/global/system-subscription/' + this.props.match.params.systemId + '/info';
    this.fields = {};
    this.props.loadSubscriptionPlans({});
    this.props.fetchSystemSubscription(this.props.match.params.systemId);
  }

  componentDidMount() {
    BsForm.addValidationRule('requiredSubscription', (value) => {
      return !!this.fields.subscription;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('requiredSubscription');
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.setState({openConfirmationWindow: false})
    this.props.updateSystemSubscription(this.props.match.params.systemId, {
      subscriptionId: this.fields.subscription,
      billingPeriod: this.fields.billingPeriod,
      pricePerBike: this.fields.pricePerBike,
      currency: this.fields.currency,
      autoCancellation: this.fields.autoCancellation,
      nextBillingPeriod: Moment(this.fields.nextBillingPeriod).format('YYYY-MM-DD')
    }, this.backUrl);
  }

  handleChange(fields) {
    const plan = this.props.subscriptionPlans.content.find(plan => plan.id === fields.subscription);
    if (plan && plan.id !== this.fields.subscription) {
      fields.billingPeriod = plan.billingPeriod;
      fields.pricePerBike = plan.pricePerBike;
      fields.currency = plan.currency;
      fields.autoCancellation = plan.autoCancellation;
    }

    this.fields = {...fields};
  }

  render() {
    if (this.props.subscriptionPlans && this.props.subscriptionPlans.content && this.props.currentSubscription) {
      const config = {
        ...EDIT_SUBSCRIPTION_SYSTEM_FORM,
        fields: [],
        title: {
          text: this.props.currentSubscription.system.name
        }
      }
      EDIT_SUBSCRIPTION_SYSTEM_FORM.fields.forEach(field => {
        if (field.name === 'subscription') {
          config.fields.push({
            ...field,
            items: this.props.subscriptionPlans.content.map(plan => {
              return {
                ...plan,
                value: plan.id,
                label: plan.name
              }
            })
          })
        } else {
          config.fields.push(field)
        }
      })

      const data = {
        ...this.props.currentSubscription,
        subscription: this.props.currentSubscription.subscription.id,
        nextBillingPeriod: this.props.currentSubscription.currentBillingPeriod
          ? Moment(this.props.currentSubscription.currentBillingPeriod).format('YYYY-MM-DD') : new Date()
      }
      if (!this.fields.billingPeriod) {
        this.fields = data;
      }

      return (
        <div className="edit-subscription-system">
          <BsFormPanel size="s">
            <ConfirmationModel
              open={this.state.openConfirmationWindow}
              message="edit_system_subscription.confirmation_message"
              handleClose={() => this.setState({openConfirmationWindow: false})}
              handleApply={this.handleUpdate.bind(this)}
            />

            <BsForm
              config={config}
              formData={data}
              onHandleSubmit={this.handleSubmit.bind(this)}
              onHandleChange={this.handleChange.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }
            />
          </BsFormPanel>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptionPlans: state.subscriptionPlan.subscriptionPlans,
    currentSubscription: state.systemSubscription.currentSubscription
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSubscriptionPlans: (query) => {
      dispatch(loadSubscriptionPlans(query));
    },
    fetchSystemSubscription: (systemId) => {
      dispatch(fetchSystemSubscription(systemId));
    },
    updateSystemSubscription: (systemId, data, redirectPath) => {
      dispatch(updateSystemSubscription(systemId, data, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSubscriptionSystem);
