export const systemReducer = (state = {
    loadingSystems: false,
    systems: {
        hasNext: true,
        current: 0,
        content: []
    },
    userSystems: {},
    currentSystem: null,
    systemId: null,
    systemIsLoading: false,
    needToLoadDefaultSystem: false,
    statistics: null,
    autocomplete: [],
    loadingAutocomplete: false
}, action) => {
    switch (action.type) {
        case 'SIGN_IN_SUCCESS':
            return {
                ...state,
                needToLoadDefaultSystem: true
            }
        case 'SYSTEMS_AUTOCOMPLETE':
            return {
                ...state,
                loadingAutocomplete: true
            }
        case 'SYSTEMS_AUTOCOMPLETE_SUCCESS':
            return {
                ...state,
                loadingAutocomplete: false,
                autocomplete: action.payload.data
            }
        case 'SYSTEMS_AUTOCOMPLETE_FAIL':
            return {
                ...state,
                autocomplete: [],
                loadingAutocomplete: false
            }
        case 'FETCH_SYSTEMS':
        case 'FETCH_USER_SYSTEMS':
        case 'FETCH_CLIENT_SYSTEMS':
            return {
                ...state,
                loadingSystems: true
            }
        case 'FETCH_SYSTEMS_SUCCESS':
            return {
                ...state,
                systems: {
                    hasNext: !action.payload.data.last,
                    current: action.payload.data.number,
                    content: [
                        ...state.systems.content,
                        ...(action.payload.data.content ? action.payload.data.content : [])
                    ]
                },
                loadingSystems: false
            }
        case 'FETCH_USER_SYSTEMS_SUCCESS':
        case 'FETCH_CLIENT_SYSTEMS_SUCCESS':
            return {
                ...state,
                userSystems: action.payload.data,
                loadingSystems: false
            }
        case 'FETCH_SYSTEMS_FAIL':
        case 'FETCH_USER_SYSTEMS_FAIL':
        case 'FETCH_CLIENT_SYSTEMS_FAIL':
            return {
                ...state,
                loadingSystems: false
            }
        case 'REFRESH_SYSTEMS':
            return {
                ...state,
                loadingSystems: true
            }
        case 'REFRESH_SYSTEMS_SUCCESS':
            return {
                ...state,
                systems: {
                    hasNext: !action.payload.data.last,
                    current: action.payload.data.number,
                    content: action.payload.data.content ? action.payload.data.content : []
                },
                loadingSystems: false
            }
        case 'REFRESH_SYSTEMS_FAIL':
            return {
                ...state,
                loadingSystems: false
            }
        case 'CLEAR_SYSTEMS':
            return {
                ...state,
                loadingSystems: false,
                systems: {
                    hasNext: true,
                    current: 0,
                    content: []
                }
            }
        case 'SET_SYSTEM_ID':
            return {
                ...state,
                systemId: action.payload.systemId
            }
        case 'LOAD_SYSTEM':
            return {
                ...state,
                systemIsLoading: true
            }
        case 'DELETE_SYSTEM_SUCCESS':
            return {
                ...state,
                systemId: null,
                currentSystem: null,
                systemIsLoading: false
            }
        case 'LOAD_SYSTEM_SUCCESS':
            return {
                ...state,
                currentSystem: action.payload.data,
                systemIsLoading: false
            }
        case 'LOAD_SYSTEM_FAIL':
            return {
                ...state,
                systemIsLoading: false
            }
        case 'UPDATE_SYSTEM_INFO':
            return state
        case 'UPDATE_SYSTEM_INFO_SUCCESS':
            return {
                ...state,
                currentSystem: action.payload.data
            }
        case 'UPDATE_SYSTEM_NAME_SUCCESS':
            return {
                ...state,
                currentSystem: action.payload.data
            }
        case 'UPDATE_SYSTEM_INFO_FAIL':
            return state
        case 'LOAD_DEFAULT_SYSTEM':
            return {
                ...state,
                systemIsLoading: true,
                needToLoadDefaultSystem: false
            }
        case 'LOAD_DEFAULT_SYSTEM_SUCCESS':
            return {
                ...state,
                systemId: action.payload.data.id,
                currentSystem: action.payload.data,
                systemIsLoading: false
            }
        case 'LOAD_DEFAULT_SYSTEM_FAIL':
            return {
                ...state,
                systemIsLoading: false
            }
        case 'CREATE_SYSTEM':
            return state
        case 'CREATE_SYSTEM_SUCCESS':
            return {
                ...state,
                systemId: action.payload.data.id
            }
        case 'CREATE_SYSTEM_FAIL':
            return state
        case 'LOGOUT_SUCCESS':
        case 'LOGOUT_FAIL':
            return {
                ...state,
                currentSystem: null,
                systemId: null
            }
        case 'UPDATE_SYSTEM_LOGO_SUCCESS':
            return {
                ...state,
                currentSystem: {
                    ...state.currentSystem,
                    logo: action.payload.data.logo
                }
            }
        case 'LOAD_SYSTEM_STATISTICS':
        case 'LOAD_SYSTEM_STATISTICS_FAIL':
            return {
                ...state,
                statistics: null
            }
        case 'LOAD_SYSTEM_STATISTICS_SUCCESS':
            return {
                ...state,
                statistics: action.payload.data
            }
        case 'UPDATE_SYSTEM_NAME':
        case 'UPDATE_SYSTEM_NAME_FAIL':
        default:
            return state
    }
}
