import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsFormCheckbox = (props) => {
    const {t} = useTranslation();

    return (
        <FormControlLabel
            className="bs-form__checkbox"
            control={
                <Checkbox
                    checked={!!props.value}
                    onChange={props.onHandleChange.bind(this)}
                    name={props.name}
                    disabled={props.disabled}
                    color="primary"
                />
            }
            label={t(props.label)}
            labelPlacement="start"
        />
    )
}

BsFormCheckbox.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    onHandleChange: PropTypes.func
};

export default BsFormCheckbox;