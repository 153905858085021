import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const LabelAdminInfoActions = (props) => {
  const {t} = useTranslation();

  const handleDelete = () => {
    props.onHandleDelete();
  }

  return (
      <Button onClick={handleDelete} variant="outlined">
        {t('label_admin_info.delete_action')}
      </Button>
  )
}

export default LabelAdminInfoActions
