export const getSystemId = () => {
    const path = document.location.pathname;
    if (path.indexOf('/system/') > -1) {
        const segments = path.replace('/system/', '').split('/');
        return segments[0];
    } else {
        return null;
    }
}

export const getLabelId = () => {
    const path = document.location.pathname;
    if (path.indexOf('/label/') > -1) {
        const segments = path.replace('/label/', '').split('/');
        return segments[0];
    } else {
        return null;
    }
}

export const getCurrentPath = () => {
    return window.location.pathname + window.location.search;
}
