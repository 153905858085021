export function loadGlobalStatistics() {
  return {
    type: "LOAD_GLOBAL_STATISTICS",
    payload: {
      request: {
        method: 'get',
        url: '/api/v1/bo/statistics'
      },
      globalLoading: true
    }
  };
}

export function loadLabelsStatistics(labelId) {
  return {
    type: "LOAD_LABEL_STATISTICS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/labels/${labelId}/statistics`
      },
      globalLoading: true
    }
  };
}
