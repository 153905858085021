import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsFormDesc = (props) => {
    const {t} = useTranslation();

    return (
        <div className="bs-form__desc-container">
            <div className="bs-form__desc-title">{t(props.title)}</div>
            <div className="bs-form__desc-info">{t(props.desc)}</div>
        </div>
    )
}

BsFormDesc.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string
};

export default BsFormDesc;