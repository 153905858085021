import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import {useTranslation} from "react-i18next";
import Row from "components/layout/Row";
import Col from "components/layout/Col";

const PasswordUsageFilters = (props) => {
  const {t} = useTranslation();
  const nameRef = React.createRef();

  return (
      <Row>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <TextField label={t('password_info.client_name_filter')}
                       ref={nameRef}
                       onChange={props.onHandleChange.bind(this, "name")}
                       value={props.fields["name"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
      </Row>
  )
}

export default PasswordUsageFilters;
