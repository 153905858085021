import React from 'react';
import BsLeftHeader from "./BsLeftHeader";
import BsRightHeader from "./BsRightHeader";
import PropTypes from "prop-types";

import './BsHeader.scss';

const BsHeader = (props) => {
    return (
        <footer className="header">
            <div className="header-content">
                <div className="header-left">
                    <BsLeftHeader history={props.history}/>
                </div>
                <div className="header-right">
                    <BsRightHeader history={props.history}/>
                </div>
            </div>
        </footer>
    );
}

BsHeader.propTypes = {
    history: PropTypes.object
};

export default BsHeader;