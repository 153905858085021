import React, {useEffect} from "react";
import {connect} from "react-redux";
import {fetchMaintenanceVisitInfo} from "core/actions/maintenance-plan-actions";
import MaintenanceVisitErrorForm from "../form/error/MaintenanceVisitErrorForm";

const MaintenanceVisitErrorInfo = (props) => {

  const [currentVisitLoaded, setCurrentVisitLoaded] = React.useState(false);
  const backUrl = '/global/maintenance-visit/' + props.match.params.visitId + '/info'


  useEffect(() => {
    if (!currentVisitLoaded) {
      props.fetchMaintenanceVisitInfo(props.match.params.visitId);
      setCurrentVisitLoaded(true);
    }
  }, [])

  if (props.currentMaintenanceVisit) {
    return (
        <MaintenanceVisitErrorForm visit={props.currentMaintenanceVisit}
                                   history={props.history}
                                   location={props.location}
                                   backUrl={backUrl}/>
    )
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    currentMaintenanceVisit: state.maintenancePlan.currentMaintenanceVisit
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMaintenanceVisitInfo: (id) => {
      dispatch(fetchMaintenanceVisitInfo(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceVisitErrorInfo);
