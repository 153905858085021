import * as Querystring from "query-string";
import tokenStore from 'core/store/token-store'
import {loadProfile} from "./profile-actions";
import {getValidationMessage} from "core/utils/errorUtils";
import {loadUserLabels} from "./label-actions";

export function signIn(username, password, redirectTo) {
    const data = Querystring.stringify({
        username,
        password,
        client_id: 'back-office-frontend',
        client_secret: 'fcc77172-6a1f-4158-ba62-752701a2d566',
        grant_type: 'password',
        scope: 'back_office'
    });

    return {
        type: "SIGN_IN",
        payload: {
            request: {
                method: 'post',
                url: '/api/v1/oauth/token',
                data: data,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            successCallback: (response) => {
                return [
                    loadProfile(true, redirectTo),
                    loadUserLabels()
                ]
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.login_error_message'
                }
            },
            globalLoading: true
        }
    };
}

export function forgotPassword(email) {
    return {
        type: "FORGOT_PASSWORD",
        payload: {
            request: {
                method: 'post',
                url: '/api/v1/bo/security/restore-password/start',
                data: {email}
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.forgot_password_success_message'
                }
            },
            redirect: '/sign-in',
            globalLoading: true
        }
    };
}

export function resetPassword(password, repeatPassword, verificationToken) {
    return {
        type: "RESET_PASSWORD",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/security/restore-password/complete/${verificationToken}`,
                data: {
                    password,
                    repeatPassword
                }
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.reset_password_success_message'
                }
            },
            redirect: '/sign-in',
            globalLoading: true
        }
    };
}

export function changePassword(data, callback) {
    return {
        type: "CHANGE_PASSWORD",
        payload: {
            request: {
                method: 'post',
                url: `/api/v1/bo/security/change-password`,
                data: data
            },
            successCallback: (response) => {
                if (callback) callback();
                return {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.change_password_success_message'
                    }
                }
            },
            globalLoading: true
        }
    };
}

export function logout() {
    return {
        type: "LOGOUT",
        payload: {
            request: {
                method: 'get',
                url: '/api/v1/protected/logout'
            },
            redirect: '/',
            globalLoading: true
        }
    };
}

export function checkSignIn() {
    return {
        type: "CHECK_SIGN_IN",
        payload: {
            isLoggedIn: tokenStore.isLoggedIn()
        }
    };
}

export function activateAccount(token, data) {
    return {
        type: "ACTIVATE_ACCOUNT",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/users/activate/${token}`,
                data: data
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.activate_account_success_message'
                }
            },
            errorCallback: (error) => {
                return {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'error',
                        message: getValidationMessage(error, 'notifications.activate_account_error_message')
                    }
                }
            },
            redirect: '/sign-in',
            globalLoading: true
        }
    };
}

export function resetAuth() {
    return {
        type: "RESET_AUTH",
        payload: {}
    };
}
