export const geocodeReducer = (state = {
    autocomplete: [],
    loading: false,
    decodedAddress: {},
    decodingAddress: false
}, action) => {
    switch (action.type) {
        case 'ADDRESS_AUTOCOMPLETE':
            return {
                ...state,
                loading: true
            }
        case 'ADDRESS_AUTOCOMPLETE_SUCCESS':
            return {
                ...state,
                loading: false,
                autocomplete: action.payload.data
            }
        case 'ADDRESS_AUTOCOMPLETE_FAIL':
            return {
                ...state,
                autocomplete: [],
                loading: false
            }
        case 'FETCH_ADDRESS_DETAILS':
            return {
                ...state,
                decodingAddress: true
            }
        case 'FETCH_ADDRESS_DETAILS_SUCCESS':
            return {
                ...state,
                decodingAddress: false,
                decodedAddress: action.payload.data
            }
        case 'FETCH_ADDRESS_DETAILS_FAIL':
            return {
                ...state,
                decodingAddress: false
            }
        case 'FETCH_LOCATION_SUCCESS':
            return {
                ...state,
                decodedAddress: action.payload.data.address
            }
        default:
            return state
    }
}