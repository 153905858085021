export function loadServices(systemId) {
  return {
    type: "FETCH_SERVICES",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/services`
      },
      globalLoading: false
    }
  };
}
