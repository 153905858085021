import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import PaymentProviderActions from "./PaymentProviderActions";
import ChangePaymentProviderModel from "./ChangePaymentProviderModel";
import {changePaymentProvider, changeTaxConfig, fetchSystemConfig} from "core/actions/system-config-actions";
import PAYMENT_PROVIDER_FORM from "./payment-provider-form";
import ChangeTaxModel from "./ChangeTaxModel";

const PaymentProviderTab = (props) => {

  const [changePaymentProviderWindow, setPaymentProviderWindow] = useState({
    open: false,
  });
  const [changeTaxWindow, setTaxWindow] = useState({
    open: false,
  });

  useEffect(() => {
    props.fetchSystemConfig(props.match.params.systemId);
  }, []);

  const onHandleChangePaymentProvider = () => {
    setPaymentProviderWindow({
      open: true
    });
  }

  const closeChangingPaymentProviderModel = () => {
    setPaymentProviderWindow({
      open: false
    });
  }

  const changePaymentProvider = (data) => {
    closeChangingPaymentProviderModel()
    props.changePaymentProvider(props.match.params.systemId, data)
  }

  const onHandleChangeTax = () => {
    setTaxWindow({
      open: true
    });
  }

  const closeChangingTaxModel = () => {
    setTaxWindow({
      open: false
    });
  }

  const changeTaxConfig = (data) => {
    closeChangingTaxModel()
    props.changeTaxConfig(props.match.params.systemId, data)
  }

  if (props.system && props.systemConfig) {
    const formConfig = {
      ...PAYMENT_PROVIDER_FORM
    };
    formConfig.title = {
      actions: <PaymentProviderActions onHandleEdit={onHandleChangePaymentProvider}
                                       onHandleEditTax={onHandleChangeTax}/>
    }

    return (
      <div className="bs-form-container general-settings">
        <PagePermission anonymous={false} permission={ADMIN}/>
        <ChangePaymentProviderModel
          open={changePaymentProviderWindow.open}
          handleClose={closeChangingPaymentProviderModel.bind(this)}
          handleSubmit={changePaymentProvider.bind(this)}
        />
        <ChangeTaxModel
          open={changeTaxWindow.open}
          handleClose={closeChangingTaxModel.bind(this)}
          handleSubmit={changeTaxConfig.bind(this)}
          config={props.systemConfig}
        />
        <BsForm
          config={formConfig}
          formData={{
            ...props.system,
            ...props.systemConfig
          }}
          disabled={true}/>
      </div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    system: state.system.currentSystem,
    systemConfig: state.systemConfig.systemConfig
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    },
    changePaymentProvider: (systemId, data) => {
      dispatch(changePaymentProvider(systemId, data));
    },
    changeTaxConfig: (systemId, data) => {
      dispatch(changeTaxConfig(systemId, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProviderTab);
