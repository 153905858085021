const BICYCLE_SCAN_BY_DAY = {
  columns: [
    {
      label: 'bicycle_scan_history.system_name_header',
      field: 'system.name',
      ordering: true,
      getValue(raw) {
        return raw.system ? raw.system.name : '';
      }
    },
    {
      label: 'bicycle_scan_history.location_name_header',
      field: 'location.name',
      ordering: true,
      getValue(raw) {
        return raw.location ? raw.location.label : '';
      }
    },
    {
      label: 'bicycle_scan_history.bike_header',
      field: 'bike.number',
      ordering: true,
      getValue(raw) {
        return raw.bike.number;
      }
    },
    {
      label: 'bicycle_scan_history.user_header',
      field: 'user.firstName,user.lastName',
      ordering: true,
      getValue(raw) {
        return raw.user ? (raw.user.firstName + ' ' + raw.user.lastName) : '';
      }
    },
    {
      label: 'bicycle_scan_history.scan_date_header',
      field: 'scanDate',
      ordering: true,
      getValue(raw) {
        return raw.scanDate ? raw.scanDate : '-'
      }
    },
    {
      label: 'bicycle_scan_history.scan_time_header',
      field: 'scanTime',
      ordering: true,
      getValue(raw) {
        return raw.scanTime ? raw.scanTime : '-'
      }
    }
  ]
}

export default BICYCLE_SCAN_BY_DAY;
