import React from "react";
import {connect} from "react-redux";
import BsDataTable from "components/data-table/BsDataTable";
import BsTabPanel from "components/panel/BsTabPanel";
import BsPanelHeaderActions from "components/panel/header/BsPanelHeaderActions";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {exportRentalsToCsv, loadRentals} from "core/actions/rental-actions";
import {TABLE_BICYCLE_RENTALS} from "./bicycle-rentals-table";
import BicycleRentalsFilters from "./BicycleRentalsFilters";
import {Trans} from "react-i18next";
import Button from "@material-ui/core/Button";

const queryString = require('query-string');

class BicycleRentalsTab extends React.Component {

    constructor(props) {
        super(props);
        const query = queryString.parse(this.props.location.search);
        this.state = {
            sort: query.sort,
            direction: query.direction,
            fields: {
                name: query.name != null ? query.name : '',
                bikeNumber: query.bikeNumber != null ? query.bikeNumber : ''
            }
        };

        this.onLoadData(query);
    }

    onRequestSort = (params) => {
        this.setState({
            sort: params.sort,
            direction: params.direction
        });
        this.onLoadData(params);
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    onHandleFilter = () => {
        const params = queryString.parse(this.props.location.search);
        params.name = this.state.fields.name;
        params.bikeNumber = this.state.fields.bikeNumber;
        params.page = '0';
        this.props.history.push('?' + queryString.stringify(params));
        this.onLoadData(params)
    }

    onHandleClear = () => {
        const params = queryString.parse(this.props.location.search);
        delete params.name;
        delete params.bikeNumber;
        params.page = '0';
        this.props.history.push('?' + queryString.stringify(params));
        this.onLoadData(params)
        this.setState({
            ...this.state,
            fields: {
                name: '',
                bikeNumber: ''
            }
        })
    }

    onLoadData = (query) => {
        this.props.loadRentals(this.props.match.params.systemId, this.prepareQuery(query));
    }

    onRowClick = (row) => {
        this.props.history.push('/system/' + this.props.match.params.systemId + '/bicycle-info/'
            + this.props.match.params.bicycleId + '/rental/' + row.id + '/info')
    }

    export = () => {
        const query = queryString.parse(this.props.location.search);
        this.props.exportRentalsToCsv(this.props.match.params.systemId, this.prepareQuery(query));
    }

    prepareQuery = (query) => {
        if (query.sort) {
            query.sort = query.sort + ',' + query.direction;
        }
        if (query.name) {
            query['client.firstName'] = query.name
            query['client.lastName'] = query.name
        }
        query['bike.id'] = this.props.match.params.bicycleId
        query['status'] = 'ACTIVE,FINISHED'

        return query
    }

    render() {
        return (
            <BsTabPanel>
                <PagePermission anonymous={false} permission={ADMIN}/>
                <BsPanelLoader show={this.props.loading}/>
                <BsPanelHeader>
                    <BsPanelHeaderActions>
                        <Button variant="outlined" type="button" onClick={this.export}>
                            <Trans i18nKey="general.export"/>
                        </Button>
                    </BsPanelHeaderActions>
                </BsPanelHeader>

                <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                      onHandleClear={this.onHandleClear.bind(this)}>
                    <BicycleRentalsFilters fields={this.state.fields}
                                           onHandleChange={this.handleChange.bind(this)}/>
                </BsPanelHeaderFilters>

                <BsDataTable location={this.props.location}
                             history={this.props.history}
                             order={this.state.direction}
                             orderBy={this.state.sort}
                             settings={TABLE_BICYCLE_RENTALS}
                             content={this.props.rentals}
                             onRowClick={this.onRowClick}
                             onChangePage={this.onLoadData}
                             onChangeSize={this.onLoadData}
                             onRequestSort={this.onRequestSort}/>
            </BsTabPanel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rentals: state.rental.rentals,
        loading: state.rental.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadRentals: (systemId, query) => {
            dispatch(loadRentals(systemId, query));
        },
        exportRentalsToCsv: (systemId, query) => {
            dispatch(exportRentalsToCsv(systemId, query));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BicycleRentalsTab);
