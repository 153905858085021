import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import Button from "@material-ui/core/Button";
import {ADMIN} from "core/utils/permissions";
import {loadLocations, exportLocationsToCsv} from "core/actions/location-actions";
import PagePermission from "components/permission/PagePermission";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import Permitted from "components/permission/Permitted";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanel from "components/panel/BsPanel";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import LocationFilters from "./LocationFilters";
import LOCATIONS_TABLE from "./locations-table";
import PlusIcon from "assets/img/plus.svg";

const queryString = require('query-string');

class Locations extends React.Component {

    constructor(props) {
        super(props);
        const query = queryString.parse(this.props.location.search);
        this.state = {
            sort: query.sort,
            direction: query.direction,
            fields: {
                label: query.label != null ? query.label : '',
                address: query.address != null ? query.address : ''
            }
        };

        this.onLoadData(query);
    }

    onRequestSort = (params) => {
        this.setState({
            sort: params.sort,
            direction: params.direction
        });
        this.onLoadData(params);
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    onHandleFilter = () => {
        const params = queryString.parse(this.props.location.search);
        params.label = this.state.fields.label;
        params.address = this.state.fields.address;
        params.page = '0';
        this.props.history.push('?' + queryString.stringify(params));
        this.onLoadData(params)
    }

    onHandleClear = () => {
        const params = queryString.parse(this.props.location.search);
        delete params.label;
        delete params.address;
        params.page = '0';
        this.props.history.push('?' + queryString.stringify(params));
        this.onLoadData(params)
        this.setState({
            ...this.state,
            fields: {
                label: '',
                address: ''
            }
        })
    }

    onLoadData = (query) => {
        this.props.loadLocations(this.props.match.params.systemId, this.prepareQuery(query));
    }

    addLocation = () => {
        this.props.history.push('/system/' + this.props.match.params.systemId + '/create-location')
    }

    onRowClick = (row) => {
        this.props.history.push('/system/' + this.props.match.params.systemId + '/location/' + row.id)
    }

    export = () => {
        const query = queryString.parse(this.props.location.search);
        this.props.exportLocationsToCsv(this.props.match.params.systemId, this.prepareQuery(query));
    }

    prepareQuery = (query) => {
        if (query.sort) {
            query.sort = query.sort + ',' + query.direction;
        }

        return query
    }

    render() {
        return (
            <BsPanel padding={true}>
                <PagePermission anonymous={false} permission={ADMIN}/>
                <BsPanelLoader show={this.props.loading}/>
                <BsPanelHeader>
                    <BsPanelTitle action={
                        <>
                            <Button variant="contained" color="primary" type="submit" onClick={this.addLocation}>
                                <PlusIcon/>&nbsp;<Trans i18nKey="general.add"/>
                            </Button>
                            <Button variant="outlined" type="button" onClick={this.export}>
                                <Trans i18nKey="general.export"/>
                            </Button>
                        </>
                    }>
                        <Trans i18nKey="locations.title"/>
                    </BsPanelTitle>
                </BsPanelHeader>

                <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                      onHandleClear={this.onHandleClear.bind(this)}>
                    <LocationFilters fields={this.state.fields}
                                    onHandleChange={this.handleChange.bind(this)}/>
                </BsPanelHeaderFilters>

                <BsDataTable location={this.props.location}
                             history={this.props.history}
                             order={this.state.direction}
                             orderBy={this.state.sort}
                             settings={LOCATIONS_TABLE}
                             content={this.props.locations}
                             onRowClick={this.onRowClick}
                             onChangePage={this.onLoadData}
                             onChangeSize={this.onLoadData}
                             onRequestSort={this.onRequestSort}/>
            </BsPanel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locations: state.location.locations,
        loading: state.location.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadLocations: (systemId, query) => {
            dispatch(loadLocations(systemId, query));
        },
        exportLocationsToCsv: (systemId, query) => {
            dispatch(exportLocationsToCsv(systemId, query));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
