import React, {useEffect} from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import BsForm from "components/form/BsForm";

import 'components/model/confirmation-model.scss';
import PAY_SUBSCRIPTION_INVOICE_FORM from "./pay-subscription-invoice-form";

const PaySubscriptionInvoiceModel = (props) => {
  const {t} = useTranslation();
  let fields = {};

  const onSubmit = () => {
    props.handleSubmit(fields)
  }

  const handleChange = (newData) => {
    fields = newData;
  }

  return (
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model-window"
          open={props.open}
          onClose={props.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
        <Fade in={props.open}>
          <div className="model-container">
            <div className="model-body">
              <h4>{t('pay_subscription_invoice_model.title')}</h4>

              <BsForm
                  config={PAY_SUBSCRIPTION_INVOICE_FORM}
                  onHandleSubmit={onSubmit}
                  onHandleChange={handleChange}
                  cancelAction={<Button variant="outlined" onClick={props.handleClose}>
                    {t('general.cancel')}
                  </Button>}
              />
            </div>
          </div>
        </Fade>
      </Modal>
  )
}

export default PaySubscriptionInvoiceModel;
