import React, {useEffect} from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import {connect} from "react-redux"
import SelectIcon from 'assets/img/select-icon.svg'
import {logout} from "core/actions/security-actions";
import {selectSystem} from "core/actions/system-actions";
import {loadSelectedLabel, loadUserLabels} from "core/actions/label-actions";
import {loadLabelsStatistics} from "core/actions/statistics-actions";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const UserSettingMenuItem = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  useEffect(() => {
    if (props.needToLoadLabels) {
      props.loadUserLabels();
    }
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    handleClose();
    props.logout()
  }

  const changeProfile = () => {
    handleClose();
    props.history.push('/change-profile')
  }

  const changePassword = () => {
    handleClose();
    props.history.push('/change-password')
  }

  const globalData = () => {
    handleClose();
    props.clearSystem()
    props.history.push('/global/statistics')
  }

  const selectLabel = (label) => {
    handleClose();
    props.clearSystem()
    props.loadLabelsStatistics(label.id);
    props.loadSelectedLabel(label.id);
    props.history.push('/label/' + label.id + '/statistics')
  }

  let username = props.profile ? props.profile.firstName + ' ' + props.profile.lastName : '';

  let labelItems = [];
  if (props.userLabels && props.userLabels.content) {
    labelItems = props.userLabels.content.map(label => {
      return <MenuItem onClick={() => selectLabel(label)} key={'label-' + label.id}>{label.label}</MenuItem>
    })
  }

  return (
      <div>
        <Button aria-controls="fade-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="primary"
                className="header__link">
          {username} <SelectIcon className="header__select-icon"/>
        </Button>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}>
          {props.showGlobalData ? <MenuItem onClick={globalData}>{t('menu.global_data')}</MenuItem> : null}
          {labelItems}
          <MenuItem onClick={changeProfile}>{t('menu.profile')}</MenuItem>
          <MenuItem onClick={changePassword}>{t('menu.change_password')}</MenuItem>
          <MenuItem onClick={logout}>{t('menu.logout')}</MenuItem>
        </Menu>
      </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    showGlobalData: state.profile.profile && (state.profile.profile.role === 'SUPER_ADMIN' || state.profile.profile.role === 'VT_SUPER_ADMIN'),
    needToLoadLabels: !state.label.userLabelsWasLoaded && state.security.isLoggedIn && !state.label.userLabelsIsLoading,
    userLabels: state.label.userLabels
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    clearSystem: () => {
      dispatch(selectSystem(null));
    },
    loadUserLabels: () => {
      dispatch(loadUserLabels());
    },
    loadLabelsStatistics: (labelId) => {
      dispatch(loadLabelsStatistics(labelId));
    },
    loadSelectedLabel: (labelId) => {
      dispatch(loadSelectedLabel(labelId));
    }
  };
};

UserSettingMenuItem.propTypes = {
  history: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingMenuItem)
