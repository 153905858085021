import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import BsFormPanel from "components/form/BsFormPanel";
import ConfirmationModel from "components/model/ConfirmationModel";
import EmptyIntegrationSettings from "./EmptyIntegrationSettings";
import IntegrationSettingsActions from "./IntegrationSettingsActions";
import {clearIntegrationWithVt, loadIntegrationWithVt} from "core/actions/integrations-actions";
import buildIntegrationForm from "./integration-form";
import BsFormTitle from "components/form/title/BsFormTitle";
import BsValidatorForm from "components/form/BsValidatorForm";

import "./IntegrationSettings.scss";

class IntegrationSettings extends React.Component {
  constructor(props) {
    super(props);
    props.loadIntegrationWithVt(props.match.params.systemId);
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/fleet';
    this.state = {
      openClearIntegrationWindow: false
    }
  }

  onHandleIntegrate() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-settings/integration/edit')
  }

  onHandleClear() {
    this.setState({
      ...this.state,
      openClearIntegrationWindow: true
    })
  }

  clearIntegration() {
    this.props.clearIntegrationWithVt(this.props.match.params.systemId);
  }

  closeIntegrationWindow() {
    this.setState({
      ...this.state,
      openClearIntegrationWindow: false
    })
  }

  render() {
    if (this.props.integration) {
      const formConfig = buildIntegrationForm();
      formConfig.title.actions = <IntegrationSettingsActions onHandleClear={this.onHandleClear.bind(this)}/>;
      formConfig.title.back = {
        url: this.backUrl,
        history: this.props.history,
        location: this.props.location
      };

      return (
          <BsFormPanel>
            <ConfirmationModel
                open={this.state.openClearIntegrationWindow}
                message="integration.clear_confirmation_message"
                handleClose={this.closeIntegrationWindow.bind(this)}
                handleApply={this.clearIntegration.bind(this)}
            />
            <BsForm
                config={formConfig}
                formData={this.props.integration}
                disabled={true}/>
          </BsFormPanel>
      );
    } else {
      return <BsFormPanel>
        <BsValidatorForm onHandleSubmit={() => {
        }}>
          <BsFormTitle title={{text: 'integration.title'}}/>
        </BsValidatorForm>
        <EmptyIntegrationSettings onHandleIntegrate={this.onHandleIntegrate.bind(this)}/>
      </BsFormPanel>
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    integration: state.integrations.vt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadIntegrationWithVt: (systemId) => {
      dispatch(loadIntegrationWithVt(systemId));
    },
    clearIntegrationWithVt: (systemId) => {
      dispatch(clearIntegrationWithVt(systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSettings);
