import React from "react";
import {connect} from "react-redux";
import {fetchSubscriptionTransactions} from "core/actions/subscription-transaction-actions";
import SubscriptionSettingsInvoice from "./SubscriptionSettingsInvoice";

class SubscriptionSettingsTransactions extends React.Component {

  constructor(props) {
    super(props);
    props.fetchSubscriptionTransactions(props.systemId, {page: 0, size: 100});
  }

  render() {
    const noRecords = this.props.transactions.content ? null : '-';

    return <div className="transaction-table">
      {(this.props.transactions.content ? this.props.transactions.content : []).map((row, index) => {
        return <SubscriptionSettingsInvoice key={index} invoice={row}/>;
      })}
      {noRecords}
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    transactions: state.subscriptionTransaction.transactions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubscriptionTransactions: (systemId, query) => {
      dispatch(fetchSubscriptionTransactions(systemId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSettingsTransactions);
