export const rentalRateReducer = (state = {
  rentalRates: {},
  currentRate: null,
  loading: false,
  loadingCurrentRate: false
}, action) => {
  switch (action.type) {
    case 'FETCH_RENTAL_RATES':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_RENTAL_RATES_SUCCESS':
      return {
        ...state,
        loading: false,
        rentalRates: action.payload.data
      }
    case 'FETCH_RENTAL_RATES_FAIL':
      return {
        ...state,
        loading: false
      }
    case 'FETCH_RENTAL_RATE':
      return {
        ...state,
        currentRate: null,
        loadingCurrentRate: true
      }
    case 'FETCH_RENTAL_RATE_FAIL':
      return {
        ...state,
        currentRate: null,
        loadingCurrentRate: false
      }
    case 'FETCH_RENTAL_RATE_SUCCESS':
    case 'UPDATE_RENTAL_RATE_SUCCESS':
      return {
        ...state,
        currentRate: action.payload.data,
        loadingCurrentRate: false
      }
    case 'DELETE_RENTAL_RATE_SUCCESS':
      return {
        ...state,
        currentRate: null,
        loadingCurrentRate: false
      }
    case 'CREATE_RENTAL_RATE':
    case 'UPDATE_RENTAL_RATE':
    case 'DELETE_RENTAL_RATE':
    case 'CREATE_RENTAL_RATE_SUCCESS':
    case 'CREATE_RENTAL_RATE_FAIL':
    case 'UPDATE_RENTAL_RATE_FAIL':
    case 'DELETE_RENTAL_RATE_FAIL':
    default:
      return state
  }
}
