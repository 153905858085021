import React from 'react';
import {connect} from "react-redux";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsPanel from "components/panel/BsPanel";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import MAINTENANCE_REPORTS_TABLE from "./maintenance-reports-table";
import {loadBikeReports} from "core/actions/bike-report-actions";
import {Trans} from "react-i18next";
import MaintenanceReportsFilters from "./MaintenanceReportsFilters";

const queryString = require('query-string');

class MaintenanceReports extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        name: query.name != null ? query.name : '',
        status: query.status != null ? query.status : ''
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.name = this.state.fields.name;
    params.status = this.state.fields.status;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.name;
    delete params.status;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        name: '',
        status: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadBikeReports(this.props.match.params.systemId, this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/maintenance-report/' + row.id)
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.name) {
      query['reporter.firstName'] = query.name
      query['reporter.lastName'] = query.name
    }

    return query
  }

  render() {
    return (
        <BsPanel padding={true}>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle>
              <Trans i18nKey="bicycle_reports.title"/>
            </BsPanelTitle>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <MaintenanceReportsFilters fields={this.state.fields}
                                       onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={MAINTENANCE_REPORTS_TABLE}
                       content={this.props.reports}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    reports: state.bikeReports.reports,
    loading: state.clientUser.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadBikeReports: (systemId, query) => {
      dispatch(loadBikeReports(systemId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceReports);
