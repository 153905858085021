import React from "react";

const BsFormActionCancelContainer = (props) => {
    return (
        <div className="bs-form__cancel-action">
            {props.children}
        </div>
    )
}

export default BsFormActionCancelContainer;