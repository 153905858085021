import React from "react";
import {connect} from "react-redux";
import {fetchMaintenanceVisit} from "core/actions/maintenance-plan-actions";
import MaintenanceVisitInfoForm from "../../../visits/info/form/MaintenanceVisitInfoForm";

class MaintenanceVisit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.props.fetchMaintenanceVisit(this.props.match.params.planId, this.props.match.params.number);
    this.backUrl = '/global/maintenance-plan/' + this.props.match.params.planId + '/info';
  }

  onHandleShowErrorModel() {
    this.props.history.push('/global/maintenance-plan/' + this.props.match.params.planId + '/visit/'
        + this.props.match.params.number + '/error')
  }

  render() {
    if (this.props.currentMaintenanceVisit) {
      return (
          <MaintenanceVisitInfoForm visit={this.props.currentMaintenanceVisit}
                                    history={this.props.history}
                                    location={this.props.location}
                                    backUrl={this.backUrl}
                                    onHandleShowErrorModel={this.onHandleShowErrorModel.bind(this)}/>
      )
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentMaintenanceVisit: state.maintenancePlan.currentMaintenanceVisit,
    loading: state.maintenancePlan.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMaintenanceVisit: (planId, number) => {
      dispatch(fetchMaintenanceVisit(planId, number));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceVisit);
