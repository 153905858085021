import React from "react";
import {Trans} from "react-i18next";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import BsForm from "components/form/BsForm";
import BsFormPanel from "components/form/BsFormPanel";
import PagePermission from "components/permission/PagePermission";
import INTEGRATE_WITH_VELOTOOLER_FORM from "./integrate-with-velotooler-form";
import {integrateWithVt} from "core/actions/integrations-actions";

class IntegrateWithVelotooler extends React.Component {

    constructor(props) {
        super(props);
        this.backUrl = '/system/' + this.props.match.params.systemId + '/edit-settings/integration/show';
        this.fields = {};
    }

    handleSubmit(fields) {
        this.props.integrateWithVt(this.props.match.params.systemId, {
            code: fields.code,
            clientId: fields.clientId,
            clientSecret: fields.clientSecret,
            scopes: fields.scopes
        });
    }

    render() {
        return (
            <BsFormPanel>
                <PagePermission anonymous={false} />
                <BsForm
                    config={INTEGRATE_WITH_VELOTOOLER_FORM}
                    onHandleSubmit={this.handleSubmit.bind(this)}
                    cancelAction={
                        <Button variant="outlined"
                                onClick={() => this.props.history.push(this.backUrl)}>
                            <Trans i18nKey="general.cancel"/>
                        </Button>
                    }/>
            </BsFormPanel>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        integrateWithVt: (systemId, data) => {
            dispatch(integrateWithVt(systemId, data));
        }
    };
};

export default connect(null, mapDispatchToProps)(IntegrateWithVelotooler);
