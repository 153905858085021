import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import BsFormPanel from "components/form/BsFormPanel";
import {MANAGER} from "core/utils/permissions";
import MaintenanceReportImages from "./MaintenanceReportImages";
import {fetchBikeReportInfo} from "core/actions/bike-report-actions";
import MAINTENANCE_REPORT_INFO_FORM from "./maintenance-report-info-form";

import './maintenance-report-info-form.scss';

class MaintenanceReportInfo extends React.Component {

  constructor(props) {
    super(props);
    this.props.fetchBikeReportInfo(this.props.match.params.reportId);
    this.backUrl = '/global/maintenance-reports'
  }

  render() {
    if (this.props.currentReport) {
      const formConfig = {
        ...MAINTENANCE_REPORT_INFO_FORM
      };
      formConfig.title = {
        back: {
          url: this.backUrl,
          history: this.props.history,
          location: this.props.location
        },
        text: 'bicycle_report_info.title'
      }
      return (
          <BsFormPanel size="m">
            <PagePermission anonymous={false} permission={MANAGER}/>

            <BsForm
                config={formConfig}
                formData={this.props.currentReport}
                disabled={true}/>

            <MaintenanceReportImages images={this.props.currentReport.maintenanceImages}
                                     bike={this.props.currentReport.bike.number}
                                     desc={this.props.currentReport.maintenanceComment}/>
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentReport: state.bikeReports.currentReport
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBikeReportInfo: (reportId) => {
      dispatch(fetchBikeReportInfo(reportId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceReportInfo);
