const buildAddAdminForm = (role) => {
    let addAdminForm = {
        title: {
            text: 'add_admin.title'
        },
        submit: {
            label: 'add_admin.submit_btn'
        },
        fields: [
            {
                name: 'firstName',
                label: 'add_admin.first_name_field',
                type: 'text',
                validators: ['required'],
                errors: ['errors.required_first_name'],
                defaultValue: ''
            },
            {
                name: 'lastName',
                label: 'add_admin.last_name_field',
                type: 'text',
                validators: ['required'],
                errors: ['errors.required_last_name'],
                defaultValue: ''
            },
            {
                name: 'email',
                label: 'add_admin.email_field',
                type: 'text',
                validators: ['required', 'isEmail'],
                errors: ['errors.required_email', 'errors.invalid_email'],
                defaultValue: ''
            },
            {
                name: 'role',
                label: 'add_admin.role_field',
                type: 'select',
                items: [
                    {value: "ADMIN", label: "admin_role.ADMIN", translate: true},
                    {value: "MECHANIC", label: "admin_role.MECHANIC", translate: true}
                ],
                validators: ['requiredRole'],
                errors: ['errors.required_role'],
                defaultValue: ''
            },
        ]
    };

    if (role === 'ADMIN') {
        addAdminForm.fields.push({
            name: 'adminNotifications',
            type: 'subTitle',
            label: 'admin_info.admin_notifications_sub_title'
        });
        addAdminForm.fields.push({
            name: 'pendingUser',
            label: 'admin_info.pending_user_notifications',
            type: 'checkbox',
            styles: {
                marginRight: '-12px'
            },
            defaultValue: true
        });
        addAdminForm.fields.push({
            name: 'problemReport',
            label: 'admin_info.problem_report_notifications',
            type: 'checkbox',
            styles: {
                marginRight: '-12px'
            },
            defaultValue: true
        });
        addAdminForm.fields.push({
            name: 'weeklyReport',
            label: 'admin_info.weekly_report_notifications',
            type: 'checkbox',
            styles: {
                marginRight: '-12px'
            },
            defaultValue: true
        });
    }

    return addAdminForm;
}

export default buildAddAdminForm;
