import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {TextValidator} from "react-material-ui-form-validator";
import {addressAutocomplete} from "core/actions/geocode-actions";
import PropTypes from "prop-types";

function BsFormGoogleAutocompleteField(props) {
    const {t} = useTranslation();
    const [inputValue, setInputValue] = React.useState('');
    let countOfCalls = React.useRef(0);

    React.useEffect(() => {
        if (inputValue) {
            countOfCalls.current++;
            setTimeout(() => {
                countOfCalls.current--;
                if (countOfCalls.current === 0) {

                    props.addressAutocomplete({
                        query: inputValue,
                        restrictions: "",
                        types: 'geocode'
                    });
                }
            }, 500);
        }
    }, [inputValue]);

    return (
        <Autocomplete
            className="form-control"
            getOptionLabel={(option) => ((option && option.address) ? option.address : '')}
            getOptionSelected={(option, value) => !!value && (option.placeId === value.placeId)}
            filterOptions={(x) => x}
            options={[props.value, ...props.options]}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={props.value}
            onChange={(event, newValue) => {
                props.onHandleChange({target: {value: newValue}});
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextValidator
                    {...params}
                    label={t(props.label)}
                    name={props.name}
                    disabled={props.disabled}
                    validators={props.validators}
                    errorMessages={props.errors.map(error => t(error))}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {props.loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    fullWidth/>
            )}
            renderOption={(option) => {
                if (option) {
                    const address = option.address;
                    return (<span>{address}</span>);
                } else {
                    return null;
                }
            }}/>
    );
}

BsFormGoogleAutocompleteField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    onHandleChange: PropTypes.func,
    value: PropTypes.any,
    validators: PropTypes.array,
    errors: PropTypes.array
};

const mapStateToProps = (state) => {
    return {
        loading: state.geocode.loading,
        options: state.geocode.autocomplete
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addressAutocomplete: (query) => {
            dispatch(addressAutocomplete(query));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BsFormGoogleAutocompleteField);
