import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanel from "components/panel/BsPanel";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import {loadAllLabels} from "core/actions/label-actions";
import AllLabelsFilters from "./AllLabelsFilters";
import ALL_LABELS_TABLE from "./all-labels-table";
import Button from "@material-ui/core/Button";
import PlusIcon from "../../../../assets/img/plus.svg";

const queryString = require('query-string');

class AllLabels extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        label: query.label != null ? query.label : ''
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.label = this.state.fields.label;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.label;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        label: ''
      }
    })
  }

  addLabel = () => {
    this.props.history.push('/global/add-label')
  }

  onLoadData = (query) => {
    this.props.loadAllLabels(this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/global/label/' + row.id)
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }

    return query
  }

  render() {
    return (
        <BsPanel padding={true}>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle action={
              <Button variant="contained" color="primary" type="submit" onClick={this.addLabel}>
                <PlusIcon/>&nbsp;<Trans i18nKey="general.add"/>
              </Button>
            }>
              <Trans i18nKey="labels.title"/>
            </BsPanelTitle>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <AllLabelsFilters fields={this.state.fields}
                              onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={ALL_LABELS_TABLE}
                       content={this.props.labels}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>

        </BsPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    labels: state.label.allLabels,
    loading: state.label.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllLabels: (query) => {
      dispatch(loadAllLabels(query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllLabels);
