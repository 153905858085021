import React, {useEffect} from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import BsPanel from "components/panel/BsPanel";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import BicycleInfoTab from "./tabs/info/BicycleInfoTab";
import BicycleProblemsTab from "./tabs/problems/BicycleProblemsTab";
import BicycleRentalsTab from "./tabs/rentals/BicycleRentalsTab";
import BicycleReportsTab from "./tabs/reports/BicycleReportsTab";
import {fetchBicycle} from "core/actions/bicycle-actions";

function BicycleInfo(props) {
    const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
    const defaultTab = lastSegment.split('?')[0]
    const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
    const {t} = useTranslation();

    useEffect(() => {
        if (!props.loadingCurrentBicycle) {
            props.fetchBicycle(props.match.params.systemId, props.match.params.bicycleId);
        }
    }, []);

    const handleChange = (event, newValue) => {
        if (value !== newValue) {
            props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
        }
        setValue(newValue);
    };

    let tabs = null;
    if (props.profile && props.system && props.system.role && props.currentBicycle) {
        tabs = (
            <Tabs value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary">
                <Tab label={t('bicycle_info.info_tab') + props.currentBicycle.number} value="info"/>
                {props.system.role.indexOf(ADMIN) !== -1 ?
                    <Tab label={t('bicycle_info.rentals_tab')} value="rentals"/> : null}
                <Tab label={t('bicycle_info.reported_problems_tab')} value="problems"/>
                <Tab label={t('bicycle_info.reports_tab')} value="reports"/>
            </Tabs>
        )
    }

    return (
        <>
            <PagePermission anonymous={false} permission={ADMIN}/>
            <BsPanel>
                {tabs}
                <Route exact path="/system/:systemId/bicycle/:bicycleId" component={BicycleInfoTab}/>
                <Route path="/system/:systemId/bicycle/:bicycleId/info" component={BicycleInfoTab}/>
                <Route path="/system/:systemId/bicycle/:bicycleId/rentals" component={BicycleRentalsTab}/>
                <Route path="/system/:systemId/bicycle/:bicycleId/problems" component={BicycleProblemsTab}/>
                <Route path="/system/:systemId/bicycle/:bicycleId/reports" component={BicycleReportsTab}/>
            </BsPanel>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile.profile,
        system: state.system.currentSystem,
        currentBicycle: state.bicycle.currentBicycle
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBicycle: (systemId, bicycleId) => {
            dispatch(fetchBicycle(systemId, bicycleId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BicycleInfo);
