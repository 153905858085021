import {loadDefaultSystem} from "./system-actions";

export function loadProfile(needToLoadDefaultSystem, redirectTo) {
    return {
        type: "LOAD_PROFILE",
        payload: {
            request: {
                method: 'get',
                url: '/api/v1/bo/profile'
            },
            successCallback: (response) => {
                if (needToLoadDefaultSystem) {
                    if (response.data.defaultSystemId) {
                        return loadDefaultSystem(response.data.defaultSystemId)
                    } else {
                        return {
                            type: 'REDIRECT_TO',
                            payload: redirectTo ? redirectTo : '/systems'
                        }
                    }
                }
            },
            globalLoading: true
        }
    };
}

export function changeProfile(data) {
    return {
        type: "CHANGE_PROFILE",
        payload: {
            request: {
                method: 'post',
                url: '/api/v1/bo/profile',
                data: data
            },
            globalLoading: true,
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.change_profile_success_message'
                }
            }
        }
    };
}
