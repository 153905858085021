import React from "react";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import ChevronRight from 'assets/img/chevron-right.svg'
import PropTypes from "prop-types";

const BsSystemItem = (props) => {
    let logo = null
    if (props.currentSystem && props.currentSystem.logo) {
        logo = <img src={props.currentSystem.logo.original}
                    alt="Logo"
                    className="system-logo"/>
    }

    return (
        <Button onClick={() => props.history.push('/systems')}
                className="header__menu-system-item">
            {logo} {props.currentSystem.name} <ChevronRight/>
        </Button>
    )
}

const mapStateToProps = (state) => {
    return {
        currentSystem: state.system.currentSystem
    };
};

BsSystemItem.propTypes = {
    history: PropTypes.object
};

export default connect(mapStateToProps)(BsSystemItem);
