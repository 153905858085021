const RESOLVE_RENTAL_PROBLEM_FORM = {
  submit: {
    label: 'rental_info.submit_btn'
  },
  fields: [
    {
      name: 'comment',
      label: 'rental_info.comment_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_comment'],
      defaultValue: ''
    }
  ]
}

export default RESOLVE_RENTAL_PROBLEM_FORM;
