import React from "react";
import {connect} from "react-redux";
import BsValidatorForm from "components/form/BsValidatorForm";
import BsFormTitle from "components/form/title/BsFormTitle";
import BsTableHead from "components/data-table/BsTableHead";
import BsTableBody from "components/data-table/BsTableBody";
import BsTabPanel from "components/panel/BsTabPanel";
import BsForm from "components/form/BsForm";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import FleetMaintenanceSettingsActions from "./FleetMaintenanceSettingsActions";
import NoFleetMaintenanceSettings from "./NoFleetMaintenanceSettings";
import {fetchActiveMaintenancePlan} from "core/actions/maintenance-plan-actions";
import FLEET_MAINTENANCE_TABLE from "./fleet-maintenance-table";
import FLEET_MAINTENANCE_FORM from "./fleet-maintenance-form";

import './FleetMaintenanceSettings.scss';

class FleetMaintenanceSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openChangeMechanicModel: false,
      openChangeServiceModel: false,
      confirmationWindow: {
        open: false,
        message: '',
        event: () => {
        }
      },
    };
    this.props.fetchActiveMaintenancePlan(this.props.match.params.systemId);
  }

  goToVisit(visit) {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-settings/visit/' + visit.id + '/info')
  }

  onHandleShowIntegration() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-settings/integration/show')
  }

  onHandleEditOfferFleetIntegration() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-settings/offer-integration')
  }

  render() {
    if (this.props.currentMaintenancePlan) {
      const plan = this.props.currentMaintenancePlan;

      return (
          <div className="integration-settings bs-form-container">
            <BsTabPanel>
              <BsForm
                  config={{
                    ...FLEET_MAINTENANCE_FORM,
                    title: {
                      actions: <FleetMaintenanceSettingsActions
                          onHandleShowIntegration={this.onHandleShowIntegration.bind(this)}
                          onHandleEditOfferFleetIntegration={this.onHandleEditOfferFleetIntegration.bind(this)}
                          isVtSuperAdmin={this.props.isVtSuperAdmin}
                          isSuperAdmin={this.props.isSuperAdmin}/>
                    }
                  }}
                  formData={plan}
                  disabled={true}/>

              <BsValidatorForm onHandleSubmit={() => {
              }}>
                <TableContainer>
                  <Table size="medium">
                    <BsTableHead settings={FLEET_MAINTENANCE_TABLE}/>
                    <BsTableBody settings={FLEET_MAINTENANCE_TABLE}
                                 content={{content: this.props.currentMaintenancePlan.schedule}}
                                 onRowClick={this.goToVisit.bind(this)}/>
                  </Table>
                </TableContainer>

              </BsValidatorForm>
            </BsTabPanel>
          </div>
      )
    } else {
      return <div className="bs-form-container">
        <div className="bs-form">
          <BsFormTitle title={{
            text: '',
            actions: <FleetMaintenanceSettingsActions
                onHandleShowIntegration={this.onHandleShowIntegration.bind(this)}
                onHandleEditOfferFleetIntegration={this.onHandleEditOfferFleetIntegration.bind(this)}
                isVtSuperAdmin={this.props.isVtSuperAdmin}
                isSuperAdmin={this.props.isSuperAdmin}/>
          }}/>

          <NoFleetMaintenanceSettings/>
        </div>
      </div>
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentMaintenancePlan: state.maintenancePlan.currentMaintenancePlan,
    loading: state.maintenancePlan.loading,
    isVtSuperAdmin: state.profile.profile != null && state.profile.profile.role != null
        && state.profile.profile.role.indexOf('VT_SUPER_ADMIN') > -1,
    isSuperAdmin: state.profile.profile != null && state.profile.profile.role != null
        && state.profile.profile.role.indexOf('SUPER_ADMIN') > -1
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActiveMaintenancePlan: (planId) => {
      dispatch(fetchActiveMaintenancePlan(planId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetMaintenanceSettings);
