import React from "react";
import {connect} from "react-redux";
import {ADMIN} from "core/utils/permissions";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import buildRentalRateInfoForm from "./rental-rate-info-form";
import RentalRateInfoActions from "./RentalRateInfoActions";
import ConfirmationModel from "components/model/ConfirmationModel";
import {withTranslation} from "react-i18next";
import {deleteRentalRate, fetchRentalRate} from "core/actions/rental-rate-actions";
import {fetchSystemConfig} from "core/actions/system-config-actions";

class RentalRateInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      backUrl: '/system/' + this.props.match.params.systemId + '/rental-rates',
      confirmationWindow: {
        open: false,
        message: ''
      }
    };
    this.props.fetchRentalRate(this.props.match.params.systemId, this.props.match.params.rateId);
    this.props.fetchSystemConfig(props.match.params.systemId);
  }

  onHandleUpdate() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-rental-rate/'
        + this.props.match.params.rateId)
  }

  onHandleRemove() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'rental_rate_info.remove_rental_rate_confirmation_message'
      }
    })
  }

  onSubmitRemoveRentalRate() {
    this.closeConfirmationModel();
    this.props.deleteRentalRate(this.props.match.params.systemId, this.props.match.params.rateId)
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  render() {
    if (this.props.currentRate && this.props.systemConfig) {
      const formConfig = buildRentalRateInfoForm(this.props.t, this.props.systemConfig);
      formConfig.title = {
        back: {
          url: this.state.backUrl,
          history: this.props.history,
          location: this.props.location
        }
      }

      formConfig.title.actions = <RentalRateInfoActions
          password={this.props.currentRate}
          onHandleUpdate={this.onHandleUpdate.bind(this)}
          onHandleRemove={this.onHandleRemove.bind(this)}/>

      console.log(this.props.currentRate)

      return (
          <div className="bs-form-container">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <ConfirmationModel
                open={this.state.confirmationWindow.open}
                message={this.state.confirmationWindow.message}
                handleClose={this.closeConfirmationModel.bind(this)}
                handleApply={this.onSubmitRemoveRentalRate.bind(this)}
            />

            <BsForm
                config={formConfig}
                formData={this.props.currentRate}
                disabled={true}/>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentRate: state.rentalRate.currentRate,
    systemConfig: state.systemConfig.systemConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRentalRate: (systemId, rateId) => {
      dispatch(deleteRentalRate(systemId, rateId));
    },
    fetchRentalRate: (systemId, rateId) => {
      dispatch(fetchRentalRate(systemId, rateId));
    },
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    },
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)
(withTranslation()(RentalRateInfoTab));
