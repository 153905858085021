export function calculateAvailablePages(current, total, size) {
    let countPages;
    let pages = [];

    if (size === 0) {
        pages = [0];
    } else {
        if (total % size === 0) {
            countPages = Math.ceil(((total + 0.0) - 1) / size) | 0;
        } else {
            countPages = Math.ceil((total + 0.0) / size) | 0;
        }

        if (countPages > 0) {
            let start = Math.max(current - 3, 0);
            const finish = Math.min(start + 6, countPages);
            start = Math.max(finish - 6, 0);

            pages = [];
            let j = 0;
            for (let i = start; i < finish; i++) {
                pages[j] = i;
                j++;
            }
        }
    }

    return pages;
}