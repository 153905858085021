import React from "react";
import {connect} from "react-redux";
import {fetchSystemConfig} from "core/actions/system-config-actions";
import {ADMIN} from "core/utils/permissions";
import BsFormTitle from "components/form/title/BsFormTitle";
import PagePermission from "components/permission/PagePermission";

import './TosSettings.scss';
import GeneralTosActions from "./GeneralTosActions";

class TosSettings extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        props.fetchSystemConfig(props.match.params.systemId);
    }

    onHandleEdit() {
        this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-settings/tos')
    }

    render() {
        if (this.props.systemConfig) {
            let tos;
            if (this.props.systemConfig.termsOfServices) {
                tos = <div dangerouslySetInnerHTML={{__html: this.props.systemConfig.termsOfServices}}/>;
            } else {
                tos = <div className="terms-of-services__no-data">
                    No Waiver
                </div>
            }

            return (
                <div className="bs-form-container">
                    <div className="bs-form">
                        <PagePermission anonymous={false} permission={ADMIN}/>
                        <BsFormTitle title={{
                            actions: <GeneralTosActions onHandleEdit={this.onHandleEdit.bind(this)}/>
                        }}/>
                        {tos}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        systemConfig: state.systemConfig.systemConfig
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSystemConfig: (systemId) => {
            dispatch(fetchSystemConfig(systemId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TosSettings);
