const LOCATION_BICYCLES_INFO_FORM = {
    columns: [
        {
            label: 'bicycles.number_header',
            field: 'number',
            ordering: true
        },
        {
            label: 'bicycles.model_header',
            field: 'model',
            ordering: true
        },
        {
            label: 'bicycles.status_header',
            field: 'status',
            ordering: true,
            translate: true,
            getValue(raw) {
                return 'bike_status.' + raw.status;
            }
        },
        {
            label: 'bicycles.serial_number_header',
            field: 'serialNumber',
            ordering: true
        },
        {
            label: 'bicycles.lock_header',
            field: 'lockMacAddress',
            ordering: true
        },
        {
            label: 'bicycles.power_level_header',
            field: 'powerLevel',
            ordering: true,
            getValue(raw) {
                return raw.powerLevel ? (raw.powerLevel + "%") : 'None';
            }
            /*translate: true,
            getValue(data) {
                if (!data.powerLevel) {
                    return 'power_level.none';
                } else if (data.powerLevel >= 80) {
                    return 'power_level.good';
                } else if (data.powerLevel >= 50) {
                    return 'power_level.low';
                } else if (data.powerLevel < 50) {
                    return 'power_level.bad';
                }

                return 'power_level.none';
            },
            getStyle(data) {
                if (!data.powerLevel) {
                    return {};
                } else if (data.powerLevel >= 80) {
                    return {color: '#4CAF50'};
                } else if (data.powerLevel >= 50) {
                    return {color: '#FF9800'};
                } else if (data.powerLevel < 50) {
                    return {color: '#f44336'};
                }

                return {};
            },*/
        },
        {
            label: 'bicycles.last_scan_header',
            field: 'lastScan',
            ordering: true,
            getValue(raw) {
                return raw.lastScan ? raw.lastScan : '-'
            }
        }
    ]
}

export default LOCATION_BICYCLES_INFO_FORM;
