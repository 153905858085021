export const bikeReportReducer = (state = {
  reports: {},
  currentReport: null,
  loading: false,
  loadingCurrentReport: false
}, action) => {
  switch (action.type) {
    case 'FETCH_BICYCLE_REPORTS':
    case 'FETCH_ALL_BICYCLE_REPORTS':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_BICYCLE_REPORTS_SUCCESS':
    case 'FETCH_ALL_BICYCLE_REPORTS_SUCCESS':
      return {
        ...state,
        loading: false,
        reports: action.payload.data
      }
    case 'FETCH_BICYCLE_REPORTS_FAIL':
    case 'FETCH_ALL_BICYCLE_REPORTS_FAIL':
      return {
        ...state,
        loading: false
      }

    case 'FETCH_BICYCLE_REPORT':
    case 'FETCH_BICYCLE_REPORT_INFO':
      return {
        ...state,
        currentReport: null,
        loadingCurrentReport: true
      }
    case 'FETCH_BICYCLE_REPORT_FAIL':
    case 'FETCH_BICYCLE_REPORT_INFO_FAIL':
      return {
        ...state,
        currentReport: null,
        loadingCurrentReport: false
      }
    case 'FETCH_BICYCLE_REPORT_SUCCESS':
    case 'FETCH_BICYCLE_REPORT_INFO_SUCCESS':
    case 'UPDATE_BICYCLE_REPORT_SUCCESS':
      return {
        ...state,
        loadingCurrentReport: false,
        currentReport: action.payload.data
      }
    case 'UPDATE_BICYCLE_REPORT':
    case 'UPDATE_BICYCLE_REPORT_FAIL':
    case 'CREATE_ISSUE_BY_REPORT':
    case 'CREATE_ISSUE_BY_REPORT_SUCCESS':
    case 'CREATE_ISSUE_BY_REPORT_FAIL':
    default:
      return state
  }
}
