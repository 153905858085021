import React from 'react';
import ImageUploading from 'react-images-uploading';
import {useTranslation} from "react-i18next";
import UploadImageError from "./UploadImageError";

export function UploadBrandingImage(props) {

  const {t} = useTranslation();

  const [image, setImage] = React.useState([]);

  const onChange = (imageList) => {
    setImage(imageList[1]);
    props.onChooseImage(imageList[1])
  };

  return (
      <>
        <div className="branding-settings__label">{t(props.label)}</div>
        <ImageUploading
            multiple
            value={[image]}
            onChange={onChange}
            maxFileSize="1048576"
            acceptType={['jpg', 'gif', 'png']}
            resolutionType="ratio"
            resolutionWidth={props.resolution.width}
            resolutionHeight={props.resolution.height}
            dataURLKey="data_url">
          {({
              onImageUpload,
              isDragging,
              dragProps,
              errors
            }) => (
              <>
                <div className={`upload__image-wrapper ${isDragging ? 'image-wrapper__dragging' : ''}`}>
                  <div className="image-wrapper__upload_zone"
                       onClick={onImageUpload}
                       {...dragProps}>
                    <div>{t('branding.upload_img_info')}</div>
                    <div className="image-wrapper__warning">{t(props.warning)}</div>
                  </div>
                </div>

                {errors && errors.acceptType && <UploadImageError msg="branding.upload_img_type_error"/>}
                {errors && errors.maxFileSize &&
                <UploadImageError msg="branding.upload_img_file_size_error"/>}
                {errors && errors.resolution &&
                <UploadImageError msg="branding.upload_img_resolution_error"/>}
              </>
          )}
        </ImageUploading>
      </>
  );
}

export default UploadBrandingImage;
