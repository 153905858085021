import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import {fetchSystemConfig, updateSystemTos} from "core/actions/system-config-actions";
import {ADMIN} from "core/utils/permissions";
import EDIT_TOS_CONFIG_FORM from "./edit-tos-settinga-form";
import EditTosActions from "./EditTosActions";

class EditTosSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/tos';
    this.fields = {};
    props.fetchSystemConfig(props.match.params.systemId);
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.props.updateSystemTos(this.props.match.params.systemId, {
      termsOfServices: this.fields.termsOfServices
    }, this.backUrl)
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    if (this.props.systemConfig) {

      const formConfig = {
        ...EDIT_TOS_CONFIG_FORM
      };
      formConfig.title = {
        ...formConfig.title,
        actions: <EditTosActions/>,
        back: {
          url: this.backUrl,
          history: this.props.history,
          location: this.props.location
        }
      }

      const data = {
        termsOfServices: this.props.systemConfig.termsOfServices ? this.props.systemConfig.termsOfServices : ''
      };

      return (
          <BsFormPanel size="l">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <ConfirmationModel
                open={this.state.openConfirmationWindow}
                message="edit_tos_config.confirmation_message"
                handleClose={() => this.setState({openConfirmationWindow: false})}
                handleApply={this.handleUpdate.bind(this)}
            />

            <BsForm
                config={formConfig}
                formData={data}
                onHandleSubmit={this.handleSubmit.bind(this)}
                onHandleChange={this.handleChange.bind(this)}
                cancelAction={
                  <BsFormCancelAction history={this.props.history}
                                      location={this.props.location}
                                      url={this.backUrl}/>
                }
            />
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    systemConfig: state.systemConfig.systemConfig
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    },
    updateSystemTos: (systemId, data, redirectPath) => {
      dispatch(updateSystemTos(systemId, data, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTosSettings);
