const EDIT_SUBSCRIPTION_PLANS_FORM = {
  title: {
    text: 'edit_subscription_plans.title'
  },
  submit: {
    label: 'edit_subscription_plans.submit_btn'
  },
  fields: [
    {
      name: 'name',
      label: 'edit_subscription_plans.name_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_name'],
      defaultValue: ''
    },
    {
      name: 'description',
      label: 'edit_subscription_plans.description_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_description'],
      defaultValue: ''
    },
    {
      name: 'billingPeriod',
      label: 'edit_subscription_plans.billing_period_field',
      type: 'select',
      items: [
        {label: 'subscription_billing_period.NONE', value: 'NONE', translate: true},
        {label: 'subscription_billing_period.MONTHLY', value: 'MONTHLY', translate: true},
        {label: 'subscription_billing_period.ANNUAL', value: 'ANNUAL', translate: true}
      ],
      validators: ['required'],
      errors: ['errors.required_billing_period'],
      defaultValue: ''
    },
    {
      name: 'price',
      type: 'grid',
      fields: [
        {
          name: 'pricePerBike',
          label: 'edit_subscription_plans.price_per_bike_field',
          type: 'text',
          validators: [
            'required',
            'matchRegexp:^[0-9]{1,100}$',
            'minNumber:0',
            'maxNumber:999999999'
          ],
          errors: [
            'errors.required_number',
            'errors.invalid_number_format',
            'errors.min_price_per_bike_error',
            'errors.max_price_per_bike_error'
          ],
          defaultValue: ''
        },
        {
          name: 'currency',
          label: 'edit_subscription_plans.currency_field',
          type: 'select',
          items: [
            {label: 'currency.usd', value: 'USD', translate: true},
            {label: 'currency.cad', value: 'CAD', translate: true}
          ],
          validators: ['required'],
          errors: ['errors.required_currency'],
          defaultValue: 'USD'
        },
      ]
    },
    {
      name: 'autoCancellation',
      label: 'edit_subscription_plans.auto_cancellation_field',
      type: 'text',
      validators: [
        'required',
        'matchRegexp:^[0-9]{1,1000}$',
        'minNumber:1',
        'maxNumber:1000'
      ],
      errors: [
        'errors.required_capacity',
        'errors.invalid_number_format',
        'errors.min_auto_cancellation_error',
        'errors.max_auto_cancellation_error'
      ],
      defaultValue: ''
    }
  ]
}

export default EDIT_SUBSCRIPTION_PLANS_FORM;
