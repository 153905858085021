import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import SubscriptionSettingsActions from "./SubscriptionSettingsActions";
import {fetchSystemSubscription} from "core/actions/system-subscription-actions";
import buildSubscriptionSettingsInfoForm from "./subscription-settings-form";
import SubscriptionSettingsTransactions from "./SubscriptionSettingsTransactions";

import './SubscriptionSettings.scss';
import SubscriptionSettingsPaymentSources from "./SubscriptionSettingsPaymentSources";

class SubscriptionSettings extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    props.fetchSystemSubscription(props.match.params.systemId);
  }

  onHandleEditBilling() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-settings/billing-address')
  }

  render() {
    if (this.props.currentSubscription) {

      const paymentMethods = <SubscriptionSettingsPaymentSources systemId={this.props.match.params.systemId}
                                                                 history={this.props.history}/>
      const invoices = <SubscriptionSettingsTransactions systemId={this.props.match.params.systemId}/>

      const formConfig = buildSubscriptionSettingsInfoForm(this.props.currentSubscription.billingPeriod, paymentMethods, invoices);
      formConfig.title = {
        actions: <SubscriptionSettingsActions onHandleEditBilling={this.onHandleEditBilling.bind(this)}/>
      }

      return (
          <div className="bs-form-container subscription-settings">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <BsForm
                config={formConfig}
                formData={this.props.currentSubscription}
                disabled={true}/>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentSubscription: state.systemSubscription.currentSubscription
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemSubscription: (systemId) => {
      dispatch(fetchSystemSubscription(systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSettings);
