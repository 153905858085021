import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import BsFormPanel from "components/form/BsFormPanel";
import {MANAGER} from "core/utils/permissions";
import {fetchRentalPayment, refundRentalPayment} from "core/actions/rental-payment-actions";
import buildRentalPaymentInfoForm from "./rental-payment-info-form";
import {fetchSystemConfig} from "core/actions/system-config-actions";
import RentalPaymentActions from "./RentalPaymentActions";
import ConfirmationModel from "components/model/ConfirmationModel";

class RentalPaymentForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmationWindow: {
        open: false
      }
    };
    this.props.fetchRentalPayment(this.props.match.params.systemId, this.props.match.params.paymentId);
    this.props.fetchSystemConfig(props.match.params.systemId);
  }

  onHandleRefund() {
    this.setState({
      confirmationWindow: {
        open: true
      }
    });
  }

  handleRefund() {
    this.props.refundRentalPayment(this.props.match.params.systemId, this.props.match.params.paymentId);
    this.closeConfirmationModel()
  }

  closeConfirmationModel() {
    this.setState({
      confirmationWindow: {
        open: false
      }
    });
  }

  render() {
    if (this.props.payment && this.props.systemConfig) {
      const formConfig = {
        ...buildRentalPaymentInfoForm(this.props.payment, this.props.systemConfig)
      };
      formConfig.title = {
        back: {
          url: this.props.backUrl,
          history: this.props.history,
          location: this.props.location
        },
        text: 'rental_payment_info.title'
      }

      if (this.props.payment.status === 'COMPLETED' || this.props.payment.status === 'PAID') {
        formConfig.title.actions = <RentalPaymentActions onHandleRefund={this.onHandleRefund.bind(this)}/>
      }

      return (
        <BsFormPanel size="m">
          <PagePermission anonymous={false} permission={MANAGER}/>
          <ConfirmationModel
            open={this.state.confirmationWindow.open}
            message="rental_payment_info.refund_rental_payment_confirmation_message"
            handleClose={this.closeConfirmationModel.bind(this)}
            handleApply={this.handleRefund.bind(this)}
          />

          <BsForm
            config={formConfig}
            formData={{
              ...this.props.systemConfig,
              ...this.props.payment
            }}
            disabled={true}/>
        </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    payment: state.rentalPayment.currentPayment,
    systemConfig: state.systemConfig.systemConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRentalPayment: (systemId, paymentId) => {
      dispatch(fetchRentalPayment(systemId, paymentId));
    },
    refundRentalPayment: (systemId, paymentId) => {
      dispatch(refundRentalPayment(systemId, paymentId));
    },
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalPaymentForm);
