const CHANGE_PASSWORD_FORM = {
    title: {
        text: 'change_password.title'
    },
    submit: {
        label: 'change_password.submit_btn'
    },
    fields: [
        {
            name: 'oldPassword',
            label: 'change_password.old_password_field',
            type: 'password',
            validators: [
                'required'
            ],
            errors: [
                'errors.required_password'
            ],
            defaultValue: ''
        },
        {
            name: 'password',
            label: 'change_password.password_field',
            type: 'password',
            validators: [
                'required',
                'minStringLength:8',
                'maxStringLength:32',
                'strongPassword'
            ],
            errors: [
                'errors.required_password',
                'errors.min_length_password',
                'errors.max_length_password',
                'errors.invalid_password_format'
            ],
            defaultValue: ''
        },
        {
            name: 'repeatPassword',
            label: 'change_password.repeat_password_field',
            type: 'password',
            validators: ['required', 'isPasswordMatch'],
            errors: [
                'errors.required_repeat_password',
                'errors.password_mismatch'
            ],
            defaultValue: ''
        }
    ]
}

export default CHANGE_PASSWORD_FORM;
