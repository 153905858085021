const ASSIGN_USER_FORM = {
    submit: {
        label: 'issue_info.assign_btn'
    },
    fields: [
        {
            name: 'user',
            label: 'issue_info.assign_user_field',
            type: 'select',
            items: [],
            validators: ['requiredUser'],
            errors: ['errors.required_assign_user'],
            defaultValue: ''
        },
        {
            name: 'assignToMe',
            label: 'issue_info.assign_to_me',
            type: 'link',
            translate: true,
            styles: {
                textAlign: 'right'
            },
            validators: [],
            errors: []
        }
    ]
}

export default ASSIGN_USER_FORM;
