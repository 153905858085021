import React from 'react';
import Paper from "@material-ui/core/Paper"
import PropTypes from "prop-types";

import './BsFormPanel.scss';

const BsFormPanel = (props) => {
    const size = props.size ? props.size : 's'
    const containerClasses = `bs-form-container bs-form-container__pd bs-form-container__${size}`

    return (
        <div className={containerClasses}>
            <Paper elevation={3}>
                {props.children}
            </Paper>
        </div>
    )
}

BsFormPanel.propTypes = {
    size: PropTypes.string
};

export default BsFormPanel;
