import axios from "axios"
import tokenStore from '../store/token-store'
import store from '../store/store'
import Querystring from "query-string";
import {getCurrentPath} from "./pathUtils";

const api = axios.create({
    baseURL: "/bs",
    responseType: "json",
    headers: {
        'X-Time-Zone-Offset': new Date().getTimezoneOffset() / 60
    }
});

api.interceptors.response.use(response => {
    return response;
}, err => {
    return new Promise((resolve, reject) => {
        const originalReq = err.config;
        if (err.response.status === 401
            && err.config && !err.config.__isRetryRequest
            && err.response.data.error === 'invalid_token'
            && tokenStore.isLoggedIn()/* && tokenStore.isExpired()*/) {
            originalReq._retry = true;

            const data = Querystring.stringify({
                client_id: 'back-office-frontend',
                client_secret: 'fcc77172-6a1f-4158-ba62-752701a2d566',
                grant_type: 'refresh_token',
                scope: 'back_office',
                refresh_token: tokenStore.getRefreshToken()
            });

            let res = fetch('/bs/api/v1/oauth/token', {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: data,
            }).then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    store.dispatch({type: 'LOGOUT'})
                    store.dispatch({type: 'REDIRECT_TO', payload: '/sign-in?redirectTo=' + getCurrentPath()})
                }
            }).then(res => {
                tokenStore.update(res);
                originalReq.headers['Authorization'] = tokenStore.getBearer();
                return api(originalReq);
            });

            return resolve(res);
        } else if (originalReq._retry) {
            store.dispatch({type: 'LOGOUT'})
            store.dispatch({type: 'REDIRECT_TO', payload: '/sign-in?redirectTo=' + getCurrentPath()})
            return Promise.reject(err);
        }

        return reject(err.response);
    });
});

export default api;
