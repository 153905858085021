export const routeReducer = (state = {
    routes: []
}, action) => {
    switch (action.type) {
        case 'STORE_ROUTE':
            if (state.routes.length > 0
                && state.routes[state.routes.length - 1].pathname === action.payload.pathname
                && state.routes[state.routes.length - 1].search === action.payload.search) {
                return state
            } else {
                return {
                    ...state,
                    routes: [
                        ...state.routes,
                        action.payload
                    ]
                }
            }
        default:
            return state
    }
}