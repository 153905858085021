import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const NoFleetMaintenanceSettings = (props) => {
  const {t} = useTranslation();

  return (
      <div className="fleet-maintenance-setting__empty">
          {t('integration.no_fleet_maintenance')}
      </div>
  )
}

export default NoFleetMaintenanceSettings
