import React, {useEffect} from "react";
import {connect} from "react-redux";
import {fetchMaintenanceVisitAdminInfo} from "core/actions/maintenance-plan-actions";
import MaintenanceVisitErrorForm
  from "../../../../../global/maintenance/visits/info/form/error/MaintenanceVisitErrorForm";

const FleetMaintenanceVisitError = (props) => {

  const [currentVisitLoaded, setCurrentVisitLoaded] = React.useState(false);
  const backUrl = '/system/' + this.props.match.params.systemId + '/edit-settings/integration/show'


  useEffect(() => {
    if (!currentVisitLoaded) {
      props.fetchMaintenanceVisitAdminInfo(this.props.match.params.systemId, this.props.match.params.visitId);
      setCurrentVisitLoaded(true);
    }
  }, [])

  if (props.currentMaintenanceVisit) {
    return (
        <MaintenanceVisitErrorForm visit={props.currentMaintenanceVisit}
                                   history={props.history}
                                   location={props.location}
                                   backUrl={backUrl}/>
    )
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    currentMaintenanceVisit: state.maintenancePlan.currentMaintenanceVisit
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMaintenanceVisitAdminInfo: (systemId, visitId) => {
      dispatch(fetchMaintenanceVisitAdminInfo(systemId, visitId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetMaintenanceVisitError);
