import React from 'react';

import './BsBody.scss';

function BsBody(props) {
    return (
        <div className="bs-body">
            {props.children}
        </div>
    );
}

export default BsBody;
