import React from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import BsPanel from "components/panel/BsPanel";
import PagePermission from "components/permission/PagePermission";
import UserReportedProblemsTab from "./tabs/reported-problems/UserReportedProblemsTab";
import UserRentalsTab from "./tabs/rentals/UserRentalsTab";
import UserInfoTab from "./tabs/info/UserInfoTab";
import {ADMIN} from "core/utils/permissions";

function UserInfo(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
  const {t} = useTranslation();

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  let tabs = null;
  if (props.profile && props.system && props.system.role) {
    tabs = (
        <Tabs value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary">
          <Tab label={t('user_info.info_tab')} value="info"/>
          {props.system.role.indexOf(ADMIN) !== -1 ?
              <Tab label={t('user_info.rentals_tab')} value="rentals"/> : null}
          <Tab label={t('user_info.reported_problems_tab')} value="reported-problems"/>
        </Tabs>
    )
  }

  return (
      <>
        <PagePermission anonymous={false} permission={ADMIN}/>
        <BsPanel>
          {tabs}
          <Route exact path="/system/:systemId/user/:userId" component={UserInfoTab}/>
          <Route path="/system/:systemId/user/:userId/info" component={UserInfoTab}/>
          <Route path="/system/:systemId/user/:userId/rentals" component={UserRentalsTab}/>
          <Route path="/system/:systemId/user/:userId/reported-problems" component={UserReportedProblemsTab}/>
        </BsPanel>
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    system: state.system.currentSystem
  };
};

export default connect(mapStateToProps)(UserInfo);
