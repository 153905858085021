import React from "react";
import {connect} from "react-redux";
import queryString from "query-string";
import BsDataTable from "components/data-table/BsDataTable";
import {
  fetchSubscriptionTransactions,
  paySystemSubscriptionInvoice
} from "core/actions/subscription-transaction-actions";
import SUBSCRIPTION_TRANSACTIONS_TABLE from "./subscription-transactions-table";
import PaySubscriptionInvoiceModel from "./PaySubscriptionInvoiceModel";
import FeedIcon from 'assets/img/feed.svg'
import InfoModel from "components/model/InfoModel";

class SubscriptionSystemTransactionTable extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      openPaymentModel: false,
      infoModel: {
        open: false,
        title: '',
        message: ''
      }
    };
    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.onLoadData(params);
  }

  onLoadData = (query) => {
    this.props.fetchSubscriptionTransactions(this.props.systemId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    return query
  }

  onRowClick = (row) => {
  }

  openInfoDialog(row) {
    this.setState({
      ...this.state,
      infoModel: {
        open: true,
        title: 'Invoice #' + row.invoiceNumber,
        message: row.additionalInfo
      }
    });
  }

  closeInfoModel() {
    this.setState({
      ...this.state,
      infoModel: {
        open: false
      }
    });
  }

  openPaymentDialog(row) {
    this.setState({
      ...this.state,
      openPaymentModel: true,
      invoice: row
    });
  }

  submitPaymentModel(fields) {
    this.props.paySystemSubscriptionInvoice(this.state.invoice.systemId, this.state.invoice.id, fields, () => {
      this.props.fetchSubscriptionTransactions(this.props.systemId, this.prepareQuery({}));
      this.props.onRefresh();
    });
    this.closePaymentModel();
  }

  closePaymentModel() {
    this.setState({
      ...this.state,
      openPaymentModel: false
    });
  }

  render() {
    if (!!this.props.transactions.content) {
      this.props.transactions.content.forEach(tx => {
        if (tx.status === 'DUE' || tx.status === 'ERROR') {
          tx.action = <span className="tx-action" onClick={() => this.openPaymentDialog(tx)}>
            Pay
          </span>
        } else if (tx.additionalInfo) {
          tx.action = <FeedIcon className="tx-add-info" onClick={() => this.openInfoDialog(tx)} />
        }
      });
    }

    return <>
      <PaySubscriptionInvoiceModel
          open={this.state.openPaymentModel}
          handleClose={this.closePaymentModel.bind(this)}
          handleSubmit={this.submitPaymentModel.bind(this)}/>

      <InfoModel
          open={this.state.infoModel.open}
          title={this.state.infoModel.title}
          message={this.state.infoModel.message}
          handleClose={this.closeInfoModel.bind(this)}/>

      <BsDataTable location={this.props.location}
                   history={this.props.history}
                   order={this.state.direction}
                   orderBy={this.state.sort}
                   settings={SUBSCRIPTION_TRANSACTIONS_TABLE}
                   content={this.props.transactions}
                   onChangePage={this.onLoadData}
                   onChangeSize={this.onLoadData}
                   onRequestSort={this.onRequestSort}/>
    </>
  }
}

const mapStateToProps = (state) => {
  return {
    transactions: state.subscriptionTransaction.transactions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubscriptionTransactions: (systemId, query) => {
      dispatch(fetchSubscriptionTransactions(systemId, query));
    },
    paySystemSubscriptionInvoice: (systemId, invoiceId, data, callback) => {
      dispatch(paySystemSubscriptionInvoice(systemId, invoiceId, data, callback));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSystemTransactionTable);
