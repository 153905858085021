import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import BsFormPanel from "components/form/BsFormPanel";
import ConfirmationModel from "components/model/ConfirmationModel";
import {MANAGER} from "core/utils/permissions";
import SUBSCRIPTION_PLAN_INFO_FORM from "./subscription-plan-info-form";
import SubscriptionPlanInfoActions from "./SubscriptionPlanInfoActions";
import {fetchSubscriptionPlan, removeSubscriptionPlan} from "core/actions/subscription-plan-actions";

import './subscription-plan-info.scss';

class SubscriptionPlanInfo extends React.Component {

  constructor(props) {
    super(props);
    this.backUrl = '/global/subscriptions/plans'
    this.state = {
      openRemovePlanConfirmationWindow: false
    };

    this.props.fetchSubscriptionPlan(this.props.match.params.planId);
  }

  onHandleEdit() {
    this.props.history.push('/global/subscription-plan/' + this.props.match.params.planId + '/edit')
  }

  onHandleRemove() {
    this.setState({
      ...this.state,
      openRemovePlanConfirmationWindow: true
    })
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      openRemovePlanConfirmationWindow: false
    })
  }

  onSubmitRemovePlan() {
    this.closeConfirmationModel();
    this.props.removeSubscriptionPlan(this.props.match.params.planId, this.backUrl)
  }

  render() {
    if (this.props.currentPlan) {
      const formConfig = {
        ...SUBSCRIPTION_PLAN_INFO_FORM,
        title: {
          back: {
            url: this.backUrl,
            history: this.props.history,
            location: this.props.location
          },
          text: this.props.currentPlan.name
        }
      };

      if (this.props.currentPlan.type !== 'INITIATED') {
        formConfig.title.actions = <SubscriptionPlanInfoActions
            plan={this.props.currentPlan}
            onHandleEdit={this.onHandleEdit.bind(this)}
            onHandleRemove={this.onHandleRemove.bind(this)}/>
      }

      return (
          <div className="subscription-plan-info">
            <BsFormPanel size="m">
              <PagePermission anonymous={false} permission={MANAGER}/>
              <ConfirmationModel
                  open={this.state.openRemovePlanConfirmationWindow}
                  message="subscription_plan_info.remove_subscription_plan_confirmation_message"
                  handleClose={this.closeConfirmationModel.bind(this)}
                  handleApply={this.onSubmitRemovePlan.bind(this)}
              />

              <BsForm
                  config={formConfig}
                  formData={this.props.currentPlan}
                  disabled={true}/>
            </BsFormPanel>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentPlan: state.subscriptionPlan.currentPlan
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubscriptionPlan: (bicycleId) => {
      dispatch(fetchSubscriptionPlan(bicycleId));
    },
    removeSubscriptionPlan: (planId, redirectPath) => {
      dispatch(removeSubscriptionPlan(planId, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlanInfo);
