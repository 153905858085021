import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import BsFormPanel from "components/form/BsFormPanel";
import ConfirmationModel from "components/model/ConfirmationModel";
import {deleteLabelSystem, loadLabelSystem} from "core/actions/label-actions";
import LabelSystemInfoActions from "./LabelSystemInfoActions";
import LABEL_SYSTEM_INFO_FORM from "./label-system-form";

class LabelSystemInfo extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      backUrl: '/global/label/' + this.props.match.params.labelId + '/systems',
      openConfirmationWindow: false
    };
    this.props.loadLabelSystem(this.props.match.params.labelId, this.props.match.params.systemId);
  }

  onHandleDelete() {
    this.setState({openConfirmationWindow: true})
  }

  onDelete() {
    this.props.deleteLabelSystem(this.props.match.params.labelId, this.props.match.params.systemId);
    this.setState({openConfirmationWindow: false})
  }

  render() {
    if (this.props.currentSystem) {
      const formConfig = {
        ...LABEL_SYSTEM_INFO_FORM
      };
      formConfig.title = {
        text: 'label_system_info.title',
        actions: <LabelSystemInfoActions onHandleDelete={this.onHandleDelete.bind(this)}/>,
        back: {
          url: this.state.backUrl,
          history: this.props.history,
          location: this.props.location
        }
      }

      return (
          <BsFormPanel size="s">
            <ConfirmationModel
                open={this.state.openConfirmationWindow}
                message="label_system_info.confirmation_message"
                handleClose={() => this.setState({openConfirmationWindow: false})}
                handleApply={this.onDelete.bind(this)}
            />

            <BsForm
                config={formConfig}
                formData={this.props.currentSystem}
                disabled={true}/>
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentSystem: state.label.currentSystem
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadLabelSystem: (labelId, systemId) => {
      dispatch(loadLabelSystem(labelId, systemId));
    },
    deleteLabelSystem: (labelId, systemId) => {
      dispatch(deleteLabelSystem(labelId, systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelSystemInfo);
