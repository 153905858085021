import React from 'react';

import './BsPanelHeader.scss';

function BsPanelHeader(props) {
    return (
        <div className="bs-header">
            {props.children}
        </div>
    );
}

export default BsPanelHeader;
