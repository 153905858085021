const queryString = require('query-string');

export function loadMaintenanceIssue(systemId, query) {
    return {
        type: "FETCH_MAINTENANCE_ISSUES",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/maintenance?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function fetchMaintenanceIssue(systemId, issueId) {
    return {
        type: "FETCH_MAINTENANCE_ISSUE",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/maintenance/${issueId}`
            },
            globalLoading: true
        }
    };
}

export function closeIssue(systemId, issueId) {
    return {
        type: "UPDATE_MAINTENANCE_ISSUE_STATUS",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/maintenance/${issueId}/close`
            },
            globalLoading: true,
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.close_maintenance_issue_success_message'
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.close_maintenance_issue_error_message'
                }
            }
        }
    };
}

export function startProgressIssue(systemId, issueId) {
    return {
        type: "UPDATE_MAINTENANCE_ISSUE_STATUS",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/maintenance/${issueId}/start`
            },
            globalLoading: true,
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.change_maintenance_issue_success_message'
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.change_maintenance_issue_error_message'
                }
            }
        }
    };
}

export function doneIssue(systemId, issueId) {
    return {
        type: "UPDATE_MAINTENANCE_ISSUE_STATUS",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/maintenance/${issueId}/finish`
            },
            globalLoading: true,
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.finish_maintenance_issue_success_message'
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.finish_maintenance_issue_error_message'
                }
            }
        }
    };
}

export function assignIssue(systemId, issueId, request) {
    return {
        type: "UPDATE_MAINTENANCE_ISSUE_STATUS",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/maintenance/${issueId}/assign`,
                data: request
            },
            globalLoading: true,
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.assign_maintenance_issue_success_message'
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.assign_maintenance_issue_error_message'
                }
            }
        }
    };
}

export function assignIssueToMe(systemId, issueId) {
    return {
        type: "UPDATE_MAINTENANCE_ISSUE_STATUS",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/maintenance/${issueId}/assign/me`
            },
            globalLoading: true,
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.assign_maintenance_issue_success_message'
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.assign_maintenance_issue_error_message'
                }
            }
        }
    };
}

export function changeIssuePriority(systemId, issueId, priority) {
    return {
        type: "UPDATE_MAINTENANCE_ISSUE_PRIORITY",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/maintenance/${issueId}/priority`,
                data: {priority: priority}
            },
            globalLoading: true,
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.change_maintenance_issue_success_message'
                }
            },
            errorAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'error',
                    message: 'notifications.change_maintenance_issue_error_message'
                }
            }
        }
    };
}

export function exportMaintenanceToCsv(systemId, query) {
    return {
        type: "EXPORT_MAINTENANCE_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/maintenance/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}

export function loadAllMaintenanceIssue(query) {
    return {
        type: "FETCH_ALL_MAINTENANCE_ISSUES",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/maintenance?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function fetchMaintenanceIssueInfo(issueId) {
    return {
        type: "FETCH_MAINTENANCE_ISSUE_INFO",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/maintenance/${issueId}`
            },
            globalLoading: true
        }
    };
}

export function exportAllMaintenanceToCsv(query) {
    return {
        type: "EXPORT_ALL_MAINTENANCE_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/maintenance/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}

export function loadLabelMaintenanceIssue(labelId, query) {
    return {
        type: "FETCH_LABEL_MAINTENANCE_ISSUES",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/labels/${labelId}/maintenance?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function fetchLabelMaintenanceIssueInfo(labelId, issueId) {
    return {
        type: "FETCH_LABEL_MAINTENANCE_ISSUE_INFO",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/labels/${labelId}/maintenance/${issueId}`
            },
            globalLoading: true
        }
    };
}

export function exportLabelMaintenanceToCsv(labelId, query) {
    return {
        type: "EXPORT_LABEL_MAINTENANCE_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/labels/${labelId}/maintenance/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}
