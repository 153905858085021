import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"

import './LoadingIndicator.scss'
import PropTypes from "prop-types";

const LoadingIndicator = (props) => {
    if (props.show) {
        return (
            <div className="loading-indicator">
                <div className="loading-indicator__progress">
                    <CircularProgress/>
                </div>
            </div>
        );
    } else {
        return null
    }
}

LoadingIndicator.propTypes = {
    show: PropTypes.bool.isRequired
};

export default LoadingIndicator