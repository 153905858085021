import React from "react";
import BsValidatorForm from "components/form/BsValidatorForm";
import BsFormTitle from "components/form/title/BsFormTitle";
import BsFormPanel from "components/form/BsFormPanel";
import {useTranslation} from "react-i18next";

import "./MaintenanceVisitErrorForm.scss";

const MaintenanceVisitErrorForm = (props) => {
  const {t} = useTranslation();

  const buildError = (error) => {
    return error
        .replace(/\t/g, '\u00a0\u00a0\u00a0\u00a0')
        .replace(/ /g, '\u00a0')
        .split('\n')
        .map(err => <div>{err}</div>);
  }

  if (props.visit) {
    const visit = props.visit;
    const body = visit.errors ? buildError(visit.errors) : t('maintenance_plan_info.no_errors')

    return (
        <BsFormPanel size="l">
          <BsValidatorForm onHandleSubmit={() => {
          }}>
            <BsFormTitle title={{
              text: visit.service.name + ' #' + visit.number,
              back: {
                url: props.backUrl,
                history: props.history,
                location: props.location
              }
            }}/>

            <div className="maintenance-errors">{body}</div>
          </BsValidatorForm>
        </BsFormPanel>
    )
  } else {
    return null;
  }
}

export default MaintenanceVisitErrorForm;
