import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import {useTranslation} from "react-i18next";

const UserRentalsFilters = (props) => {
    const {t} = useTranslation();
    const bikeRef = React.createRef();

    return (
        <>
            <BsPanelHeaderFilterControl>
                <TextField label={t('rentals.bicycle_filter')}
                           ref={bikeRef}
                           onChange={props.onHandleChange.bind(this, "bikeNumber")}
                           value={props.fields["bikeNumber"]}
                           className="form-control"/>
            </BsPanelHeaderFilterControl>
        </>
    )
}

export default UserRentalsFilters;
