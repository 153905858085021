import React from "react";
import {connect} from "react-redux";
import BsDataTable from "components/data-table/BsDataTable";
import BsTabPanel from "components/panel/BsTabPanel";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {TABLE_PASSWORD_USAGE} from "./password-usage-table";
import PasswordUsageFilters from "./PasswordUsageFilters";
import {loadSystemPasswordUsage} from "core/actions/password-actions";

const queryString = require('query-string');

class PasswordUsageTab extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        name: query.name != null ? query.name : '',
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.name = this.state.fields.name;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.name;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        name: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadSystemPasswordUsage(this.props.match.params.systemId, this.props.match.params.passwordId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.name) {
      query['client.firstName'] = query.name
      query['client.lastName'] = query.name
    }

    return query
  }

  render() {
    return (
        <BsTabPanel>
          <PagePermission anonymous={false} permission={ADMIN}/>
          <BsPanelLoader show={this.props.loading}/>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <PasswordUsageFilters fields={this.state.fields}
                                  onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={TABLE_PASSWORD_USAGE}
                       content={this.props.history}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsTabPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    history: state.systemPassword.usageHistory,
    loading: state.systemPassword.loadingUsageHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSystemPasswordUsage: (systemId, passwordId, query) => {
      dispatch(loadSystemPasswordUsage(systemId, passwordId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordUsageTab);
