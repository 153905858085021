import React from "react";
import {connect} from "react-redux";
import BsDataTable from "components/data-table/BsDataTable";
import BsTabPanel from "components/panel/BsTabPanel";
import BsPanelHeaderActions from "components/panel/header/BsPanelHeaderActions";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import {exportLabelRentalsToCsv, loadLabelRentals} from "core/actions/rental-actions";
import {LABEL_GENERAL_BICYCLE_RENTALS_TABLE} from "./label-general-bicycle-rentals-table";
import LabelGeneralBicycleRentalsFilters from "./LabelGeneralBicycleRentalsFilters";
import {Trans} from "react-i18next";
import Button from "@material-ui/core/Button";

const queryString = require('query-string');

class LabelGeneralBicycleRentalsTab extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        name: query.name != null ? query.name : ''
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.name = this.state.fields.name;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.name;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        name: '',
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadLabelRentals(this.props.match.params.labelId, this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/label/' + this.props.match.params.labelId +
        '/bicycle-info/' + this.props.match.params.bicycleId + '/rental/' + row.id + '/info')
  }

  export = () => {
    const query = queryString.parse(this.props.location.search);
    this.props.exportLabelRentalsToCsv(this.props.match.params.labelId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.name) {
      query['client.firstName'] = query.name;
      query['client.lastName'] = query.name;
    }
    query['bike.id'] = this.props.match.params.bicycleId;

    return query
  }

  render() {
    return (
        <BsTabPanel>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelHeaderActions>
              <Button variant="outlined" type="button" onClick={this.export}>
                <Trans i18nKey="general.export"/>
              </Button>
            </BsPanelHeaderActions>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <LabelGeneralBicycleRentalsFilters fields={this.state.fields}
                                               onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={LABEL_GENERAL_BICYCLE_RENTALS_TABLE}
                       content={this.props.rentals}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsTabPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rentals: state.rental.labelRentals,
    loading: state.rental.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadLabelRentals: (labelId, query) => {
      dispatch(loadLabelRentals(labelId, query));
    },
    exportLabelRentalsToCsv: (labelId, query) => {
      dispatch(exportLabelRentalsToCsv(labelId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelGeneralBicycleRentalsTab);
