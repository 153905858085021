import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import BsFormPanel from "components/form/BsFormPanel";
import ConfirmationModel from "components/model/ConfirmationModel";
import {MANAGER} from "core/utils/permissions";
import MaintenanceBikeReportImages from "./MaintenanceBikeReportImages";
import {changeBicycleReportStatus, createIssueByReport, fetchBikeReportInfo} from "core/actions/bike-report-actions";
import MAINTENANCE_BIKE_REPORT_INFO_FORM from "./maintenance-bike-report-info-form";
import MaintenanceBicycleReportActions from "./MaintenanceBicycleReportActions";

import './maintenance-bike-report-info-form.scss';

class MaintenanceBikeReportInfo extends React.Component {

  constructor(props) {
    super(props);
    this.backUrl = '/system/' + this.props.match.params.systemId + '/maintenance-reports'
    this.state = {
      openChangeStatusConfirmationWindow: false,
      openCreateIssueConfirmationWindow: false
    };

    this.props.fetchBikeReportInfo(this.props.match.params.reportId);
  }

  onHandleReportProblem() {
    this.setState({
      ...this.state,
      openCreateIssueConfirmationWindow: true
    })
  }

  closeReportProblemModel() {
    this.setState({
      ...this.state,
      openCreateIssueConfirmationWindow: false
    })
  }

  onSubmitReportProblem() {
    this.closeReportProblemModel();
    this.props.createIssueByReport(this.props.match.params.systemId,
        this.props.match.params.reportId)
  }

  onHandleChangeStatus() {
    this.setState({
      ...this.state,
      openChangeStatusConfirmationWindow: true
    })
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      openChangeStatusConfirmationWindow: false
    })
  }

  onSubmitChangeStatus() {
    this.closeConfirmationModel();
    this.props.changeBicycleReportStatus(this.props.match.params.systemId,
        this.props.match.params.reportId, {status: "COMPLETED"})
  }

  render() {
    if (this.props.currentReport) {
      const formConfig = {
        ...MAINTENANCE_BIKE_REPORT_INFO_FORM
      };
      formConfig.title = {
        back: {
          url: this.backUrl,
          history: this.props.history,
          location: this.props.location
        },
        text: 'bicycle_report_info.title',
        actions: <MaintenanceBicycleReportActions
            report={this.props.currentReport}
            onHandleReportProblem={this.onHandleReportProblem.bind(this)}
            onHandleChangeStatus={this.onHandleChangeStatus.bind(this)}/>
      }
      return (
          <BsFormPanel size="m">
            <PagePermission anonymous={false} permission={MANAGER}/>
            <ConfirmationModel
                open={this.state.openChangeStatusConfirmationWindow}
                message="bicycle_report_info.complete_report_confirmation_message"
                handleClose={this.closeConfirmationModel.bind(this)}
                handleApply={this.onSubmitChangeStatus.bind(this)}
            />

            <ConfirmationModel
                open={this.state.openCreateIssueConfirmationWindow}
                message="bicycle_report_info.create_issue_confirmation_message"
                handleClose={this.closeReportProblemModel.bind(this)}
                handleApply={this.onSubmitReportProblem.bind(this)}
            />

            <BsForm
                config={formConfig}
                formData={this.props.currentReport}
                disabled={true}/>

            <MaintenanceBikeReportImages images={this.props.currentReport.maintenanceImages}
                                         bike={this.props.currentReport.bike.number}
                                         desc={this.props.currentReport.maintenanceComment}/>
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentReport: state.bikeReports.currentReport
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBikeReportInfo: (reportId) => {
      dispatch(fetchBikeReportInfo(reportId));
    },
    changeBicycleReportStatus: (systemId, reportId, data) => {
      dispatch(changeBicycleReportStatus(systemId, reportId, data));
    },
    createIssueByReport: (systemId, reportId) => {
      dispatch(createIssueByReport(systemId, reportId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceBikeReportInfo);
