export const appReducer = (state = {
    loading: false,
    notifications: [],
    redirectPath: null,
    appVersion: '',
    needToLoadAppVersion: true
}, action) => {
    switch (action.type) {
        case 'START_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'STOP_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'SHOW_NOTIFICATION':
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        variant: action.payload.variant,
                        message: action.payload.message
                    }
                ]
            }
        case 'CLEAR_NOTIFICATIONS':
            return {
                ...state,
                notifications: []
            }
        case 'REDIRECT_TO':
            return {
                ...state,
                redirectPath: action.payload
            }
        case 'CLEAR_REDIRECT':
            return {
                ...state,
                redirectPath: null
            }
        case 'FETCH_VERSION':
        case 'FETCH_VERSION_FAIL':
            return {
                ...state,
                needToLoadAppVersion: false
            }
        case 'FETCH_VERSION_SUCCESS':
            return {
                ...state,
                appVersion: action.payload.data.version,
                needToLoadAppVersion: false
            }
        default:
            return state
    }
}
