import React from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useTranslation} from "react-i18next";
import BsPanel from "components/panel/BsPanel";
import BackOfficeUserInfoTab from "./tabs/info/BackOfficeUserInfoTab";
import BackOfficeUserSystemsTab from "./tabs/systems/BackOfficeUserSystemsTab";

function BackOfficeUserInfo(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
  const {t} = useTranslation();

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  return (
      <BsPanel>
        <Tabs value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary">
          <Tab label={t('admin_info.info_tab')} value="info"/>
          <Tab label={t('admin_info.systems_tab')} value="systems"/>
        </Tabs>

        <Route exact path="/global/bo-user-info/:userId" component={BackOfficeUserInfoTab}/>
        <Route path="/global/bo-user-info/:userId/info" component={BackOfficeUserInfoTab}/>
        <Route path="/global/bo-user-info/:userId/systems" component={BackOfficeUserSystemsTab}/>
      </BsPanel>
  );
}

export default BackOfficeUserInfo;
