import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Permitted from "components/permission/Permitted";
import DashboardIcon from 'assets/img/dashboard.svg'
import RentalsIcon from 'assets/img/rentals.svg'
import UsersIcon from 'assets/img/users.svg'
import BicyclesIcon from 'assets/img/bicycles.svg'
import LocationsIcon from 'assets/img/locations.svg'
import CustomerSupportIcon from 'assets/img/customer-support.svg'
import SettingsIcon from 'assets/img/settings.svg'
import BuildCircleIcon from 'assets/img/build_circle.svg'
import SummarizeIcon from 'assets/img/summarize.svg'
import ResourcesIcon from 'assets/img/resources.svg'
import PackageIcon from 'assets/img/package.svg'
import LabelsIcon from 'assets/img/labels.svg'
import MoneyIcon from 'assets/img/money.svg'
import MonetizationIcon from 'assets/img/monetization.svg'
import WifiIcon from 'assets/img/wifi.svg'
import {getLabelId, getSystemId} from "core/utils/pathUtils";
import {loadSelectedLabel} from "core/actions/label-actions";
import {MANAGER, ADMIN} from "core/utils/permissions";
import PropTypes from "prop-types";

import './SideMenu.scss';

const SideMenu = (props) => {
  const {t} = useTranslation();
  const currentPath = props.route.location.pathname;

  useEffect(() => {
    if (props.needToLoadSelectedLabel) {
      const labelId = getLabelId() ? getLabelId() : props.labelId;
      if (labelId) {
        props.loadSelectedLabel(labelId);
      }
    }
  });

  const goToSystem = (path) => {
    if (props.route.location.pathname.indexOf('/system') === 0 || props.systemId) {
      const newUrl = getNewSystemPath(path);
      if (currentPath !== newUrl) {
        props.route.history.push(newUrl)
      }
    }
  }

  const getNewSystemPath = (path) => {
    if (getSystemId()) {
      return '/system/' + getSystemId() + path;
    } else {
      return '/system/' + props.systemId + path;
    }
  }

  const goToLabel = (path) => {
    if (props.route.location.pathname.indexOf('/label') === 0 || props.labelId) {
      const newUrl = getNewLabelPath(path);
      if (currentPath !== newUrl) {
        props.route.history.push(newUrl)
      }
    }
  }

  const getNewLabelPath = (path) => {
    if (getLabelId()) {
      return '/label/' + getLabelId() + path;
    } else {
      return '/label/' + props.labelId + path;
    }
  }

  const goToGlobal = (path) => {
    const newUrl = '/global/' + path;
    props.route.history.push(newUrl)
  }

  if (props.type === 'system') {
    return (
        <div className="side-menu">
          <List>
            <ListItem button
                      onClick={() => goToSystem('/dashboard')}
                      className={currentPath.indexOf('/dashboard') > -1 ? 'active' : ''}>
              <ListItemIcon><DashboardIcon/></ListItemIcon>
              <ListItemText primary={t('menu.dashboard')}/>
            </ListItem>
            <Permitted for={ADMIN}>
              <ListItem button
                        onClick={() => goToSystem('/rentals')}
                        className={currentPath.indexOf('/rentals') > -1 ? 'active' : ''}>
                <ListItemIcon><RentalsIcon/></ListItemIcon>
                <ListItemText primary={t('menu.rentals')}/>
              </ListItem>
            </Permitted>
            <Permitted for={ADMIN}>
              <ListItem button
                        onClick={() => goToSystem('/users')}
                        className={currentPath.indexOf('/users') > -1 ? 'active' : ''}>
                <ListItemIcon><UsersIcon/></ListItemIcon>
                <ListItemText primary={t('menu.users')}/>
              </ListItem>
            </Permitted>
            <Permitted for={MANAGER}>
              <ListItem button
                        onClick={() => goToSystem('/bicycles')}
                        className={currentPath.indexOf('/bicycles') > -1 ? 'active' : ''}>
                <ListItemIcon><BicyclesIcon/></ListItemIcon>
                <ListItemText primary={t('menu.bicycles')}/>
              </ListItem>
            </Permitted>
            <Permitted for={ADMIN}>
              <ListItem button
                        onClick={() => goToSystem('/locations')}
                        className={currentPath.indexOf('/locations') > -1 ? 'active' : ''}>
                <ListItemIcon><LocationsIcon/></ListItemIcon>
                <ListItemText primary={t('menu.locations')}/>
              </ListItem>
            </Permitted>
            <Permitted for={MANAGER}>
              <ListItem button
                        onClick={() => goToSystem('/customer-support')}
                        className={currentPath.indexOf('/customer-support') > -1 ? 'active' : ''}>
                <ListItemIcon><CustomerSupportIcon/></ListItemIcon>
                <ListItemText primary={t('menu.customer_support')}/>
              </ListItem>
            </Permitted>
            <Permitted for={MANAGER}>
              <ListItem button
                        onClick={() => goToSystem('/maintenance-reports')}
                        className={currentPath.indexOf('/maintenance-reports') > -1 ? 'active' : ''}>
                <ListItemIcon><SummarizeIcon/></ListItemIcon>
                <ListItemText primary={t('menu.maintenance_reports')}/>
              </ListItem>
            </Permitted>
            {props.showRentalRates ? <Permitted for={ADMIN}>
              <ListItem button
                        onClick={() => goToSystem('/rental-rates')}
                        className={currentPath.indexOf('/rental-rates') > -1 ? 'active' : ''}>
                <ListItemIcon><MonetizationIcon/></ListItemIcon>
                <ListItemText primary={t('menu.rental_rates')}/>
              </ListItem>
            </Permitted> : null}
            <Permitted for={ADMIN}>
              <ListItem button
                        onClick={() => goToSystem('/settings')}
                        className={currentPath.indexOf('/settings') > -1 ? 'active' : ''}>
                <ListItemIcon><SettingsIcon/></ListItemIcon>
                <ListItemText primary={t('menu.settings')}/>
              </ListItem>
            </Permitted>

            <hr/>

            <ListItem button component="a" href="https://onbikeshare.com/customer-resources/" target="_blank">
              <ListItemIcon><ResourcesIcon/></ListItemIcon>
              <ListItemText primary={t('menu.resources')}/>
            </ListItem>

            <ListItem button component="a" href="https://www.onbikeshare.com/store/" target="_blank">
              <ListItemIcon><PackageIcon/></ListItemIcon>
              <ListItemText primary={t('menu.parts_order')}/>
            </ListItem>
          </List>
        </div>
    );
  } else if (props.type === 'global') {
    return (
        <div className="side-menu">
          <List>
            <ListItem button
                      onClick={() => goToGlobal('statistics')}
                      className={currentPath.indexOf('/statistics') > -1 ? 'active' : ''}>
              <ListItemIcon><DashboardIcon/></ListItemIcon>
              <ListItemText primary={t('menu.statistics')}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToGlobal('bo-users')}
                      className={currentPath.indexOf('/global/bo-users') > -1 ? 'active' : ''}>
              <ListItemIcon><UsersIcon/></ListItemIcon>
              <ListItemText primary={t('menu.all_admins')}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToGlobal('rentals')}
                      className={currentPath.indexOf('/global/rental') > -1 ? 'active' : ''}>
              <ListItemIcon><RentalsIcon/></ListItemIcon>
              <ListItemText primary={t('menu.all_rentals')}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToGlobal('users')}
                      className={currentPath.indexOf('/global/user') > -1 ? 'active' : ''}>
              <ListItemIcon><UsersIcon/></ListItemIcon>
              <ListItemText primary={t('menu.all_users')}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToGlobal('bicycles')}
                      className={currentPath.indexOf('/global/bicycle') > -1 ? 'active' : ''}>
              <ListItemIcon><BicyclesIcon/></ListItemIcon>
              <ListItemText primary={t('menu.all_bicycles')}/>
            </ListItem>
            {
              props.showForVt ?
                  <ListItem button
                            onClick={() => goToGlobal('scan-bicycles-history')}
                            className={currentPath.indexOf('/global/scan-bicycles-history') > -1 ? 'active' : ''}>
                    <ListItemIcon><WifiIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.bicycle_scan_history')}/>
                  </ListItem>
                  : null
            }
            <ListItem button
                      onClick={() => goToGlobal('reported-problems')}
                      className={currentPath.indexOf('/global/reported-problem') > -1 ? 'active' : ''}>
              <ListItemIcon><CustomerSupportIcon/></ListItemIcon>
              <ListItemText primary={t('menu.all_customer_support')}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToGlobal('maintenance-reports')}
                      className={currentPath.indexOf('/global/maintenance-reports') > -1 ? 'active' : ''}>
              <ListItemIcon><SummarizeIcon/></ListItemIcon>
              <ListItemText primary={t('menu.all_maintenance_reports')}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToGlobal('maintenance/plans')}
                      className={currentPath.indexOf('/global/maintenance-plan') > -1 ? 'active' : ''}>
              <ListItemIcon><BuildCircleIcon/></ListItemIcon>
              <ListItemText primary={t('menu.maintenance_plans')}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToGlobal('labels')}
                      className={currentPath.indexOf('/global/labels') > -1 ? 'active' : ''}>
              <ListItemIcon><LabelsIcon/></ListItemIcon>
              <ListItemText primary={t('menu.labels')}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToGlobal('subscriptions')}
                      className={currentPath.indexOf('/global/subscriptions') > -1 ? 'active' : ''}>
              <ListItemIcon><MoneyIcon/></ListItemIcon>
              <ListItemText primary={t('menu.subscriptions')}/>
            </ListItem>
          </List>
        </div>
    )
  } else if (props.type === 'label') {
    return (
        <div className="side-menu">
          <List>
            <ListItem button
                      onClick={() => goToLabel('/statistics')}
                      className={currentPath.indexOf('/statistics') > -1 ? 'active' : ''}>
              <ListItemIcon><DashboardIcon/></ListItemIcon>
              <ListItemText primary={t('menu.label_statistics', {label: props.selectedLabel.label})}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToLabel('/rentals')}
                      className={currentPath.indexOf('/rental') > -1 ? 'active' : ''}>
              <ListItemIcon><RentalsIcon/></ListItemIcon>
              <ListItemText primary={t('menu.label_rentals', {label: props.selectedLabel.label})}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToLabel('/users')}
                      className={currentPath.indexOf('/user') > -1 ? 'active' : ''}>
              <ListItemIcon><UsersIcon/></ListItemIcon>
              <ListItemText primary={t('menu.label_users', {label: props.selectedLabel.label})}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToLabel('/bicycles')}
                      className={currentPath.indexOf('/bicycle') > -1 ? 'active' : ''}>
              <ListItemIcon><BicyclesIcon/></ListItemIcon>
              <ListItemText primary={t('menu.label_bicycles', {label: props.selectedLabel.label})}/>
            </ListItem>
            <ListItem button
                      onClick={() => goToLabel('/reported-problems')}
                      className={currentPath.indexOf('/reported-problem') > -1 ? 'active' : ''}>
              <ListItemIcon><CustomerSupportIcon/></ListItemIcon>
              <ListItemText primary={t('menu.label_customer_support', {label: props.selectedLabel.label})}/>
            </ListItem>
          </List>
        </div>
    )
  }

  return null
}

const mapStateToProps = (state) => {
  return {
    systemId: state.system.systemId,
    showRentalRates: state.system.currentSystem && state.system.currentSystem.paid,
    needToLoadSelectedLabel: !state.label.selectedLabelWasLoaded && state.security.isLoggedIn && !state.label.selectedLabelIsLoading,
    selectedLabel: state.label.selectedLabel,
    showForVt: state.profile.profile && state.profile.profile.role === 'VT_SUPER_ADMIN'
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSelectedLabel: (labelId) => {
      dispatch(loadSelectedLabel(labelId));
    }
  };
};

SideMenu.propTypes = {
  route: PropTypes.object.isRequired,
  type: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
