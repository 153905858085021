import React from 'react';

import './BsPanelTitle.scss';
import PropTypes from "prop-types";
import Row from "../../layout/Row";
import Col from "../../layout/Col";

function BsPanelTitle(props) {
    return (
        <Row>
            <Col size="8">
                <h1 className="bs-header__title">
                    {props.children}
                </h1>
            </Col>
            <Col size="4">
                <div className="bs-header__title-action">
                    {props.action}
                </div>
            </Col>
        </Row>
    );
}

BsPanelTitle.propTypes = {
    action: PropTypes.any
};

export default BsPanelTitle;
