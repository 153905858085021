import {saveFile} from "../utils/export";

export const clientUserReducer = (state = {
    users: {},
    allUsers: {},
    labelUsers: {},
    currentUser: null,
    loading: false
}, action) => {
    switch (action.type) {
        case 'FETCH_CLIENT_USERS':
        case 'FETCH_ALL_CLIENT_USERS':
        case 'FETCH_LABEL_CLIENT_USERS':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_CLIENT_USERS_SUCCESS':
            return {
                ...state,
                loading: false,
                users: action.payload.data
            }
        case 'FETCH_ALL_CLIENT_USERS_SUCCESS':
            return {
                ...state,
                loading: false,
                allUsers: action.payload.data
            }
        case 'FETCH_LABEL_CLIENT_USERS_SUCCESS':
            return {
                ...state,
                loading: false,
                labelUsers: action.payload.data
            }
        case 'FETCH_CLIENT_USERS_FAIL':
        case 'FETCH_ALL_CLIENT_USERS_FAIL':
        case 'FETCH_LABEL_CLIENT_USERS_FAIL':
            return {
                ...state,
                loading: false
            }
        case 'FETCH_CLIENT_USER':
        case 'FETCH_CLIENT_USER_FAIL':
        case 'FETCH_CLIENT_USER_INFO':
        case 'FETCH_CLIENT_USER_INFO_FAIL':
        case 'FETCH_LABEL_CLIENT_USER_INFO':
        case 'FETCH_LABEL_CLIENT_USER_INFO_FAIL':
            return {
                ...state,
                currentUser: null
            }
        case 'FETCH_CLIENT_USER_SUCCESS':
        case 'FETCH_CLIENT_USER_INFO_SUCCESS':
        case 'FETCH_LABEL_CLIENT_USER_INFO_SUCCESS':
        case 'UPDATE_CLIENT_USER_EMAIL_SUCCESS':
        case 'UPDATE_CLIENT_USER_STATUS_SUCCESS':
            return {
                ...state,
                currentUser: action.payload.data
            }
        case 'EXPORT_CLIENT_USERS_TO_CSV_SUCCESS':
        case 'EXPORT_ALL_CLIENT_USERS_TO_CSV_SUCCESS':
        case 'EXPORT_LABEL_CLIENT_USERS_TO_CSV_SUCCESS': {
            saveFile(action.payload)
            return state
        }
        case 'UPDATE_CLIENT_USER_STATUS':
        case 'UPDATE_CLIENT_USER_STATUS_FAIL':
        case 'CREATE_CLIENT_USER':
        case 'CREATE_CLIENT_USER_SUCCESS':
        case 'CREATE_CLIENT_USER_FAIL':
        case 'UPDATE_CLIENT_USER_EMAIL':
        case 'UPDATE_CLIENT_USER_EMAIL_FAIL':
        case 'EXPORT_CLIENT_USERS_TO_CSV':
        case 'EXPORT_CLIENT_USERS_TO_CSV_FAIL':
        case 'EXPORT_ALL_CLIENT_USERS_TO_CSV':
        case 'EXPORT_ALL_CLIENT_USERS_TO_CSV_FAIL':
        case 'EXPORT_LABEL_CLIENT_USERS_TO_CSV':
        case 'EXPORT_LABEL_CLIENT_USERS_TO_CSV_FAIL':
        default:
            return state
    }
}
