import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {parsePhone} from "core/utils/phone";
import {ADMIN} from "core/utils/permissions";
import ADD_USER_FORM from "./add-user-form";
import {createClientUser} from "core/actions/client-user-actions";
import isPossiblePhoneNumber from "components/validation/phone";

class AddUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.backUrl = '/system/' + this.props.match.params.systemId + '/users';
    }

    componentDidMount() {
        BsForm.addValidationRule('isValidPhoneNumber', (value) => {
            return isPossiblePhoneNumber(value);
        });
    }

    componentWillUnmount() {
        BsForm.removeValidationRule('isValidPhoneNumber');
    }

    handleSubmit(fields, e) {
        const phone = parsePhone(fields.phone);
        this.props.createClientUser(this.props.match.params.systemId, {
            firstName: fields.firstName,
            lastName: fields.lastName,
            email: fields.email,
            phone: {
                countryCode: phone.countryCallingCode,
                number: phone.nationalNumber
            }
        });
    }

    render() {
        return (
            <BsFormPanel>
                <PagePermission anonymous={false} permission={ADMIN} />
                <BsForm
                    config={ADD_USER_FORM}
                    onHandleSubmit={this.handleSubmit.bind(this)}
                    cancelAction={
                        <BsFormCancelAction history={this.props.history}
                                            location={this.props.location}
                                            url={this.backUrl}/>
                    }/>
            </BsFormPanel>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        createClientUser: (systemId, data) => {
            dispatch(createClientUser(systemId, data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
