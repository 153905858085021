import {priceToString} from "../../../../../core/utils/priceUtils";

const SUBSCRIPTION_SYSTEMS_TABLE = {
  columns: [
    {
      label: 'subscription_systems.system_name_header',
      field: 'system.name',
      getValue: (raw) => {
        return raw.system.name;
      },
      ordering: false
    },
    {
      label: 'subscription_systems.status_header',
      field: 'status',
      ordering: true,
      translate: true,
      getValue(raw) {
        return 'system_subscription_status.' + raw.status;
      },
      getStyle(raw) {
        const style = {
          display: 'inline-block',
          padding: '6px 8px 4px',
          borderRadius: '4px',
          color: '#808080',
          textTransform: 'uppercase',
          fontSize: '0.75rem',
          lineHeight: '0.75rem'
        };

        if (raw.status === 'ACTIVE') {
          style.background = '#CAF8D2'
        } else if (raw.status === 'CANCELLED') {
          style.background = '#FFE9E9'
        } else {
          style.background = '#FFFCB3'
        }

        return style;
      }
    },
    {
      label: 'subscription_systems.number_of_bikes_header',
      field: 'bikes',
      ordering: false,
      getValue(raw) {
        return raw.bikes;
      }
    },
    {
      label: 'subscription_systems.price_per_bike_header',
      field: 'pricePerBike',
      ordering: false,
      getValue(raw) {
        return priceToString(raw.pricePerBike, raw.currency);
      }
    },
    {
      label: 'subscription_systems.billing_period_header',
      field: 'billingPeriod',
      ordering: false,
      translate: true,
      getValue(raw) {
        return 'subscription_billing_period.' + raw.billingPeriod;
      }
    },
    {
      label: 'subscription_systems.payment_status_header',
      field: 'paymentStatus',
      ordering: false,
      translate: true,
      getValue(raw) {
        return 'subscription_payment_status.' + raw.paymentStatus;
      }
    },
  ]
}

export default SUBSCRIPTION_SYSTEMS_TABLE;
