import React from "react";
import {connect} from "react-redux";
import BsDataTable from "components/data-table/BsDataTable";
import BsTabPanel from "components/panel/BsTabPanel";
import BsPanelHeaderActions from "components/panel/header/BsPanelHeaderActions";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {TABLE_ACTIVE_RENTALS} from "./active-rentals-table";
import ActiveRentalsFilters from "./ActiveRentalsFilters";
import {exportRentalsToCsv, finishRental, loadRentals} from "core/actions/rental-actions";
import Button from "@material-ui/core/Button";
import {Trans} from "react-i18next";
import ConfirmationModel from "../../../../../../components/model/ConfirmationModel";

const queryString = require('query-string');

class ActiveRentals extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        name: query.name != null ? query.name : '',
        bikeNumber: query.bikeNumber != null ? query.bikeNumber : ''
      },
      confirmationWindow: {
        open: false,
        message: '',
        event: () => {
        }
      },
    };

    this.config = {
      ...TABLE_ACTIVE_RENTALS
    }
    for (let i = 0; i < this.config.columns.length; i++) {
      if (this.config.columns[i].field === 'finishRental') {
        this.config.columns[i].getValue = (raw) => {
          return <Button variant="contained" color="primary" type="button"
                         onClick={() => this.onFinishRental(raw)}>
            <Trans i18nKey="rental_info.finish"/>
          </Button>
        };
      }
    }

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.name = this.state.fields.name;
    params.bikeNumber = this.state.fields.bikeNumber;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.name;
    delete params.bikeNumber;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        name: '',
        bikeNumber: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadRentals(this.props.match.params.systemId, this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/rental/active/' + row.id + '/info')
  }

  export = () => {
    const query = queryString.parse(this.props.location.search);
    this.props.exportRentalsToCsv(this.props.match.params.systemId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.name) {
      query['client.firstName'] = query.name
      query['client.lastName'] = query.name
    }
    if (query.bikeNumber) {
      query['bike.number'] = query.bikeNumber
    }
    query['status'] = 'ACTIVE'

    return query
  }

  onFinishRental = (raw) => {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'rental_info.finish_rental_message',
        event: () => this.finishRental(raw)
      }
    })
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  finishRental = (raw) => {
    this.closeConfirmationModel();
    this.props.finishRental(this.props.match.params.systemId, raw.id, () => {
      const query = queryString.parse(this.props.location.search);
      this.onLoadData(query);
    })
  }

  render() {
    return (
      <BsTabPanel>
        <PagePermission anonymous={false} permission={ADMIN}/>

        <ConfirmationModel
          open={this.state.confirmationWindow.open}
          message={this.state.confirmationWindow.message}
          handleClose={this.closeConfirmationModel.bind(this)}
          handleApply={this.state.confirmationWindow.event.bind(this)}
        />

        <BsPanelLoader show={this.props.loading}/>
        <BsPanelHeader>
          <BsPanelHeaderActions>
            <Button variant="outlined" type="button" onClick={this.export}>
              <Trans i18nKey="general.export"/>
            </Button>
          </BsPanelHeaderActions>
        </BsPanelHeader>

        <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                              onHandleClear={this.onHandleClear.bind(this)}>
          <ActiveRentalsFilters fields={this.state.fields}
                                onHandleChange={this.handleChange.bind(this)}/>
        </BsPanelHeaderFilters>

        <BsDataTable location={this.props.location}
                     history={this.props.history}
                     order={this.state.direction}
                     orderBy={this.state.sort}
                     settings={this.config}
                     content={this.props.rentals}
                     onRowClick={this.onRowClick}
                     onChangePage={this.onLoadData}
                     onChangeSize={this.onLoadData}
                     onRequestSort={this.onRequestSort}/>
      </BsTabPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rentals: state.rental.rentals,
    loading: state.rental.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRentals: (systemId, query) => {
      dispatch(loadRentals(systemId, query));
    },
    exportRentalsToCsv: (systemId, query) => {
      dispatch(exportRentalsToCsv(systemId, query));
    },
    finishRental: (systemId, rentalId, callback) => {
      dispatch(finishRental(systemId, rentalId, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveRentals);
