export const LABEL_ADMINS_TABLE = {
  columns: [
    {
      label: 'label_admin_info.full_name_header',
      field: 'user.firstName,user.lastName',
      getValue: (raw) => {
        return raw.firstName + ' ' + raw.lastName
      },
      ordering: true
    },
    {
      label: 'label_admin_info.email_header',
      field: 'user.email',
      getValue: (raw) => {
        return raw.email
      },
      ordering: true
    }
  ]
}
