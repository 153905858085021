import {priceToString} from "../../../../../core/utils/priceUtils";

const buildRenalInfoForm = (rental) => {
    const form = {
        fields: [
            {
                name: 'client',
                label: 'rental_info.customer_field',
                type: 'reference-link',
                styles: {
                    marginTop: '1em'
                },
                getValue: (data) => {
                    return data.client.firstName + ' ' + data.client.lastName
                },
                link: (data) => {
                    if (data.membershipId) {
                        return '/system/' + data.system.id + '/user/' + data.membershipId
                    } else {
                        return "/"
                    }
                }
            },
            {
                name: 'bike',
                label: 'rental_info.bicycle_field',
                type: 'reference-link',
                getValue: (data) => {
                    return '#' + data.bike.number
                },
                link: (data) => {
                    if (data.bike) {
                        return '/system/' + data.system.id + '/bicycle/' + data.bike.id
                    } else {
                        return "/"
                    }
                }
            },
            {
                name: 'startLocation',
                label: 'rental_info.start_location_field',
                type: 'reference-link',
                getValue: (data) => {
                    return data.startLocation.label
                },
                link: (data) => {
                    if (data.startLocation) {
                        return '/system/' + data.system.id + '/location/' + data.startLocation.id
                    } else {
                        return "/"
                    }
                }
            },
            {
                name: 'endLocation',
                label: 'rental_info.end_location_field',
                type: 'reference-link',
                getValue: (data) => {
                    return data.endLocation ? data.endLocation.label : ''
                },
                link: (data) => {
                    if (data.endLocation) {
                        return '/system/' + data.system.id + '/location/' + data.endLocation.id
                    } else {
                        return "/"
                    }
                },
                showFn: (data) => {
                    return data.endLocation
                }
            },
            {
                name: 'startTime',
                label: 'rental_info.start_time_field',
                type: 'info',
                getValue: (data) => {
                    return data.startTime
                }
            },
            {
                name: 'endTime',
                label: 'rental_info.end_time_field',
                type: 'info',
                getValue: (data) => {
                    return data.endTime ? data.endTime : '-'
                }
            },
            {
                name: 'status',
                label: 'rental_info.status_field',
                type: 'info',
                translate: true,
                getValue: (data) => {
                    return 'rental_status.' + data.status
                }
            }
        ]
    }

    if (rental.rate) {
        form.fields.push({
            name: 'rate.name',
            label: 'rental_info.rate_name_field',
            type: 'info',
            translate: true,
            getValue: (data) => {
                return data.rate.name
            }
        });
        form.fields.push({
            name: 'rate.price',
            label: 'rental_info.rate_price_field',
            type: 'info',
            translate: true,
            getValue: (data) => {
                return priceToString(data.rate.price, data.rate.currency)
            }
        });
        form.fields.push({
            name: 'rate.rentalDuration',
            label: 'rental_info.rate_rental_duration_field',
            type: 'info',
            translate: true,
            getValue: (data) => {
                return data.rate.rentalDuration.amount + " " + data.rate.rentalDuration.unit.toLowerCase();
            }
        });
    }

    if (rental.lateReturn) {
        form.fields.push({
            name: 'lateReturn',
            label: 'rental_info.late_return_field',
            type: 'info',
            translate: true,
            getValue: (data) => {
                let lateReturn = data.lateReturn;

                const mins = Math.floor(lateReturn / 60);
                const sec = lateReturn % 60;
                lateReturn = mins;

                let result = sec + ' secs';

                if (lateReturn > 0) {
                    const hours = Math.floor(lateReturn / 60);
                    const minsResult = lateReturn % 60;
                    result = minsResult + " mins, " + result;
                    lateReturn = hours;
                }

                if (lateReturn > 0) {
                    const days = Math.floor(lateReturn / 24);
                    const hoursResult = lateReturn % 24;
                    result = hoursResult + " hours, " + result;

                    if (days > 0) {
                        result = days + " days, " + result;
                    }
                }


                return result;
            }
        });
    }

    if (rental.resolveProblemComment) {
        form.fields.push({
            name: 'resolveProblemComment',
            label: 'rental_info.resolve_problem_comment_field',
            type: 'info',
            translate: true,
            getValue: (data) => {
                return data.resolveProblemComment
            }
        });
    }

    return form;
}


export default buildRenalInfoForm;
