import Moment from 'moment';

const columnStyle = (row) => {
  if (['RENEWED', 'CANCELED', 'DONE'].indexOf(row.status) !== -1) {
    return {color: 'rgba(0, 0, 0, 0.54)'};
  } else {
    return {}
  }
};

const MAINTENANCE_PLANS_TABLE = {
  columns: [
    {
      label: 'maintenance_plans.system_name_header',
      field: 'system.name',
      getValue: (raw) => {
        return raw.system.name;
      },
      ordering: false,
      getStyle: columnStyle
    },
    {
      label: 'maintenance_plans.status_header',
      field: 'status',
      ordering: false,
      translate: true,
      getValue(raw) {
        return 'maintenance_plan_status.' + raw.status;
      },
      getStyle: columnStyle
    },
    {
      label: 'maintenance_plans.mechanic_header',
      field: 'mechanic.firstName,mechanic.lastName',
      getValue: (raw) => {
        return raw.mechanic.firstName + ' ' + raw.mechanic.lastName
      },
      ordering: false,
      getStyle: columnStyle
    },
    {
      label: 'maintenance_plans.plan_header',
      field: 'plan',
      ordering: false,
      translate: true,
      getValue: (raw) => {
        return 'maintenance_plan_type.plan_' + raw.plan;
      },
      getStyle: columnStyle
    },
    {
      label: 'maintenance_plans.run_time_header',
      field: 'runTime',
      getValue: (raw) => {
        return raw.runTime ? Moment(raw.runTime).format('MMM YYYY') : ''
      },
      ordering: false,
      getStyle: columnStyle
    },
    {
      label: 'maintenance_plans.visits_left_header',
      field: 'visitsLeft',
      getValue: (raw) => {
        return raw.visitsLeft
      },
      ordering: false,
      getStyle: columnStyle
    }
  ]
}

export default MAINTENANCE_PLANS_TABLE;
