import Moment from 'moment';

const USERS_TABLE = {
    columns: [
        {
            label: 'users.full_name_header',
            field: 'client.firstName,client.lastName',
            getValue: (raw) => {
                if (raw.firstName && raw.lastName) {
                    return raw.firstName + ' ' + raw.lastName
                } else {
                    return '';
                }
            },
            ordering: true
        },
        {
            label: 'users.email_header',
            field: 'client.email',
            getValue: (raw) => {
                return raw.email
            },
            ordering: true
        },
        {
            label: 'users.phone_header',
            field: 'client.phone',
            getValue: (raw) => {
                return raw.phone ? raw.phone.fullNumber : ''
            },
            ordering: true
        },
        {
            label: 'users.status_header',
            field: 'status',
            ordering: true,
            translate: true,
            getValue(raw) {
                return 'user_status.' + raw.status;
            }
        },
        {
            label: 'users.accept_waiver_header',
            field: 'acceptWaiver',
            getValue: (raw) => {
                return raw.acceptWaiver ? raw.acceptWaiver : '-'
            },
            ordering: true
        },
        {
            label: 'users.activated_at_header',
            field: 'activatedAt',
            getValue: (raw) => {
                return raw.activatedAt ? Moment(raw.activatedAt).format('DD MMM YYYY') : '-'
            },
            ordering: true
        },
        {
            label: 'users.device_info_header',
            field: 'client.lastSuccessAuthDeviceInfo',
            getValue: (raw) => {
                if (raw.deviceInfo) {
                    const device = raw.deviceInfo.split(";device=")[1].split(";type=")[0];
                    const os = raw.deviceInfo.split(";device=")[0].split("os=")[1];
                    if (!device || device === 'unknown') {
                        return os;
                    } else {
                        return device + " (" + os + ")"
                    }
                }

                return "";
            },
            ordering: true
        },
        {
            label: 'users.app_version_header',
            field: 'client.lastSuccessAuthAppVersion',
            getValue: (raw) => {
                return raw.appVersion
            },
            ordering: true
        }
    ]
}

export default USERS_TABLE;
