import tokenStore from "../store/token-store";

export const profileReducer = (state = {
    profile: null,
    profileWasLoaded: false,
    profileIsLoading: false
}, action) => {
    switch (action.type) {
        case 'LOAD_PROFILE':
            return {
                ...state,
                profileIsLoading: true
            }
        case 'LOAD_PROFILE_SUCCESS':
            return {
                ...state,
                profile: action.payload.data,
                profileWasLoaded: true,
                profileIsLoading: false
            }
        case 'LOAD_PROFILE_FAIL':
            return {
                ...state,
                profileWasLoaded: true,
                profileIsLoading: false
            }
        case 'LOGOUT':
        case 'LOGOUT_SUCCESS':
        case 'LOGOUT_FAIL':
            tokenStore.clear()
            return {
                ...state,
                profile: null,
                profileWasLoaded: false,
                profileIsLoading: false
            }

        case 'CHANGE_PROFILE_SUCCESS':
            return {
                ...state,
                profile: action.payload.data
            }
        case 'CHANGE_PROFILE':
        case 'CHANGE_PROFILE_FAIL':
        default:
            return state
    }
}