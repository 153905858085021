import Moment from "moment";

function makePassword(length) {
  let result = '';
  //const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const characters = '0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const buildAddPasswordForm = (unlimited) => {
  const form = {
    title: {
      text: 'add_password.title'
    },
    submit: {
      label: 'add_password.submit_btn'
    },
    fields: [
      {
        name: 'password',
        label: 'add_password.password_field',
        type: 'text',
        validators: ['required', 'passwordFormat'],
        errors: ['errors.required_system_password', 'errors.invalid_system_password_format'],
        defaultValue: makePassword(6)
      },
      {
        name: 'from',
        label: 'add_password.from_field',
        type: 'date',
        validators: ['required'],
        errors: ['errors.required_from_date'],
        defaultValue: Moment(new Date()).format('yyyy MM DD')
      },
      {
        name: 'to',
        label: 'add_password.to_field',
        type: 'date',
        validators: ['required', 'passwordDateValidity'],
        errors: ['errors.required_to_date', 'errors.invalid_password_dates'],
        defaultValue: Moment(new Date()).format('yyyy MM DD')
      },
      {
        name: 'unlimited',
        label: 'add_password.unlimited_field',
        type: 'checkbox',
        styles: {
          marginRight: '-12px'
        },
        defaultValue: true
      },
    ]
  };

  if (!unlimited) {
    form.fields.push({
      name: 'limit',
      label: 'add_password.limit_field',
      type: 'text',
      validators: [
        'required',
        'minNumber:1',
        'maxNumber:255'
      ],
      errors: [
        'errors.required_limit_system_password',
        'errors.min_limit_system_password_error',
        'errors.max_limit_system_password_error'
      ],
      defaultValue: ''
    });
  }

  return form;
}

export default buildAddPasswordForm;
