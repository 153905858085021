import React from "react";
import SupportIssueInfoForm from "../../../../../cusomer-support/form/SupportIssueInfoForm";

class UserProblemIssueInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            backUrl: '/system/' + this.props.match.params.systemId + '/user/' + this.props.match.params.userId + '/reported-problems'
        };
    }

    render() {
        return (
            <SupportIssueInfoForm backUrl={this.state.backUrl}
                                  history={this.props.history}
                                  location={this.props.location}
                                  match={this.props.match}/>
        )
    }
}

export default UserProblemIssueInfo;