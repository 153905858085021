const CHANGE_PAYMENT_PROVIDER_FORM = {
  submit: {
    label: 'general_config.save_payment_provider_btn'
  },
  fields: [
    {
      name: 'apiKey',
      label: 'general_config.api_key_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_api_key'],
      defaultValue: ''
    },
    {
      name: 'secretKey',
      label: 'general_config.secret_key_field',
      type: 'password',
      validators: ['required'],
      errors: ['errors.required_secret_key'],
      defaultValue: ''
    },
    {
      name: 'howToFind',
      label: 'general_config.how_to_find_api_key',
      type: 'link',
      translate: true,
      styles: {
        textAlign: 'left'
      },
      onClick: () => {
        window.open('https://stripe.com/docs/keys', "_blank", "noreferrer");
      }
    }
  ]
}

export default CHANGE_PAYMENT_PROVIDER_FORM;
