import TIME_ZONES from './time-zones';
import TIMES from './times';

const editGeneralConfigForm = (isSuperAdmin) => {
  const generalConfigForm = {
    title: {
      text: 'edit_system_config.title'
    },
    submit: {
      label: 'edit_system_config.submit_btn'
    },
    fields: [
      {
        name: 'description',
        label: 'edit_system_config.description_field',
        type: 'text',
        multiline: true,
        validators: ['required'],
        errors: ['errors.required_desc'],
        defaultValue: ''
      },
      {
        name: 'phone',
        label: 'edit_system_config.phone_field',
        type: 'phone',
        validators: ['required', 'isValidPhoneNumber'],
        errors: ['errors.required_phone', 'errors.invalid_phone_number'],
        defaultValue: '',
        styles: {
          display: 'flex'
        }
      },
      {
        name: 'email',
        label: 'edit_system_config.email_field',
        type: 'text',
        validators: ['required', 'isEmail'],
        errors: ['errors.required_email', 'errors.invalid_email'],
        defaultValue: ''
      },
      {
        name: 'country',
        label: 'edit_system_config.country_field',
        type: 'select',
        items: [
          {value: "US", label: "US"},
          {value: "CA", label: "CA"},
          {value: "BY", label: "BY"}
        ],
        validators: ['requiredCountry'],
        errors: ['errors.required_country'],
        defaultValue: ''
      },
      {
        name: 'status',
        label: 'edit_system_config.status_field',
        type: 'select',
        items: [
          {value: "OPEN", label: "system_status.OPEN", translate: true},
          {value: "CLOSED", label: "system_status.CLOSED", translate: true}
        ],
        validators: ['requiredStatus'],
        errors: ['errors.required_status'],
        defaultValue: ''
      },
      {
        name: 'rentalRates',
        label: 'edit_rental_config.rental_rates_field',
        type: 'checkbox',
        styles: {
          marginRight: '-12px'
        },
        showFn: (data) => {
          return isSuperAdmin
        },
        defaultValue: false
      },
      {
        name: 'changeLocationWithFinishingRental',
        label: 'edit_rental_config.change_location_with_finishing_rental',
        type: 'checkbox',
        styles: {
          marginRight: '-12px'
        },
        defaultValue: false
      },
      {
        name: 'timeRestrictions',
        label: 'edit_rental_config.time_restrictions_field',
        type: 'checkbox',
        styles: {
          marginRight: '-12px'
        },
        defaultValue: true
      },
      {
        name: 'timeZone',
        label: 'edit_rental_config.time_zone_field',
        type: 'select',
        items: TIME_ZONES,
        validators: [],
        errors: [],
        defaultValue: '',
        showFn: (data) => {
          return data.timeRestrictions
        }
      },
      {
        name: 'timeFrom',
        label: 'edit_rental_config.work_time_from_field',
        type: 'select',
        items: TIMES,
        validators: [],
        errors: [],
        defaultValue: '',
        showFn: (data) => {
          return data.timeRestrictions
        }
      },
      {
        name: 'timeTo',
        label: 'edit_rental_config.work_time_to_field',
        type: 'select',
        items: TIMES,
        validators: [],
        errors: [],
        defaultValue: '',
        showFn: (data) => {
          return data.timeRestrictions
        }
      }
    ]
  }

  return generalConfigForm;
}

export default editGeneralConfigForm;
