import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const LabelInfoActions = (props) => {
  const {t} = useTranslation();

  const handleEdit = () => {
    props.onHandleEdit();
  }

  return (
      <Button onClick={handleEdit} variant="outlined">
        {t('label_info.edit_action')}
      </Button>
  )
}

export default LabelInfoActions
