import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const RentalPaymentActions = (props) => {
  const {t} = useTranslation();

  const handleClick = (event) => {
    props.onHandleRefund()
  };

  return (
    <Button onClick={handleClick}
            variant="contained"
            color="primary"
            style={{
              marginBottom: '1em'
            }}>
      {t('rental_payment_info.refund_btn')}
    </Button>
  )
}

export default RentalPaymentActions
