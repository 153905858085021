import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import Col from "components/layout/Col";
import Row from "components/layout/Row";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const SubscriptionSystemsFilters = (props) => {
  const {t} = useTranslation();
  const mechanicNameRef = React.createRef();
  const systemNameRef = React.createRef();

  return (
      <Row>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <TextField label={t('subscription_systems.system_name_filter')}
                       ref={systemNameRef}
                       onChange={props.onHandleChange.bind(this, "systemName")}
                       value={props.fields["systemName"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <FormControl className="form-control">
              <InputLabel id="status-label">{t('subscription_systems.status_filter')}</InputLabel>
              <Select
                  labelId="status-label"
                  id="status"
                  value={props.fields["status"]}
                  onChange={props.onHandleChange.bind(this, "status")}>
                <MenuItem value="">{t('system_subscription_status.na_status_filter')}</MenuItem>
                <MenuItem value="ACTIVE">{t('system_subscription_status.ACTIVE')}</MenuItem>
                <MenuItem value="CANCELLED">{t('system_subscription_status.CANCELLED')}</MenuItem>
                <MenuItem value="INVOICE_DUE">{t('system_subscription_status.INVOICE_DUE')}</MenuItem>
              </Select>
            </FormControl>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="4">
          <BsPanelHeaderFilterControl>
            <FormControl className="form-control">
              <InputLabel id="billing-period-label">{t('subscription_systems.billing_period_filter')}</InputLabel>
              <Select
                  labelId="billing-period-label"
                  id="status"
                  value={props.fields["billingPeriod"]}
                  onChange={props.onHandleChange.bind(this, "billingPeriod")}>
                <MenuItem value="">{t('subscription_billing_period.na_billing_period_filter')}</MenuItem>
                <MenuItem value="NONE">{t('subscription_billing_period.FREE')}</MenuItem>
                <MenuItem value="MONTHLY">{t('subscription_billing_period.MONTHLY')}</MenuItem>
                <MenuItem value="ANNUAL">{t('subscription_billing_period.ANNUAL')}</MenuItem>
                <MenuItem value="ANNUAL,MONTHLY">{t('subscription_billing_period.PAID')}</MenuItem>
              </Select>
            </FormControl>
          </BsPanelHeaderFilterControl>
        </Col>
      </Row>
  )
}

export default SubscriptionSystemsFilters;
