import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import isPossiblePhoneNumber from "components/validation/phone";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import {updateSystem} from "core/actions/system-actions";
import {parsePhone} from "core/utils/phone";
import {ADMIN} from "core/utils/permissions";
import editGeneralConfigForm from "./edit-general-settinga-form";
import {fetchSystemConfig} from "core/actions/system-config-actions";

class EditGeneralSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/general';
    this.fields = {};
    props.fetchSystemConfig(props.match.params.systemId);
  }

  componentDidMount() {
    BsForm.addValidationRule('isValidPhoneNumber', (value) => {
      return isPossiblePhoneNumber(value);
    });
    BsForm.addValidationRule('requiredCountry', (value) => {
      return !!this.fields.country;
    });
    BsForm.addValidationRule('requiredStatus', (value) => {
      return !!this.fields.status;
    });
    BsForm.addValidationRule('requiredLocation', (value) => {
      return !!this.fields.location;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('isValidPhoneNumber');
    BsForm.removeValidationRule('requiredCountry');
    BsForm.removeValidationRule('requiredStatus');
    BsForm.removeValidationRule('requiredLocation');
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.setState({openConfirmationWindow: false})
    const phone = parsePhone(this.fields.phone);
    this.props.updateSystem(this.props.match.params.systemId, {
      description: this.fields.description,
      country: this.fields.country,
      status: this.fields.status,
      phone: {
        countryCode: phone.countryCallingCode,
        number: phone.nationalNumber
      },
      email: this.fields.email,
      rentalRates: this.fields.rentalRates,
      changeLocationWithFinishingRental: this.fields.changeLocationWithFinishingRental,
      timeRestrictions: this.fields.timeRestrictions,
      timeZone: this.fields.timeZone,
      workTime: {
        from: this.fields.timeFrom,
        to: this.fields.timeTo
      }
    }, this.backUrl);
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    if (this.props.system && this.props.systemConfig) {
      const data = {
        ...this.props.system,
        ...this.props.systemConfig,
        phone: this.props.system.phone ? this.props.system.phone.fullNumber : ''
      }

      if (this.props.systemConfig.workTime) {
        data.timeFrom = this.props.systemConfig.workTime.from;
        data.timeTo = this.props.systemConfig.workTime.to;
      }

      if (!this.fields.phone) {
        this.fields = data;
      }

      const formConfig = editGeneralConfigForm(this.props.isSuperAdmin)

      return (
          <BsFormPanel size="s">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <ConfirmationModel
                open={this.state.openConfirmationWindow}
                message="edit_system_config.confirmation_message"
                handleClose={() => this.setState({openConfirmationWindow: false})}
                handleApply={this.handleUpdate.bind(this)}
            />

            <BsForm
                config={formConfig}
                formData={data}
                onHandleSubmit={this.handleSubmit.bind(this)}
                onHandleChange={this.handleChange.bind(this)}
                cancelAction={
                  <BsFormCancelAction history={this.props.history}
                                      location={this.props.location}
                                      url={this.backUrl}/>
                }
            />
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    system: state.system.currentSystem,
    systemConfig: state.systemConfig.systemConfig,
    isSuperAdmin: state.profile.profile != null && state.profile.profile.role != null
        && state.profile.profile.role.indexOf('SUPER_ADMIN') > -1
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    },
    updateSystem: (systemId, data, redirectPath) => {
      dispatch(updateSystem(systemId, data, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGeneralSettings);
