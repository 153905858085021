import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import Col from "components/layout/Col";
import Row from "components/layout/Row";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const LabelUsersFilters = (props) => {
  const {t} = useTranslation();
  const nameRef = React.createRef();
  const emailRef = React.createRef();
  const phoneRef = React.createRef();

  return (
      <Row>
        <Col size="3">
          <BsPanelHeaderFilterControl>
            <TextField label={t('users.name_filter')}
                       ref={nameRef}
                       onChange={props.onHandleChange.bind(this, "name")}
                       value={props.fields["name"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="3">
          <BsPanelHeaderFilterControl>
            <TextField label={t('users.email_filter')}
                       ref={emailRef}
                       onChange={props.onHandleChange.bind(this, "email")}
                       value={props.fields["email"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="3">
          <BsPanelHeaderFilterControl>
            <TextField label={t('users.phone_filter')}
                       ref={phoneRef}
                       onChange={props.onHandleChange.bind(this, "phone")}
                       value={props.fields["phone"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
      </Row>
  )
}

export default LabelUsersFilters;
