import React from "react";
import {Trans, useTranslation} from "react-i18next";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsBody from "components/panel/BsBody";
import BsPanel from "components/panel/BsPanel";

import Step1 from 'assets/img/delete-account/step-1.png'
import Step2 from 'assets/img/delete-account/step-2.png'
import Step3 from 'assets/img/delete-account/step-3.png'
import Step4 from 'assets/img/delete-account/step-4.png'
import Step5 from 'assets/img/delete-account/step-5.png'
import Step6 from 'assets/img/delete-account/step-6.png'

import './delete-account.scss';

const DeleteAccount = (props) => {
  const {t} = useTranslation();

  return (
      <BsPanel padding={true}>
        <BsPanelHeader>
          <BsPanelTitle>
            <Trans i18nKey="delete_account.title"/>
          </BsPanelTitle>
        </BsPanelHeader>
        <BsBody>
          <div className="delete-account__content">
            {t('delete_account.description')}

            <ol>
              <li className="delete-account__step">
                {t('delete_account.step_1')}
              </li>
              <li className="delete-account__step">
                {t('delete_account.step_2')}
                <img src={Step1} className="delete-account__image" alt={t('delete_account.step1')}/>
              </li>
              <li className="delete-account__step">
                {t('delete_account.step_3')}
                <img src={Step2} className="delete-account__image" alt={t('delete_account.step2')}/>
              </li>
              <li className="delete-account__step">
                {t('delete_account.step_4')}
                <img src={Step3} className="delete-account__image" alt={t('delete_account.step3')}/>
              </li>
              <li className="delete-account__step">
                {t('delete_account.step_5')}
                <img src={Step4} className="delete-account__image" alt={t('delete_account.step4')}/>
              </li>
              <li className="delete-account__step">
                {t('delete_account.step_6')}
                <img src={Step5} className="delete-account__image" alt={t('delete_account.step5')}/>
              </li>
              <li className="delete-account__step">
                {t('delete_account.step_7')}
                <img src={Step6} className="delete-account__image" alt={t('delete_account.step6')}/>
              </li>
            </ol>
          </div>
        </BsBody>
      </BsPanel>
  )
}

export default DeleteAccount;
