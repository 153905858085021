const buildRentalRatesPaymentStatisticsForm = (showCollectTaxes) => {
  const form = {
    fields: [
      {
        name: 'collectFees',
        label: 'rental_rates.collect_fees_field',
        type: 'info',
        getValue: (data) => {
          return '$' + data.collectFees;
        },
        styles: {
          maxWidth: '500px'
        },
      }
    ]
  }

  if (showCollectTaxes) {
    form.fields.push({
      name: 'collectTax',
      label: 'rental_rates.collect_tax_field',
      type: 'info',
      getValue: (data) => {
        return '$' + data.collectTaxes;
      },
      styles: {
        maxWidth: '500px'
      },
    });
  }

  form.fields.push({
    name: 'paidRentals',
    label: 'rental_rates.paid_rentals_field',
    type: 'info',
    getValue: (data) => {
      return data.paidRentals + '';
    },
    styles: {
      maxWidth: '500px'
    },
  });
  form.fields.push({
    name: 'rental_rates_delimiter',
    type: 'delimiter',
    styles: {
      maxWidth: '500px'
    },
  })

  return form;
}

export default buildRentalRatesPaymentStatisticsForm;
