import React from "react";
import BACK_OFFICE_USER_INFO_FORM from "./back-office-user-info-form";
import BsForm from "components/form/BsForm";
import BsTabPanel from "components/panel/BsTabPanel";
import {fetchBoUser} from "core/actions/admin-actions";
import {connect} from "react-redux";

class BackOfficeUserInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.props.fetchBoUser(this.props.match.params.userId);
  }

  render() {
    if (this.props.currentAdmin) {
      const formConfig = {
        ...BACK_OFFICE_USER_INFO_FORM,
        title: {
          back: {
            url: '/global/bo-users',
            history: this.props.history,
            location: this.props.location
          }
        }
      };

      return (
          <div className="bs-form-container">
            <BsForm
                config={formConfig}
                formData={this.props.currentAdmin}
                disabled={true}/>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentAdmin: state.admin.currentAdmin
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBoUser: (userId) => {
      dispatch(fetchBoUser(userId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeUserInfoTab);
