import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import {loadMaintenancePlans} from "core/actions/maintenance-plan-actions";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsTabPanel from "components/panel/BsTabPanel";
import MaintenancePlansFilters from "./MaintenancePlansFilters";
import MAINTENANCE_PLANS_TABLE from "./maintenance-plans-table";
import SelectSystemModel from "./SelectSystemModel";
import Button from "@material-ui/core/Button";
import PlusIcon from "assets/img/plus.svg";

const queryString = require('query-string');

class MaintenancePlans extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        systemName: query.systemName != null ? query.systemName : '',
        mechanicName: query.mechanicName != null ? query.mechanicName : '',
        status: query.status != null ? query.status : '',
      },
      selectSystemWindow: {
        open: false
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.systemName = this.state.fields.systemName;
    params.mechanicName = this.state.fields.mechanicName;
    params.status = this.state.fields.status;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.systemName;
    delete params.mechanicName;
    delete params.status;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        systemName: '',
        mechanicName: '',
        status: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadMaintenancePlans(this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.mechanicName) {
      query['mechanic.firstName'] = query.mechanicName
      query['mechanic.lastName'] = query.mechanicName
      delete query.mechanicName;
    }
    if (query.systemName) {
      query['system.name'] = query.systemName
      delete query.systemName
    }

    return query
  }

  onRowClick = (row) => {
    this.props.history.push('/global/maintenance-plan/' + row.id + '/info')
  }

  onHandleAddPlan() {
    this.setState({
      ...this.state,
      selectSystemWindow: {
        open: true
      }
    })
  }

  selectSystem(system) {
    this.closeSelectSystemModel();
    this.props.history.push('/global/add-maintenance-plan?systemId=' + system.id);
  }

  closeSelectSystemModel() {
    this.setState({
      ...this.state,
      selectSystemWindow: {
        open: false
      }
    });
  }

  render() {
    let addPlanAction = undefined;
    if (this.props.manageMaintenance) {
      addPlanAction = <Button variant="contained" color="primary" type="submit" onClick={this.onHandleAddPlan.bind(this)}>
        <PlusIcon/>&nbsp;<Trans i18nKey="general.add"/>
      </Button>
    }

    return (
        <BsTabPanel>
          <SelectSystemModel
              open={this.state.selectSystemWindow.open}
              handleClose={this.closeSelectSystemModel.bind(this)}
              handleSelect={this.selectSystem.bind(this)}/>

          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle action={addPlanAction}/>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <MaintenancePlansFilters fields={this.state.fields}
                                     onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={MAINTENANCE_PLANS_TABLE}
                       content={this.props.maintenancePlans}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsTabPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    maintenancePlans: state.maintenancePlan.maintenancePlans,
    loading: state.maintenancePlan.loading,
    manageMaintenance: state.profile.profile != null && state.profile.profile.role != null
        && state.profile.profile.role.indexOf('VT_SUPER_ADMIN') > -1
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadMaintenancePlans: (query) => {
      dispatch(loadMaintenancePlans(query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePlans);
