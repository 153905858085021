import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import Col from "components/layout/Col";
import Row from "components/layout/Row";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const PasswordFilters = (props) => {
  const {t} = useTranslation();
  const nameRef = React.createRef();

  return (
      <Row>
        <Col size="3">
          <BsPanelHeaderFilterControl>
            <TextField label={t('passwords.password_filter')}
                       ref={nameRef}
                       onChange={props.onHandleChange.bind(this, "password")}
                       value={props.fields["password"]}
                       className="form-control"/>
          </BsPanelHeaderFilterControl>
        </Col>
        <Col size="3">
          <BsPanelHeaderFilterControl>
            <FormControl className="form-control">
              <InputLabel id="status-label">{t('passwords.status_filter')}</InputLabel>
              <Select
                  labelId="status-label"
                  id="status"
                  value={props.fields["status"]}
                  onChange={props.onHandleChange.bind(this, "status")}>
                <MenuItem value="">{t('passwords.na_status_filter')}</MenuItem>
                <MenuItem value="CREATED">{t('password_status.CREATED')}</MenuItem>
                <MenuItem value="ACTIVE">{t('password_status.ACTIVE')}</MenuItem>
                <MenuItem value="INACTIVE">{t('password_status.INACTIVE')}</MenuItem>
                <MenuItem value="USED">{t('password_status.USED')}</MenuItem>
              </Select>
            </FormControl>
          </BsPanelHeaderFilterControl>
        </Col>
      </Row>
  )
}

export default PasswordFilters;
