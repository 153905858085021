import React from 'react';
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import {loadAllAdmins} from "core/actions/admin-actions";
import {loadServices} from "core/actions/service-actions";
import {renewMaintenancePlan, fetchMaintenancePlan} from "core/actions/maintenance-plan-actions";
import RenewMaintenancePlanScheduleService from "./RenewMaintenancePlanScheduleService";
import Moment from 'moment';
import RenewMaintenancePlanForm from "./RenewMaintenancePlanForm";

class RenewMaintenancePlan extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      planWasSubmitted: false,
      planWasChanged: true,
      schedule: null
    };
    this.fields = null;
    this.backUrl = '/global/maintenance-plan/' + this.props.match.params.planId + '/info';

    this.systemId = new URLSearchParams(this.props.location.search).get("systemId")
    this.props.fetchMaintenancePlan(this.props.match.params.planId);
    this.props.loadServices(this.systemId);
    this.props.loadAllAdmins(this.systemId);
  }

  componentDidMount() {
    BsForm.addValidationRule('requiredPlan', (value) => {
      return !!this.fields.plan;
    });
    BsForm.addValidationRule('requiredService', (value) => {
      return !!this.fields.service;
    });
    BsForm.addValidationRule('requiredMechanic', (value) => {
      return !!this.fields.mechanic;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('requiredPlan');
    BsForm.removeValidationRule('requiredService');
    BsForm.removeValidationRule('requiredMechanic');
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    if (this.state.planWasChanged) {
      this.generateSchedule()
    }
    this.setState({
      ...this.state,
      planWasSubmitted: true,
      planWasChanged: false
    });
  }

  handleChange(fields) {
    this.fields = fields;
    this.setState({
      ...this.state,
      planWasChanged: true
    })
  }

  goBack() {
    this.setState({
      ...this.state,
      planWasSubmitted: false
    });
  }

  generateSchedule() {
    let schedule = [];
    let currentDate = new Date(this.fields.runTime);
    this.getEndDate(currentDate)
    for (let i = 0; i < this.fields.plan; i++) {
      schedule.push({
        number: i + 1,
        service: this.fields.service,
        startDate: currentDate,
        endDate: this.getEndDate(currentDate),
        startTime: Moment('2020-01-01 08:00:00'),
        endTime: Moment('2020-01-01 20:00:00')
      });
      currentDate = this.getNextStartDate(currentDate);
    }

    this.setState({
      ...this.state,
      schedule: schedule
    })
  }

  getNextStartDate(date) {
    const momentDate = Moment(date);
    if (momentDate.date() !== 1) {
      momentDate.date(1);
    }
    momentDate.add(1, 'month');
    return new Date(momentDate.valueOf());
  }

  getEndDate(date) {
    const momentDate = Moment(date);
    if (momentDate.date() !== 1) {
      momentDate.date(1);
    }
    momentDate.add(1, 'month');
    momentDate.subtract(1, 'day');
    return new Date(momentDate.valueOf());
  }

  onChangeSchedule(index, field, value) {
    const schedule = this.state.schedule;
    schedule[index][field] = value;
    this.setState({
      ...this.state,
      schedule: schedule
    })
  }

  onRemoveRow(index) {
    const schedule = this.state.schedule;
    schedule.splice(index, 1);
    for (let i = 0; i < schedule.length; i++) {
      schedule[i].number = i + 1;
    }
    this.setState({
      ...this.state,
      schedule: schedule
    })
  }

  onAddRow() {
    const schedule = this.state.schedule;
    const lastElem = schedule[schedule.length - 1];
    const startDate = lastElem ? lastElem.startDate : new Date(this.fields.runTime);
    const lastDate = this.getNextStartDate(startDate);

    schedule.push({
      number: schedule.length + 1,
      service: this.fields.service,
      startDate: lastDate,
      endDate: this.getEndDate(lastDate),
      startTime: Moment('2020-01-01 08:00:00'),
      endTime: Moment('2020-01-01 20:00:00')
    });

    this.setState({
      ...this.state,
      schedule: schedule
    })
  }

  onHandleCreatePlan() {
    if (this.state.schedule.length === 0) {
      this.props.showNotification('error', 'errors.blank_schedule_error');
      return;
    }

    const request = {
      plan: this.fields.plan,
      runTime: Moment(this.fields.runTime).format('YYYY-MM-DD'),
      systemId: this.systemId,
      mechanicId: this.fields.mechanic,
      service: this.props.services.filter((service) => service.taskStepId === this.fields.service)[0],
      schedule: this.state.schedule.map((row) => {
        return {
          number: row.number,
          startDate: Moment(row.startDate).format('YYYY-MM-DD'),
          endDate: Moment(row.endDate).format('YYYY-MM-DD'),
          startTime: row.startTime.format('HH:mm:ss'),
          endTime: row.endTime.format('HH:mm:ss'),
          service: this.props.services.filter((service) => service.taskStepId === row.service)[0]
        }
      })
    };

    this.props.renewMaintenancePlan(this.props.match.params.planId, request);
  }

  render() {
    if (this.props.currentMaintenancePlan) {
      if (!this.fields) {
        this.fields = {
          service: this.props.currentMaintenancePlan.service.taskStepId,
          mechanic: this.props.currentMaintenancePlan.mechanic.id,
          plan: this.props.currentMaintenancePlan.plan
        }
      }

      if (!this.state.planWasSubmitted) {
        return (
            <RenewMaintenancePlanForm
                handleSubmit={this.handleSubmit.bind(this)}
                handleChange={this.handleChange.bind(this)}
                services={this.props.services}
                mechanics={this.props.mechanics}
                loading={this.props.loading}
                history={this.props.history}
                location={this.props.location}
                backUrl={this.backUrl}
                fields={this.fields}
            />
        );
      } else {
        return (
            <RenewMaintenancePlanScheduleService
                onHandleCreatePlan={this.onHandleCreatePlan.bind(this)}
                onChangeSchedule={this.onChangeSchedule.bind(this)}
                onRemoveRow={this.onRemoveRow.bind(this)}
                onAddRow={this.onAddRow.bind(this)}
                goBack={this.goBack.bind(this)}
                schedule={this.state.schedule}
                services={this.props.services}
            />
        )
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentMaintenancePlan: state.maintenancePlan.currentMaintenancePlan,
    mechanics: state.admin.allAdmins,
    services: state.service.services,
    loading: state.admin.loading && state.service.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMaintenancePlan: (planId) => {
      dispatch(fetchMaintenancePlan(planId));
    },
    loadServices: (systemId) => {
      dispatch(loadServices(systemId));
    },
    loadAllAdmins: (systemId, query = {page: 0, size: 100, status: 'ACTIVE'}) => {
      dispatch(loadAllAdmins(systemId, query));
    },
    renewMaintenancePlan: (planId, request) => {
      dispatch(renewMaintenancePlan(planId, request));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RenewMaintenancePlan);
