import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import {loadLabel} from "core/actions/label-actions";
import LABEL_INFO_FORM from "./label-info-form";
import LabelInfoActions from "./LabelInfoActions";

class LabelInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      backUrl: '/global/labels',
    };
    this.props.loadLabel(this.props.match.params.labelId);
  }

  onHandleEdit() {
    this.props.history.push('/global/edit-label/' + this.props.match.params.labelId)
  }

  render() {
    if (this.props.currentLabel) {
      const formConfig = {
        ...LABEL_INFO_FORM
      };
      formConfig.title = {
        actions: <LabelInfoActions onHandleEdit={this.onHandleEdit.bind(this)}/>,
        back: {
          url: this.state.backUrl,
          history: this.props.history,
          location: this.props.location
        }
      }

      return (
          <div className="bs-form-container">
            <BsForm
                config={formConfig}
                formData={this.props.currentLabel}
                disabled={true}/>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentLabel: state.label.currentLabel
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadLabel: (labelId) => {
      dispatch(loadLabel(labelId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelInfoTab);
