import React, {useEffect} from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import BsPanel from "components/panel/BsPanel";
import {fetchLabelBicycleInfo} from "core/actions/bicycle-actions";
import LabelGeneralBicycleInfoTab from "./tabs/info/LabelGeneralBicycleInfoTab";
import LabelGeneralBicycleRentalsTab from "./tabs/rentals/LabelGeneralBicycleRentalsTab";
import LabelGeneralBicycleProblemsTab from "./tabs/problems/LabelGeneralBicycleProblemsTab";

function LabelGeneralBicycleInfo(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
  const [currentBicycleLoaded, setCurrentBicycleLoaded] = React.useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (!currentBicycleLoaded) {
      props.fetchLabelBicycleInfo(props.match.params.labelId, props.match.params.bicycleId);
      setCurrentBicycleLoaded(true);
    }
  }, []);

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  if (props.currentBicycle) {
    return (
        <BsPanel>
          <Tabs value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary">
            <Tab label={t('bicycle_info.info_tab') + props.currentBicycle.number} value="info"/>
            <Tab label={t('bicycle_info.rentals_tab')} value="rentals"/>
            <Tab label={t('bicycle_info.reported_problems_tab')} value="reported-problems"/>
          </Tabs>

          <Route exact path="/label/:labelId/bicycle/:bicycleId" component={LabelGeneralBicycleInfoTab}/>
          <Route path="/label/:labelId/bicycle/:bicycleId/info" component={LabelGeneralBicycleInfoTab}/>
          <Route path="/label/:labelId/bicycle/:bicycleId/rentals" component={LabelGeneralBicycleRentalsTab}/>
          <Route path="/label/:labelId/bicycle/:bicycleId/reported-problems"
                 component={LabelGeneralBicycleProblemsTab}/>
        </BsPanel>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    currentBicycle: state.bicycle.currentBicycle
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLabelBicycleInfo: (labelId, bicycleId) => {
      dispatch(fetchLabelBicycleInfo(labelId, bicycleId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelGeneralBicycleInfo);
