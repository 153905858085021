import React from "react";
import {connect} from "react-redux";
import {
  changeAdminStatus,
  fetchSystemAdmin,
  resendActivationLink
} from "core/actions/admin-actions";
import {ADMIN} from "core/utils/permissions";
import BsFormPanel from "components/form/BsFormPanel";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import AdminInfoActions from "./AdminInfoActions";
import buildAdminInfoForm from './admin-info-form'

class AdminInfo extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      backUrl: '/system/' + this.props.match.params.systemId + '/settings/admins',
      confirmationWindow: {
        open: false,
        message: ''
      },
      event: () => {
      }
    };
  }

  componentDidMount() {
    this.props.fetchSystemAdmin(this.props.match.params.systemId, this.props.match.params.userId);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.currentAdmin) {
      let formData = {
        fullName: props.currentAdmin.firstName + ' ' + props.currentAdmin.lastName,
        email: props.currentAdmin.email,
        status: props.currentAdmin.status,
        role: props.currentAdmin.role,
        notifications: props.currentAdmin.notifications
      };

      const formConfig = buildAdminInfoForm(props.currentAdmin.role);

      formConfig.title.back = {
        url: state.backUrl,
        history: props.history,
        location: props.location
      };

      if (!state.formConfig) {
        return {
          ...state,
          formConfig: formConfig,
          formData: formData,
        }
      } else {
        return {
          ...state,
          formData: formData
        }
      }
    }

    return null;
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  onHandleEnable() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'admin_info.enable_admin_confirmation_message'
      },
      event: this.enableAdmin
    })
  }

  enableAdmin() {
    this.closeConfirmationModel()
    this.props.changeStatus(this.props.match.params.systemId, this.props.match.params.userId, 'ACTIVE')
  }

  onHandleDisable() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'admin_info.disable_admin_confirmation_message'
      },
      event: this.disableAdmin
    })
  }

  onHandleResendActivationLink() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'admin_info.resend_activation_link_confirmation_message'
      },
      event: this.resendActivationLink
    })
  }

  disableAdmin() {
    this.closeConfirmationModel()
    this.props.changeStatus(this.props.match.params.systemId, this.props.match.params.userId, 'INACTIVE')
  }

  resendActivationLink() {
    this.closeConfirmationModel()
    this.props.resendActivationLink(this.props.match.params.systemId, this.props.match.params.userId)
  }

  onOpenEditModel() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/admins/' + this.props.match.params.userId + '/edit')
  }

  render() {
    if (this.state.formConfig && this.props.currentAdmin) {
      const formConfig = this.state.formConfig;
      formConfig.title.actions = <AdminInfoActions
          user={this.props.currentAdmin}
          onHandleEdit={this.onOpenEditModel.bind(this)}
          onHandleEnable={this.onHandleEnable.bind(this)}
          onHandleDisable={this.onHandleDisable.bind(this)}
          onHandleResendActivationLink={this.onHandleResendActivationLink.bind(this)}/>;

      return (
          <BsFormPanel size="m">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <ConfirmationModel
                open={this.state.confirmationWindow.open}
                message={this.state.confirmationWindow.message}
                handleClose={this.closeConfirmationModel.bind(this)}
                handleApply={this.state.event.bind(this)}
            />

            <BsForm
                config={this.state.formConfig}
                formData={this.state.formData}
                disabled={true}/>
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentAdmin: state.admin.currentAdmin
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeStatus: (systemId, userId, status) => {
      dispatch(changeAdminStatus(systemId, userId, status));
    },
    resendActivationLink: (systemId, userId) => {
      dispatch(resendActivationLink(systemId, userId));
    },
    fetchSystemAdmin: (systemId, userId) => {
      dispatch(fetchSystemAdmin(systemId, userId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminInfo);
