import React, {useEffect} from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import BsForm from "components/form/BsForm";

import 'components/model/confirmation-model.scss';

const ChangeServiceModel = (props) => {
  const {t} = useTranslation();
  let fields = {service: props.taskStepId};

  useEffect(() => {
    BsForm.addValidationRule('requiredService', (value) => {
      return !!fields.service;
    });

    return () => {
      BsForm.removeValidationRule('requiredService');
    };
  });

  const onSubmit = () => {
    props.handleSubmit(fields.service)
  }

  const handleChange = (newData) => {
    fields = newData;
  }

  if (props.services) {
    const config = {
      submit: {
        label: 'maintenance_plan_info.save_btn'
      },
      fields: [
        {
          name: 'service',
          label: 'maintenance_plan_info.service_field',
          type: 'select',
          items: props.services.map(service => {
            return {
              ...service,
              value: service.taskStepId,
              label: service.name
            }
          }),
          validators: ['requiredService'],
          errors: ['errors.required_service'],
          defaultValue: ''
        }
      ]
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="model-window"
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
          <Fade in={props.open}>
            <div className="model-container">
              <div className="model-body">
                <h4>{t('maintenance_plan_info.change_service_model_title')}</h4>

                <BsForm
                    config={config}
                    onHandleSubmit={onSubmit}
                    onHandleChange={handleChange}
                    cancelAction={<Button variant="outlined" onClick={props.handleClose}>
                      {t('general.cancel')}
                    </Button>}
                />
              </div>
            </div>
          </Fade>
        </Modal>
    )
  } else {
    return null
  }
}

ChangeServiceModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.any.isRequired,
  handleSubmit: PropTypes.any.isRequired,
  services: PropTypes.any
};

export default ChangeServiceModel;
