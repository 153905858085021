const SUBSCRIPTION_SYSTEM_INFO_FORM = {
  fields: [
    {
      name: 'subscription',
      label: 'subscription_system_info.subscription_field',
      type: 'info',
      getValue: (data) => {
        return data.subscription.name
      },
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'billingPeriod',
      label: 'subscription_system_info.billing_period_field',
      type: 'info',
      getValue: (data) => {
        return 'subscription_billing_period.' + data.billingPeriod
      },
      translate: true,
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'status',
      label: 'subscription_system_info.status_field',
      type: 'info',
      getValue: (data) => {
        return 'system_subscription_status.' + data.status
      },
      translate: true,
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'status_delimiter',
      type: 'delimiter',
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'bikes',
      label: 'subscription_system_info.bikes_field',
      type: 'info',
      getValue: (data) => {
        return data.bikes + ''
      },
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'pricePerBike',
      label: 'subscription_system_info.price_per_bike_field',
      type: 'price',
      currency: 'currency',
      getValue: (data) => {
        return '' + data.pricePerBike
      },
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'bikes_delimiter',
      type: 'delimiter',
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'paymentStatus',
      label: 'subscription_system_info.payment_status_field',
      type: 'info',
      getValue: (data) => {
        return 'subscription_payment_status.' + data.paymentStatus
      },
      translate: true,
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'billingAddress',
      label: 'subscription_system_info.billing_contact_field',
      type: 'info',
      getValue: (data) => {
        return data.billingAddress
            ? (data.billingAddress.name + ', ' + data.billingAddress.building + ' ' + data.billingAddress.street
                + ', ' + data.billingAddress.city + ', ' + data.billingAddress.zip) : '-'
      },
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'billing_delimiter',
      type: 'delimiter',
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'currentBillingPeriod',
      label: 'subscription_system_info.current_billing_period_field',
      type: 'info',
      getValue: (data) => {
        return data.currentBillingPeriod
      },
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'nextBillingPeriod',
      label: 'subscription_system_info.next_billing_period_field',
      type: 'info',
      getValue: (data) => {
        return data.nextBillingPeriod
      },
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'billing_period_delimiter',
      type: 'delimiter'
    },
  ]
}

export default SUBSCRIPTION_SYSTEM_INFO_FORM;
