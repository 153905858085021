import React from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import BsValidatorForm from "components/form/BsValidatorForm";
import Row from "components/layout/Row";
import Col from "components/layout/Col";
import BsFormActions from "components/form/actions/BsFormActions";
import BsFormSubmitAction from "components/form/actions/BsFormSubmitAction";
import DateMomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import Moment from "moment";

import 'components/model/confirmation-model.scss';

const ChangeServiceExpectedTimeModel = (props) => {
  const {t} = useTranslation();

  const [fields, setFields] = React.useState({
    startDate: Moment(props.visit.startDate),
    endDate: Moment(props.visit.endDate),
    startTime: Moment(props.visit.startDate + ' ' + props.visit.startTime),
    endTime: Moment(props.visit.endDate + ' ' + props.visit.endTime)
  });

  const onSubmit = () => {
    props.handleSubmit(fields)
  }

  function handleChange(fieldName, newData) {
    const newState = {...fields};
    newState[fieldName] = newData;
    setFields(newState);
  }

  if (props.visit) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="model-window"
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
          <Fade in={props.open}>
            <div className="model-container">
              <div className="model-body">
                <h4>{t('maintenance_plan_info.change_time_model_title')}</h4>

                <BsValidatorForm onHandleSubmit={onSubmit.bind(this)}>
                  <Row spacing={true}>
                    <Col size="6">
                      <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <DatePicker
                            label={t('maintenance_plan_info.start_date_label')}
                            name="start-date"
                            onChange={handleChange.bind(this, 'startDate')}
                            value={fields.startDate}
                            format="yyyy/MM/DD"
                            className="form-control"/>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col size="6">
                      <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <DatePicker
                            label={t('maintenance_plan_info.end_date_label')}
                            name="end-date"
                            onChange={handleChange.bind(this, 'endDate')}
                            value={fields.endDate}
                            format="yyyy/MM/DD"
                            className="form-control"/>
                      </MuiPickersUtilsProvider>
                    </Col>
                  </Row>

                  <Row spacing={true}>
                    <Col size="6">
                      <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <TimePicker
                            showTodayButton
                            todayLabel={t('maintenance_plan_info.today_label')}
                            label={t('maintenance_plan_info.start_time_label')}
                            name="start-time"
                            value={fields.startTime}
                            minutesStep={15}
                            ampm={false}
                            format="HH:mm"
                            onChange={handleChange.bind(this, 'startTime')}/>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col size="6">
                      <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <TimePicker
                            showTodayButton
                            todayLabel={t('maintenance_plan_info.today_label')}
                            label={t('maintenance_plan_info.end_time_label')}
                            name="end-name"
                            value={fields.endTime}
                            minutesStep={15}
                            ampm={false}
                            format="HH:mm"
                            onChange={handleChange.bind(this, 'endTime')}/>
                      </MuiPickersUtilsProvider>
                    </Col>
                  </Row>

                  <BsFormActions>
                    <Button variant="outlined" onClick={props.handleClose.bind(this)}>{t('general.cancel')}</Button>
                    <BsFormSubmitAction label="maintenance_plan_info.save_btn"/>
                  </BsFormActions>
                </BsValidatorForm>
              </div>
            </div>
          </Fade>
        </Modal>
    )
  } else {
    return null
  }
}

ChangeServiceExpectedTimeModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.any.isRequired,
  handleSubmit: PropTypes.any.isRequired,
  visit: PropTypes.any
};

export default ChangeServiceExpectedTimeModel;
