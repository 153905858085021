const PAY_SUBSCRIPTION_INVOICE_FORM = {
  submit: {
    label: 'pay_subscription_invoice_model.submit_btn'
  },
  fields: [
    {
      name: 'method',
      label: 'pay_subscription_invoice_model.payment_method_field',
      type: 'select',
      items: [
        {label: 'payment_method.CARD', value: 'CARD', translate: true},
        {label: 'payment_method.BANK_TRANSFER', value: 'BANK_TRANSFER', translate: true},
        {label: 'payment_method.CASH', value: 'CASH', translate: true},
        {label: 'payment_method.CHECK', value: 'CHECK', translate: true}
      ],
      validators: ['required'],
      errors: ['errors.required_payment_method'],
      defaultValue: ''
    },
    {
      name: 'additionalInfo',
      label: 'pay_subscription_invoice_model.additional_info_field',
      type: 'text',
      validators: ['required'],
      errors: ['errors.required_additional_info'],
      defaultValue: ''
    }
  ]
}

export default PAY_SUBSCRIPTION_INVOICE_FORM;
