const ADD_SYSTEM_PAYMENT_SOURCE_FORM = {
  title: {
    text: 'add_payment_source.title'
  },
  submit: {
    label: 'add_payment_source.submit_btn'
  },
  fields: [
    {
      name: 'name',
      label: 'add_payment_source.name_field',
      type: 'text',
      validators: [
        'required',
        'isValidCardName'
      ],
      errors: [
        'errors.required_card_name',
        'errors.invalid_card_name'
      ],
      defaultValue: ''
    },
    {
      name: 'number',
      label: 'add_payment_source.number_field',
      type: 'curd-number',
      validators: [
          'required',
          'isValidCardNumber'
      ],
      errors: [
          'errors.required_card_number',
          'errors.invalid_card_number'
      ],
      defaultValue: ''
    },
    {
      name: 'expiry',
      label: 'add_payment_source.expiry_field',
      type: 'masked-text',
      mask: '99/99',
      formatChars: {
        '9': '[0-9]',
        'a': '[A-Fa-f]',
        '*': '[1-9A-Fa-f]'
      },
      validators: [
        'required',
        'isValidExpiryDate'
      ],
      errors: [
        'errors.required_expiry_date',
        'errors.invalid_expiry_date'
      ],
      defaultValue: ''
    },
    {
      name: 'cvc',
      label: 'add_payment_source.cvc_field',
      type: 'text',
      validators: [
        'required',
        'maxStringLength:4'
      ],
      errors: [
        'errors.required_cvc',
        'errors.invalid_cvc'
      ],
      defaultValue: ''
    },
    {
      name: 'zip',
      label: 'add_payment_source.zip_field',
      type: 'text',
      validators: [
        'required',
        'maxStringLength:10'
      ],
      errors: [
        'errors.required_zip_code',
        'errors.invalid_zip'
      ],
      defaultValue: ''
    },
  ]
}

export default ADD_SYSTEM_PAYMENT_SOURCE_FORM;
