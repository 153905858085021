export const systemConfigReducer = (state = {
    systemConfig: null
}, action) => {
    switch (action.type) {
        case 'LOAD_SYSTEM_CONFIG':
        case 'LOAD_SYSTEM_CONFIG_FAIL':
            return {
                ...state,
                systemConfig: null
            }
        case 'LOAD_SYSTEM_CONFIG_SUCCESS':
        case 'UPDATE_SYSTEM_LOGO_SUCCESS':
        case 'UPDATE_SYSTEM_PAYMENT_PROVIDER_SUCCESS':
        case 'UPDATE_SYSTEM_TAX_CONFIG_SUCCESS':
        case 'TOGGLE_OFFER_INTEGRATION_INTEGRATION_SUCCESS':
            return {
                ...state,
                systemConfig: action.payload.data
            }
        case 'UPDATE_SYSTEM_LOGO':
        case 'UPDATE_SYSTEM_LOGO_FAIL':
        case 'UPDATE_SYSTEM_PAYMENT_PROVIDER':
        case 'UPDATE_SYSTEM_TAX_CONFIG_FAIL':
        case 'TOGGLE_OFFER_INTEGRATION_INTEGRATION':
        case 'TOGGLE_OFFER_INTEGRATION_INTEGRATION_FAIL':
        default:
            return state
    }
}
