import Moment from "moment";
import {priceToString} from "core/utils/priceUtils";

const SUBSCRIPTION_TRANSACTIONS_TABLE = {
  columns: [
    {
      label: 'subscription_transactions.invoice_number_header',
      field: 'invoiceNumber',
      getValue: (raw) => {
        return raw.invoiceNumber;
      },
      ordering: false
    },
    {
      label: 'subscription_transactions.subscription_name_header',
      field: 'description',
      getValue: (raw) => {
        return raw.description;
      },
      ordering: false
    },
    {
      label: 'subscription_transactions.billing_period_header',
      field: 'billingPeriod',
      ordering: false,
      getValue(raw) {
        return raw.billingPeriodFrom && raw.billingPeriodTo ?  raw.billingPeriodFrom + ' - ' + raw.billingPeriodTo : '-';
      },
    },
    {
      label: 'subscription_transactions.amount_header',
      field: 'amount',
      ordering: false,
      getValue(raw) {
        return priceToString(raw.amount, raw.currency);
      }
    },
    {
      label: 'subscription_transactions.payment_method_header',
      field: 'paymentMethod',
      ordering: false,
      translate: true,
      getValue(raw) {
        return 'payment_method.' + raw.paymentMethod;
      }
    },
    {
      label: 'subscription_transactions.payment_status_header',
      field: 'status',
      ordering: false,
      translate: true,
      getValue(raw) {
        return 'subscription_payment_status.' + raw.status;
      },
      getStyle(raw) {
        const style = {
          display: 'inline-block',
          padding: '6px 8px 4px',
          borderRadius: '4px',
          color: '#808080',
          textTransform: 'uppercase',
          fontSize: '0.75rem',
          lineHeight: '0.75rem'
        };

        if (raw.status === 'FREE' || raw.status === 'PAID') {
          style.background = '#CAF8D2'
        } else if (raw.status === 'CANCELED' || raw.status === 'ERROR') {
          style.background = '#FFE9E9'
        } else {
          style.background = '#FFFCB3'
        }

        return style;
      }
    },
    {
      label: 'subscription_transactions.payment_date_header',
      field: 'paidAt',
      ordering: false,
      getValue(raw) {
        return raw.paidAt ? Moment(raw.paidAt).format('YYYY-MM-DD') : '-';
      },
    },
    {
      label: '',
      field: 'action',
      ordering: false,
      getValue(raw) {
        return raw.action;
      },
      styles: {
        textAlign: 'right'
      }
    },
  ]
}

export default SUBSCRIPTION_TRANSACTIONS_TABLE;
