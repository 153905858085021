const LABEL_CUSTOMER_SUPPORT_ISSUE_INFO = {
  fields: [
    {
      name: 'system',
      label: 'issue_info.system_field',
      type: 'info',
      getValue: (data) => {
        return data.system ? data.system.name : ''
      },
      styles: {
        marginTop: '2em'
      }
    },
    {
      name: 'reporter',
      label: 'issue_info.reporter_field',
      type: 'info',
      getValue: (data) => {
        if (data.reporter) {
          return data.reporter.firstName + ' ' + data.reporter.lastName
        } else {
          return '-'
        }
      }
    },
    {
      name: 'assignee',
      label: 'issue_info.assignee_field',
      type: 'info',
      getValue: (data) => {
        if (data.assignee) {
          return data.assignee.firstName + ' ' + data.assignee.lastName
        } else {
          return '-'
        }
      }
    },
    {
      name: 'bike',
      label: 'issue_info.bicycle_field',
      type: 'info',
      getValue: (data) => {
        return data.bike ? ('#' + data.bike.number) : '-'
      }
    },
    {
      name: 'location',
      label: 'issue_info.location_field',
      type: 'info',
      getValue: (data) => {
        return data.location ? data.location.label : '-'
      }
    },
    {
      name: 'status',
      label: 'issue_info.status_field',
      type: 'info',
      translate: true,
      getValue: (data) => {
        return 'issue_status.' + data.status;
      }
    },
    {
      name: 'priority',
      label: 'issue_info.priority_field',
      type: 'info',
      translate: true,
      getValue: (data) => {
        return 'issue_priority.' + data.priority;
      }
    },
    {
      name: 'problem',
      label: 'issue_info.problem_field',
      type: 'info',
      translate: true,
      getValue: (data) => {
        const problem = data.problem ? data.problem : "OTHER";
        return 'issue_problem.' + problem;
      }
    },
    {
      name: 'description',
      label: 'issue_info.description_field',
      type: 'info',
      getValue: (data) => {
        return data.description ? data.description : '-'
      }
    },
    {
      name: 'createdAt',
      label: 'issue_info.created_at_field',
      type: 'info',
      getValue: (data) => {
        return data.createdAt
      }
    },
    {
      name: 'lastModifiedAt',
      label: 'issue_info.last_modified_at_field',
      type: 'info',
      getValue: (data) => {
        return data.lastModifiedAt ? data.lastModifiedAt : '-'
      }
    },
    {
      name: 'completedAt',
      label: 'issue_info.completed_at_field',
      type: 'info',
      getValue: (data) => {
        return data.completedAt ? data.completedAt : '-'
      }
    }
  ]
}

export default LABEL_CUSTOMER_SUPPORT_ISSUE_INFO;
