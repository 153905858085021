import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import {fetchSystemConfig, updateSystemGeneralHelp} from "core/actions/system-config-actions";
import {ADMIN} from "core/utils/permissions";
import EDIT_GENERAL_HELP_CONFIG_FORM from "./edit-general-help-settinga-form";
import EditGeneralHelpActions from "./EditGeneralHelpActions";

class EditGeneralHelpSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/user';
    this.fields = {};
    props.fetchSystemConfig(props.match.params.systemId);
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.props.updateSystemGeneralHelp(this.props.match.params.systemId, {
      generalHelp: this.fields.generalHelp
    }, this.backUrl)
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    if (this.props.systemConfig) {

      const formConfig = {
        ...EDIT_GENERAL_HELP_CONFIG_FORM
      };
      formConfig.title = {
        ...formConfig.title,
        actions: <EditGeneralHelpActions/>,
        back: {
          url: this.backUrl,
          history: this.props.history,
          location: this.props.location
        }
      }

      const data = {
        generalHelp: this.props.systemConfig.generalHelp ? this.props.systemConfig.generalHelp : ''
      };

      return (
          <BsFormPanel size="l">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <ConfirmationModel
                open={this.state.openConfirmationWindow}
                message="edit_general_help_config.confirmation_message"
                handleClose={() => this.setState({openConfirmationWindow: false})}
                handleApply={this.handleUpdate.bind(this)}
            />

            <BsForm
                config={formConfig}
                formData={data}
                onHandleSubmit={this.handleSubmit.bind(this)}
                onHandleChange={this.handleChange.bind(this)}
                cancelAction={
                  <BsFormCancelAction history={this.props.history}
                                      location={this.props.location}
                                      url={this.backUrl}/>
                }
            />
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    systemConfig: state.systemConfig.systemConfig
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    },
    updateSystemGeneralHelp: (systemId, data, redirectPath) => {
      dispatch(updateSystemGeneralHelp(systemId, data, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGeneralHelpSettings);
