import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";
import SelectIcon from "assets/img/select-icon.svg";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";

const GeneralSettingsActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (event) => {
    props.onHandleEdit()
    handleClose()
  };

  const handleDelete = (event) => {
    props.onHandleDelete()
    handleClose()
  };

  let action = <Button onClick={handleEdit}
                       variant="contained"
                       color="primary">
    {t('general.edit')}
  </Button>;

  if (props.isSuperAdmin) {
    action = <>
      <Button aria-controls="admin-info-menu"
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="primary"
              style={{
                marginBottom: '1em'
              }}>
        {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
      </Button>
      <Menu
          id="admin-info-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}>
        <MenuItem onClick={handleEdit}>{t('general.edit')}</MenuItem>
        <MenuItem onClick={handleDelete}>{t('general.delete')}</MenuItem>
      </Menu>
    </>
  }

  return (
      <>{action}</>
  )
}

export default GeneralSettingsActions
