import queryString from "query-string";

export function loadSystemSubscriptions(query) {
  return {
    type: "FETCH_SYSTEM_SUBSCRIPTIONS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/system-subscriptions?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function fetchSystemSubscription(systemId) {
  return {
    type: "FETCH_SYSTEM_SUBSCRIPTION",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/system-subscriptions/${systemId}`
      },
      globalLoading: true
    }
  };
}

export function updateSystemSubscription(systemId, data, redirectPath) {
  return {
    type: "UPDATE_SYSTEM_SUBSCRIPTION",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/system-subscriptions/${systemId}`,
        data: data
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_system_subscription_success_message'
        }
      },
      redirect: redirectPath,
      globalLoading: true
    }
  };
}

export function updateSystemBillingAddress(systemId, data, redirectPath) {
  return {
    type: "UPDATE_SYSTEM_SUBSCRIPTION",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/${systemId}/system-subscriptions/billing`,
        data: data
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_system_billing_address_success_message'
        }
      },
      redirect: redirectPath,
      globalLoading: true
    }
  };
}
