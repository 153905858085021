import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import BsFormPanel from "components/form/BsFormPanel";
import ConfirmationModel from "components/model/ConfirmationModel";
import {toggleOfferIntegration} from "core/actions/integrations-actions";
import {fetchSystemConfig} from "core/actions/system-config-actions";
import OfferIntegrationAction from "./OfferIntegrationAction";

import './OfferIntegrationSettings.scss';

class OfferIntegrationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/fleet';
    this.props.fetchSystemConfig(this.props.match.params.systemId);
    this.state = {
      openConfirmationWindow: false,
      confirmationMessage: ''
    }
  }

  onHandleToggleOffer() {
    this.setState({
      ...this.state,
      openConfirmationWindow: true,
      confirmationMessage: this.props.offerFleetIntegration
          ? 'integration.enable_offer_maintenance_message' : 'integration.disable_offer_maintenance_message'
    })
  }

  toggleOffer() {
    this.props.toggleOfferIntegration(this.props.match.params.systemId, {
      offerFleetIntegration: !this.props.offerFleetIntegration
    });
    this.closeToggleOfferWindow();
  }

  closeToggleOfferWindow() {
    this.setState({
      ...this.state,
      openConfirmationWindow: false
    })
  }

  render() {
    const formConfig = {
      title: {
        text: 'integration.offer_title'
      },
      fields: [
        {
          name: 'name',
          label: 'integration.offer_descriptions',
          type: 'notes',
          styles: {
            maxWidth: '500px',
            marginTop: '1em'
          }
        }
      ]
    }

    return (
        <BsFormPanel>
          <ConfirmationModel
              open={this.state.openConfirmationWindow}
              message={this.state.confirmationMessage}
              handleClose={this.closeToggleOfferWindow.bind(this)}
              handleApply={this.toggleOffer.bind(this)}
          />
          <BsForm
              config={formConfig}
              formData={{}}
              disabled={true}/>

          <OfferIntegrationAction
              offerFleetIntegration={this.props.offerFleetIntegration}
              onHandleClick={this.onHandleToggleOffer.bind(this)} />
        </BsFormPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offerFleetIntegration: state.systemConfig.systemConfig && state.systemConfig.systemConfig.offerFleetIntegration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleOfferIntegration: (systemId, data) => {
      dispatch(toggleOfferIntegration(systemId, data));
    },
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferIntegrationSettings);
