import React from 'react'
import Button from '@material-ui/core/Button'
import Permitted from "components/permission/Permitted";
import {ADMIN} from 'core/utils/permissions'
import {useTranslation} from "react-i18next";

const RentalInfoFormActions = (props) => {
    const {t} = useTranslation();

    const handleFinishClick = (event) => {
        props.onHandleFinish()
    };

    const handleResolveProblemClick = (event) => {
        props.onHandleResolve()
    };

    let btn = null;
    if (props.rental && props.rental.status === 'ACTIVE') {
        btn = <Button onClick={handleFinishClick}
                      variant="contained"
                      color="primary"
                      style={{
                          marginBottom: '1em'
                      }}>
            {t('rental_info.finish')}
        </Button>
    } else if (props.rental.hasReturnProblem || props.rental.forceFinished) {
        btn = <Button onClick={handleResolveProblemClick}
                      variant="contained"
                      color="primary"
                      style={{
                          marginBottom: '1em'
                      }}>
            {t('rental_info.resolve_problem')}
        </Button>
    }

    return (
        <Permitted for={ADMIN}>{btn}</Permitted>
    )
}

export default RentalInfoFormActions
