import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const OfferIntegrationAction = (props) => {
  const {t} = useTranslation();
  const label = props.offerFleetIntegration ? 'general.disable' : 'general.enable';

  const handleClick = (event) => {
    props.onHandleClick()
  };

  return (
      <div className="offer-integration__action">
        <Button onClick={handleClick}
                variant="contained"
                color="primary">
          {t(label)}
        </Button>
      </div>
  )
}

export default OfferIntegrationAction
