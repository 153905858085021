import React from "react";
import SupportIssueInfoForm from "../../../../../cusomer-support/form/SupportIssueInfoForm";

class BicycleProblemIssueInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      backUrl: '/system/' + this.props.match.params.systemId + '/bicycle/' + this.props.match.params.bicycleId + '/problems'
    };
  }

  render() {
    return (
        <SupportIssueInfoForm backUrl={this.state.backUrl}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}/>
    )
  }
}

export default BicycleProblemIssueInfo;
