import React from "react";
import {connect} from "react-redux";
import UserSettingMenuItem from "./UserSettingMenuItem";
import SignInMenuItem from "./SignInMenuItem";
import PropTypes from "prop-types";

const BsRightHeader = (props) => {
    if (props.isLoggedIn) {
        return <UserSettingMenuItem history={props.history}/>
    } else {
        return <SignInMenuItem history={props.history}/>
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.security.isLoggedIn
    };
};

BsRightHeader.propTypes = {
    history: PropTypes.object
};

export default connect(mapStateToProps)(BsRightHeader);