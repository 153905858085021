import React from "react";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";

const SearchSystems = (props) => {
    const {t} = useTranslation();
    const searchRef = React.createRef();

    return (
        <>
            <div className="system-description">
                {t('systems.desc')}
            </div>
            <TextField label={t('systems.search_field')}
                       ref={searchRef}
                       onChange={props.onHandleChange.bind(this, "search")}
                       value={props.search}/>
        </>
    )
}

export default SearchSystems;