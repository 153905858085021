import React from "react";
import BsBackBtn from "../../routing/BsBackBtn";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsFormTitle = (props) => {
    if (!props.title) {
        return null;
    }

    const {t} = useTranslation();
    const size = props.title.size ? props.title.size : 'l';
    const position = props.title.position ? props.title.position : 'center';
    const containerClasses = `bs-form__title bs-form__title-${size} align-${position}`;

    let backBtn = null;
    if (props.title.back) {
        backBtn = <div className="bs-form__title-back">
            <BsBackBtn type="fab" url={props.title.back.url}
                       history={props.title.back.history}
                       location={props.title.back.location}/>
        </div>
    }

    let actions = null;
    if (props.title.actions) {
        actions = <div className="bs-form__title-actions">
            {props.title.actions}
        </div>
    }

    return (
        <div className="bs-form__title-container">
            {backBtn}
            <h1 className={containerClasses}>{t(props.title.text)}</h1>
            {actions}
        </div>
    )
}

BsFormTitle.propTypes = {
    title: PropTypes.object
};

export default BsFormTitle