export const LABEL_CLIENT_TABLE_USER_RENTALS = {
  columns: [
    {
      label: 'rentals.bicycle_header',
      field: 'bike.number',
      getValue: (raw) => {
        return "# " + raw.bike.number
      },
      ordering: true
    },
    {
      label: 'rentals.system_header',
      field: 'system.name',
      ordering: true,
      getValue(raw) {
        return raw.system.name;
      }
    },
    {
      label: 'rentals.start_time_header',
      field: 'startTime',
      getValue: (raw) => {
        return raw.startTime ? raw.startTime : '-'
      },
      ordering: true
    },
    {
      label: 'rentals.end_time_header',
      field: 'endTime',
      getValue: (raw) => {
        return raw.endTime ? raw.endTime : '-'
      },
      ordering: true
    }
  ]
}
