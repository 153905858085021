export const subscriptionTransactionReducer = (state = {
  transactions: {},
  loading: false
}, action) => {
  switch (action.type) {
    case 'FETCH_SUBSCRIPTION_TRANSACTIONS':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_SUBSCRIPTION_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        transactions: action.payload.data
      }
    case 'FETCH_SUBSCRIPTION_TRANSACTIONS_FAIL':
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
