import React from "react";
import {connect} from "react-redux";
import {ADMIN} from "core/utils/permissions";
import {changeLocationStatus, fetchLocation} from "core/actions/location-actions";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import GoogleMap from "components/map/GoogleMap";
import ConfirmationModel from "components/model/ConfirmationModel";
import LocationInfoActions from "./LocationInfoActions";
import LOCATION_INFO_FORM from "./location-info-form";

class LocationInfoTab extends React.Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            backUrl: '/system/' + this.props.match.params.systemId + '/locations',
            confirmationWindow: {
                open: false,
                message: '',
                event: () => {}
            }
        };
        this.props.fetchLocation(this.props.match.params.systemId, this.props.match.params.locationId);
    }

    onHandleEdit() {
        this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-location/' + this.props.match.params.locationId)
    }

    onHandleActive() {
        this.setState({
            ...this.state,
            confirmationWindow: {
                open: true,
                message: 'location_info.activate_location_confirmation_message',
                event: this.activeLocation
            }
        })
    }

    activeLocation() {
        this.closeConfirmationModel();
        this.props.changeLocationStatus(this.props.match.params.systemId, this.props.match.params.locationId, 'ACTIVE')
    }

    onHandleInactive() {
        this.setState({
            ...this.state,
            confirmationWindow: {
                open: true,
                message: 'location_info.inactivate_location_confirmation_message',
                event: this.inactiveLocation
            }
        })
    }

    inactiveLocation() {
        this.closeConfirmationModel();
        this.props.changeLocationStatus(this.props.match.params.systemId, this.props.match.params.locationId, 'INACTIVE')
    }

    closeConfirmationModel() {
        this.setState({
            ...this.state,
            confirmationWindow: {
                ...this.state.confirmationWindow,
                open: false
            }
        });
    }

    render() {
        if (this.props.currentLocation) {
            const formConfig = {
                ...LOCATION_INFO_FORM
            };
            formConfig.title = {
                actions: <LocationInfoActions
                    location={this.props.currentLocation}
                    onHandleEdit={this.onHandleEdit.bind(this)}
                    onHandleEnable={this.onHandleActive.bind(this)}
                    onHandleDisable={this.onHandleInactive.bind(this)}/>,
                back: {
                    url: this.state.backUrl,
                    history: this.props.history,
                    location: this.props.location
                }
            }

            const markerLocation = {
                lat: this.props.currentLocation.address.latitude,
                lng: this.props.currentLocation.address.longitude
            };

            return (
                <div className="bs-form-container">
                    <PagePermission anonymous={false} permission={ADMIN}/>
                    <ConfirmationModel
                        open={this.state.confirmationWindow.open}
                        message={this.state.confirmationWindow.message}
                        handleClose={this.closeConfirmationModel.bind(this)}
                        handleApply={this.state.confirmationWindow.event.bind(this)}
                    />

                    <BsForm
                        config={formConfig}
                        formData={this.props.currentLocation}
                        disabled={true}
                        additionalContent={<GoogleMap marker={markerLocation} center={markerLocation}/>}/>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        currentLocation: state.location.currentLocation
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeLocationStatus: (systemId, locationId, status) => {
            dispatch(changeLocationStatus(systemId, locationId, status));
        },
        fetchLocation: (systemId, locationId) => {
            dispatch(fetchLocation(systemId, locationId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationInfoTab);
