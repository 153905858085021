import React from 'react';
import {connect} from "react-redux";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsTabPanel from "components/panel/BsTabPanel";
import SUBSCRIPTION_SYSTEMS_TABLE from "./subscription-systems-table";
import SubscriptionSystemsFilters from "./SubscriptionSystemsFilters";
import {loadSystemSubscriptions} from "core/actions/system-subscription-actions";

const queryString = require('query-string');

class SubscriptionSystems extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        systemName: query.systemName != null ? query.systemName : '',
        status: query.status != null ? query.status : '',
        billingPeriod: query.billingPeriod != null ? query.billingPeriod : '',
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.systemName = this.state.fields.systemName;
    params.status = this.state.fields.status;
    params.billingPeriod = this.state.fields.billingPeriod;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.systemName;
    delete params.status;
    delete params.billingPeriod;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        systemName: '',
        status: '',
        billingPeriod: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadSystemSubscriptions(this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.systemName) {
      query['system.name'] = query.systemName
      delete query.systemName
    }

    return query
  }

  onRowClick = (row) => {
    this.props.history.push('/global/system-subscription/' + row.system.id + '/info')
  }

  render() {
    return (
        <BsTabPanel>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle/>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <SubscriptionSystemsFilters fields={this.state.fields}
                                        onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={SUBSCRIPTION_SYSTEMS_TABLE}
                       content={this.props.subscriptionSystems}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsTabPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptionSystems: state.systemSubscription.systemSubscriptions,
    loading: state.systemSubscription.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSystemSubscriptions: (query) => {
      dispatch(loadSystemSubscriptions(query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSystems);
