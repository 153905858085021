const LOCATIONS_TABLE = {
    columns: [
        {
            label: 'locations.label_header',
            field: 'label',
            ordering: true
        },
        {
            label: 'locations.location_header',
            field: 'address',
            getValue: (raw) => {
                return raw.address.address
            },
            ordering: true
        },
        {
            label: 'locations.beacon_header',
            field: 'beaconMacAddress',
            ordering: true
        },
        {
            label: 'locations.status_header',
            field: 'status',
            ordering: true,
            translate: true,
            getValue(raw) {
                return 'location_status.' + raw.status;
            }
        },
        {
            label: 'locations.bicycles_header',
            field: 'countOfBicycle',
            ordering: true
        }
    ]
}

export default LOCATIONS_TABLE;
