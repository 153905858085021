import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";
import SelectIcon from "assets/img/select-icon.svg";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";

const PaymentProviderActions = (props) => {
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (event) => {
    handleClose()
    props.onHandleEdit()
  };

  const handleEditTax = (event) => {
    handleClose()
    props.onHandleEditTax()
  };

  return (
    <>
      <Button aria-controls="admin-info-menu"
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="primary"
              style={{
                marginBottom: '1em'
              }}>
        {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
      </Button>
      <Menu
        id="admin-info-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}>
        <MenuItem key="edit_settings" onClick={handleEdit}>{t('general.edit')}</MenuItem>
        <MenuItem key="edit_tax" onClick={handleEditTax}>{t('general_config.edit_tax')}</MenuItem>
      </Menu>
    </>
  )
}

export default PaymentProviderActions
