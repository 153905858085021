const queryString = require('query-string');

export function loadPasswords(systemId, query) {
  return {
    type: "FETCH_SYSTEM_PASSWORDS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/passwords?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function createSystemPassword(systemId, data) {
  return {
    type: "CREATE_SYSTEM_PASSWORDS",
    payload: {
      request: {
        method: 'post',
        url: `/api/v1/bo/${systemId}/passwords`,
        data: data
      },
      globalLoading: true,
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: `/system/${systemId}/edit-settings/password/${response.data.id}/info`
        }
      }
    }
  };
}

export function fetchSystemPassword(systemId, passwordId) {
  return {
    type: "FETCH_SYSTEM_PASSWORD",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/passwords/${passwordId}`
      },
      globalLoading: true
    }
  };
}

export function changeSystemPasswordStatus(systemId, passwordId, status) {
  return {
    type: "UPDATE_SYSTEM_PASSWORD_STATUS",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/${systemId}/passwords/${passwordId}/status`,
        data: {
          status: status
        }
      },
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.change_system_password_status_success_message'
        }
      },
      globalLoading: true
    }
  };
}

export function loadSystemPasswordUsage(systemId, passwordId, query) {
  return {
    type: "FETCH_SYSTEM_PASSWORD_USAGE_HISTORY",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/passwords/${passwordId}/history?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}
