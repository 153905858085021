const ACTIVATION_ACCOUNT_FORM = {
    title: {
        text: 'activation_account.title'
    },
    submit: {
        label: 'activation_account.submit_btn'
    },
    fields: [
        {
            name: 'notes',
            type: 'notes',
            label: 'activation_account.desc'
        },
        {
            name: 'password',
            label: 'activation_account.password_field',
            type: 'password',
            validators: [
                'required',
                'minStringLength:8',
                'maxStringLength:32',
                'strongPassword'
            ],
            errors: [
                'errors.required_password',
                'errors.min_length_password',
                'errors.max_length_password',
                'errors.invalid_password_format'
            ],
            defaultValue: ''
        },
        {
            name: 'repeatPassword',
            label: 'activation_account.repeat_password_field',
            type: 'password',
            validators: ['required', 'isPasswordMatch'],
            errors: [
                'errors.required_repeat_password',
                'errors.password_mismatch'
            ],
            defaultValue: ''
        }
    ]
}

export default ACTIVATION_ACCOUNT_FORM;
