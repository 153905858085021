const buildUserInfoForm = (actions) => {
    return {
        fields: [
            {
                name: 'fullName',
                label: 'user_info.full_name_field',
                type: 'info',
                getValue: (data) => {
                    if (data.firstName && data.lastName) {
                        return data.firstName + ' ' + data.lastName;
                    } else {
                        return ''
                    }
                },
                styles: {
                    maxWidth: '500px',
                    marginTop: '2em'
                }
            },
            {
                name: 'email',
                label: 'user_info.email_field',
                type: 'info',
                styles: {
                    maxWidth: '500px'
                },
                action: actions.changeEmail
            },
            {
                name: 'phone',
                label: 'user_info.phone_field',
                type: 'info',
                getValue: (data) => {
                    return data.phone ? data.phone.fullNumber : ''
                },
                styles: {
                    maxWidth: '500px'
                }
            },
            {
                name: 'acceptWaiver',
                label: 'user_info.accept_waiver',
                type: 'info',
                getValue: (data) => {
                    return data.acceptWaiver ? data.acceptWaiver : '-'
                },
                styles: {
                    maxWidth: '500px'
                }
            },
            {
                name: 'activatedAt',
                label: 'user_info.activated_at',
                type: 'info',
                getValue: (data) => {
                    return data.activatedAt ? data.activatedAt : '-'
                },
                styles: {
                    maxWidth: '500px'
                }
            },
            {
                name: 'status',
                label: 'user_info.status_field',
                type: 'info',
                translate: true,
                getValue: (data) => {
                    return 'user_status.' + data.status;
                },
                styles: {
                    maxWidth: '500px'
                }
            },
            {
                name: 'deviceInfo',
                label: 'user_info.device_info_field',
                type: 'info',
                getValue: (data) => {
                    if (data.deviceInfo) {
                        const device = data.deviceInfo.split(";device=")[1].split(";type=")[0];
                        const os = data.deviceInfo.split(";device=")[0].split("os=")[1];
                        if (!device || device === 'unknown') {
                            return os;
                        } else {
                            return device + " (" + os + ")"
                        }
                    }

                    return "";
                },
                styles: {
                    maxWidth: '500px'
                }
            },
            {
                name: 'appVersion',
                label: 'user_info.app_version_field',
                type: 'info',
                styles: {
                    maxWidth: '500px'
                }
            }
        ]
    }
}

export default buildUserInfoForm;
