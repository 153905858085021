import React from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AdminsSettings from "./tabs/admins/AdminsSettings";
import GeneralSettings from "./tabs/general/GeneralSettings";
import TosSettings from "./tabs/tos/TosSettings";
import UserSettings from "./tabs/user/UserSettings";
import FleetMaintenanceSettings from "./tabs/fleet/FleetMaintenanceSettings";
import SubscriptionSettings from "./tabs/subscription/SubscriptionSettings";
import BsPanel from "components/panel/BsPanel";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

function Settings(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'general');
  const {t} = useTranslation();

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  let tabs = null;
  if (props.profile && props.system && props.system.role) {
    tabs = (
        <Tabs value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant={"scrollable"}
              scrollButtons={"on"}>
          <Tab label={t('settings.general')} value="general"/>
          <Tab label={t('settings.user')} value="user"/>
          <Tab label={t('settings.terms_of_service')} value="tos"/>
          {props.showFleet ? <Tab label={t('settings.fleet')} value="fleet"/> : null}
          <Tab label={t('settings.admins')} value="admins"/>
          <Tab label={t('settings.subscription')} value="subscription"/>
        </Tabs>
    )
  }

  return (
      <>
        <PagePermission anonymous={false}/>
        <BsPanel>
          {tabs}
          <Route exact path="/system/:systemId/settings" component={GeneralSettings}/>
          <Route path="/system/:systemId/settings/general" component={GeneralSettings}/>
          <Route path="/system/:systemId/settings/user" component={UserSettings}/>
          <Route path="/system/:systemId/settings/tos" component={TosSettings}/>
          <Route path="/system/:systemId/settings/fleet" component={FleetMaintenanceSettings}/>
          <Route path="/system/:systemId/settings/admins" component={AdminsSettings}/>
          <Route path="/system/:systemId/settings/subscription" component={SubscriptionSettings}/>
        </BsPanel>
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    system: state.system.currentSystem,
    showFleet: (state.profile.profile != null && state.profile.profile.role != null
        && state.profile.profile.role.indexOf('SUPER_ADMIN') > -1)
        || ((state.system.currentSystem && state.system.currentSystem.offerFleetIntegration)
            && state.system && state.system.role && state.system.role.indexOf(ADMIN) > -1)
  };
};

export default connect(mapStateToProps)(Settings);
