import React from 'react'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";
import SelectIcon from "assets/img/select-icon.svg";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";

const UserSettingsActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditUserSettings = (event) => {
    props.onHandleEditUserSettings()
    handleClose()
  };

  const handleEditHelp = (event) => {
    props.onHandleEditHelp()
    handleClose()
  };

  const actions = [];
  if (!props.config.rentalRates) {
    actions.push(<MenuItem key="edit_settings" onClick={handleEditUserSettings}>{t('user_config.edit_settings')}</MenuItem>);
  }
  actions.push(<MenuItem key="edit_help" onClick={handleEditHelp}>{t('user_config.edit_help')}</MenuItem>);

  return (
      <>
        <Button aria-controls="admin-info-menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                color="primary"
                style={{
                  marginBottom: '1em'
                }}>
          {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
        </Button>
        <Menu
            id="admin-info-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}>
          {actions}
        </Menu>
      </>
  )
}

export default UserSettingsActions
