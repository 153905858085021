const CHANGE_INFO_PRIORITY_FORM = {
    submit: {
        label: 'issue_info.change_priority_btn'
    },
    fields: [
        {
            name: 'priority',
            label: 'issue_info.priority_field',
            type: 'select',
            items: [
                {
                    label: 'issue_priority.LOWEST',
                    value: 'LOWEST',
                    translate: true
                },
                {
                    label: 'issue_priority.LOW',
                    value: 'LOW',
                    translate: true
                },
                {
                    label: 'issue_priority.NORMAL',
                    value: 'NORMAL',
                    translate: true
                },
                {
                    label: 'issue_priority.HIGH',
                    value: 'HIGH',
                    translate: true
                },
                {
                    label: 'issue_priority.HIGHEST',
                    value: 'HIGHEST',
                    translate: true
                }
            ],
            translate: true,
            validators: ['requiredPriority'],
            errors: ['errors.required_priority'],
            defaultValue: ''
        }
    ]
}

export default CHANGE_INFO_PRIORITY_FORM;
