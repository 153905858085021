import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsTableBody = (props) => {
    const {t} = useTranslation();
    const {settings, content, onRowClick} = props;

    const handleClick = (row, col) => {
        if (onRowClick && !col.preventClick) {
            onRowClick(row)
        }
    }

    const getValue = (col, row) => {
        let val;
        if (col.getValue) {
            val = col.getValue(row);
        } else {
            val = row[col.field];
        }

        if (col.translate) {
            return t(val)
        }

        return val;
    }

    const getStyle = (col, row) => {
        let val = {};
        if (col.getStyle) {
            val = col.getStyle(row);
        }

        return val;
    }

    let noRecords;
    if (!content.content || content.content.size === 0) {
        noRecords = <tr>
            <td colSpan={settings.columns.length}>
                <div className="no-records">{t('general.no_records')}</div>
            </td>
        </tr>
    }

    return (
        <TableBody>
            {(content.content ? content.content : []).map((row, index) => {
                return (
                    <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        className={onRowClick ? 'cursor-pointer' : ''}
                        key={index}>

                        {settings.columns.map((col, colIndex) => {
                            return (
                                <TableCell component="th"
                                           key={col.field + row.id + colIndex}
                                           onClick={(e) => handleClick(row, col)}>
                                    <span style={getStyle(col, row)}>{getValue(col, row)}</span>
                                </TableCell>
                            )
                        })}

                    </TableRow>
                );
            })}
            {noRecords}
        </TableBody>
    )
}

BsTableBody.propTypes = {
    content: PropTypes.object,
    settings: PropTypes.object,
    onRowClick: PropTypes.func
};

export default BsTableBody;
