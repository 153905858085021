const SIGN_IN_FORM = {
    title: {
        text: 'sign_in.title'
    },
    submit: {
        label: 'sign_in.submit_btn'
    },
    fields: [
        {
            name: 'email',
            label: 'sign_in.email_field',
            type: 'text',
            validators: ['required', 'isEmail'],
            errors: ['errors.required_email', 'errors.invalid_email'],
            defaultValue: ''
        },
        {
            name: 'password',
            label: 'sign_in.password',
            type: 'password',
            validators: ['required'],
            errors: ['errors.required_password'],
            defaultValue: ''
        }
    ]
}

export default SIGN_IN_FORM;
