export const serviceReducer = (state = {
  services: [],
  loading: false
}, action) => {
  switch (action.type) {
    case 'FETCH_SERVICES':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_SERVICES_SUCCESS':
      return {
        ...state,
        loading: false,
        services: action.payload.data
      }
    case 'FETCH_SERVICES_FAIL':
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
