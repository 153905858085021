import React from 'react'
import Button from '@material-ui/core/Button';
import {useTranslation} from "react-i18next";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import SelectIcon from "assets/img/select-icon.svg";

const MaintenancePlanInfoFormActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeMechanic = () => {
    handleClose();
    props.onHandleChangeMechanic();
  };

  const handleChangeService = () => {
    handleClose();
    props.onHandleChangeService();
  };

  const handleCancel = () => {
    handleClose();
    props.onHandleCancel();
  };

  const handleRenew = () => {
    handleClose();
    props.onHandleRenew();
  };

  let menuItems = null;
  if (props.status === 'ACTIVE') {
    menuItems = [
      <MenuItem key="change_service" onClick={handleChangeService}>
        {t('maintenance_plan_info.action_change_service')}</MenuItem>,
      <MenuItem key="change_mechanic" onClick={handleChangeMechanic}>
        {t('maintenance_plan_info.action_change_mechanic')}</MenuItem>,
      <MenuItem key="cancel" onClick={handleCancel}>{t('maintenance_plan_info.action_cancel')}</MenuItem>,
    ]
  } else if (props.status === 'COMPLETED' || props.status === 'CANCELED') {
    menuItems = [
      <MenuItem key="renew" onClick={handleRenew}>{t('maintenance_plan_info.action_renew')}</MenuItem>
    ]
  }

  if (menuItems != null && props.manageMaintenance) {
    return (
        <>
          <Button aria-controls="support-issue-info-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  variant="contained"
                  color="primary"
                  style={{
                    marginBottom: '1em'
                  }}>
            {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
          </Button>
          <Menu
              id="support-issue-info-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}>
            {menuItems}
          </Menu>
        </>
    )
  } else {
    return null
  }
}

export default MaintenancePlanInfoFormActions
