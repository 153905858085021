import React from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useTranslation} from "react-i18next";
import BsPanel from "components/panel/BsPanel";
import LabelClientUserInfoTab from "./tabs/info/LabelClientUserInfoTab";
import LabelClientUserRentalsTab from "./tabs/rentals/LabelClientUserRentalsTab";
import LabelClientUserReportedProblemsTab from "./tabs/reported-problems/LabelClientUserReportedProblemsTab";

function LabelClientUserInfo(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
  const {t} = useTranslation();

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  return (
      <>
        <BsPanel>
          <Tabs value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary">
            <Tab label={t('user_info.info_tab')} value="info"/>
            <Tab label={t('user_info.rentals_tab')} value="rentals"/>
            <Tab label={t('user_info.reported_problems_tab')} value="reported-problems"/>
          </Tabs>

          <Route exact path="/label/:labelId/user/:userId" component={LabelClientUserInfoTab}/>
          <Route path="/label/:labelId/user/:userId/info" component={LabelClientUserInfoTab}/>
          <Route path="/label/:labelId/user/:userId/rentals" component={LabelClientUserRentalsTab}/>
          <Route path="/label/:labelId/user/:userId/reported-problems" component={LabelClientUserReportedProblemsTab}/>
        </BsPanel>
      </>
  );
}

export default LabelClientUserInfo;
