import React from 'react'
import Button from '@material-ui/core/Button';
import {useTranslation} from "react-i18next";
import SelectIcon from "assets/img/select-icon.svg";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";

const FleetMaintenanceSettingsActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowIntegration = () => {
    props.onHandleShowIntegration();
  };

  const handleEditOfferFleetIntegration = () => {
    props.onHandleEditOfferFleetIntegration();
  };

  if (props.isSuperAdmin) {
    /*let offerFleetIntegrationBtn = props.isVtSuperAdmin ? <MenuItem onClick={handleEditOfferFleetIntegration}>
      {t('integration.offer_integration')}</MenuItem> : null;*/
    return (
        <>
          <Button aria-controls="admin-info-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  variant="contained"
                  color="primary"
                  style={{
                    marginBottom: '1em'
                  }}>
            {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
          </Button>
          <Menu
              id="admin-info-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}>
            <MenuItem onClick={handleShowIntegration}>{t('integration.show_integration')}</MenuItem>
            <MenuItem onClick={handleEditOfferFleetIntegration}>{t('integration.offer_integration')}</MenuItem>
          </Menu>
        </>
    )
  } else {
    return null
  }
}

export default FleetMaintenanceSettingsActions
