import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import BsFormPanel from "components/form/BsFormPanel";
import {fetchMaintenanceIssueInfo} from "core/actions/maintenance-actions";
import CUSTOMER_SUPPORT_ISSUE_INFO from "./customer-support-issue-info";
import SupportIssueImages from "../../../admin/cusomer-support/form/SupportIssueImages";

import './customer-support-issue-info.scss'

class CustomerSupportIssueInfo extends React.Component {

  constructor(props) {
    super(props);
    this.props.fetchMaintenanceIssueInfo(this.props.match.params.issueId);
  }

  render() {
    if (this.props.currentIssue) {
      const formConfig = {
        ...CUSTOMER_SUPPORT_ISSUE_INFO,
        title: {
          back: {
            url: '/global/reported-problems',
            history: this.props.history,
            location: this.props.location
          },
          text: 'issue_info.title'
        }
      };

      return (
          <BsFormPanel size="m">
            <BsForm
                config={formConfig}
                formData={this.props.currentIssue}
                disabled={true}/>

            <SupportIssueImages images={this.props.currentIssue.images}
                                title={this.props.currentIssue.problem}
                                desc={this.props.currentIssue.description}/>
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentIssue: state.maintenance.currentIssue
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMaintenanceIssueInfo: (issueId) => {
      dispatch(fetchMaintenanceIssueInfo(issueId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupportIssueInfo);
