import React from 'react';

import './Row.scss'

const Row = (props) => {
    const className = (props.spacing ? "row with-spacing " : "row ") + props.className;

    return (
        <div className={className}>
            {props.children}
        </div>
    );
}

export default Row;
