const ALL_LABELS_TABLE = {
  columns: [
    {
      label: 'labels.label_header',
      field: 'label',
      ordering: true
    }
  ]
}

export default ALL_LABELS_TABLE;
