export const systemPaymentSourceReducer = (state = {
  paymentSources: {},
  bankAccount: null,
  loading: false
}, action) => {
  switch (action.type) {
    case 'FETCH_SYSTEM_PAYMENT_SOURCES':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_SYSTEM_PAYMENT_SOURCES_SUCCESS':
    case 'UPDATE_SYSTEM_PAYMENT_SOURCES_SUCCESS':
    case 'REMOVE_SYSTEM_PAYMENT_SOURCES_SUCCESS':
      return {
        ...state,
        loading: false,
        paymentSources: action.payload.data
      }
    case 'FETCH_SYSTEM_PAYMENT_SOURCES_FAIL':
      return {
        ...state,
        loading: false
      }
    case 'FETCH_SYSTEM_BANK_ACCOUNT_SUCCESS':
    case 'SET_DEFAULT_SYSTEM_BANK_ACCOUNT_SUCCESS':
      return {
        ...state,
        loading: false,
        bankAccount: action.payload.data
      }
    case 'FETCH_SYSTEM_BANK_ACCOUNT_FAIL':
    case 'REMOVE_SYSTEM_BANK_ACCOUNT_SUCCESS':
      return {
        ...state,
        loading: false,
        bankAccount: null
      }
    case 'UPDATE_SYSTEM_PAYMENT_SOURCES':
    case 'REMOVE_SYSTEM_PAYMENT_SOURCES':
    case 'UPDATE_SYSTEM_PAYMENT_SOURCES_FAIL':
    case 'REMOVE_SYSTEM_PAYMENT_SOURCES_FAIL':
    case 'ADD_SYSTEM_PAYMENT_SOURCES':
    case 'ADD_SYSTEM_PAYMENT_SOURCES_SUCCESS':
    case 'ADD_SYSTEM_PAYMENT_SOURCES_FAIL':
    case 'UPDATE_SYSTEM_BANK_ACCOUNT':
    case 'UPDATE_SYSTEM_BANK_ACCOUNT_SUCCESS':
    case 'UPDATE_SYSTEM_BANK_ACCOUNT_FAIL':
    case 'FETCH_SYSTEM_BANK_ACCOUNT':
    case 'SET_DEFAULT_SYSTEM_BANK_ACCOUNT':
    case 'SET_DEFAULT_SYSTEM_BANK_ACCOUNT_FAIL':
    case 'REMOVE_SYSTEM_BANK_ACCOUNT':
    case 'REMOVE_SYSTEM_BANK_ACCOUNT_FAIL':
    default:
      return state
  }
}
