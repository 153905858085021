const queryString = require('query-string');

export function loadRentals(systemId, query) {
  return {
    type: "FETCH_RENTALS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/rentals?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function fetchRental(systemId, rentalId) {
  return {
    type: "FETCH_RENTAL",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/rentals/${rentalId}`
      },
      globalLoading: true
    }
  };
}

export function finishRental(systemId, rentalId, callback) {
  return {
    type: "FINISH_RENTAL",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/${systemId}/rentals/${rentalId}/finish`
      },
      globalLoading: true,
      successCallback: (response) => {
        callback ? callback() : undefined;
        return [
          fetchRental(systemId, rentalId),
          {
            type: 'SHOW_NOTIFICATION',
            payload: {
              variant: 'success',
              message: 'notifications.finish_rental_success_message'
            }
          }
        ]
      }
    }
  };
}

export function resolveRentalProblem(systemId, rentalId, data) {
  return {
    type: "RESOLVE_RENTAL_PROBLEM",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/${systemId}/rentals/${rentalId}/resolve-problem`,
        data: data
      },
      globalLoading: true,
      successCallback: (response) => {
        return [
          {
            type: 'SHOW_NOTIFICATION',
            payload: {
              variant: 'success',
              message: 'notifications.update_rental_success_message'
            }
          }
        ]
      }
    }
  };
}

export function exportRentalsToCsv(systemId, query) {
  return {
    type: "EXPORT_RENTALS_TO_CSV",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/rentals/export/csv?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: true,
      successCallback: (response) => {
        let notification = {
          type: 'SHOW_NOTIFICATION',
          payload: {
            variant: 'success',
            message: 'notifications.export_data_sent_to_email_success_message'
          }
        };
        return response.data ? null : notification
      }
    }
  };
}

export function loadAllRentals(query) {
  return {
    type: "FETCH_ALL_RENTALS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/rentals?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function fetchRentalInfo(rentalId) {
  return {
    type: "FETCH_RENTAL_INFO",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/rentals/${rentalId}`
      },
      globalLoading: true
    }
  };
}

export function exportAllRentalsToCsv(query) {
  return {
    type: "EXPORT_ALL_RENTALS_TO_CSV",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/rentals/export/csv?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: true,
      successCallback: (response) => {
        let notification = {
          type: 'SHOW_NOTIFICATION',
          payload: {
            variant: 'success',
            message: 'notifications.export_data_sent_to_email_success_message'
          }
        };
        return response.data ? null : notification
      }
    }
  };
}

export function loadLabelRentals(labelId, query) {
  return {
    type: "FETCH_LABEL_RENTALS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/labels/${labelId}/rentals?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function fetchLabelRentalInfo(labelId, rentalId) {
  return {
    type: "FETCH_LABEL_RENTAL_INFO",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/labels/${labelId}/rentals/${rentalId}`
      },
      globalLoading: true
    }
  };
}

export function exportLabelRentalsToCsv(labelId, query) {
  return {
    type: "EXPORT_LABEL_RENTALS_TO_CSV",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/labels/${labelId}/rentals/export/csv?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: true,
      successCallback: (response) => {
        let notification = {
          type: 'SHOW_NOTIFICATION',
          payload: {
            variant: 'success',
            message: 'notifications.export_data_sent_to_email_success_message'
          }
        };
        return response.data ? null : notification
      }
    }
  };
}
