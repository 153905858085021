export const clientAuthReducer = (state = {
    joinedClient: null,
    joiningError: false
}, action) => {
    switch (action.type) {
        case 'JOIN_SYSTEM':
            return {
                ...state,
                joinedClient: null,
                joiningError: false
            }
        case 'JOIN_SYSTEM_SUCCESS':
            return {
                ...state,
                joinedClient: action.payload.data,
                joiningError: false
            }
        case 'JOIN_SYSTEM_FAIL':
            return {
                ...state,
                joinedClient: null,
                joiningError: true
            }
        default:
            return state
    }
}
