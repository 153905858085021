import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";
import PropTypes from "prop-types";

const BsValidatorForm = (props) => {
    return (
        <ValidatorForm className="bs-form"
                       ref={props.formRef}
                       autoComplete="off"
                       noValidate
                       onSubmit={props.onHandleSubmit}>
            {props.children}
        </ValidatorForm>
    )
}

BsValidatorForm.addValidationRule = (type, rule) => {
    ValidatorForm.addValidationRule(type, rule);
}

BsValidatorForm.removeValidationRule = (type) => {
    ValidatorForm.removeValidationRule(type);
}

BsValidatorForm.propTypes = {
    formRef: PropTypes.object,
    onHandleSubmit: PropTypes.func
};

export default BsValidatorForm;