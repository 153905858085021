import React from 'react'
import Button from '@material-ui/core/Button';
import {useTranslation} from "react-i18next";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import Permitted from "components/permission/Permitted";
import {MANAGER} from 'core/utils/permissions';
import SelectIcon from "assets/img/select-icon.svg";

const SupportIssueInfoFormActions = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {t} = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAssign = () => {
        handleClose();
        props.onHandleAssign();
    };

    const handleCloseIssue = () => {
        handleClose();
        props.onHandleCloseIssue();
    };

    const handleStartProgress = () => {
        handleClose();
        props.onHandleStartProgress();
    };

    const handleDone = () => {
        handleClose();
        props.onHandleDone();
    };

    let menuItems = null;
    if (props.issue) {
        if (props.issue.status === 'CREATED') {
            menuItems = [
                <MenuItem key="assign" onClick={handleAssign}>{t('issue_info.action_assign')}</MenuItem>,
                <MenuItem key="close" onClick={handleCloseIssue}>{t('issue_info.action_close')}</MenuItem>
            ]
        } else if (props.issue.status === 'ASSIGNED') {
            menuItems = [
                <MenuItem key="start_progress" onClick={handleStartProgress}>{t('issue_info.action_start_progress')}</MenuItem>,
                <MenuItem key="close" onClick={handleCloseIssue}>{t('issue_info.action_close')}</MenuItem>
            ]
        } else if (props.issue.status === 'IN_PROGRESS') {
            menuItems = [
                <MenuItem key="done" onClick={handleDone}>{t('issue_info.action_done')}</MenuItem>
            ]
        }
    }


    if (menuItems != null) {
        return (
            <Permitted for={MANAGER}>
                <Button aria-controls="support-issue-info-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        variant="contained"
                        color="primary"
                        style={{
                            marginBottom: '1em'
                        }}>
                    {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
                </Button>
                <Menu
                    id="support-issue-info-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}>
                    {menuItems}
                </Menu>
            </Permitted>
        )
    } else {
        return null
    }
}

export default SupportIssueInfoFormActions
