export const TABLE_USER_RENTALS = {
    columns: [
        {
            label: 'rentals.bicycle_header',
            field: 'bike.number',
            getValue: (raw) => {
                return "# " + raw.bike.number
            },
            ordering: true
        },
        {
            label: 'rentals.start_location_header',
            field: 'startLocation.label',
            ordering: true,
            getValue(raw) {
                return raw.startLocation.label;
            }
        },
        {
            label: 'rentals.end_location_header',
            field: 'endLocation.label',
            ordering: true,
            getValue(raw) {
                return raw.endLocation ? raw.endLocation.label : '-';
            }
        },
        {
            label: 'rentals.start_time_header',
            field: 'startTime',
            getValue: (raw) => {
                return raw.startTime
            },
            ordering: true
        },
        {
            label: 'rentals.end_time_header',
            field: 'endTime',
            getValue: (raw) => {
                return raw.startTime ? raw.startTime : '-'
            },
            ordering: true
        },
        {
            label: 'rentals.status_header',
            field: 'status',
            translate: true,
            getValue: (raw) => {
                return 'rental_status.' + raw.status
            },
            ordering: true
        }
    ]
}
