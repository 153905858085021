import {applyMiddleware, createStore} from "redux";
import {createLogger} from "redux-logger";
import createSagaMiddleware from 'redux-saga'
import apiSaga from 'core/sagas/api-saga'
import {rootReducer} from "core/reducers/root-reducer";

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    rootReducer,
    {},
    applyMiddleware(/*createLogger(), */sagaMiddleware)
);

sagaMiddleware.run(apiSaga)

export default store
