export const passwordReducer = (state = {
  passwords: {},
  usageHistory: {},
  currentPassword: null,
  loading: false,
  loadingUsageHistory: false,
  loadingCurrentPassword: false
}, action) => {
  switch (action.type) {
    case 'FETCH_SYSTEM_PASSWORDS':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_SYSTEM_PASSWORDS_SUCCESS':
      return {
        ...state,
        loading: false,
        passwords: action.payload.data
      }
    case 'FETCH_SYSTEM_PASSWORDS_FAIL':
      return {
        ...state,
        loading: false
      }
    case 'FETCH_SYSTEM_PASSWORD_USAGE_HISTORY':
      return {
        ...state,
        loadingUsageHistory: true
      }
    case 'FETCH_SYSTEM_PASSWORD_USAGE_HISTORY_SUCCESS':
      return {
        ...state,
        loadingUsageHistory: false,
        usageHistory: action.payload.data
      }
    case 'FETCH_SYSTEM_PASSWORD_USAGE_HISTORY_FAIL':
      return {
        ...state,
        loadingUsageHistory: false
      }
    case 'FETCH_SYSTEM_PASSWORD':
      return {
        ...state,
        currentPassword: null,
        loadingCurrentPassword: true
      }
    case 'FETCH_SYSTEM_PASSWORD_FAIL':
      return {
        ...state,
        currentPassword: null,
        loadingCurrentPassword: false
      }
    case 'FETCH_SYSTEM_PASSWORD_SUCCESS':
    case 'UPDATE_SYSTEM_PASSWORD_STATUS_SUCCESS':
      return {
        ...state,
        loadingCurrentPassword: false,
        currentPassword: action.payload.data
      }
    case 'CREATE_SYSTEM_PASSWORDS':
    case 'UPDATE_SYSTEM_PASSWORD_STATUS':
    case 'CREATE_SYSTEM_PASSWORDS_SUCCESS':
    case 'CREATE_SYSTEM_PASSWORDS_FAIL':
    case 'UPDATE_SYSTEM_PASSWORD_STATUS_FAIL':
    default:
      return state
  }
}
