const queryString = require('query-string');

export function loadLocations(systemId, query) {
    return {
        type: "FETCH_LOCATIONS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/locations?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: false
        }
    };
}

export function loadAllLocations(systemId) {
    return {
        type: "FETCH_ALL_LOCATIONS",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/locations?size=1000`
            },
            globalLoading: false
        }
    };
}

export function createLocation(systemId, data) {
    return {
        type: "CREATE_LOCATION",
        payload: {
            request: {
                method: 'post',
                url: `/api/v1/bo/${systemId}/locations`,
                data: data
            },
            globalLoading: true,
            successCallback: (response) => {
                return {
                    type: 'REDIRECT_TO',
                    payload: `/system/${systemId}/location/${response.data.id}`
                }
            }
        }
    };
}

export function updateLocation(systemId, locationId, data, redirectPath) {
    return {
        type: "UPDATE_LOCATION",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/locations/${locationId}`,
                data: data
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: 'notifications.update_location_success_message'
                }
            },
            redirect: redirectPath,
            globalLoading: true
        }
    };
}

export function fetchLocation(systemId, locationId) {
    return {
        type: "FETCH_LOCATION",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/locations/${locationId}`
            },
            globalLoading: true
        }
    };
}

export function changeLocationStatus(systemId, userId, status) {
    return {
        type: "UPDATE_LOCATION_STATUS",
        payload: {
            request: {
                method: 'put',
                url: `/api/v1/bo/${systemId}/locations/${userId}/status`,
                data: {
                    status: status
                }
            },
            successAction: {
                type: 'SHOW_NOTIFICATION',
                payload: {
                    variant: 'success',
                    message: status === 'ACTIVE' ? 'notifications.activate_location_success_message' : 'notifications.inactivate_location_success_message'
                }
            },
            globalLoading: true
        }
    };
}

export function exportLocationsToCsv(systemId, query) {
    return {
        type: "EXPORT_LOCATIONS_TO_CSV",
        payload: {
            request: {
                method: 'get',
                url: `/api/v1/bo/${systemId}/locations/export/csv?` + queryString.stringify(query)
            },
            query: query,
            globalLoading: true,
            successCallback: (response) => {
                let notification = {
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        variant: 'success',
                        message: 'notifications.export_data_sent_to_email_success_message'
                    }
                };
                return response.data ? null : notification
            }
        }
    };
}
