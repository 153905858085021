const buildIntegrationForm = (actions) => {
  return {
    title: {
      text: 'integration.title'
    },
    fields: [
      {
        name: 'code',
        label: 'integration.code_field',
        type: 'info',
        styles: {
          maxWidth: '500px',
          marginTop: '2em'
        }
      },
      {
        name: 'code_delimiter',
        type: 'delimiter',
        styles: {
          maxWidth: '500px'
        }
      },
      {
        name: 'clientId',
        label: 'integration.clientId_field',
        type: 'info',
        styles: {
          maxWidth: '500px'
        }
      },
      {
        name: 'clientId_delimiter',
        type: 'delimiter',
        styles: {
          maxWidth: '500px'
        }
      },
      {
        name: 'scopes',
        label: 'integration.scopes_field',
        type: 'info',
        styles: {
          maxWidth: '500px'
        }
      },
    ]
  }
}

export default buildIntegrationForm;
