import React from 'react';
import Paper from "@material-ui/core/Paper"
import PropTypes from "prop-types";

import './BsPanel.scss';

function BsPanel(props) {
    return (
        <div className="bs-container">
            <Paper elevation={3}
                   className={props.padding ? 'bs-container__paper bs-container__paper-passing' : 'bs-container__paper'}>
                {props.children}
            </Paper>
        </div>
    );
}

BsPanel.propTypes = {
    padding: PropTypes.bool
};

export default BsPanel;
