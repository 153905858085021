export function loadAllLabels(query) {
  return {
    type: "FETCH_ALL_LABELS",
    payload: {
      request: {
        method: 'get',
        url: '/api/v1/bo/systems/labels',
        params: query
      }
    }
  };
}

export function loadUserLabels() {
  return {
    type: "FETCH_USER_LABELS",
    payload: {
      request: {
        method: 'get',
        url: '/api/v1/bo/systems/labels',
        params: {
          page: 0,
          size: 100
        }
      }
    }
  };
}

export function loadLabel(id) {
  return {
    type: "LOAD_LABEL",
    payload: {
      request: {
        method: 'get',
        url: '/api/v1/bo/systems/labels/' + id
      },
      globalLoading: true
    }
  };
}

export function loadSelectedLabel(id) {
  return {
    type: "LOAD_SELECTED_LABEL",
    payload: {
      request: {
        method: 'get',
        url: '/api/v1/bo/systems/labels/' + id
      },
      globalLoading: true
    }
  };
}

export function createLabel(data) {
  return {
    type: "CREATE_LABEL",
    payload: {
      request: {
        method: 'post',
        url: '/api/v1/bo/systems/labels',
        data: data
      },
      successCallback: (response) => {
        return [
          {
            type: 'REDIRECT_TO',
            payload: '/global/label/' + response.data.id
          },
          loadUserLabels()
        ]
      },
      globalLoading: true
    }
  };
}

export function updateLabel(labelId, data) {
  return {
    type: "UPDATE_LABEL",
    payload: {
      request: {
        method: 'put',
        url: '/api/v1/bo/systems/labels/' + labelId,
        data: data
      },
      successCallback: (response) => {
        return [
          {
            type: 'REDIRECT_TO',
            payload: '/global/label/' + response.data.id
          },
          loadUserLabels()
        ]
      },
      globalLoading: true
    }
  };
}

export function loadLabelSystems(labelId, query) {
  return {
    type: "FETCH_LABELS_SYSTEMS",
    payload: {
      request: {
        method: 'get',
        url: '/api/v1/bo/systems/labels/' + labelId + '/systems',
        params: query
      }
    }
  };
}

export function loadLabelSystem(labelId, systemId) {
  return {
    type: "FETCH_LABELS_SYSTEM",
    payload: {
      request: {
        method: 'get',
        url: '/api/v1/bo/systems/labels/' + labelId + '/systems/' + systemId
      }
    }
  };
}

export function addLabelSystem(labelId, systemId) {
  return {
    type: "ADD_LABEL_SYSTEM",
    payload: {
      request: {
        method: 'post',
        url: '/api/v1/bo/systems/' + systemId + '/labels',
        data: {
          labelId: labelId
        }
      },
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: '/global/label/' + labelId + '/systems'
        }
      },
      globalLoading: true
    }
  };
}

export function deleteLabelSystem(labelId, systemId) {
  return {
    type: "DELETE_LABEL_SYSTEM",
    payload: {
      request: {
        method: 'delete',
        url: '/api/v1/bo/systems/' + systemId + '/labels/' + labelId
      },
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: '/global/label/' + labelId + '/systems'
        }
      },
      globalLoading: true
    }
  };
}

export function loadLabelAdmins(labelId, query) {
  return {
    type: "FETCH_LABELS_ADMINS",
    payload: {
      request: {
        method: 'get',
        url: '/api/v1/bo/systems/labels/' + labelId + '/users',
        params: query
      }
    }
  };
}

export function loadLabelAdmin(labelId, userId) {
  return {
    type: "FETCH_LABEL_ADMIN",
    payload: {
      request: {
        method: 'get',
        url: '/api/v1/bo/systems/labels/' + labelId + '/users/' + userId
      }
    }
  };
}

export function addLabelAdmin(labelId, userId) {
  return {
    type: "ADD_LABEL_ADMIN",
    payload: {
      request: {
        method: 'post',
        url: '/api/v1/bo/systems/labels/' + labelId + '/users',
        data: {
          userId: userId
        }
      },
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: '/global/label/' + labelId + '/admins'
        }
      },
      globalLoading: true
    }
  };
}

export function deleteLabelAdmin(labelId, userId) {
  return {
    type: "DELETE_LABEL_ADMIN",
    payload: {
      request: {
        method: 'delete',
        url: '/api/v1/bo/systems/labels/' + labelId + '/users/' + userId
      },
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: '/global/label/' + labelId + '/admins'
        }
      },
      globalLoading: true
    }
  };
}
