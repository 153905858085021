import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PagePermission from 'components/permission/PagePermission';
import StatisticsPanel from 'components/statistics-panel/StatisticsPanel';
import { withTranslation } from 'react-i18next';
import { loadGlobalStatistics } from 'core/actions/statistics-actions';

import RentalsIcon from 'assets/img/rentals.svg';
import UsersIcon from 'assets/img/users.svg';
import BicyclesIcon from 'assets/img/bicycles.svg';
import CustomerSupportIcon from 'assets/img/customer-support.svg';
import ProgressWrenchIcon from 'assets/img/progress-wrench.svg';
import WrenchClockIcon from 'assets/img/wrench-clock.svg';

import './GlobalStatistics.scss';

class GlobalStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.props.loadGlobalStatistics();
  }

  openActiveRentals() {
    this.props.history.push('/global/rentals?status=ACTIVE');
  }

  openRentalsHistory() {
    this.props.history.push('/global/rentals?status=FINISHED');
  }

  openActiveUsers() {
    this.props.history.push('/global/users?status=ACTIVE');
  }

  openActiveBicycles() {
    this.props.history.push('/global/bicycles?status=ACTIVE&status=IN_RENTAL');
  }

  openActiveBicyclesWithBatterySort() {
    this.props.history.push('/global/bicycles?direction=asc&sort=powerLevel');
  }

  openMaintenanceBicycles() {
    this.props.history.push('/global/bicycles?status=IN_MAINTENANCE');
  }

  openOpenProblems() {
    this.props.history.push(
      '/global/reported-problems?status=CREATED&status=ASSIGNED&status=IN_PROGRESS'
    );
  }

  openClosedProblems() {
    this.props.history.push(
      '/global/reported-problems?status=DONE&status=CLOSED'
    );
  }

  openActivePlans() {
    this.props.history.push('/global/maintenance/plans?status=ACTIVE');
  }

  openPlansToRenew() {
    this.props.history.push('/global/maintenance/plans?status=COMPLETED');
  }

  openScheduledVisits() {
    this.props.history.push('/global/maintenance/visits?status=SCHEDULED');
  }

  openFailedVisits() {
    this.props.history.push('/global/maintenance/visits?status=FAILED');
  }

  render() {
    if (this.props.statistics) {
      return (
        <Grid container className='global-statistics'>
          <PagePermission anonymous={false} />
          <StatisticsPanel
            data={{
              title: {
                icon: <RentalsIcon />,
                label: 'dashboard.rentals_title',
              },
              first: {
                label: 'dashboard.active_rentals',
                value: this.props.statistics.activeRentals,
                onClick: this.openActiveRentals.bind(this),
              },
              second: {
                label: 'dashboard.completed_rentals',
                value: this.props.statistics.completedRentals,
                onClick: this.openRentalsHistory.bind(this),
              },
            }}
          />
          <StatisticsPanel
            data={{
              title: {
                icon: <UsersIcon />,
                label: 'dashboard.users_title',
              },
              first: {
                label: 'dashboard.active_users',
                value: this.props.statistics.activeUsers,
                onClick: this.openActiveUsers.bind(this),
              },
            }}
          />
          <StatisticsPanel
            data={{
              title: {
                icon: <BicyclesIcon />,
                label: 'dashboard.bicycles_title',
              },
              first: {
                label: 'dashboard.active_bicycles',
                value: this.props.statistics.activeBicycles,
                onClick: this.openActiveBicycles.bind(this),
                warning:
                  this.props.t('dashboard.critical_battery') +
                  this.props.statistics.locksWithCriticalBattery,
                onWarningClick:
                  this.openActiveBicyclesWithBatterySort.bind(this),
              },
              second: {
                label: 'dashboard.maintenance_bicycles',
                value: this.props.statistics.maintenanceBicycles,
                onClick: this.openMaintenanceBicycles.bind(this),
              },
            }}
          />
          <StatisticsPanel
            data={{
              title: {
                icon: <CustomerSupportIcon />,
                label: 'dashboard.customer_support_title',
              },
              first: {
                label: 'dashboard.open_problems',
                value: this.props.statistics.openProblems,
                onClick: this.openOpenProblems.bind(this),
                valueStyle: {
                  color: '#f44336',
                },
              },
              second: {
                label: 'dashboard.closed_problems',
                value: this.props.statistics.closedProblems,
                onClick: this.openClosedProblems.bind(this),
              },
            }}
          />
          <StatisticsPanel
            data={{
              title: {
                icon: <WrenchClockIcon />,
                label: 'global_statistics.maintenance_plans_title',
              },
              first: {
                label: 'global_statistics.active_maintenance_plans',
                value: this.props.statistics.activePlans,
                onClick: this.openActivePlans.bind(this),
              },
              second: {
                label: 'global_statistics.maintenance_plans_to_renew',
                value: this.props.statistics.plansToRenew,
                onClick: this.openPlansToRenew.bind(this),
              },
            }}
          />
          <StatisticsPanel
            data={{
              title: {
                icon: <ProgressWrenchIcon />,
                label: 'global_statistics.maintenance_visits_title',
              },
              first: {
                label: 'global_statistics.scheduled_visits',
                value: this.props.statistics.scheduledVisits,
                onClick: this.openScheduledVisits.bind(this),
              },
              second: {
                label: 'global_statistics.failed_visits',
                value: this.props.statistics.failedVisits,
                onClick: this.openFailedVisits.bind(this),
                valueStyle: {
                  color: '#f44336',
                },
              },
            }}
          />
        </Grid>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    statistics: state.statistics.statistics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadGlobalStatistics: () => {
      dispatch(loadGlobalStatistics());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(GlobalStatistics));
