import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import store from 'core/store/store'
import App from './app/App'
import {SnackbarProvider} from "notistack";
import i18n from 'core/utils/i18n';
import "smartbanner.js/dist/smartbanner.js";
import "smartbanner.js/dist/smartbanner.min.css";
import './index.scss'

ReactDOM.render(
    <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
            <SnackbarProvider maxSnack={3} classes={{containerRoot: 'snackbars-container'}}>
                <App/>
            </SnackbarProvider>
        </Provider>
    </Suspense>,
    document.getElementById("app"));
