import React from "react";
import {connect} from "react-redux";
import {fetchMaintenanceVisitAdminInfo} from "core/actions/maintenance-plan-actions";
import MaintenanceVisitInfoForm from "../../../../../global/maintenance/visits/info/form/MaintenanceVisitInfoForm";

class FleetMaintenanceVisit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.props.fetchMaintenanceVisitAdminInfo(this.props.match.params.systemId, this.props.match.params.visitId);
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/fleet';
  }

  onHandleShowErrorModel() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/edit-settings/visit/' + visit.id + '/error')
  }

  render() {
    if (this.props.currentMaintenanceVisit) {
      return (
          <MaintenanceVisitInfoForm visit={this.props.currentMaintenanceVisit}
                                    history={this.props.history}
                                    location={this.props.location}
                                    backUrl={this.backUrl}
                                    onHandleShowErrorModel={this.onHandleShowErrorModel.bind(this)}/>
      )
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentMaintenanceVisit: state.maintenancePlan.currentMaintenanceVisit,
    loading: state.maintenancePlan.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMaintenanceVisitAdminInfo: (systemId, visitId) => {
      dispatch(fetchMaintenanceVisitAdminInfo(systemId, visitId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetMaintenanceVisit);
