import React from "react";
import RentalPaymentForm from "../../rates/info/tabs/payments/form/RentalPaymentForm";

class RentalRatePaymentInfo extends React.Component {

  constructor(props) {
    super(props);
    this.backUrl = '/system/' + this.props.match.params.systemId + '/rental-rates/payments'
  }

  render() {
    return (
        <RentalPaymentForm backUrl={this.backUrl}
                           history={this.props.history}
                           location={this.props.location}
                           match={this.props.match}/>
    );
  }
}

export default RentalRatePaymentInfo;
