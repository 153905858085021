import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import {fetchClientUserInfo} from "core/actions/client-user-actions";
import CLIENT_USER_INFO_FORM from "./client-user-info-form";

class ClientUserInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.props.fetchClientUserInfo(this.props.match.params.userId);
  }

  render() {
    if (this.props.currentUser) {
      const formConfig = {
        ...CLIENT_USER_INFO_FORM,
        title: {
          back: {
            url: '/global/users',
            history: this.props.history,
            location: this.props.location
          }
        }
      };

      return (
          <div className="bs-form-container">
            <BsForm
                config={formConfig}
                formData={this.props.currentUser}
                disabled={true}/>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.clientUser.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClientUserInfo: (userId) => {
      dispatch(fetchClientUserInfo(userId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientUserInfoTab);
