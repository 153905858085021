import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import buildAddPasswordForm from "./add-password-form";
import {createSystemPassword} from "core/actions/password-actions";
import Moment from "moment";
import {showNotification} from "core/actions/app-actions";

class AddPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        unlimited: true
      }
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/edit-settings/passwords';
  }

  componentDidMount() {
    BsForm.addValidationRule('passwordFormat', (value) => {
      return !!value && value.length >= 4 && value.length <= 16;
    });
    BsForm.addValidationRule('passwordDateValidity', (value) => {
      let from = Moment(this.state.fields.from).toDate().getTime();
      let to = Moment(this.state.fields.to).toDate().getTime();
      return from <= to;
    });
  }

  componentWillUnmount() {
    BsForm.removeValidationRule('passwordFormat');
    BsForm.removeValidationRule('passwordDateValidity');
  }

  handleSubmit(fields, e) {
    let from = Moment(this.state.fields.from).toDate().getTime();
    let to = Moment(this.state.fields.to).toDate().getTime();
    if (from > to) {
      this.props.showNotification('error', 'errors.invalid_password_dates');
      return;
    }

    this.props.createPassword(this.props.match.params.systemId, {
      password: fields.password,
      from: Moment(fields.from).format('YYYY-MM-DD'),
      to: Moment(fields.to).format('YYYY-MM-DD'),
      unlimited: fields.unlimited,
      limit: fields.limit
    });
  }

  handleChange(fields) {
    this.setState({
      ...this.state,
      fields: fields
    });
  }

  render() {
    const formConfig = buildAddPasswordForm(this.state.fields.unlimited);
    return (
        <BsFormPanel>
          <PagePermission anonymous={false} permission={ADMIN}/>
          <BsForm
              config={formConfig}
              onHandleSubmit={this.handleSubmit.bind(this)}
              onHandleChange={this.handleChange.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }/>
        </BsFormPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPassword: (systemId, data) => {
      dispatch(createSystemPassword(systemId, data));
    },
    showNotification: (variant, error) => {
      dispatch(showNotification(variant, error));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPassword);
