import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import BsFormPanel from "components/form/BsFormPanel";
import SUBSCRIPTION_SYSTEM_INFO_FORM from "./subscription-system-info-form";
import SubscriptionSystemInfoActions from "./SubscriptionSystemInfoActions";
import {fetchSystemSubscription} from "core/actions/system-subscription-actions";
import SubscriptionSystemTransactionTable from "./SubscriptionSystemTransactionTable";

import './subscription-system-info.scss';

class SubscriptionSystemInfo extends React.Component {

  constructor(props) {
    super(props);
    this.backUrl = '/global/subscriptions/systems'
    this.props.fetchSystemSubscription(this.props.match.params.systemId);
  }

  onHandleEdit() {
    this.props.history.push('/global/system-subscription/' + this.props.match.params.systemId + '/edit')
  }

  onRefresh() {
    this.props.fetchSystemSubscription(this.props.match.params.systemId);
  }

  render() {
    if (this.props.currentSubscription) {
      const formConfig = {
        ...SUBSCRIPTION_SYSTEM_INFO_FORM,
        title: {
          back: {
            url: this.backUrl,
            history: this.props.history,
            location: this.props.location
          },
          text: this.props.currentSubscription.system.name,
          actions: <SubscriptionSystemInfoActions onHandleEdit={this.onHandleEdit.bind(this)}/>
        },
        fields: [
            ...SUBSCRIPTION_SYSTEM_INFO_FORM.fields
        ]
      };

      if (formConfig.fields.length < 14) {
        formConfig.fields.push({
          name: 'transactions',
          label: 'subscription_system_info.transactions_field',
          type: 'any',
          value: <SubscriptionSystemTransactionTable systemId={this.props.match.params.systemId}
                                                     onRefresh={this.onRefresh.bind(this)}
                                                     location={this.props.location}
                                                     history={this.props.history}/>,
          className: "transactions"
        });
      }

      return (
          <div className="subscription-system-info">
            <BsFormPanel size="l">
              <BsForm
                  config={formConfig}
                  formData={this.props.currentSubscription}
                  disabled={true}/>
            </BsFormPanel>
          </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentSubscription: state.systemSubscription.currentSubscription
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemSubscription: (systemId) => {
      dispatch(fetchSystemSubscription(systemId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSystemInfo);
