import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanel from "components/panel/BsPanel";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import LABEL_CUSTOMER_SUPPORT_TABLE from "./label-customer-support-table";
import {exportLabelMaintenanceToCsv, loadLabelMaintenanceIssue,} from "core/actions/maintenance-actions";
import LabelCustomerSupportFilters from "./LabelCustomerSupportFilters";
import Button from "@material-ui/core/Button";

const queryString = require('query-string');

class LabelCustomerSupport extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        status: query.status != null ? (typeof query.status === "string" ? [query.status] : query.status) : [],
        systemName: query.systemName != null ? query.systemName : ''
      }
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.status = this.state.fields.status;
    params.systemName = this.state.fields.systemName;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.status;
    delete params.systemName;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        status: [],
        systemName: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadLabelMaintenanceIssue(this.props.match.params.labelId, this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/label/' + this.props.match.params.labelId + '/reported-problem-info/' + row.id)
  }

  export = () => {
    const query = queryString.parse(this.props.location.search);
    this.props.exportLabelMaintenanceToCsv(this.props.match.params.labelId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }

    if (query.status) {
      query.status = query.status instanceof Array ? query.status.join(',') : query.status
    }

    if (query.systemName) {
      query['system.name'] = query.systemName;
    }

    return query
  }

  render() {
    return (
        <BsPanel padding={true}>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle action={
              <Button variant="outlined" type="button" onClick={this.export}>
                <Trans i18nKey="general.export"/>
              </Button>
            }>
              <Trans i18nKey="customer_support.title"/>
            </BsPanelTitle>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <LabelCustomerSupportFilters fields={this.state.fields}
                                         onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={LABEL_CUSTOMER_SUPPORT_TABLE}
                       content={this.props.issues}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    issues: state.maintenance.labelIssues,
    loading: state.clientUser.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadLabelMaintenanceIssue: (labelId, query) => {
      dispatch(loadLabelMaintenanceIssue(labelId, query));
    },
    exportLabelMaintenanceToCsv: (labelId, query) => {
      dispatch(exportLabelMaintenanceToCsv(labelId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelCustomerSupport);
