const queryString = require('query-string');

export function loadMaintenancePlans(query) {
  return {
    type: "FETCH_MAINTENANCE_PLANS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/maintenance-plans?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function exportMaintenancePlansToCsv(query) {
  return {
    type: "EXPORT_MAINTENANCE_PLANS_TO_CSV",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/maintenance-plans/export/csv?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: true,
      successCallback: (response) => {
        let notification = {
          type: 'SHOW_NOTIFICATION',
          payload: {
            variant: 'success',
            message: 'notifications.export_data_sent_to_email_success_message'
          }
        };
        return response.data ? null : notification
      }
    }
  };
}

export function createMaintenancePlan(data) {
  return {
    type: "CREATE_MAINTENANCE_PLAN",
    payload: {
      request: {
        method: 'post',
        url: `/api/v1/bo/maintenance-plans`,
        data: data
      },
      globalLoading: true,
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: `/global/maintenance-plan/${response.data.id}/info`
        }
      }
    }
  };
}

export function renewMaintenancePlan(planId, data) {
  return {
    type: "RENEW_MAINTENANCE_PLAN",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/maintenance-plans/${planId}/renew`,
        data: data
      },
      globalLoading: true,
      successCallback: (response) => {
        return {
          type: 'REDIRECT_TO',
          payload: `/global/maintenance-plan/${response.data.id}/info`
        }
      }
    }
  };
}

export function fetchMaintenancePlan(planId) {
  return {
    type: "FETCH_MAINTENANCE_PLAN",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/maintenance-plans/${planId}`
      },
      globalLoading: true
    }
  };
}

export function fetchMaintenanceVisit(planId, number) {
  return {
    type: "FETCH_MAINTENANCE_VISIT",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/maintenance-plans/${planId}/visits/${number}`
      },
      globalLoading: true
    }
  };
}

export function updateMaintenancePlanMechanic(planId, data) {
  return {
    type: "UPDATE_MAINTENANCE_PLAN_MECHANIC",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/maintenance-plans/${planId}/mechanic`,
        data: data
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_maintenance_plan_success_message'
        }
      },
    }
  };
}

export function updateMaintenancePlanService(planId, data) {
  return {
    type: "UPDATE_MAINTENANCE_PLAN_SERVICE",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/maintenance-plans/${planId}/service`,
        data: data
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_maintenance_plan_success_message'
        }
      },
    }
  };
}

export function cancelMaintenancePlan(planId) {
  return {
    type: "UPDATE_MAINTENANCE_PLAN_STATUS",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/maintenance-plans/${planId}/cancel`,
        data: {}
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_maintenance_plan_success_message'
        }
      },
    }
  };
}

export function updateMaintenanceVisitMechanic(planId, number, data) {
  return {
    type: "UPDATE_MAINTENANCE_VISIT_MECHANIC",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/maintenance-plans/${planId}/visits/${number}/mechanic`,
        data: data
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_maintenance_visit_success_message'
        }
      },
    }
  };
}

export function updateMaintenanceVisitService(planId, number, data) {
  return {
    type: "UPDATE_MAINTENANCE_VISIT_SERVICE",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/maintenance-plans/${planId}/visits/${number}/service`,
        data: data
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_maintenance_visit_success_message'
        }
      },
    }
  };
}

export function updateMaintenanceVisitTime(planId, number, data) {
  return {
    type: "UPDATE_MAINTENANCE_VISIT_TIME",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/maintenance-plans/${planId}/visits/${number}/time`,
        data: data
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_maintenance_visit_success_message'
        }
      },
    }
  };
}

export function refreshMaintenanceVisit(planId, number) {
  return {
    type: "REFRESH_MAINTENANCE_VISIT",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/maintenance-plans/${planId}/visits/${number}/refresh`,
        data: {}
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.refresh_maintenance_visit_success_message'
        }
      },
    }
  };
}

export function updateMaintenanceExternalVisit(planId, number, data) {
  return {
    type: "UPDATE_MAINTENANCE_VISIT",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/maintenance-plans/${planId}/visits/${number}/external-visits`,
        data: data
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.update_maintenance_visit_success_message'
        }
      },
    }
  };
}

export function cancelMaintenanceVisit(planId, number) {
  return {
    type: "REFRESH_MAINTENANCE_VISIT",
    payload: {
      request: {
        method: 'put',
        url: `/api/v1/bo/maintenance-plans/${planId}/visits/${number}/cancel`,
        data: {}
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.cancel_maintenance_visit_success_message'
        }
      },
    }
  };
}

export function loadMaintenanceVisits(query) {
  return {
    type: "FETCH_MAINTENANCE_VISITS",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/maintenance-visits?` + queryString.stringify(query)
      },
      query: query,
      globalLoading: false
    }
  };
}

export function fetchMaintenanceVisitInfo(id) {
  return {
    type: "FETCH_MAINTENANCE_VISIT_INFO",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/maintenance-visits/${id}`
      },
      globalLoading: true
    }
  };
}

export function fetchMaintenanceVisitAdminInfo(systemId, id) {
  return {
    type: "FETCH_MAINTENANCE_VISIT_INFO",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/maintenance-visits/${id}`
      },
      globalLoading: true
    }
  };
}

export function fetchActiveMaintenancePlan(systemId) {
  return {
    type: "FETCH_ACTIVE_MAINTENANCE_PLAN",
    payload: {
      request: {
        method: 'get',
        url: `/api/v1/bo/${systemId}/maintenance-plans`
      },
      globalLoading: true
    }
  };
}

export function requestMaintenancePlan(systemId) {
  return {
    type: "REQUEST_MAINTENANCE_PLAN",
    payload: {
      request: {
        method: 'post',
        url: `/api/v1/bo/${systemId}/maintenance-plans/request`
      },
      globalLoading: true,
      successAction: {
        type: 'SHOW_NOTIFICATION',
        payload: {
          variant: 'success',
          message: 'notifications.request_maintenance_plan_success_message'
        }
      },
    }
  };
}
