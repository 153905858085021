import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import PagePermission from "components/permission/PagePermission";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BsTabPanel from "components/panel/BsTabPanel";
import Permitted from "components/permission/Permitted";
import BICYCLE_PROBLEMS_TABLE from "./bicycle-problems-table";
import {MANAGER} from "core/utils/permissions";
import {exportMaintenanceToCsv, loadMaintenanceIssue} from "core/actions/maintenance-actions";
import Button from "@material-ui/core/Button";

const queryString = require('query-string');

class BicycleProblemsTab extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  onLoadData = (query) => {
    this.props.loadMaintenanceIssue(this.props.match.params.systemId, this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/bicycle-info/' +
        this.props.match.params.bicycleId + '/problem/' + row.id)
  }

  export = () => {
    const query = queryString.parse(this.props.location.search);
    this.props.exportMaintenanceToCsv(this.props.match.params.systemId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    query['bike.id'] = this.props.match.params.bicycleId

    return query
  }

  render() {
    return (
        <BsTabPanel>
          <PagePermission anonymous={false} permission={MANAGER}/>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle action={
              <Button variant="outlined" type="button" onClick={this.export}>
                <Trans i18nKey="general.export"/>
              </Button>
            }/>
          </BsPanelHeader>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={BICYCLE_PROBLEMS_TABLE}
                       content={this.props.issues}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsTabPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    issues: state.maintenance.issues,
    loading: state.clientUser.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadMaintenanceIssue: (systemId, query) => {
      dispatch(loadMaintenanceIssue(systemId, query));
    },
    exportMaintenanceToCsv: (systemId, query) => {
      dispatch(exportMaintenanceToCsv(systemId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BicycleProblemsTab);
