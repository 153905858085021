const FORGOT_PASSWORD_FORM = {
    title: {
        text: 'forgot_password.title'
    },
    submit: {
        label: 'forgot_password.submit_btn'
    },
    fields: [
        {
            name: 'notes',
            type: 'notes',
            label: 'forgot_password.desc'
        },
        {
            name: 'email',
            label: 'forgot_password.email_field',
            type: 'text',
            validators: ['required', 'isEmail'],
            errors: ['errors.required_email', 'errors.invalid_email'],
            defaultValue: ''
        }
    ]
}

export default FORGOT_PASSWORD_FORM;
