import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Permitted from "components/permission/Permitted";
import {ADMIN} from 'core/utils/permissions'
import SelectIcon from 'assets/img/select-icon.svg'
import {useTranslation} from "react-i18next";

const PasswordInfoActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    props.onHandleEdit();
  }

  const handleChangeStatus = (status) => {
    handleClose();
    props.onHandleChangeStatus(status);
  }

  if (!props.password || props.password.status === 'INACTIVE' || props.password.status === 'USED') {
    return null;
  }

  let menuItems = [];
  if (props.password.status === 'CREATED') {
    menuItems.push(<MenuItem key="active" onClick={() => handleChangeStatus('ACTIVE')}>
      {t('general.active')}</MenuItem>)
  }
  if (props.password.status === 'ACTIVE') {
    menuItems.push(<MenuItem key="inactive" onClick={() => handleChangeStatus('INACTIVE')}>
      {t('general.inactive')}</MenuItem>)
  }
  //menuItems.push(<MenuItem key="edit" onClick={() => handleEdit()}>{t('general.edit')}</MenuItem>)

  return (
      <Permitted for={ADMIN}>
        <Button aria-controls="admin-info-menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                color="primary"
                style={{
                  marginBottom: '1em'
                }}>
          {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
        </Button>
        <Menu
            id="admin-info-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}>
          {menuItems}
        </Menu>
      </Permitted>
  )
}

export default PasswordInfoActions
