import React from 'react';
import {connect} from "react-redux";
import {Trans, withTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import {ADMIN} from "core/utils/permissions";
import PagePermission from "components/permission/PagePermission";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import getRentalRatesTable from "./rental-rates-table";
import PlusIcon from "assets/img/plus.svg";
import {loadRentalRates} from "core/actions/rental-rate-actions";
import BsTabPanel from "components/panel/BsTabPanel";

const queryString = require('query-string');

class RentalRatesTab extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction
    };

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  onLoadData = (query) => {
    this.props.loadRentalRates(this.props.match.params.systemId, this.prepareQuery(query));
  }

  addRentalRate = () => {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/create-rental-rate')
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }

    return query
  }

  onRowClick = (row) => {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/rental-rate/' + row.id)
  }

  render() {
    const tableConfig = getRentalRatesTable(this.props.t);

    return (
        <BsTabPanel>
          <PagePermission anonymous={false} permission={ADMIN}/>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle action={
              <Button variant="contained" color="primary" type="submit" onClick={this.addRentalRate}>
                <PlusIcon/>&nbsp;<Trans i18nKey="general.add"/>
              </Button>
            }>
            </BsPanelTitle>
          </BsPanelHeader>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={tableConfig}
                       content={this.props.rentalRates}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>
        </BsTabPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rentalRates: state.rentalRate.rentalRates,
    loading: state.rentalRate.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRentalRates: (systemId, query) => {
      dispatch(loadRentalRates(systemId, query));
    }
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(RentalRatesTab));
