import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsFormNotes = (props) => {
    const {t} = useTranslation();

    return (
        <div>
            {t(props.label)}
        </div>
    )
}

BsFormNotes.propTypes = {
    label: PropTypes.string
};

export default BsFormNotes;