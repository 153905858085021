import {priceToString} from "core/utils/priceUtils";

const getRentalRatesTable = (t) => {
  return {
    columns: [
      {
        label: 'rental_rates.name_header',
        field: 'name',
        ordering: true
      },
      {
        label: 'rental_rates.price_header',
        field: 'price',
        ordering: true,
        getValue(raw) {
          return priceToString(raw.price, raw.currency);
        }
      },
      {
        label: 'rental_rates.rental_duration_header',
        field: 'rentalDuration',
        getValue: (raw) => {
          return raw.rentalDuration.amount + ' ' + t('duration_unit.' + raw.rentalDuration.unit)
        },
        ordering: false
      },
      /*{
        label: 'rental_rates.refund_policy_header',
        field: 'refundPolicy',
        getValue: (raw) => {
          return raw.refundPolicy.amount + ' ' + t('duration_unit.' + raw.refundPolicy.unit)
        },
        ordering: false
      },*/
      /*{
        label: 'rental_rates.grace_period_header',
        field: 'gracePeriod',
        getValue: (raw) => {
          return raw.gracePeriod.amount + ' ' + t('duration_unit.' + raw.gracePeriod.unit)
        },
        ordering: false
      },*/
      {
        label: 'rental_rates.late_fee_amount_header',
        field: 'lateFeeAmount',
        getValue: (raw) => {
          return priceToString(raw.lateFeeAmount, raw.lateFeeCurrency);
        },
        ordering: false
      },
      {
        label: 'rental_rates.type_header',
        field: 'type',
        translate: true,
        getValue(raw) {
          return 'rental_rate_type.' + raw.type;
        }
      }
    ]
  }
}

export default getRentalRatesTable;
